import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  Fragment,
  useMemo,
  useRef,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24
import axios from "axios";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import { StyleContext } from "../contexts/StyleContext";
import { DataContext } from "../contexts/DataContext";

import dateStringer from "../tools/dateStringer";
import serverURL from "../serverURL";

import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import navyClose from "../img/general/navyClose.svg";
import whiteBin from "../img/general/shiftDelete 2.svg";
import tsIcon from "../img/general/ts.svg";
import rightTri from "../img/general/rightTri.svg";
import greyCross from "../img/general/greyCross.svg";
import whiteAddThin from "../img/general/whiteAddThin.svg";
import timesheetsDayLine from "../img/general/timesheetsDayLine.svg";
import power from "../img/general/schedLimitPowerOff.svg";
import edit from "../img/general/edit.svg";
import pin from "../img/general/pin.svg";
import popup from "../img/general/popup.svg";

import "../styles/calendar.css";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const TimesheetModal = ({ setLaunchTimesheet }) => {
  const {
    showTimesheet,
    setShowTimesheet,
    setIndicate,
    setShowViewTimesheet,
    updateUserTimesheets,
    setUpdateUserTimesheets,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
  } = useContext(DataContext);
  const { mob, mobModal } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      showTimesheet,
      setShowTimesheet,
      setIndicate,
      setShowViewTimesheet,
      updateUserTimesheets,
      setUpdateUserTimesheets,
      mob,
      mobModal,
      modalOpen,
      setModalOpen,
      device,
    }),
    [
      showTimesheet, //
      setShowTimesheet, //
      setIndicate, //
      setShowViewTimesheet, //
      updateUserTimesheets, //
      setUpdateUserTimesheets, //
      mob, //
      mobModal, //
      modalOpen,
      setModalOpen,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [tooFarBack, setTooFarBack] = useState(0);

  let [locations, setLocations] = useState([]);

  let [teams, setTeams] = useState([]);
  let [selectedTeam, setSelectedTeam] = useState("");
  let [startDateInput, setStartDateInput] = useState(
    dateStringer.createDateInputFromDateString(
      dateStringer.createStringFromTimestamp(new Date().getTime())
    )
  );

  let [saveable, setSaveable] = useState(false);

  let [inputState, setInputState] = useState([]);
  // [{ ds, start, end, brk }, {ds, start1, end1, brk1}]

  let [activeDs, setActiveDs] = useState([]);

  let [addHoursToDs, setAddHoursToDs] = useState([]); // array of ds
  let [addHoursToDs1, setAddHoursToDs1] = useState([]); // array of ds
  let [addHoursToDs2, setAddHoursToDs2] = useState([]); // array of ds
  let [addHoursToDs3, setAddHoursToDs3] = useState([]); // array of ds
  let [addHoursToDs4, setAddHoursToDs4] = useState([]); // array of ds
  let [submitted, setSubmitted] = useState(false);

  let [openTimesheetsTeamNames, setOpenTimesheetsTeamNames] = useState([]);
  let [availableTags, setAvailableTags] = useState([]);

  let [otherTimesheets, setOtherTimesheets] = useState([]);
  let [showOtherTimesheets, setShowOtherTimesheets] = useState(false);

  useEffect(() => {
    if (!availableTags[0] && selectedTeam) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/get-available-tags`,
          {
            teamID: selectedTeam,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setAvailableTags(response.data.tags);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, [selectedTeam]);

  let [overlappingItem, setOverlappingItem] = useState({
    show: false,
    item: {},
  });

  let getLocation = (locationID) => {
    if (!locationID) {
      return "No location";
    } else {
      let locFilt = locations.filter((x) => {
        return x.locationID === locationID;
      });
      if (locFilt[0]) {
        return locFilt[0].locationName;
      } else {
        return "No location";
      }
    }
  };

  let [sureDelete, setSureDelete] = useState(false);
  let [sureSubmit, setSureSubmit] = useState(false);
  let [sureExit, setSureExit] = useState(false);
  let [noTeams, setNoTeams] = useState(false);
  let [cantFindTeam, setCantFindTeam] = useState([]);
  let [savedButNotSubmitted, setSavedButNotSubmitted] = useState("");
  let [startDs, setStartDs] = useState("");
  let [endDs, setEndDs] = useState("");

  useEffect(() => {
    memoVals.setShowViewTimesheet((x) => {
      return { show: false };
    });
  }, []);

  let closeModal = () => {
    if (memoVals.showTimesheet.openViewTimesheetAfterClose) {
      memoVals.setShowViewTimesheet((x) => {
        return {
          timesheetID:
            typeof memoVals.showTimesheet === "object"
              ? memoVals.showTimesheet.timesheetID
              : memoVals.showTimesheet,
          show: true,
        };
      });
      memoVals.setShowTimesheet((x) => false);
    } else {
      memoVals.setShowTimesheet((x) => false);
    }
  };
  let generateTeams = teams.map((team) => {
    return (
      <option key={team.teamID} value={team.teamID}>
        {selectedTeam === team.teamID
          ? dateStringer.shorten(team.teamName, 22)
          : team.teamName}
      </option>
    );
  });

  useEffect(() => {
    // cleaned not needed
    let startDateTs = dateStringer.createTimestampFromString(
      dateStringer.createStringFromDateInput(startDateInput)
    );
    let today00Ds = `${dateStringer
      .createStringFromTimestamp(new Date().getTime())
      .substr(0, 11)}H00M00`;

    if (startDateTs > dateStringer.createTimestampFromString(today00Ds)) {
      setStartDateInput(dateStringer.createDateInputFromDateString(today00Ds));
    }
  }, [startDateInput]);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!teams[0] && typeof memoVals.showTimesheet !== "object") {
      axios
        .post(
          `${serverURL}/get-user-teams`,
          {
            loadInactiveTimesheetsOnly: dateStringer.createStringFromTimestamp(
              new Date().getTime()
            ),
            getUserTeamsOnly: 1,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (!response.data.partOfTeams[0]) {
              setNoTeams(response.data.userTeams);
            } else {
              setTeams(response.data.partOfTeams);
              setOpenTimesheetsTeamNames(response.data.openTimesheetsTeamNames);
              let doesPTeamExist = false;
              response.data.partOfTeams.forEach((t) => {
                if (t.teamID === response.data.primaryTeamID) {
                  doesPTeamExist = true;
                }
              });
              setSelectedTeam(
                doesPTeamExist
                  ? response.data.primaryTeamID
                  : response.data.partOfTeams[0]
                  ? response.data.partOfTeams[0].teamID
                  : ""
              );
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.showTimesheet]);

  useEffect(() => {
    // clean not needed
    if (
      typeof memoVals.showTimesheet === "object" &&
      memoVals.showTimesheet.options
    ) {
      // setTimeout(() => {
      setLoading(false);
      // }, 3000);
    }
  }, [memoVals.showTimesheet]);

  let mobileWidthBreak = 820;
  mobileWidthBreak = 99999999999999;
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    // cleaned
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [dsArr, setDsArr] = useState([]);

  let [loadedTeamName, setLoadedTeamName] = useState("");
  try {
    const addShiftDateInput = document.getElementById("497297522");

    if (addShiftDateInput && !memoVals.mob) {
      addShiftDateInput.addEventListener("focus", function (evt) {
        try {
          if (this.getAttribute("type") === "date") {
            if (this.showPicker) {
              this.showPicker();
            }
          }
        } catch (error) {
          console.error(
            "Error in addShiftDateInput focus event listener:",
            error
          );
        }
      });
    }
  } catch (error) {
    console.error("Error in setting up addShiftDateInput:", error);
  }

  // let [, setLoadedTeamName] = useState("");

  let [loading, setLoading] = useState(true);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={popup}
        alt="Navy Close"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={pin}
        alt="White Bin"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={whiteBin}
        alt="White Bin"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={tsIcon}
        alt="TS Icon"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={rightTri}
        alt="Right Triangle"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={greyCross}
        alt="Grey Cross"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={whiteAddThin}
        alt="White Add Thin"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={timesheetsDayLine}
        alt="Timesheets Day Line"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={power}
        alt="Power"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
      <img
        src={edit}
        alt="Power"
        className=""
        // onLoad={() => {
        //   setImagesLoaded(imagesLoaded + 1);
        // }}
      />
    </div>
  );

  let [amendShiftID, setAmendShiftID] = useState("");

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    if (
      typeof memoVals.showTimesheet === "object" &&
      memoVals.showTimesheet.timesheetID
    ) {
      setSavedButNotSubmitted(memoVals.showTimesheet.timesheetID);
      axios
        .post(
          `${serverURL}/load-active-timesheet`,
          {
            timesheetID: memoVals.showTimesheet.timesheetID,
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message.includes("does not exist")) {
            closeModal();
          }
          if (response.data.message === "success") {
            setLoadedTeamName(response.data.teamName);
            setSelectedTeam(response.data.timesheet.teamID);
            setStartDateInput(
              dateStringer.createDateInputFromDateString(
                response.data.timesheet.startDs
              )
            );
            setSaveable(false);
            setStartDs(response.data.timesheet.startDs);
            setEndDs(response.data.timesheet.endDs);

            let activeDatestrings = [];
            response.data.timesheet.items.forEach((dsObj) => {
              activeDatestrings.push(dsObj.ds);
            });
            // setActiveDs(activeDatestrings);
            setSubmitted(response.data.timesheet.submitted);
            let loadedSchedAmends = response.data.timesheet.schedAmends;
            axios
              .post(
                `${serverURL}/timesheet-date-change`,
                {
                  teamID: response.data.timesheet.teamID,
                  ds: response.data.timesheet.startDs,
                  cancelToken: cancelSource2.token,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((resp) => {
                if (resp.data.message === "too far back") {
                  setTooFarBack(resp.data.daysLimit);
                }
                if (resp.data.message === "overlap") {
                  setOverlappingItem({
                    show: true,
                    startDs: resp.data.overlapItem.startDs,
                    endDs: resp.data.overlapItem.endDs,
                    timesheetID: resp.data.overlapItem.timesheetID,
                    teamName: resp.data.overlapItem.teamName,
                    submitted: resp.data.overlapItem.submitted,
                    approved: resp.data.overlapItem.approved,
                    declined: resp.data.overlapItem.declined,
                  });
                }
                if (resp.data.message === "success") {
                  setDsArr(resp.data.output);
                  // harry

                  setTimeout(() => {
                    if (loadedSchedAmends && Array.isArray(loadedSchedAmends)) {
                      // - - - - - - - - -

                      let newArr = [];
                      resp.data.output.forEach((day) => {
                        if (
                          day.ds &&
                          day.scheduled &&
                          Array.isArray(day.scheduled)
                        ) {
                          day.scheduled.forEach((x) => {
                            // is it in loadedSchedAmends
                            let loadedFilt = loadedSchedAmends.filter((l) => {
                              return (x.shiftID || x.tilID) === l.shiftID;
                            });
                            if (loadedFilt[0]) {
                              newArr.push(loadedFilt[0]);
                            } else {
                              newArr.push({
                                editing: false,
                                shiftID: x.shiftID || x.tilID,
                                type: x.tilType || "shift",
                                start: `${x.startDs.substr(
                                  12,
                                  2
                                )}:${x.startDs.substr(15, 2)}`,
                                end: `${x.endDs.substr(12, 2)}:${x.endDs.substr(
                                  15,
                                  2
                                )}`,
                                brkMins: x.brkMins,
                                tags: x.tags || [],
                                locationID: x.locationID,
                              });
                            }
                          });
                        }
                      });

                      // let finalNewArr = [];
                      // newArr.forEach((x) => {});
                      setSchedAmends(newArr);

                      //- - - - - - - - -
                      // setSchedAmends(loadedSchedAmends);
                    }
                  }, 10);

                  setActiveDs(resp.data.output.map((x) => x.ds));

                  let newInputState = [];
                  let addHoursDsArr = [];
                  let addHoursDsArr2 = [];
                  let addHoursDsArr3 = [];
                  let addHoursDsArr4 = [];
                  let addHoursDsArr5 = [];
                  setLocations(resp.data.locations);
                  let items = response.data.timesheet.items || [];
                  resp.data.output
                    .map((x) => x.ds)
                    .forEach((ds) => {
                      let itemFilt = items.filter((x) => {
                        return x.ds === ds;
                      });
                      let a = itemFilt[0];

                      if (a && a.start) {
                        addHoursDsArr.push(ds);
                      }

                      if (a && a.start1) {
                        addHoursDsArr2.push(ds);
                      }

                      if (a && a.start2) {
                        addHoursDsArr3.push(ds);
                      }

                      if (a && a.start3) {
                        addHoursDsArr4.push(ds);
                      }
                      if (a && a.start4) {
                        addHoursDsArr5.push(ds);
                      }

                      newInputState.push({
                        ds,
                        start: a ? a.start : "",
                        end: a ? a.end : "",
                        brk: a ? a.brk : 0,
                        note: a ? a.note : "",
                        tags: a ? a.tags : [],
                        type: a ? a.type : "",

                        start1: a ? a.start1 : "",
                        end1: a ? a.end1 : "",
                        brk1: a ? a.brk1 : 0,
                        note1: a ? a.note1 : "",
                        tags1: a ? a.tags1 : [],
                        type1: a ? a.type1 : "",

                        start2: a ? a.start2 : "",
                        end2: a ? a.end2 : "",
                        brk2: a ? a.brk2 : 0,
                        note2: a ? a.note2 : "",
                        tags2: a ? a.tags2 : [],
                        type2: a ? a.type2 : "",

                        start3: a ? a.start3 : "",
                        end3: a ? a.end3 : "",
                        brk3: a ? a.brk3 : 0,
                        note3: a ? a.note3 : "",
                        tags3: a ? a.tags3 : [],
                        type3: a ? a.type3 : "",

                        start4: a ? a.start4 : "",
                        end4: a ? a.end4 : "",
                        brk4: a ? a.brk4 : 0,
                        note4: a ? a.note4 : "",
                        tags4: a ? a.tags4 : [],
                        type4: a ? a.type4 : "",
                      });
                    });

                  setInputState(newInputState);
                  setAddHoursToDs(addHoursDsArr);
                  setAddHoursToDs2(addHoursDsArr2);
                  setAddHoursToDs3(addHoursDsArr3);
                  setAddHoursToDs4(addHoursDsArr4);
                  setTimeout(() => {
                    setLoading(false);
                  }, 500);

                  // setAddHoursToDs5(addHoursDsArr)
                  // setLoading(false);
                  // setSaveable(true);
                  // let newArr = [];
                  // dsArr.forEach((ds) => {
                  //   if (
                  //     ds.ts >=
                  //     dateStringer.createTimestampFromString(
                  //       dateStringer.createStringFromDateInput(startDateInput)
                  //     )
                  //   ) {
                  //     newArr.push(ds);
                  //   }
                  // });
                  // response.data.output.forEach((item) => {
                  //   let alreadyExistsFilt = dsArr.filter((x) => {
                  //     return x.ds === item.ds;
                  //   });

                  //   if (!alreadyExistsFilt[0]) {
                  //     newArr.push(item);
                  //   }
                  // });

                  // newArr.sort((a, b) => {
                  //   return a.startTs - b.startTs;
                  // });
                  // setDsArr(newArr);

                  // update dsArr
                }
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, [memoVals.showTimesheet]);

  let [schedAmends, setSchedAmends] = useState([
    // { editing,active, type,shiftID, start, end, brkMins, locationID, tags}
  ]);

  useEffect(() => {
    let newArr = [];
    dsArr.forEach((day) => {
      if (day.ds && day.scheduled && Array.isArray(day.scheduled)) {
        day.scheduled.forEach((x) => {
          newArr.push({
            editing: false,
            shiftID: x.shiftID || x.tilID,
            type: x.tilType || "shift",
            start: `${x.startDs.substr(12, 2)}:${x.startDs.substr(15, 2)}`,
            end: `${x.endDs.substr(12, 2)}:${x.endDs.substr(15, 2)}`,
            brkMins: x.brkMins,
            tags: x.tags || [],
            locationID: x.locationID,
          });
        });
      }
    });

    setSchedAmends(newArr);
  }, [dsArr]);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    if (typeof memoVals.showTimesheet !== "object") {
      if (
        selectedTeam &&
        startDateInput &&
        startDateInput[0] === "2" &&
        startDateInput[1] === "0" &&
        !overlappingItem.show
      ) {
        axios
          .post(
            `${serverURL}/timesheet-date-change`,
            {
              teamID: selectedTeam,
              ds: dateStringer.createStringFromDateInput(startDateInput),
              nowDs: dateStringer.createStringFromTimestamp(
                new Date().getTime()
              ),
              checkOverlaps: !loading ? true : false,
            },

            {
              withCredentials: true,
              credentials: "include",
              cancelToken: cancelSource1.token,
            }
          )
          .then((response) => {
            if (response.data.message === "too far back") {
              console.log(response.data);
              setTooFarBack(response.data.daysLimit);
              setOverlappingItem({ show: false });
            }
            if (response.data.message === "overlap" && !overlappingItem.show) {
              console.log("OVERLAPPED ITEM");

              setOverlappingItem({
                show: true,
                startDs: response.data.overlapItem.startDs,
                endDs: response.data.overlapItem.endDs,
                timesheetID: response.data.overlapItem.timesheetID,
                teamName: response.data.overlapItem.teamName,
                submitted: response.data.overlapItem.submitted,
                approved: response.data.overlapItem.approved,
                declined: response.data.overlapItem.declined,
              });
            }
            if (response.data.message === "success") {
              setLocations(response.data.locations);

              console.log(response.data);
              setOverlappingItem({ show: false });
              setDsArr(response.data.output);
              setActiveDs(
                response.data.output.map((x) => {
                  return x.ds;
                })
              );

              // set input state here

              let newInputState = [];
              let addHoursDsArr = [];
              let addHoursDsArr2 = [];
              let addHoursDsArr3 = [];
              let addHoursDsArr4 = [];
              let addHoursDsArr5 = [];

              response.data.output
                .map((x) => x.ds)
                .forEach((ds) => {
                  newInputState.push({
                    ds,
                    start: "",
                    end: "",
                    brk: 0,
                    note: "",
                    tags: [],
                    type: "",

                    start1: "",
                    end1: "",
                    brk1: 0,
                    note1: "",
                    tags1: [],
                    type1: "",

                    start2: "",
                    end2: "",
                    brk2: 0,
                    note2: "",
                    tags2: [],
                    type2: "",

                    start3: "",
                    end3: "",
                    brk3: 0,
                    note3: "",
                    tags3: [],
                    type3: "",

                    start4: "",
                    end4: "",
                    brk4: 0,
                    note4: "",
                    tags4: [],
                    type4: "",
                  });
                });

              setInputState(newInputState);
              setAddHoursToDs(addHoursDsArr);
              setAddHoursToDs2(addHoursDsArr2);
              setAddHoursToDs3(addHoursDsArr3);
              setAddHoursToDs4(addHoursDsArr4);

              // - - - - - -

              // setSaveable(true);
              setTimeout(() => {
                setLoading(false);
              }, 500); // let newArr = [];
              // dsArr.forEach((ds) => {
              //   if (
              //     ds.ts >=
              //     dateStringer.createTimestampFromString(
              //       dateStringer.createStringFromDateInput(startDateInput)
              //     )
              //   ) {
              //     newArr.push(ds);
              //   }
              // });
              // response.data.output.forEach((item) => {
              //   let alreadyExistsFilt = dsArr.filter((x) => {
              //     return x.ds === item.ds;
              //   });

              //   if (!alreadyExistsFilt[0]) {
              //     newArr.push(item);
              //   }
              // });

              // newArr.sort((a, b) => {
              //   return a.startTs - b.startTs;
              // });
              // setDsArr(newArr);

              // update dsArr
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [startDateInput, selectedTeam, memoVals.showTimesheet]);

  let getTimeState = (ds, what, number, state) => {
    if (what === "tag") {
      let filt = state.filter((item) => {
        return item.ds === ds;
      });

      if (filt[0]) {
        if (number === 0) {
          return filt[0].tags || [];
        }
        if (number === 1) {
          return filt[0].tags1 || [];
        }
        if (number === 2) {
          return filt[0].tags2 || [];
        }
        if (number === 3) {
          return filt[0].tags3 || [];
        }
        if (number === 4) {
          return filt[0].tags4 || [];
        }
      } else {
        return [];
      }
    }
    if (what === "start") {
      let filt = state.filter((item) => {
        return item.ds === ds;
      });
      if (filt[0]) {
        if (number === 0) {
          return filt[0].start;
        }
        if (number === 1) {
          return filt[0].start1;
        }
        if (number === 2) {
          return filt[0].start2;
        }
        if (number === 3) {
          return filt[0].start3;
        }
        if (number === 4) {
          return filt[0].start4;
        }
      } else {
        return "";
      }
    }

    if (what === "end") {
      let filt = state.filter((item) => {
        return item.ds === ds;
      });
      if (filt[0]) {
        if (number === 0) {
          return filt[0].end;
        }
        if (number === 1) {
          return filt[0].end1;
        }
        if (number === 2) {
          return filt[0].end2;
        }
        if (number === 3) {
          return filt[0].end3;
        }
        if (number === 4) {
          return filt[0].end4;
        }
      } else {
        return "";
      }
    }

    if (what === "break") {
      let filt = state.filter((item) => {
        return item.ds === ds;
      });
      if (filt[0]) {
        if (number === 0) {
          return filt[0].brk;
        }
        if (number === 1) {
          return filt[0].brk1;
        }
        if (number === 2) {
          return filt[0].brk2;
        }
        if (number === 3) {
          return filt[0].brk3;
        }
        if (number === 4) {
          return filt[0].brk4;
        }
      } else {
        return "";
      }
    }

    if (what === "type") {
      let filt = state.filter((item) => {
        return item.ds === ds;
      });
      if (filt[0]) {
        if (number === 0) {
          return filt[0].type;
        }
        if (number === 1) {
          return filt[0].type1;
        }
        if (number === 2) {
          return filt[0].type2;
        }
        if (number === 3) {
          return filt[0].type3;
        }
        if (number === 4) {
          return filt[0].type4;
        }
      } else {
        return "";
      }
    }

    if (what === "note") {
      let filt = state.filter((item) => {
        return item.ds === ds;
      });
      if (filt[0]) {
        if (number === 0) {
          return filt[0].note;
        }
        if (number === 1) {
          return filt[0].note1;
        }
        if (number === 2) {
          return filt[0].note2;
        }
        if (number === 3) {
          return filt[0].note3;
        }
        if (number === 4) {
          return filt[0].note4;
        }
      } else {
        return "";
      }
    }
  };

  let setTimeState = (ds, what, number, state, data) => {
    // console.log(first);
    if (what === "start") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = number === 0 ? data : obj.start;
          let end = obj.end;
          let brk = obj.brk;
          let type = obj.type;
          let tags = obj.tags;
          let note = obj.note;
          let start1 = number === 1 ? data : obj.start1;
          let end1 = obj.end1;
          let brk1 = obj.brk1;
          let type1 = obj.type1;
          let note1 = obj.note1;
          let tags1 = obj.tags1;
          let start2 = number === 2 ? data : obj.start2;
          let end2 = obj.end2;
          let brk2 = obj.brk2;
          let type2 = obj.type2;
          let note2 = obj.note2;
          let tags2 = obj.tags2;
          let start3 = number === 3 ? data : obj.start3;
          let end3 = obj.end3;
          let brk3 = obj.brk3;
          let type3 = obj.type3;
          let note3 = obj.note3;
          let tags3 = obj.tags3;
          let start4 = number === 4 ? data : obj.start4;
          let end4 = obj.end4;
          let brk4 = obj.brk4;
          let type4 = obj.type4;
          let note4 = obj.note4;
          let tags4 = obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,
            start2,
            end2,
            brk2,
            type2,
            note2,
            start3,
            end3,
            brk3,
            type3,
            note3,
            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }

    if (what === "end") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = obj.start;
          let end = number === 0 ? data : obj.end;
          let brk = obj.brk;
          let type = obj.type;
          let note = obj.note;
          let tags = obj.tags;

          let start1 = obj.start1;
          let end1 = number === 1 ? data : obj.end1;
          let brk1 = obj.brk1;
          let type1 = obj.type1;
          let note1 = obj.note1;
          let tags1 = obj.tags1;

          let start2 = obj.start2;
          let end2 = number === 2 ? data : obj.end2;
          let brk2 = obj.brk2;
          let type2 = obj.type2;
          let note2 = obj.note2;
          let tags2 = obj.tags2;

          let start3 = obj.start3;
          let end3 = number === 3 ? data : obj.end3;
          let brk3 = obj.brk3;
          let type3 = obj.type3;
          let note3 = obj.note3;
          let tags3 = obj.tags3;

          let start4 = obj.start4;
          let end4 = number === 4 ? data : obj.end4;
          let brk4 = obj.brk4;
          let type4 = obj.type4;
          let note4 = obj.note4;
          let tags4 = obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,

            start2,
            end2,
            brk2,
            type2,
            note2,

            start3,
            end3,
            brk3,
            type3,
            note3,

            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }

    if (what === "break") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = obj.start;
          let end = obj.end;
          let brk = number === 0 ? (data < 0 ? 0 : data) : obj.brk;
          let type = obj.type;
          let note = obj.note;
          let tags = obj.tags;

          let start1 = obj.start1;
          let end1 = obj.end1;
          let brk1 = number === 1 ? (data < 0 ? 0 : data) : obj.brk1;
          let type1 = obj.type1;
          let note1 = obj.note1;
          let tags1 = obj.tags1;

          let start2 = obj.start2;
          let end2 = obj.end2;
          let brk2 = number === 2 ? (data < 0 ? 0 : data) : obj.brk2;
          let type2 = obj.type2;
          let note2 = obj.note2;
          let tags2 = obj.tags2;

          let start3 = obj.start3;
          let end3 = obj.end3;
          let brk3 = number === 3 ? (data < 0 ? 0 : data) : obj.brk3;
          let type3 = obj.type3;
          let note3 = obj.note3;
          let tags3 = obj.tags3;

          let start4 = obj.start4;
          let end4 = obj.end4;
          let brk4 = number === 4 ? (data < 0 ? 0 : data) : obj.brk4;
          let type4 = obj.type4;
          let note4 = obj.note4;
          let tags4 = obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,

            start2,
            end2,
            brk2,
            type2,
            note2,

            start3,
            end3,
            brk3,
            type3,
            note3,

            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }

    if (what === "removeRow") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = number === 0 ? "" : obj.start;
          let end = number === 0 ? "" : obj.end;
          let brk = number === 0 ? 0 : obj.brk;
          let type = number === 0 ? "shift" : obj.type;
          let note = number === 0 ? "" : obj.note;
          let tags = obj.tags;

          let start1 = number === 1 ? "" : obj.start1;
          let end1 = number === 1 ? "" : obj.end1;
          let brk1 = number === 1 ? 0 : obj.brk1;
          let type1 = number === 1 ? "shift" : obj.type1;
          let note1 = number === 1 ? "" : obj.note1;
          let tags1 = obj.tags4;

          let start2 = number === 2 ? "" : obj.start2;
          let end2 = number === 2 ? "" : obj.end2;
          let brk2 = number === 2 ? 0 : obj.brk2;
          let type2 = number === 2 ? "shift" : obj.type2;
          let note2 = number === 2 ? "" : obj.note2;
          let tags2 = obj.tags2;

          let start3 = number === 3 ? "" : obj.start3;
          let end3 = number === 3 ? "" : obj.end3;
          let brk3 = number === 3 ? 0 : obj.brk3;
          let type3 = number === 3 ? "shift" : obj.type3;
          let note3 = number === 3 ? "" : obj.note3;
          let tags3 = obj.tags3;

          let start4 = number === 4 ? "" : obj.start4;
          let end4 = number === 4 ? "" : obj.end4;
          let brk4 = number === 4 ? 0 : obj.brk4;
          let type4 = number === 4 ? "shift" : obj.type4;
          let note4 = number === 4 ? "" : obj.note4;
          let tags4 = obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,

            start2,
            end2,
            brk2,
            type2,
            note2,

            start3,
            end3,
            brk3,
            type3,
            note3,

            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }

    if (what === "type") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = obj.start;
          let end = obj.end;
          let brk = obj.brk;
          let type = number === 0 ? data : obj.type;
          let note = obj.note;
          let tags = obj.tags;

          let start1 = obj.start1;
          let end1 = obj.end1;
          let brk1 = obj.brk1;
          let type1 = number === 1 ? data : obj.type1;
          let note1 = obj.note1;
          let tags1 = obj.tags1;

          let start2 = obj.start2;
          let end2 = obj.end2;
          let brk2 = obj.brk2;
          let type2 = number === 2 ? data : obj.type2;
          let note2 = obj.note2;
          let tags2 = obj.tags2;

          let start3 = obj.start3;
          let end3 = obj.end3;
          let brk3 = obj.brk3;
          let type3 = number === 3 ? data : obj.type3;
          let note3 = obj.note3;
          let tags3 = obj.tags3;

          let start4 = obj.start4;
          let end4 = obj.end4;
          let brk4 = obj.brk4;
          let type4 = number === 4 ? data : obj.type4;
          let note4 = obj.note4;
          let tags4 = obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,
            start2,
            end2,
            brk2,
            type2,
            note2,
            start3,
            end3,
            brk3,
            type3,
            note3,
            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);

      console.log({ newArr });
    }

    if (what === "note") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = obj.start;
          let end = obj.end;
          let brk = obj.brk;
          let type = obj.type;
          let note = number === 0 ? data : obj.note;
          let tags = obj.tags;

          let start1 = obj.start1;
          let end1 = obj.end1;
          let brk1 = obj.brk1;
          let type1 = obj.type1;
          let note1 = number === 1 ? data : obj.note1;
          let tags1 = obj.tags1;

          let start2 = obj.start2;
          let end2 = obj.end2;
          let brk2 = obj.brk2;
          let type2 = obj.type2;
          let note2 = number === 2 ? data : obj.note2;
          let tags2 = obj.tags2;

          let start3 = obj.start3;
          let end3 = obj.end3;
          let brk3 = obj.brk3;
          let type3 = obj.type3;
          let note3 = number === 3 ? data : obj.note3;
          let tags3 = obj.tags3;

          let start4 = obj.start4;
          let end4 = obj.end4;
          let brk4 = obj.brk4;
          let type4 = obj.type4;
          let note4 = number === 4 ? data : obj.note4;
          let tags4 = obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,
            start2,
            end2,
            brk2,
            type2,
            note2,
            start3,
            end3,
            brk3,
            type3,
            note3,
            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }

    if (what === "tag") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = obj.start;
          let end = obj.end;
          let brk = obj.brk;
          let type = obj.type;
          let note = obj.note;
          let tags = number === 0 ? [...(obj.tags || []), data] : obj.tags;

          let start1 = obj.start1;
          let end1 = obj.end1;
          let brk1 = obj.brk1;
          let type1 = obj.type1;
          let note1 = obj.note1;
          let tags1 = number === 1 ? [...(obj.tags1 || []), data] : obj.tags1;

          let start2 = obj.start2;
          let end2 = obj.end2;
          let brk2 = obj.brk2;
          let type2 = obj.type2;
          let note2 = obj.note2;
          let tags2 = number === 2 ? [...(obj.tags2 || []), data] : obj.tags2;

          let start3 = obj.start3;
          let end3 = obj.end3;
          let brk3 = obj.brk3;
          let type3 = obj.type3;
          let note3 = obj.note3;
          let tags3 = number === 3 ? [...(obj.tags3 || []), data] : obj.tags3;

          let start4 = obj.start4;
          let end4 = obj.end4;
          let brk4 = obj.brk4;
          let type4 = obj.type4;
          let note4 = obj.note4;
          let tags4 = number === 4 ? [...(obj.tags4 || []), data] : obj.tags4;

          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,
            start2,
            end2,
            brk2,
            type2,
            note2,
            start3,
            end3,
            brk3,
            type3,
            note3,
            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }

    if (what === "removeTag") {
      let newArr = [];
      state.forEach((obj) => {
        if (obj.ds === ds) {
          let start = obj.start;
          let end = obj.end;
          let brk = obj.brk;
          let type = obj.type;
          let note = obj.note;
          let tags =
            number === 0
              ? (obj.tags || []).filter((x) => {
                  return x !== data;
                })
              : obj.tags;

          let start1 = obj.start1;
          let end1 = obj.end1;
          let brk1 = obj.brk1;
          let type1 = obj.type1;
          let note1 = obj.note1;
          let tags1 =
            number === 1
              ? (obj.tags1 || []).filter((x) => {
                  return x !== data;
                })
              : obj.tags1;
          let start2 = obj.start2;
          let end2 = obj.end2;
          let brk2 = obj.brk2;
          let type2 = obj.type2;
          let note2 = obj.note2;
          let tags2 =
            number === 2
              ? (obj.tags2 || []).filter((x) => {
                  return x !== data;
                })
              : obj.tags2;
          let start3 = obj.start3;
          let end3 = obj.end3;
          let brk3 = obj.brk3;
          let type3 = obj.type3;
          let note3 = obj.note3;
          let tags3 =
            number === 3
              ? (obj.tags3 || []).filter((x) => {
                  return x !== data;
                })
              : obj.tags3;
          let start4 = obj.start4;
          let end4 = obj.end4;
          let brk4 = obj.brk4;
          let type4 = obj.type4;
          let note4 = obj.note4;
          let tags4 =
            number === 4
              ? (obj.tags4 || []).filter((x) => {
                  return x !== data;
                })
              : obj.tags4;
          newArr.push({
            ds,
            start,
            end,
            brk,
            type,
            note,
            start1,
            end1,
            brk1,
            type1,
            note1,
            start2,
            end2,
            brk2,
            type2,
            note2,
            start3,
            end3,
            brk3,
            type3,
            note3,
            start4,
            end4,
            brk4,
            type4,
            note4,
            tags,
            tags1,
            tags2,
            tags3,
            tags4,
          });
        } else {
          newArr.push(obj);
        }
      });

      setInputState(newArr);
    }
  };

  let calcDayDurMins = (ds) => {
    if (activeDs.includes(ds)) {
      let mins = 0;

      let filt = inputState.filter((x) => {
        return x.ds === ds;
      });

      if (filt[0]) {
        if (filt[0].start && filt[0].end) {
          let startObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].start, false, ds)
          );
          let endObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].end, false, ds)
          );

          if (endObj.getTime() <= startObj.getTime()) {
            endObj.setDate(endObj.getDate() + 1);
          }

          let totalMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
          if (typeof parseInt(filt[0].brk) === "number") {
            totalMins -= parseInt(filt[0].brk || 0);
          }

          mins += totalMins;
        }

        if (filt[0].start1 && filt[0].end1) {
          let startObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].start1, false, ds)
          );
          let endObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].end1, false, ds)
          );

          if (endObj.getTime() <= startObj.getTime()) {
            endObj.setDate(endObj.getDate() + 1);
          }

          let totalMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
          if (typeof parseInt(filt[0].brk1) === "number") {
            totalMins -= parseInt(filt[0].brk1 || 0);
          }

          mins += totalMins;
        }

        if (filt[0].start2 && filt[0].end2) {
          let startObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].start2, false, ds)
          );
          let endObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].end2, false, ds)
          );

          if (endObj.getTime() <= startObj.getTime()) {
            endObj.setDate(endObj.getDate() + 1);
          }

          let totalMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
          if (typeof parseInt(filt[0].brk2) === "number") {
            totalMins -= parseInt(filt[0].brk2 || 0);
          }

          mins += totalMins;
        }

        if (filt[0].start3 && filt[0].end3) {
          let startObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].start3, false, ds)
          );
          let endObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].end3, false, ds)
          );

          if (endObj.getTime() <= startObj.getTime()) {
            endObj.setDate(endObj.getDate() + 1);
          }

          let totalMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
          if (typeof parseInt(filt[0].brk3) === "number") {
            totalMins -= parseInt(filt[0].brk3 || 0);
          }

          mins += totalMins;
        }

        if (filt[0].start4 && filt[0].end4) {
          let startObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].start4, false, ds)
          );
          let endObj = new Date(
            dateStringer.HHMMToMsToday(filt[0].end4, false, ds)
          );

          if (endObj.getTime() <= startObj.getTime()) {
            endObj.setDate(endObj.getDate() + 1);
          }

          let totalMins = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
          if (typeof parseInt(filt[0].brk4) === "number") {
            totalMins -= parseInt(filt[0].brk4 || 0);
          }

          mins += totalMins;
        }
      }

      let clocksFilt = dsArr.filter((x) => {
        return x.ds === ds;
      });

      if (clocksFilt[0]) {
        clocksFilt[0].clocks.forEach((c) => {
          let startObj = new Date(c.startTs);
          let endObj = new Date(
            dateStringer.createTimestampFromString(c.endDs)
          );
          let m = (endObj.getTime() - startObj.getTime()) / 1000 / 60;
          if (typeof c.break1durMins && c.break1durMins === "number") {
            m -= c.break1durMins;
          }
          if (typeof c.break2durMins && c.break2durMins === "number") {
            m -= c.break2durMins;
          }
          if (typeof c.break3durMins && c.break3durMins === "number") {
            m -= c.break3durMins;
          }
          mins += m;
        });

        clocksFilt[0].scheduled.forEach((s) => {
          mins += s.payableDurMins;
        });
      }

      return mins;
    }
  };

  // let calcTotMins = useMemo(() => {
  let calcTotMins = () => {
    let totMins = 0;
    let dsStringArr = [];
    dsArr.forEach((d) => {
      dsStringArr.push(d.ds);
    });

    dsStringArr.forEach((ds) => {
      console.log(calcDayDurMins(ds));
      let dayMins = calcDayDurMins(ds);
      totMins += typeof dayMins === "number" ? dayMins || 0 : 0;
    });

    return totMins;
  };
  // }, [dsArr]);

  let [valid, setValid] = useState(false);

  useEffect(() => {
    // clean not needed
    let invalid = false;
    inputState.forEach((x) => {
      if (x.start) {
        if (x.start.length !== 5 || !x.end) {
          invalid = true;
        }
      }

      if (x.end) {
        if (x.end.length !== 5 || !x.start) {
          invalid = true;
        }
      }

      if (x.start1) {
        if (x.start1.length !== 5 || !x.end1) {
          invalid = true;
        }
      }

      if (x.end1) {
        if (x.end1.length !== 5 || !x.start1) {
          invalid = true;
        }
      }

      if (x.start2) {
        if (x.start2.length !== 5 || !x.end2) {
          invalid = true;
        }
      }

      if (x.end2) {
        if (x.end2.length !== 5 || !x.start2) {
          invalid = true;
        }
      }

      if (x.start3) {
        if (x.start3.length !== 5 || !x.end3) {
          invalid = true;
        }
      }

      if (x.end3) {
        if (x.end3.length !== 5 || !x.start3) {
          invalid = true;
        }
      }

      if (x.start4) {
        if (x.start4.length !== 5 || !x.end4) {
          invalid = true;
        }
      }

      if (x.end4) {
        if (x.end4.length !== 5 || !x.start4) {
          invalid = true;
        }
      }
      if (!inputState[0]) {
        invalid = true;
      }
      setValid(!invalid);
    });
  }, [inputState]);

  let generateDsArr = dsArr.map((ds, i) => {
    return (
      <div
        className={`timesheetDsItem ${
          activeDs.includes(ds.ds) ? "timesheetActiveDs" : ""
        } ${i === dsArr.length - 1 ? "noBorder" : ""}`}
        onClick={() => {
          if (!activeDs.includes(ds.ds)) {
            setActiveDs([...activeDs, ds.ds]);
            // update input state
            let newInputState = [
              {
                ds: ds.ds,
                start: "",
                end: "",
                brk: 0,
                type: "shift",
                note: "",
                start1: "",
                end1: "",
                brk1: 0,
                type1: "shift",
                note1: "",

                start2: "",
                end2: "",
                brk2: 0,
                type2: "shift",
                note2: "",

                start3: "",
                end3: "",
                brk3: 0,
                type3: "shift",
                note3: "",

                start4: "",
                end4: "",
                brk4: 0,
                type4: "shift",
                note4: "",
              },
            ];

            inputState.forEach((item) => {
              if (item.ds !== ds.ds) {
                newInputState.push(item);
              }
            });

            setInputState(newInputState);
          }
        }}
      >
        <div className="tsItemHeader">
          {/* <img
              src={power}
              alt="Timesheet active day"
              className={`tsPower ${
                !activeDs.includes(ds.ds) ? "tsPowerOff" : ""
              } `}
              onClick={() => {
                setSaveable(true);

                if (activeDs.includes(ds.ds)) {
                  let newArr = activeDs.filter((item) => {
                    return item !== ds.ds;
                  });
                  setActiveDs(newArr);
                  let newArr2 = addHoursToDs.filter((item) => {
                    return item !== ds.ds;
                  });
                  setAddHoursToDs(newArr2);
                  // remove from input state
                  setInputState(
                    inputState.filter((item) => {
                      return item.ds !== ds.ds;
                    })
                  );
                } else {
                  setActiveDs([...activeDs, ds.ds]);

                  // update input state
                  let newInputState = [
                    {
                      ds: ds.ds,
                      start: "",
                      end: "",
                      brk: 0,
                      type: "shift",
                      note: "",
                      start1: "",
                      end1: "",
                      brk1: 0,
                      type1: "shift",
                      note1: "",

                      start2: "",
                      end2: "",
                      brk2: 0,
                      type2: "shift",
                      note2: "",

                      start3: "",
                      end3: "",
                      brk3: 0,
                      type3: "shift",
                      note3: "",

                      start4: "",
                      end4: "",
                      brk4: 0,
                      type4: "shift",
                      note4: "",
                    },
                  ];

                  inputState.forEach((item) => {
                    if (item.ds !== ds.ds) {
                      newInputState.push(item);
                    }
                  });

                  setInputState(newInputState);
                }
              }}
            /> */}
          <p
            className={`tsItemTitle tsItemTitleMy noBorder ${
              !activeDs.includes(ds.ds) ? "inactiveTsItemTitle" : ""
            }`}
          >
            {dateStringer.printedDateWithYrFromDs(ds.ds, true, true, true)}
          </p>
          {activeDs.includes(ds.ds) > 0 ? (
            <div className="tsItemHeaderSection">
              {dateStringer.formatMinsDurationToHours(calcDayDurMins(ds.ds))}
            </div>
          ) : (
            <p></p>
          )}
        </div>
        {ds.clocks[0] ? (
          <div className="tsClocks">
            {/* <p className="tsClocksTitle">Your clocked times</p> */}
            {ds.clocks.map((clock) => {
              return (
                <div
                  className={`tsClockItem ${
                    activeDs.includes(ds.ds) ? "colourAuto" : ""
                  }`}
                >
                  <p className="tsSchedClockType">
                    Clocked{" "}
                    {clock.type === "shift"
                      ? "shift"
                      : clock.type === "til"
                      ? "time in lieu"
                      : "overtime"}
                  </p>
                  <div className="tsSchedParent">
                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Clocked on</p>
                      <p className="tsClockOnVal">
                        {dateStringer.dsToTimeStrip(clock.startDs, true)}
                      </p>
                    </div>

                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Clocked off</p>
                      <p className="tsClockOnVal">
                        {clock.endDs
                          ? dateStringer.dsToTimeStrip(clock.endDs, true)
                          : "-"}
                      </p>
                    </div>

                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Clocked break</p>
                      <p className="tsClockOnVal">
                        {dateStringer.formatMinsDurationToHours(
                          (clock.break1durMins || 0) +
                            (clock.break2durMins || 0) +
                            (clock.break3durMins || 0)
                        )}
                      </p>
                    </div>
                  </div>
                  {clock.tags[0] ? (
                    <div className="tsInlineTags">
                      {clock.tags.map((tag) => {
                        return (
                          <p
                            className={`tsInlineTagItem ${
                              activeDs.includes(ds.ds)
                                ? "activeTsTagsInline"
                                : ""
                            }`}
                          >
                            {tag}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {ds.scheduled[0] ? (
          <div className="tsClocks">
            {/* <p className="tsClocksTitle">Your clocked times</p> */}
            {ds.scheduled.map((sched) => {
              let editing =
                Array.isArray(
                  schedAmends.filter((x) => {
                    return x.shiftID === (sched.shiftID || sched.tilID);
                  })
                ) &&
                schedAmends.filter((x) => {
                  return x.shiftID === (sched.shiftID || sched.tilID);
                })[0] &&
                schedAmends.filter((x) => {
                  return x.shiftID === (sched.shiftID || sched.tilID);
                })[0].editing;

              let active =
                Array.isArray(
                  schedAmends.filter((x) => {
                    return x.shiftID === (sched.shiftID || sched.tilID);
                  })
                ) &&
                schedAmends.filter((x) => {
                  return x.shiftID === (sched.shiftID || sched.tilID);
                })[0] &&
                schedAmends.filter((x) => {
                  return x.shiftID === (sched.shiftID || sched.tilID);
                })[0].active;

              let newTagsArr = active
                ? schedAmends.filter((x) => {
                    return x.shiftID === (sched.shiftID || sched.tilID);
                  })[0].newTags || []
                : [];

              let tagsArr = active
                ? [...sched.tags, ...newTagsArr]
                : sched.tags;
              return (
                <div
                  className={`tsClockItem tsSchedItem ${
                    activeDs.includes(ds.ds) ? "colourAuto" : ""
                  }`}
                >
                  <p className="tsSchedClockType">
                    Scheduled{" "}
                    {sched.shiftID
                      ? "shift"
                      : sched.tilType === "til"
                      ? "time in lieu"
                      : "overtime"}
                  </p>
                  <div className="tsSchedParent">
                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Start</p>
                      <p className="tsClockOnVal">
                        {dateStringer.dsToTimeStrip(sched.startDs, true)}
                      </p>
                      {Array.isArray(
                        schedAmends.filter((x) => {
                          return x.shiftID === (sched.shiftID || sched.tilID);
                        })
                      ) &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0] &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].editing ? (
                        <input
                          type="time"
                          value={
                            Array.isArray(
                              schedAmends.filter((x) => {
                                return (
                                  x.shiftID === (sched.shiftID || sched.tilID)
                                );
                              })
                            ) &&
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0] &&
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].start
                          }
                          onChange={(e) => {
                            let newArr = [];
                            schedAmends.map((x) => {
                              if (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              ) {
                                let obj = x;
                                obj["start"] = e.target.value;
                                x = obj;
                              }
                              newArr.push(x);
                            });

                            setSchedAmends(newArr);
                          }}
                          className="tsSchedTimeEditInput"
                        ></input>
                      ) : (
                        ""
                      )}

                      {active &&
                      !editing &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].start &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].start.length === 5 ? (
                        <p className="tsSchedAmendPrint">
                          {
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].start
                          }
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">End</p>
                      <p className="tsClockOnVal">
                        {sched.endDs
                          ? dateStringer.dsToTimeStrip(sched.endDs, true)
                          : "-"}
                      </p>
                      {Array.isArray(
                        schedAmends.filter((x) => {
                          return x.shiftID === (sched.shiftID || sched.tilID);
                        })
                      ) &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0] &&
                      editing ? (
                        <input
                          type="time"
                          value={
                            Array.isArray(
                              schedAmends.filter((x) => {
                                return (
                                  x.shiftID === (sched.shiftID || sched.tilID)
                                );
                              })
                            ) &&
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0] &&
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].end
                          }
                          onChange={(e) => {
                            let newArr = [];
                            schedAmends.map((x) => {
                              if (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              ) {
                                let obj = x;
                                obj["end"] = e.target.value;
                                x = obj;
                              }
                              newArr.push(x);
                            });

                            setSchedAmends(newArr);
                          }}
                          className="tsSchedTimeEditInput"
                        ></input>
                      ) : (
                        ""
                      )}

                      {active &&
                      !editing &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].end &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].end.length === 5 ? (
                        <p className="tsSchedAmendPrint">
                          {
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].end
                          }
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="tsClockItemChild">
                      <p className="tsClockOnTitle">Break</p>
                      <p className="tsClockOnVal">
                        {dateStringer.formatMinsDurationToHours(
                          sched.brkMins || 0
                        )}
                      </p>{" "}
                      {Array.isArray(
                        schedAmends.filter((x) => {
                          return x.shiftID === (sched.shiftID || sched.tilID);
                        })
                      ) && editing ? (
                        <div className="tsEditBrkDiv">
                          {" "}
                          <input
                            type="number"
                            value={
                              Array.isArray(
                                schedAmends.filter((x) => {
                                  return (
                                    x.shiftID === (sched.shiftID || sched.tilID)
                                  );
                                })
                              ) &&
                              schedAmends.filter((x) => {
                                return (
                                  x.shiftID === (sched.shiftID || sched.tilID)
                                );
                              })[0] &&
                              schedAmends.filter((x) => {
                                return (
                                  x.shiftID === (sched.shiftID || sched.tilID)
                                );
                              })[0].brkMins
                            }
                            onChange={(e) => {
                              let newArr = [];
                              schedAmends.map((x) => {
                                if (
                                  x.shiftID === (sched.shiftID || sched.tilID)
                                ) {
                                  let obj = x;
                                  obj["brkMins"] = e.target.value;
                                  x = obj;
                                }

                                newArr.push(x);
                              });

                              setSchedAmends(newArr);
                            }}
                            className="tsSchedTimeEditInput tsSchedTimeEditInputBrk"
                          ></input>
                          <p className="tsEditbrkTxt">mins</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {Array.isArray(
                        schedAmends.filter((x) => {
                          return x.shiftID === (sched.shiftID || sched.tilID);
                        })
                      ) &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0] &&
                      active &&
                      !editing &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].brkMins ? (
                        <p className="tsSchedAmendPrint">
                          {dateStringer.formatMinsDurationToHours(
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].brkMins
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="tsSchedItem"> </div>
                  </div>

                  {sched.tags[0] ? (
                    <div className="tsInlineTags">
                      {(Array.isArray(
                        schedAmends.filter((x) => {
                          return x.shiftID === (sched.shiftID || sched.tilID);
                        })
                      ) &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0] &&
                      editing
                        ? schedAmends.filter((x) => {
                            return x.shiftID === (sched.shiftID || sched.tilID);
                          })[0].tags
                        : tagsArr
                      ).map((tag) => {
                        return (
                          <p
                            className={`tsInlineTagItem ${
                              activeDs.includes(ds.ds)
                                ? `activeTsTagsInline ${
                                    schedAmends.filter((x) => {
                                      return (
                                        x.shiftID ===
                                        (sched.shiftID || sched.tilID)
                                      );
                                    }) &&
                                    schedAmends.filter((x) => {
                                      return (
                                        x.shiftID ===
                                        (sched.shiftID || sched.tilID)
                                      );
                                    })[0].active &&
                                    !schedAmends
                                      .filter((x) => {
                                        return (
                                          x.shiftID ===
                                          (sched.shiftID || sched.tilID)
                                        );
                                      })[0]
                                      .tags.includes(tag)
                                      ? "fadeTagTsSchedEdit"
                                      : ""
                                  }`
                                : ""
                            } ${newTagsArr.includes(tag) ? "newTsTag" : ""}`}
                          >
                            {newTagsArr.includes(tag) ? "+ " : ""}
                            {tag}{" "}
                            {Array.isArray(
                              schedAmends.filter((x) => {
                                return (
                                  x.shiftID === (sched.shiftID || sched.tilID)
                                );
                              })
                            ) &&
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0] &&
                            schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].editing ? (
                              <img
                                src={navyClose}
                                alt="Remove tag"
                                className="removeTagTsSchedImg"
                                onClick={() => {
                                  let newArr = [];
                                  schedAmends.map((x) => {
                                    if (
                                      x.shiftID ===
                                      (sched.shiftID || sched.tilID)
                                    ) {
                                      let obj = x;
                                      obj["tags"] = schedAmends
                                        .filter((x) => {
                                          return (
                                            x.shiftID ===
                                            (sched.shiftID || sched.tilID)
                                          );
                                        })[0]
                                        .tags.filter((xx) => {
                                          return xx !== tag;
                                        });
                                      obj["newTags"] =
                                        schedAmends.filter((x) => {
                                          return (
                                            x.shiftID ===
                                            (sched.shiftID || sched.tilID)
                                          );
                                        })[0] &&
                                        schedAmends.filter((x) => {
                                          return (
                                            x.shiftID ===
                                            (sched.shiftID || sched.tilID)
                                          );
                                        })[0].newTags
                                          ? schedAmends
                                              .filter((x) => {
                                                return (
                                                  x.shiftID ===
                                                  (sched.shiftID || sched.tilID)
                                                );
                                              })[0]
                                              .newTags.filter((xx) => {
                                                return xx !== tag;
                                              })
                                          : [];
                                      x = obj;
                                    }
                                    newArr.push(x);
                                  });

                                  setSchedAmends(newArr);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </p>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  {editing ? (
                    <select
                      className="editTsSchedDropDownTag"
                      value=""
                      onChange={(e) => {
                        if (e.target.value) {
                          let val = e.target.value;
                          let newArr = [];
                          schedAmends.map((x) => {
                            if (x.shiftID === (sched.shiftID || sched.tilID)) {
                              let obj = x;
                              obj["tags"] = [
                                ...schedAmends.filter((x) => {
                                  return (
                                    x.shiftID === (sched.shiftID || sched.tilID)
                                  );
                                })[0].tags,
                                val,
                              ];

                              obj["newTags"] = [
                                ...(schedAmends.filter((x) => {
                                  return (
                                    x.shiftID === (sched.shiftID || sched.tilID)
                                  );
                                })[0].newTags || []),
                                val,
                              ];
                              x = obj;
                            }
                            newArr.push(x);
                          });

                          setSchedAmends(newArr);
                        }
                      }}
                    >
                      <option value="">-- Add a tag</option>
                      {availableTags.map((tag, ind) => {
                        if (
                          !schedAmends
                            .filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0]
                            .tags.includes(tag)
                        ) {
                          return (
                            <option value={tag} key={ind}>
                              {tag}
                            </option>
                          );
                        }
                      })}
                    </select>
                  ) : (
                    ""
                  )}
                  <p
                    className={`schedTsRowLocation ${
                      !sched.tags || !sched.tags[0]
                        ? "schedTsRowLocationNoTags"
                        : ""
                    } ${
                      !editing &&
                      active &&
                      sched.locationID !==
                        schedAmends.filter((x) => {
                          return x.shiftID === (sched.shiftID || sched.tilID);
                        })[0].locationID
                        ? "activeTsLocName"
                        : ""
                    }`}
                  >
                    <img src={pin} alt="Pin" className="tsLocPinImg" />
                    {editing ? (
                      <select
                        className="editTsSchedDropDownTag editTsSchedDropDownTagLocation"
                        value={
                          schedAmends.filter((x) => {
                            return x.shiftID === (sched.shiftID || sched.tilID);
                          })[0].locationID
                        }
                        onChange={(e) => {
                          if (e.target.value) {
                            let val = e.target.value;
                            let newArr = [];
                            schedAmends.map((x) => {
                              if (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              ) {
                                let obj = x;
                                obj["locationID"] = val;

                                x = obj;
                              }
                              newArr.push(x);
                            });

                            setSchedAmends(newArr);
                          }
                        }}
                      >
                        <option value="">-- Select location</option>
                        {locations.map((loc, ind) => {
                          if (
                            !schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].locationID !== loc.locationID
                          ) {
                            return (
                              <option value={loc.locationID} key={ind}>
                                {loc.locationName}
                              </option>
                            );
                          }
                        })}
                      </select>
                    ) : (
                      getLocation(
                        active
                          ? schedAmends.filter((x) => {
                              return (
                                x.shiftID === (sched.shiftID || sched.tilID)
                              );
                            })[0].locationID
                          : sched.locationID
                      )
                    )}
                  </p>
                  {amendShiftID === (sched.shiftID || sched.tilID) ? (
                    <div className="tsEditBrkDiv">
                      <p
                        className="addTimesheetItemAfterClock editTsHoursBtn editTsHoursBtnSave lightBlueColours_"
                        onClick={() => {
                          setAmendShiftID("");
                          let newArr = [];
                          for (let x of schedAmends) {
                            if (x.shiftID === (sched.shiftID || sched.tilID)) {
                              let obj = x;
                              obj["editing"] = false;
                              obj["active"] = true;
                              x = obj;
                            }

                            newArr.push(x);
                          }
                          setSchedAmends(newArr);
                          setSaveable(true);
                        }}
                      >
                        Save
                      </p>
                      {schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0] &&
                      schedAmends.filter((x) => {
                        return x.shiftID === (sched.shiftID || sched.tilID);
                      })[0].active ? (
                        <p
                          className="addTimesheetItemAfterClock editTsHoursBtn editTsHoursBtnSave x38484844 greyColours"
                          onClick={() => {
                            // let itemFilt = dsArr
                            //   .filter((x) => {
                            //     return x.ds === ds;
                            //   })
                            //   .filter((x) => {
                            //     return (
                            //       (sched.shiftID || sched.tilID) ===
                            //       (x.shiftID || x.tilID)
                            //     );
                            //   });

                            // let newObj;
                            // if (itemFilt[0]) {
                            // }

                            // setTimeout(() => {
                            //   console.log({ itemFilt, dsArr });
                            // }, 1500);

                            let itemFilt = [];

                            for (const x of dsArr) {
                              console.log(
                                x.ds,
                                ds.ds,
                                "----",
                                x.ds === ds.ds ? "same" : "diff"
                              );
                              if (x.ds === ds.ds) {
                                console.log(x.scheduled);
                                if (x.scheduled) {
                                  for (const xx of x.scheduled) {
                                    if (
                                      (xx.shiftID || xx.tilID) ===
                                      (sched.shiftID || sched.tilID)
                                    ) {
                                      // Found the matching item
                                      itemFilt = [xx];
                                      break; // Exit the inner loop
                                    }
                                  }
                                }
                              }
                              // If `itemFilt` is populated, exit the outer loop
                              if (itemFilt.length > 0) {
                                break;
                              }
                            }

                            let newArr = [];
                            schedAmends.map((x) => {
                              if (
                                x.shiftID === (sched.tilID || sched.shiftID)
                              ) {
                                newArr.push({
                                  shiftID: sched.shiftID || sched.tilID,
                                  type: sched.tilType || "shift",
                                  editing: false,
                                  active: false,
                                  brkMins: itemFilt[0]
                                    ? itemFilt[0].brkMins
                                    : 0,
                                  start: itemFilt[0]
                                    ? `${itemFilt[0].startDs.substr(
                                        12,
                                        2
                                      )}:${itemFilt[0].startDs.substr(15, 2)}`
                                    : "",
                                  end: itemFilt[0]
                                    ? `${itemFilt[0].endDs.substr(
                                        12,
                                        2
                                      )}:${itemFilt[0].endDs.substr(15, 2)}`
                                    : "",
                                  tags: itemFilt[0] ? itemFilt[0].tags : [],
                                  newTags: [],
                                  locationID: itemFilt[0]
                                    ? itemFilt[0].locationID
                                    : "",
                                });
                              } else {
                                newArr.push(x);
                              }
                            });
                            setAmendShiftID("");

                            setSchedAmends(newArr);
                          }}
                        >
                          Remove amendments
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <p
                      className="addTimesheetItemAfterClock editTsHoursBtn lightBlueColours"
                      onClick={() => {
                        // hurdy
                        setAmendShiftID(sched.shiftID || sched.tilID);

                        let newArr = [];
                        schedAmends.map((x) => {
                          if (x.shiftID === (sched.shiftID || sched.tilID)) {
                            let obj = x;
                            obj["editing"] = true;
                            x = obj;
                          }
                          newArr.push(x);
                        });

                        setSchedAmends(newArr);

                        // let newArr = [];
                        // dsArr.forEach((day) => {
                        //   if (
                        //     day.ds &&
                        //     day.scheduled &&
                        //     Array.isArray(day.scheduled)
                        //   ) {
                        //     day.scheduled.forEach((x) => {
                        //       if (
                        //         x.shiftID === (sched.shiftID || sched.tilID) && !x.
                        //       ) {
                        //         newArr.push({
                        //           editing: true,
                        //           shiftID: x.shiftID || x.tilID,
                        //           type: x.tilType || "shift",
                        //           start: `${x.startDs.substr(
                        //             12,
                        //             2
                        //           )}:${x.startDs.substr(15, 2)}`,
                        //           end: `${x.endDs.substr(
                        //             12,
                        //             2
                        //           )}:${x.endDs.substr(15, 2)}`,
                        //           brkMins: x.brkMins,
                        //           tags: x.tags || [],
                        //           locationID: x.locatioinID,
                        //         });
                        //       } else {
                        //         newArr.push(x);
                        //       }
                        //     });
                        //   }
                        // });

                        // setSchedAmends(newArr);
                      }}
                    >
                      Amend{" "}
                      <img
                        src={edit}
                        alt="Edit hours"
                        className="amendTsHrsImg"
                      />
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {(ds.clocks[0] || ds.scheduled[0]) &&
        (addHoursToDs.includes(ds.ds) ||
          addHoursToDs2.includes(ds.ds) ||
          addHoursToDs3.includes(ds.ds) ||
          addHoursToDs4.includes(ds.ds)) ? (
          <p
            className={`clockAlreadyRecords ${
              !activeDs.includes(ds.ds) ? "inactiveTsItemTitle" : ""
            }`}
          >
            {ds.clocks[0] && !ds.scheduled[0]
              ? "No need to record clocked times from above. These are recorded automatically."
              : !ds.clocks[0] && ds.scheduled[0]
              ? "No need to record scheduled times from above. Amend the hours instead if the times you have worked vary from those that were scheduled."
              : "No need to record clocked and scheduled times from above. These are recorded automatically."}
            <br />
          </p>
        ) : (
          ""
        )}
        {activeDs.includes(ds.ds) ? (
          ds.clocks[0] ? (
            !addHoursToDs.includes(ds.ds) ? (
              <p
                className="addTimesheetItemAfterClock"
                onClick={() => {
                  setAddHoursToDs([...addHoursToDs, ds.ds]);
                }}
              >
                Add more hours{" "}
                <img src={whiteAddThin} className="addIconTs" alt="Add" />
              </p>
            ) : (
              ""
            )
          ) : !addHoursToDs.includes(ds.ds) ? (
            <p
              className="addTimesheetItemAfterClock addHrsbtn"
              onClick={() => {
                setAddHoursToDs([...addHoursToDs, ds.ds]);
              }}
            >
              Add hours{" "}
              <img src={whiteAddThin} className="addIconTs" alt="Add" />
            </p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {activeDs.includes(ds.ds) && addHoursToDs.includes(ds.ds) ? (
          <div className="tsAddInputDiv">
            <p className="tsAddInputDsTitle">First entry</p>
            <div className="tsAddInputRow">
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Started</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "start", 0, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);
                    setTimeState(ds.ds, "start", 0, inputState, e.target.value);
                  }}
                ></input>
              </div>
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Ended</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "end", 0, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "end", 0, inputState, e.target.value);
                  }}
                ></input>
              </div>

              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">
                  Break <span className="tsAddInputUnpaidSpan">unpaid</span>
                </p>
                <input
                  type="number"
                  value={getTimeState(ds.ds, "break", 0, inputState)}
                  className="tsBrkInput"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "break", 0, inputState, e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="tsAddInputRow metaRow">
              <select
                onChange={(e) => {
                  setSaveable(true);

                  console.log(e.target.value);
                  setTimeState(ds.ds, "type", 0, inputState, e.target.value);
                }}
                className="tsAddInputMetaType"
                value={getTimeState(ds.ds, "type", 0, inputState)}
              >
                <option value="shift">Shift</option>
                <option value="overtime">Overtime</option>
                <option value="til">Lieu shift</option>
              </select>

              <input
                type="text"
                className="tsAddInputMetaNote"
                placeholder="Add a note"
                value={getTimeState(ds.ds, "note", 0, inputState)}
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "note", 0, inputState, e.target.value);
                }}
              ></input>
              <div className="tsAddInputCol"></div>
            </div>
            {activeDs.includes(ds.ds) ? (
              <div className="tsInlineTags tsInlineTagsAddHours">
                {getTimeState(ds.ds, "tag", 0, inputState).map((tag) => {
                  return (
                    <p className={`tsInlineTagItem tagItemAddHours`}>
                      {tag}{" "}
                      <img
                        src={greyCross}
                        alt="Remove tag"
                        className="removeTagFromTsImg"
                        onClick={() => {
                          setTimeState(ds.ds, "removeTag", 0, inputState, tag);
                          setSaveable(true);
                        }}
                      />
                    </p>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {activeDs.includes(ds.ds) ? (
              <div
                className={`addTagAndAddRowDiv ${
                  getTimeState(ds.ds, "tag", 0, inputState)[0]
                    ? "x23972871"
                    : ""
                }`}
              >
                {" "}
                <select
                  className={`clockTagSelector x248241 ${
                    !availableTags[0] ? "invis" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value !== "-") {
                      setSaveable(true);

                      setTimeState(ds.ds, "tag", 0, inputState, e.target.value);
                    }
                  }}
                >
                  {" "}
                  <option value="-">-- Add tag</option>
                  {availableTags.map((tag) => {
                    if (
                      !getTimeState(ds.ds, "tag", 0, inputState).includes(tag)
                    ) {
                      return <option value={tag}>{tag}</option>;
                    } else return;
                  })}
                </select>
                {addHoursToDs2.includes(ds.ds) ? (
                  <p></p>
                ) : (
                  <div
                    className="tsAddInlineAddBtn firstTsAddBtn"
                    onClick={() => {
                      setAddHoursToDs2([...addHoursToDs2, ds.ds]);
                    }}
                  >
                    <img
                      src={whiteAddThin}
                      alt="Add more hours"
                      className="addMoreHrsImg"
                    />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {activeDs.includes(ds.ds) && addHoursToDs2.includes(ds.ds) ? (
          <div className="tsAddInputDiv">
            <p className="tsAddInputDsTitle">Second entry</p>
            <div className="tsAddInputRow">
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Started</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "start", 1, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "start", 1, inputState, e.target.value);
                  }}
                ></input>
              </div>
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Ended</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "end", 1, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "end", 1, inputState, e.target.value);
                  }}
                ></input>
              </div>

              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">
                  Break <span className="tsAddInputUnpaidSpan">unpaid</span>
                </p>
                <input
                  type="number"
                  value={getTimeState(ds.ds, "break", 1, inputState)}
                  className="tsBrkInput"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "break", 1, inputState, e.target.value);
                  }}
                ></input>
              </div>
            </div>{" "}
            <div className="tsAddInputRow metaRow">
              <select
                className="tsAddInputMetaType"
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "type", 1, inputState, e.target.value);
                }}
                value={getTimeState(ds.ds, "type", 1, inputState)}
              >
                <option value="shift">Shift</option>
                <option value="overtime">Overtime</option>
                <option value="til">Lieu shift</option>
              </select>

              <input
                type="text"
                className="tsAddInputMetaNote"
                placeholder="Add a note"
                value={getTimeState(ds.ds, "note", 1, inputState)}
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "note", 1, inputState, e.target.value);
                }}
              ></input>
              <div className="tsAddInputCol flexTsItems"></div>
            </div>{" "}
            {activeDs.includes(ds.ds) ? (
              <div className="tsInlineTags tsInlineTagsAddHours">
                {getTimeState(ds.ds, "tag", 1, inputState).map((tag) => {
                  return (
                    <p className={`tsInlineTagItem tagItemAddHours`}>
                      {tag}{" "}
                      <img
                        src={greyCross}
                        alt="Remove tag"
                        className="removeTagFromTsImg"
                        onClick={() => {
                          setTimeState(ds.ds, "removeTag", 1, inputState, tag);
                        }}
                      />
                    </p>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {activeDs.includes(ds.ds) ? (
              <div
                className={`addTagAndAddRowDiv ${
                  getTimeState(ds.ds, "tag", 1, inputState)[0]
                    ? "x23972871"
                    : ""
                }`}
              >
                {" "}
                <select
                  className={`clockTagSelector x248241 ${
                    !availableTags[0] ? "invis" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value !== "-") {
                      setSaveable(true);

                      setTimeState(ds.ds, "tag", 1, inputState, e.target.value);
                    }
                  }}
                >
                  {" "}
                  <option value="-">-- Add tag</option>
                  {availableTags.map((tag) => {
                    if (
                      !getTimeState(ds.ds, "tag", 1, inputState).includes(tag)
                    ) {
                      return <option value={tag}>{tag}</option>;
                    } else return;
                  })}
                </select>
                {addHoursToDs3.includes(ds.ds) ? (
                  <p></p>
                ) : (
                  <div className="tsAddInputCol flexTsItems">
                    <div
                      className="tsAddInlineAddBtn binColours"
                      onClick={() => {
                        let filt = addHoursToDs2.filter((x) => {
                          return x !== ds.ds;
                        });

                        setAddHoursToDs2(filt);

                        setTimeState(ds.ds, "removeRow", 1, inputState, "");
                      }}
                    >
                      <img
                        src={whiteBin}
                        alt="Add more hours"
                        className="addMoreHrsImg hrsBin"
                      />
                    </div>
                    {addHoursToDs3.includes(ds.ds) ? (
                      ""
                    ) : (
                      <div
                        className="tsAddInlineAddBtn"
                        onClick={() => {
                          setAddHoursToDs3([...addHoursToDs3, ds.ds]);
                        }}
                      >
                        <img
                          src={whiteAddThin}
                          alt="Add more hours"
                          className="addMoreHrsImg"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {activeDs.includes(ds.ds) && addHoursToDs3.includes(ds.ds) ? (
          <div className="tsAddInputDiv">
            <p className="tsAddInputDsTitle">Third entry</p>
            <div className="tsAddInputRow">
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Started</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "start", 2, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "start", 2, inputState, e.target.value);
                  }}
                ></input>
              </div>
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Ended</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "end", 2, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "end", 2, inputState, e.target.value);
                  }}
                ></input>
              </div>

              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">
                  Break <span className="tsAddInputUnpaidSpan">unpaid</span>
                </p>
                <input
                  type="number"
                  value={getTimeState(ds.ds, "break", 2, inputState)}
                  className="tsBrkInput"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "break", 2, inputState, e.target.value);
                  }}
                ></input>
              </div>
            </div>{" "}
            <div className="tsAddInputRow metaRow">
              <select
                className="tsAddInputMetaType"
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "type", 2, inputState, e.target.value);
                }}
                value={getTimeState(ds.ds, "type", 2, inputState)}
              >
                <option value="shift">Shift</option>
                <option value="overtime">Overtime</option>
                <option value="til">Lieu shift</option>
              </select>

              <input
                value={getTimeState(ds.ds, "note", 2, inputState)}
                type="text"
                className="tsAddInputMetaNote"
                placeholder="Add a note"
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "note", 2, inputState, e.target.value);
                }}
              ></input>
              <div className="tsAddInputCol flexTsItems"></div>
            </div>{" "}
            {/* - - - */}
            {activeDs.includes(ds.ds) ? (
              <div className="tsInlineTags tsInlineTagsAddHours">
                {getTimeState(ds.ds, "tag", 2, inputState).map((tag) => {
                  return (
                    <p className={`tsInlineTagItem tagItemAddHours`}>
                      {tag}{" "}
                      <img
                        src={greyCross}
                        alt="Remove tag"
                        className="removeTagFromTsImg"
                        onClick={() => {
                          setTimeState(ds.ds, "removeTag", 2, inputState, tag);
                        }}
                      />
                    </p>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {activeDs.includes(ds.ds) ? (
              <div
                className={`addTagAndAddRowDiv ${
                  getTimeState(ds.ds, "tag", 2, inputState)[0]
                    ? "x23972871"
                    : ""
                }`}
              >
                {" "}
                <select
                  className={`clockTagSelector x248241 ${
                    !availableTags[0] ? "invis" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value !== "-") {
                      setSaveable(true);

                      setTimeState(ds.ds, "tag", 2, inputState, e.target.value);
                    }
                  }}
                >
                  {" "}
                  <option value="-">-- Add tag</option>
                  {availableTags.map((tag) => {
                    if (
                      !getTimeState(ds.ds, "tag", 2, inputState).includes(tag)
                    ) {
                      return <option value={tag}>{tag}</option>;
                    } else return;
                  })}
                </select>
                {addHoursToDs4.includes(ds.ds) ? (
                  <p></p>
                ) : (
                  <div className="tsAddInputCol flexTsItems">
                    <div
                      className="tsAddInlineAddBtn binColours"
                      onClick={() => {
                        let filt = addHoursToDs3.filter((x) => {
                          return x !== ds.ds;
                        });

                        setAddHoursToDs3(filt);

                        setTimeState(ds.ds, "removeRow", 2, inputState, "");
                      }}
                    >
                      <img
                        src={whiteBin}
                        alt="Add more hours"
                        className="addMoreHrsImg hrsBin"
                      />
                    </div>
                    {addHoursToDs4.includes(ds.ds) ? (
                      ""
                    ) : (
                      <div
                        className="tsAddInlineAddBtn"
                        onClick={() => {
                          setAddHoursToDs4([...addHoursToDs4, ds.ds]);
                        }}
                      >
                        <img
                          src={whiteAddThin}
                          alt="Add more hours"
                          className="addMoreHrsImg"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {/* .. .. .. .. .. */}
          </div>
        ) : (
          ""
        )}
        {activeDs.includes(ds.ds) && addHoursToDs4.includes(ds.ds) ? (
          <div className="tsAddInputDiv">
            <p className="tsAddInputDsTitle">Fourth entry</p>
            <div className="tsAddInputRow">
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Started</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "start", 3, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "start", 3, inputState, e.target.value);
                  }}
                ></input>
              </div>
              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">Ended</p>
                <input
                  type="time"
                  value={getTimeState(ds.ds, "end", 3, inputState)}
                  className="tsAddInputTime"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "end", 3, inputState, e.target.value);
                  }}
                ></input>
              </div>

              <div className="tsAddInputCol">
                <p className="tsAddInputTitle">
                  Break <span className="tsAddInputUnpaidSpan">unpaid</span>
                </p>
                <input
                  type="number"
                  value={getTimeState(ds.ds, "break", 3, inputState)}
                  className="tsBrkInput"
                  onChange={(e) => {
                    setSaveable(true);

                    setTimeState(ds.ds, "break", 3, inputState, e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="tsAddInputRow metaRow">
              <select
                className="tsAddInputMetaType"
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "type", 3, inputState, e.target.value);
                }}
                value={getTimeState(ds.ds, "type", 3, inputState)}
              >
                <option value="shift">Shift</option>
                <option value="overtime">Overtime</option>
                <option value="til">TOIL</option>
              </select>

              <input
                value={getTimeState(ds.ds, "note", 3, inputState)}
                type="text"
                className="tsAddInputMetaNote"
                placeholder="Add a note"
                onChange={(e) => {
                  setSaveable(true);

                  setTimeState(ds.ds, "note", 3, inputState, e.target.value);
                }}
              ></input>
              <div className="tsAddInputCol flexTsItems"></div>
            </div>{" "}
            {/* == ++ ++ */}
            {/* - - - */}
            {activeDs.includes(ds.ds) ? (
              <div className="tsInlineTags tsInlineTagsAddHours">
                xxx
                {getTimeState(ds.ds, "tag", 3, inputState).map((tag) => {
                  return (
                    <p className={`tsInlineTagItem tagItemAddHours`}>
                      {tag}{" "}
                      <img
                        src={greyCross}
                        alt="Remove tag"
                        className="removeTagFromTsImg"
                        onClick={() => {
                          setTimeState(ds.ds, "removeTag", 3, inputState, tag);
                        }}
                      />
                    </p>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {activeDs.includes(ds.ds) ? (
              <div
                className={`addTagAndAddRowDiv ${
                  getTimeState(ds.ds, "tag", 3, inputState)[0]
                    ? "x23972871"
                    : ""
                }`}
              >
                {" "}
                <select
                  className={`clockTagSelector x248241 ${
                    !availableTags[0] ? "invis" : ""
                  }`}
                  onChange={(e) => {
                    if (e.target.value !== "-") {
                      setSaveable(true);

                      setTimeState(ds.ds, "tag", 3, inputState, e.target.value);
                    }
                  }}
                >
                  {" "}
                  <option value="-">-- Add tag</option>
                  {availableTags.map((tag) => {
                    if (
                      !getTimeState(ds.ds, "tag", 3, inputState).includes(tag)
                    ) {
                      return <option value={tag}>{tag}</option>;
                    } else return;
                  })}
                </select>
                <div className="tsAddInputCol flexTsItems">
                  <div
                    className="tsAddInlineAddBtn binColours"
                    onClick={() => {
                      let filt = addHoursToDs4.filter((x) => {
                        return x !== ds.ds;
                      });

                      setAddHoursToDs4(filt);
                      setTimeState(ds.ds, "removeRow", 3, inputState);
                    }}
                  >
                    <img
                      src={whiteBin}
                      alt="Add more hours"
                      className="addMoreHrsImg hrsBin"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* .. .. .. .. .. */}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  });

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        if (saveable) {
          setSureExit(true);
        } else {
          closeModal();
        }
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        ""
      )}
      {loading ? (
        ""
      ) : typeof memoVals.showTimesheet === "object" &&
        memoVals.showTimesheet.options ? (
        <div
          className={`${
            width < mobileWidthBreak
              ? "availModalBox"
              : "availModalBox saveTemplateWideModalBox"
          } ${
            memoVals.mobModal ? "availModalBoxMob mobModalShoulder" : ""
          } timesheetModalBox ${memoVals.device.ios ? "paddingBottom20" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {!loading && memoVals.mobModal ? (
            <div className="modalSwiper modalSwiperCreateTimesheet"></div>
          ) : (
            ""
          )}
          {!loading ? (
            <div
              className="bulkEditLimitModalHeader"
              onClick={() => {
                if (memoVals.mob) {
                  if (saveable) {
                    setSureExit(true);
                  } else {
                    closeModal();
                  }
                }
              }}
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon"
                onClick={() => {
                  if (saveable) {
                    setSureExit(true);
                  } else {
                    closeModal();
                  }
                }}
              />
              <p
                className="bulkEditLimitModalHeaderTitle"
                onClick={() => {
                  console.log({ inputState });
                  if (memoVals.mob) {
                    if (saveable) {
                      setSureExit(true);
                    } else {
                      closeModal();
                    }
                  }
                }}
              >
                Active timesheets{" "}
              </p>
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
              />{" "}
            </div>
          ) : (
            ""
          )}
          <p className="activeTsInfo">
            Timesheets you have created but not yet submitted.
          </p>
          {!loading ? (
            <div className="tsOptionsContainer">
              {memoVals.showTimesheet.optionsArr.map((opt) => {
                return (
                  <div
                    className="tsOptionBox"
                    onClick={() => {
                      // load timesheet
                      setLoading(true);
                      memoVals.setShowTimesheet((x) => {
                        return {
                          timesheetID: opt.timesheetID,
                        };
                      });
                    }}
                  >
                    <img
                      src={tsIcon}
                      alt="Timesheet icon"
                      className="activeTsIcon"
                    />
                    <div className="tsOptionsUnitRight">
                      <div className="tsOptionUnitRightSection">
                        <p className="tsOptionTitle">From</p>
                        <p className="tsOptionValue">
                          {dateStringer.printedDateFromDs(opt.startDs)}
                        </p>
                      </div>
                      <div className="tsOptionUnitRightSection">
                        <p className="tsOptionTeam">
                          {opt.teamName.length > 16
                            ? opt.teamName.slice(0, 14) + "..."
                            : opt.teamName}{" "}
                          <img
                            src={rightTri}
                            alt="Timesheet"
                            className="tsRightIcon"
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {memoVals.showTimesheet.ableToLaunchNew ? (
            <div
              className="addTsBtnLaunch"
              onClick={() => {
                memoVals.setShowTimesheet((x) => "new");
                setLoading(true);
              }}
            >
              New timesheet{" "}
              <img
                src={whiteAddThin}
                alt="New timesheet"
                className="newTsAddIconWhite"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div
          className={`${
            width < mobileWidthBreak
              ? "availModalBox"
              : "availModalBox saveTemplateWideModalBox"
          } ${memoVals.mob ? "availModalBoxMob mobModalShoulder" : ""} ${
            memoVals.device.ios ? "paddingBottom20" : ""
          } timesheetModalBox ${noTeams ? "none" : ""} ${
            loading ? "invis" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {!loading && memoVals.mobModal ? (
            <div className="modalSwiper modalSwiperCreateTimesheet"></div>
          ) : (
            ""
          )}

          {!loading ? (
            <div
              className="bulkEditLimitModalHeader"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            >
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon"
                onClick={() => {
                  if (saveable) {
                    setSureExit(true);
                  } else {
                    closeModal();
                  }
                }}
              />
              <p
                className={`bulkEditLimitModalHeaderTitle ${
                  loading ? "none" : ""
                }`}
                onClick={() => {
                  console.log({ inputState });

                  if (memoVals.mob) {
                    if (saveable) {
                      setSureExit(true);
                    } else {
                      closeModal();
                    }
                  }
                }}
              >
                {savedButNotSubmitted
                  ? loadedTeamName
                    ? `${
                        loadedTeamName.length > 14
                          ? loadedTeamName.slice(0, 11) + "..."
                          : loadedTeamName
                      } timesheet`
                    : "Active timesheet"
                  : memoVals.showTimesheet === "new" ||
                    memoVals.showTimesheet === "new2"
                  ? "New timesheet"
                  : "Timesheet"}
              </p>
              <img
                src={navyClose}
                alt="Close"
                className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
              />{" "}
            </div>
          ) : (
            ""
          )}
          {!loading ? (
            <div
              className={`bulkEditTextDiv ${loading ? "none" : ""}`}
              onClick={() => {}}
            >
              <p className="availTxtInfo">
                Record the hours you have worked
                {loadedTeamName ? ` for ${loadedTeamName}` : ""}. Once approved,
                the timesheet will be converted to hours that you will be paid
                for.
                <br />
              </p>
            </div>
          ) : (
            ""
          )}
          {!loading ? (
            <div
              className={`${
                width > mobileWidthBreak
                  ? "timesheetBoxContainerWide"
                  : "timesheetBoxContainerMob"
              }`}
            >
              <div
                className={`availUnit1 ${
                  width <= mobileWidthBreak
                    ? "availUnit1MobOnly marginTopMinus12"
                    : ""
                } ${loading ? "none" : ""} ${
                  !loadedTeamName ? "x193981" : "x982765"
                }`}
              >
                {" "}
                {/* {!loadedTeamName ? (
                  <div className="timesheetDateDay">
                    {dateStringer.convertDayNumberToDayString(
                      new Date(
                        dateStringer.createTimestampFromString(
                          dateStringer.createStringFromDateInput(startDateInput)
                        )
                      ).getDay()
                    )}{" "}
                    <img
                      src={timesheetsDayLine}
                      alt="Day"
                      className="tsDayLine"
                    />
                  </div>
                ) : (
                  ""
                )} */}
                {!loadedTeamName ? (
                  <div
                    className={`addAvailSelectRow timesheetSelectorRow ${
                      savedButNotSubmitted ? "x82181" : ""
                    }`}
                  >
                    <p className="selectDateRange">Timesheet starts</p>
                    <div className="teamSchedFilterBarLeftAvail">
                      <input
                        type="date"
                        id="497297522"
                        className={`addShiftDateInput editShiftDateInputBox timesheetStartDateInput ${
                          memoVals.device.ios ? "iosDateInput" : ""
                        }`}
                        max={dateStringer.createDateInputFromDateString(
                          `${dateStringer
                            .createStringFromTimestamp(new Date().getTime())
                            .substr(0, 11)}H00M00`
                        )}
                        value={startDateInput}
                        onChange={(e) => {
                          setSaveable(true);
                          if (e.target.value[0] === "2") {
                            setStartDateInput(e.target.value);
                          }
                        }}
                        onFocus={(e) => {
                          if (
                            !memoVals.mob &&
                            typeof e.target.showPicker === "function"
                          ) {
                            e.target.showPicker();
                          }
                        }}
                      />

                      {/* <p>selector</p> */}
                    </div>{" "}
                  </div>
                ) : (
                  <Fragment>
                    <div className="addAvailSelectRow timesheetSelectorRow x28291">
                      <p className="selectDateRange">Timesheet period</p>
                      <p className="timesheetPeriodDates">
                        {dateStringer.createDatePeriod(startDs, endDs)}
                      </p>
                    </div>

                    <div className="addAvailSelectRow timesheetSelectorRow x28291">
                      <p
                        className="selectDateRange"
                        onClick={() => {
                          console.log({ schedAmends });
                        }}
                      >
                        Recorded hours
                      </p>
                      <p className="timesheetPeriodDates colour0077ff">
                        {dateStringer.formatMinsDurationToHours(calcTotMins())}
                      </p>
                    </div>
                  </Fragment>
                )}
                {!savedButNotSubmitted ? (
                  <div className="addAvailSelectRow timesheetSelectorRow">
                    <p
                      className="selectDateRange"
                      onClick={() => {
                        console.log({ schedAmends });
                      }}
                    >
                      Team
                    </p>
                    <select
                      className="availTeamsDropdown timesheetTeamSelect"
                      value={selectedTeam}
                      onChange={(e) => {
                        //   if (changed) {
                        //     setSureTeam(e.target.value);
                        //   } else {
                        setSelectedTeam(e.target.value);
                        setSaveable(true);

                        //   }
                      }}
                    >
                      {generateTeams}
                    </select>
                    {/* <p>selector</p> */}
                  </div>
                ) : (
                  ""
                )}
                {!savedButNotSubmitted && openTimesheetsTeamNames[0] ? (
                  <p
                    className="cantSeeYourTeam"
                    onClick={() => {
                      setCantFindTeam(openTimesheetsTeamNames);
                    }}
                  >
                    Can't find the right team?
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {loading ? (
            ""
          ) : (
            <div className="timesheetBody createTsBody">
              {!loading ? generateDsArr : ""}{" "}
            </div>
          )}
          <div className={`availModalFooter ${loading ? "none" : ""}`}>
            <div className="availSaveAndDeleteContainer">
              {memoVals.showTimesheet === "new" ||
              memoVals.showTimesheet === "new2" ? (
                valid ? (
                  <div className="saveAndSubmitContainer">
                    {!loadedTeamName && !submitted ? (
                      <div className="submitAndloadTsBtns">
                        {" "}
                        <p
                          className="submitTsBtn"
                          onClick={() => {
                            setSureSubmit(true);
                          }}
                        >
                          Submit
                        </p>
                        <p
                          className="submitTsBtn greyColours loadOtherTsBtn"
                          onClick={() => {
                            if (!otherTimesheets[0]) {
                              axios
                                .post(
                                  `${serverURL}/recent-timesheets`,
                                  {
                                    someData: 1,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setOtherTimesheets(
                                      response.data.timesheets
                                    );
                                    setShowOtherTimesheets(true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            } else {
                              setShowOtherTimesheets(true);
                            }
                          }}
                        >
                          Existing timesheets{" "}
                          <img
                            src={popup}
                            alt="Existing timesheets"
                            className="existTsPopup"
                          />
                        </p>
                      </div>
                    ) : submitted ? (
                      <p className="tsSubmittedTs paddingTop0x">
                        <span className="tsSubmittedTsTitleSpan">
                          Submitted
                        </span>
                        <br />
                        {dateStringer.printedDateFromDs(submitted)}
                      </p>
                    ) : (
                      ""
                    )}
                    {saveable ? (
                      <p
                        className={`saveTsBtn marginLeft0`}
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/save-timesheet`,
                              {
                                timesheetID: memoVals.showTimesheet, // "new" is new one needs to be made
                                teamID:
                                  memoVals.showTimesheet === "new" ||
                                  memoVals.showTimesheet === "new2"
                                    ? selectedTeam
                                    : "",
                                data: inputState,
                                startDs:
                                  memoVals.showTimesheet === "new" ||
                                  memoVals.showTimesheet === "new2"
                                    ? dateStringer.createStringFromDateInput(
                                        startDateInput
                                      )
                                    : "",
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                                schedAmends,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setUpdateUserTimesheets(
                                  (x) => !memoVals.updateUserTimesheets
                                );
                                setSavedButNotSubmitted(memoVals.showTimesheet);
                                setLoadedTeamName(response.data.teamName);
                                setSaveable(false);

                                if (
                                  memoVals.showTimesheet === "new" ||
                                  memoVals.showTimesheet === "new2"
                                ) {
                                  setCountData({
                                    userTimesheets:
                                      countData.userTimesheets + 1,
                                  });
                                }
                                memoVals.setIndicate((x) => {
                                  return {
                                    show: true,
                                    message: "Saved but not submitted yet",
                                    colour: "blue",
                                    duration: 4000,
                                  };
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Save
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <p></p>
                )
              ) : (
                ""
              )}
              {savedButNotSubmitted && loadedTeamName && valid && !submitted ? (
                <p
                  className="submitTsBtn"
                  onClick={() => {
                    setSureSubmit(true);
                  }}
                >
                  Submit
                </p>
              ) : submitted ? (
                // (
                <p className="tsSubmittedTs marginRight10">
                  <span className="tsSubmittedTsTitleSpan">Submitted</span>
                  <br />
                  {dateStringer.printedDateFromDs(submitted)}
                </p>
              ) : (
                // )
                ""
              )}

              {saveable && loadedTeamName && valid && inputState[0] ? (
                <p
                  className={`saveTsBtn marginLeft0`}
                  onClick={() => {
                    // console.log({ inputState });

                    // let newArr = [];
                    // inputState.forEach((item) => {
                    //   let clocksFilt = dsArr.filter((x) => {
                    //     return x.ds === item.ds;
                    //   });

                    //   let newObj = item;
                    //   if (clocksFilt[0]) {
                    //     newObj["clocks"] = clocksFilt[0].clocks;
                    //   }

                    //   newArr.push(newObj);
                    // });

                    //   console.log([{ newArr }]);
                    axios
                      .post(
                        `${serverURL}/save-timesheet`,
                        {
                          timesheetID:
                            typeof memoVals.showTimesheet === "object"
                              ? memoVals.showTimesheet.timesheetID
                              : memoVals.showTimesheet, // "new" is new one needs to be made
                          teamID:
                            memoVals.showTimesheet === "new" ||
                            memoVals.showTimesheet === "new2"
                              ? selectedTeam
                              : "",
                          data: inputState,
                          startDs:
                            memoVals.showTimesheet === "new" ||
                            memoVals.showTimesheet === "new2"
                              ? dateStringer.createStringFromDateInput(
                                  startDateInput
                                )
                              : "",
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          schedAmends,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          memoVals.setUpdateUserTimesheets(
                            (x) => !memoVals.updateUserTimesheets
                          );

                          setSavedButNotSubmitted(memoVals.showTimesheet);
                          setLoadedTeamName(response.data.teamName);
                          setSaveable(false);

                          if (response.data.submitted) {
                            setCountData({
                              userTimesheets: countData.userTimesheets - 1,
                            });
                          }

                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: response.data.submitted
                                ? "Saved timesheet"
                                : "Saved but not submitted yet",
                              colour: "blue",
                              duration: 4000,
                            };
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Save
                </p>
              ) : (
                ""
              )}
              {loadedTeamName ? (
                <p
                  className={`deleteAvailBtn ${
                    valid
                      ? `deleteTsBtn ${
                          saveable ? "marginLeft0" : "marginLeft0"
                        }`
                      : "marginLeft10"
                  }`}
                  onClick={() => {
                    setSureDelete(true);
                  }}
                >
                  Delete
                </p>
              ) : (
                ""
              )}
            </div>
            <p
              className="cancelBulkModalBtn"
              onClick={() => {
                if (saveable) {
                  setSureExit(true);
                } else {
                  closeModal();
                }
              }}
            >
              {memoVals.showTimesheet === "new" ||
              memoVals.showTimesheet === "new2"
                ? "Cancel"
                : "Close"}
            </p>
          </div>
        </div>
      )}
      {cantFindTeam[0] ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setCantFindTeam([]);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You already have{" "}
              {cantFindTeam.length == 1
                ? "an existing timesheet"
                : "existing timesheets"}{" "}
              for {dateStringer.list(cantFindTeam)} that will capture today's
              hours.
              <br />
              <br />
              Wait until tomorrow to create a new timesheet for the team you
              need - otherwise amend your existing timesheet in 'Timesheets' in
              'My hours' to record hours for today. <br />
              <br />
              Two timesheets for the same team cannot overlap each other.
            </p>
            <div className="areYouModalBtnsDiv">
              <NavLink
                to="timesheets"
                className="areYouSureModalYesBtn understoodBtn"
                onClick={() => {
                  setCantFindTeam([]);
                  // setNoTeams(false);
                }}
              >
                {/* <p
                  // className="areYouSureModalYesBtn understoodBtn"
                  onClick={() => {
                    setCantFindTeam([]);
                  }}
                > */}
                Understood
                {/* </p> */}
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {noTeams ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            memoVals.setShowTimesheet((x) => false);
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You already have{" "}
              {noTeams.length == 1
                ? "an existing timesheet"
                : "existing timesheets"}{" "}
              for {dateStringer.list(noTeams)}.<br />
              <br />
              Wait until tomorrow to create a new timesheet - otherwise amend
              your existing timesheets to record hours for today.
            </p>
            <div className="areYouModalBtnsDiv">
              <NavLink
                to="timesheets"
                className="areYouSureModalYesBtn understoodBtn"
                onClick={() => {
                  memoVals.setShowTimesheet((x) => false);
                  // setNoTeams(false);
                }}
              >
                {/* <p
                  // className="areYouSureModalYesBtn understoodBtn"
                  onClick={() => {
                    // memoVals.setShowTimesheet( x=>false);
                  }}
                > */}
                Understood
                {/* </p> */}
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {tooFarBack > 0 ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setTooFarBack(0);
            setStartDateInput(
              dateStringer.createDateInputFromDateString(
                dateStringer.createStringFromTimestamp(new Date().getTime())
              )
            );
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              You cannot create a timesheet to start more than {tooFarBack} days
              ago.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn understoodBtn"
                onClick={() => {
                  setTooFarBack(0);
                  setStartDateInput(
                    dateStringer.createDateInputFromDateString(
                      dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      )
                    )
                  );
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureDelete ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureDelete(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to delete this timesheet?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/delete-timesheet`,
                      {
                        timesheetID: savedButNotSubmitted, // "new" is new one needs to be made
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setShowTimesheet((x) => false);
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );
                        if (savedButNotSubmitted) {
                          setCountData({
                            userTimesheets: countData.userTimesheets - 1,
                          });
                        }
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDelete(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {overlappingItem.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setOverlappingItem({ show: false });
            setStartDateInput(
              dateStringer.createDateInputFromDateString(
                dateStringer.createStringFromTimestamp(new Date().getTime())
              )
            );
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              The date you have selected this timesheet to start from overlaps
              another timesheet you have already{" "}
              {overlappingItem.submitted ? "submitted" : "saved"} for{" "}
              {overlappingItem.teamName}.
            </p>
            {overlappingItem.approved || overlappingItem.declined ? (
              <p className="overlapsRenTxt">
                Your last timesheet ended{" "}
                {dateStringer.printedDateFromDs(
                  overlappingItem.endDs,
                  true,
                  true
                )}
                . Your new timesheet should start after this date.
              </p>
            ) : (
              ""
            )}
            {overlappingItem.approved || overlappingItem.declined ? (
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setOverlappingItem({ show: false });
                  setStartDateInput(
                    dateStringer.createDateInputFromDateString(
                      dateStringer.createStringFromTimestamp(
                        new Date().getTime()
                      )
                    )
                  );
                }}
              >
                Understood
              </p>
            ) : (
              <div className="areYouModalBtnsDiv">
                <p
                  className="areYouSureModalYesBtn loadOtherTs"
                  onClick={() => {
                    memoVals.setShowTimesheet((x) => {
                      return {
                        timesheetID: overlappingItem.timesheetID,
                      };
                    });
                    setOverlappingItem({ show: false });
                  }}
                >
                  Load existing
                  {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
                </p>{" "}
                <p
                  className="areYouModalNoBtn"
                  onClick={() => {
                    setStartDateInput(
                      dateStringer.createDateInputFromDateString(
                        dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        )
                      )
                    );
                    setOverlappingItem({ show: false });
                  }}
                >
                  Cancel
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {sureSubmit ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureDelete(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you ready to submit this timesheet to your manager for
              approval?
              <br />
            </p>
            <div className="areYouModalBtnsDiv">
              <NavLink
                to="/timesheets"
                className="areYouSureModalYesBtn positiveBtn"
                onClick={() => {
                  let nowDs = dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  );
                  axios
                    .post(
                      `${serverURL}/save-timesheet`,
                      {
                        submit: nowDs,
                        timesheetID:
                          typeof memoVals.showTimesheet === "object" &&
                          memoVals.showTimesheet.timesheetID
                            ? memoVals.showTimesheet.timesheetID
                            : memoVals.showTimesheet, // "new" is new one needs to be made
                        teamID:
                          memoVals.showTimesheet === "new" ||
                          memoVals.showTimesheet === "new2"
                            ? selectedTeam
                            : "",
                        data: inputState,
                        startDs:
                          memoVals.showTimesheet === "new" ||
                          memoVals.showTimesheet === "new2"
                            ? dateStringer.createStringFromDateInput(
                                startDateInput
                              )
                            : "",
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                        schedAmends,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setUpdateUserTimesheets(
                          (x) => !memoVals.updateUserTimesheets
                        );

                        setSubmitted(nowDs);

                        setCountData({
                          userTimesheets: countData.userTimesheets - 1,
                        });
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Timesheet submitted",
                            colour: "green",
                            duration: 4000,
                          };
                        });
                        memoVals.setShowTimesheet((x) => false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Submit{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </NavLink>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureSubmit(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {sureExit ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureExit(false);
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              You have unsaved changes.
              <br />
              <br />
              Are you sure you want to exit this timesheet?
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  memoVals.setShowTimesheet((x) => false);
                }}
              >
                Exit{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureExit(false);
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showOtherTimesheets ? (
        <div
          className="filtTagsUnderlay"
          onClick={() => {
            setShowOtherTimesheets(false);
          }}
        >
          <div className="tagsFiltDropper">
            <div
              className={`filtTagsModal ${
                memoVals.mobModal ? "filtTagsModalMob" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className={`toilHeader `}>
                <img
                  src={navyClose}
                  onClick={() => {
                    setShowOtherTimesheets(false);
                  }}
                  alt="Close"
                  className="closeMyShiftModalImg"
                />
                <p className="toilHeaderTxt">Outstanding timesheets</p>
                {/* <div className="myShiftModalHeaderRight">&nbsp;</div> */}
              </div>
              <div className="tagsFiltScrollBox">
                {!otherTimesheets[0] ? (
                  <p className="noFiltTagsTxt">
                    You have no outstanding timesheets
                  </p>
                ) : (
                  otherTimesheets
                    .sort((a, b) => {
                      return a.startTs - b.startTs;
                    })
                    .map((ts) => {
                      return (
                        <div
                          className="tagFiltItem tsOtherItem"
                          onClick={() => {
                            if (ts.submitted) {
                              memoVals.setShowViewTimesheet((x) => {
                                return {
                                  show: true,
                                  timesheetID: ts.timesheetID,
                                  isMgr: false,
                                };
                              });
                              setShowOtherTimesheets(false);
                            } else {
                              memoVals.setShowTimesheet((x) => {
                                return {
                                  timesheetID: ts.timesheetID,
                                };
                              });
                              setShowOtherTimesheets(false);
                            }
                          }}
                        >
                          <p className="otherTsDsStrip">
                            {dateStringer.printedDateFromDs(ts.startDs)}{" "}
                            {ts.startDs.substr(0, 11) !== ts.endDs.substr(0, 11)
                              ? "-"
                              : ""}{" "}
                            {ts.startDs.substr(0, 11) !== ts.endDs.substr(0, 11)
                              ? dateStringer.printedDateFromDs(ts.endDs)
                              : ""}
                          </p>
                          <p className="otherTsTeamName">
                            {ts.submitted ? (
                              <span className="tsSubmitOther">Submitted -</span>
                            ) : (
                              ""
                            )}
                            {dateStringer.shorten(ts.teamName, 24)}
                          </p>
                        </div>
                      );
                    })
                )}
              </div>
              <div className="toilFooter">
                <p></p>
                <p
                  className="closeToilBtn"
                  onClick={() => {
                    setShowOtherTimesheets(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default TimesheetModal;
