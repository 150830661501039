import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  Fragment,
} from "react";

// this is a subcomponent of the Dashboard -- the dashboard file is already big enough

import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";
import websiteURL from "../../websiteURL";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import grid from "../../img/general/grid.svg";
import list from "../../img/general/list.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import edit from "../../img/general/edit.svg";
import convert from "../../img/general/convert.svg";
import convArr from "../../img/general/navySwap.svg";
import download from "../../img/general/download.svg";
import threeGreyDots from "../../img/general/threeGreyDots.svg";

import whiteTriangle from "../../img/general/whiteTriangle.svg";
import up from "../../img/general/up.svg";
import plane2 from "../../img/general/plane2.svg";
import mgrIcon2 from "../../img/general/mgrIcon2.svg";
import clock from "../../img/general/rotaClock2.svg";
import code from "../../img/general/fileTypes/code.svg";
import doc from "../../img/general/fileTypes/doc.svg";
import checkbox from "../../img/general/checkboxLilac.svg";
import img from "../../img/general/fileTypes/img.svg";
import music from "../../img/general/fileTypes/music.svg";
import pdf from "../../img/general/fileTypes/pdf.svg";
import ppt from "../../img/general/fileTypes/ppt.svg";
import spreadsheet from "../../img/general/fileTypes/spreadsheet.svg";
import zip from "../../img/general/fileTypes/zip.svg";
import video from "../../img/general/fileTypes/video.svg";
import folder from "../../img/general/fileTypes/folder.svg";
import folderIcon from "../../img/general/fileTypes/folderIcon.svg";
import folderHidden from "../../img/general/fileTypes/folderHidden.svg";
import privateIcon from "../../img/general/fileTypes/private.svg";

import close from "../../img/modals/close.svg";
import dateStringer from "../../tools/dateStringer";

const DashboardDocs = ({ users, setUsers, countBytes, teams, isAdmin }) => {
  const { checkUserState } = useContext(UserContext);

  const { mob, full, fullButNarrow, mobModal } = useContext(StyleContext);
  const {
    setShowEditUser,
    setShowEditShiftModal,
    updateSingleUserAllowances,

    setUpdateSingleUserAllowances,
    setShowConvertToilModal,
    setIndicate,
    device,
    setShowIndicate,
  } = useContext(DataContext);

  let [loading, setLoading] = useState(true);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let [inFolderID, setInFolderID] = useState("");
  let [inFolderName, setInFolderName] = useState("");
  let [fileViewGrid, setFileViewGrid] = useState(false);
  let [totBytes, setTotBytes] = useState(0);
  let [uploadLoading, setUploadLoading] = useState(false);
  let [selectedUser, setSelectedUser] = useState("");
  let [selectedTeamID, setSelectedTeamID] = useState("company");
  let [showUploadModal, setShowUploadModal] = useState(false);
  let [exceedSizeLimit, setExceedSizeLimit] = useState(false);
  let [hoveredFolderID, setHoveredFolderID] = useState("");
  let [renameFolderID, setRenameFolderID] = useState("");
  let [newFolderNameValue, setNewFolderNameValue] = useState("");
  let [sureDeleteFile, setSureDeleteFile] = useState("");
  let [moveToFolder, setMoveToFolder] = useState({ show: false });
  let folderNameInput = useRef();
  useEffect(() => {
    if (renameFolderID && folderNameInput && folderNameInput.current && !mob) {
      folderNameInput.current.focus();
    }
  }, [renameFolderID]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  const cancelSource1 = axios.CancelToken.source();
  useEffect(() => {
    axios
      .post(
        `${serverURL}/get-dash-files`,
        {
          userID:
            showTeamDocs && selectedTeamID === "company"
              ? "company"
              : selectedUser,
          folderID: inFolderID,
          teamID:
            showTeamDocs && selectedTeamID === "company" ? "" : selectedTeamID,
          // fromFolderID: inFolderID,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setFolders(
            response.data.files.filter((x) => {
              return x.folder;
            })
          );
          setFileViewGrid(response.data.fileViewGrid);
          setInFolderName(response.data.currFolder);

          setFiles(response.data.files);
          setLoading(false);
          setTotBytes(response.data.bytes);
        }
      });
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [inFolderID, selectedUser, selectedTeamID]);

  let [files, setFiles] = useState([]);
  let [folders, setFolders] = useState([]);

  let [showTeamDocs, setShowTeamDocs] = useState(true);
  const [draggedItem, setDraggedItem] = useState(null);

  const handleDragStart = (event, item) => {
    setDraggedItem(item);
  };

  const handleTouchStart = (event, item) => {
    setDraggedItem(item);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, targetFolder, folderID) => {
    event.preventDefault();
    if (!draggedItem || !targetFolder) return;

    // Check if dragged item is a file and target folder is a folder
    console.log({ draggedItem, targetFolder });

    axios
      .post(
        `${serverURL}/move-file-or-folder`,
        {
          fileID: draggedItem.folder ? "" : draggedItem.fileID,
          folderID: draggedItem.folder ? draggedItem.folderID : "",
          intoFolderID: targetFolder.folderID,
        },

        {
          withCredentials: true,
          credentials: "include",
          // cancelToken: cancelSource1.token,
        }
      )
      .then((response1) => {
        if (response1.data.message === "success") {
          axios
            .post(
              `${serverURL}/get-dash-files`,
              {
                userID:
                  showTeamDocs && selectedTeamID === "company"
                    ? "company"
                    : selectedUser,
                folderID: inFolderID,
                teamID:
                  showTeamDocs && selectedTeamID === "company"
                    ? ""
                    : selectedTeamID,
              },

              {
                withCredentials: true,
                credentials: "include",
              }
            )
            .then((response) => {
              if (response.data.message === "success") {
                setFolders(
                  response.data.files.filter((x) => {
                    return x.folder;
                  })
                );
                setFiles(response.data.files);
                setLoading(false);
                setInFolderName(response.data.currFolder);

                setTotBytes(response.data.bytes);
              }
            });
        } else {
          console.log("Error moving file or folder");
        }
      });

    if (!draggedItem.folder && targetFolder.folder) {
      // Do something to move the file into the folder
      console.log(
        `Moving file "${draggedItem.fileName}" into folder "${targetFolder.fileName}"`
      );
    } else if (draggedItem.folder && targetFolder.folder) {
      // Do something to move the folder into the folder
      console.log(
        `Moving folder "${draggedItem.fileName}" into folder "${targetFolder.fileName}"`
      );
    } else if (!draggedItem.folder && targetFolder.upRoot) {
      console.log(
        `Moving file "${draggedItem.fileName}" into parent folder "${targetFolder.folderName} which is ${targetFolder.folderID}"`
      );
    } else if (draggedItem.folder && targetFolder.upRoot) {
      console.log(
        `Moving file "${draggedItem.fileName}" into parent folder "${targetFolder.folderName} which is ${targetFolder.folderID}"`
      );
    } else if (!draggedItem.folder && folderID) {
      `Moving file "${draggedItem.fileName}" into folder "${folderID} x17171"`;
    }
    setDraggedItem(null);
  };

  let getFileImg = (type) => {
    if (type.startsWith("image/")) {
      return img;
    } else if (
      type.startsWith("text/") &&
      !type.includes("html") &&
      !type.includes("css") &&
      !type.includes("javascript")
    ) {
      if (type.includes("xls") || type.includes("csv")) {
        return spreadsheet;
      }
      return doc;
    } else if (type.startsWith("audio/")) {
      return music;
    } else if (type.startsWith("video/")) {
      return video;
    } else if (type.startsWith("application/") && type.includes("pdf")) {
      return pdf;
    } else if (
      type.startsWith(
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) ||
      type === "application/vnd.ms-powerpoint" ||
      type === "application/keynote"
    ) {
      return ppt;
    } else if (type.startsWith("application/zip")) {
      return zip;
    } else if (type.includes("xls") || type.includes("csv")) {
      return spreadsheet;
    } else {
      return code;
    }
  };

  let [fName, setFName] = useState("");

  // master return
  return (
    <div className="dashAllowancesContainer">
      <div className="dashAllowsTopBar">
        <div className="toilHelpBtns docHeaderWidth">
          <select
            className={`dashDocsUserSelect ${
              window.innerWidth < 407 ? "dashDocsUserSelectMini" : ""
            }`}
            value={showTeamDocs ? selectedTeamID : selectedUser}
            onChange={(e) => {
              if (e.target.value) {
                if (e.target.value === "company") {
                  setFName("");
                } else {
                  if (showTeamDocs) {
                    let tFilt = teams.filter((x) => {
                      return x.teamID === e.target.value;
                    });

                    if (tFilt[0]) {
                      setFName(tFilt[0].teamName);
                    }
                  } else {
                    let userFilt = users.filter((x) => {
                      return x.userID === e.target.value;
                    });

                    if (userFilt[0]) {
                      setFName(userFilt[0].fName);
                    }
                  }
                }
                setLoading(true);

                if (showTeamDocs) {
                  setSelectedUser("");
                  setSelectedTeamID(e.target.value);
                } else {
                  setSelectedUser(e.target.value);
                  setSelectedTeamID("");
                }

                setInFolderName("Root directory");
                setInFolderID("");
              }
            }}
          >
            {/* {checkUserState.permissions === 2 ? ( */}
            {!showTeamDocs && <option value="">-- Employee</option>}
            {showTeamDocs && isAdmin && (
              <option value="company">Company documents</option>
            )}
            {showTeamDocs && checkUserState.permissions === 2 && (
              <option value="">-- Team</option>
            )}

            {/* <option value="company">-- Company documents</option> */}
            {showTeamDocs
              ? teams
                  .sort((a, b) => {
                    return a.teamName.localeCompare(b.teamName);
                  })
                  .map((team) => {
                    return (
                      <option key={team.teamID} value={team.teamID}>
                        {team.teamName}
                      </option>
                    );
                  })
              : users
                  .sort((a, b) => {
                    return a.fName.localeCompare(b.fName);
                  })
                  .map((user) => {
                    return (
                      <option key={user.userID} value={user.userID}>
                        {user.fName} {user.lName}
                      </option>
                    );
                  })}
          </select>
          <div
            className="docsTeamToggle lilacColours"
            onClick={() => {
              setShowTeamDocs(!showTeamDocs);
              setInFolderID("");
              setFiles([]);
              setFiles([]);
              if (!showTeamDocs) {
                setSelectedTeamID("company");
                setTotBytes(0);
              } else {
                setSelectedTeamID("");
                setSelectedUser("");
                setTotBytes(0);
              }
            }}
          >
            {window.innerWidth > 440
              ? showTeamDocs
                ? "Employee documents"
                : "Team documents"
              : showTeamDocs
              ? "Employee docs"
              : "Team docs"}
            {/* <div
              className={`docTeamToggleImgFrame ${!showTeamDocs && "opac08"}`}
            > */}
            {/* <img
                src={checkbox}
                alt="Team docs"
                className={`docTeamToggleImg ${showTeamDocs ? "" : "invis"}`}
              /> */}
            {/* </div> */}
          </div>
        </div>{" "}
        <div className="toilWeeksLimitTxt"></div>
      </div>
      <div className={`userDashRight noScroll dashRightMob100w`}>
        <div className={`avgMpwDashRow x10910 absPage1Height docsTopBar`}>
          <div className="userDashDetailsLeftRow docSashLeftRow noBorder">
            <div className={loading ? "" : "x1093901111a"}>
              {loading ? (
                ""
              ) : (
                // <img
                //   src={horizontalBalls}
                //   alt="Loading user"
                //   className="dashUserLoadingimg docsLoadingDash"
                // />
                <div
                  className={`dashMenuFilesLeft`}
                  onClick={() => {
                    console.log({
                      selectedUser,
                      selectedTeamID,
                    });
                  }}
                >
                  {!selectedUser && !selectedTeamID ? (
                    <p className="dashDocsSizeMb bbcada">Select an employee</p>
                  ) : totBytes ? (
                    <p className="dashDocsSizeMb">
                      {dateStringer.bytes(totBytes)}
                    </p>
                  ) : (
                    <p className="dashDocsSizeMb">
                      /{" "}
                      {inFolderID === "self_"
                        ? `${dateStringer.possession(fName)} uploads`
                        : dateStringer.shorten(
                            inFolderName || "Root directory",
                            33
                          )}
                    </p>
                  )}
                </div>
              )}
            </div>

            <div
              className={`newFileNewFolderWrap ${
                !selectedTeamID && !selectedUser ? "hidden" : ""
              }`}
            >
              {" "}
              {/* {!inFolderID && ( */}
              <p
                className={`uploadDocDashBtn ${
                  countBytes(files, true) > 2147483648 ? "invis" : ""
                }`}
                onClick={() => {
                  setShowUploadModal({ show: true, userID: selectedUser });
                  //   if (uploadLoading) {
                  //     setUploadLoading(false);
                  //   }
                }}
              >
                Upload file
              </p>
              {/* )} */}
              {!inFolderID && !showTeamDocs && selectedTeamID !== "company" ? (
                <img
                  src={folder}
                  className="newUserFolder"
                  alt="Create new folder"
                  onClick={() => {
                    setNewFolderNameValue("");
                    // let existing = files.filter((x) => {
                    //   return x.new;
                    // });

                    // if (!existing[0]) {
                    let id = new Date().getTime();
                    setFiles([
                      {
                        folder: true,
                        fileName: "",
                        new: true,
                        folderID: id,
                      },
                      ...files,
                    ]);
                    setRenameFolderID({
                      show: true,
                      name: "",
                      new: true,
                      folderID: id,
                      visible: false,
                    });
                    // }
                  }}
                />
              ) : (
                ""
              )}
              <img
                src={fileViewGrid ? list : grid}
                className="newUserFolder fileListViewToggleImg"
                alt="Create new folder"
                onClick={() => {
                  setFileViewGrid(!fileViewGrid);
                  axios
                    .post(
                      `${serverURL}/toggle-file-view`,
                      {
                        data: !fileViewGrid,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setFileViewGrid(response.data.fileViewGrid);
                      }
                    });
                }}
              />
            </div>
          </div>
        </div>
        {showTeamDocs && (
          <div className="teamDocsExplainBox">
            {selectedTeamID === "company"
              ? "The files shown below are only available to FlowRota administrators"
              : `The files shown below are available for all employees in ${fName}.`}
          </div>
        )}
        {loading ? (
          <img
            src={horizontalBalls}
            alt="Loading absence"
            className="loadingDashAbsImg"
          />
        ) : (
          <div
            className={`dashUserDocsGrid ${
              showTeamDocs ? "dashUserDocsGridTeams" : ""
            } dashUserDocsList ${device.ios ? "dashUserDocsListIos" : ""} ${
              !fileViewGrid
                ? `listDocsDiv ${
                    showTeamDocs
                      ? `listDocsDivTeams ${
                          mob ? "" : "listDocsDivTeamsDesktop"
                        }`
                      : mob
                      ? "listDocsDivMob"
                      : ""
                  }`
                : ""
            } `}
            onDragOver={handleDragOver}
            onDrop={(event) => {}} // Assuming root folder ID is 'root'
          >
            {!loading && inFolderID ? (
              <div
                // root item
                className={`backFolderItem ${
                  !fileViewGrid && `backFolderItemList `
                }`}
                onDragOver={handleDragOver}
                onDrop={(event) =>
                  handleDrop(event, {
                    folderID: "",
                    upRoot: true,
                    folderName: "Root directory",
                  })
                } // Assuming root folder ID is 'root'
                onTouchMove={handleTouchMove}
                onClick={() => {
                  setLoading(true);
                  axios
                    .post(
                      `${serverURL}/get-dash-files`,
                      {
                        userID:
                          showTeamDocs && selectedTeamID === "company"
                            ? "company"
                            : selectedUser,
                        folderID: inFolderID,
                        teamID:
                          showTeamDocs && selectedTeamID === "company"
                            ? ""
                            : selectedTeamID,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setFolders(
                          response.data.files.filter((x) => {
                            return x.folder;
                          })
                        );
                        // if (response.data.intoParent) {
                        // console.log(
                        //   "x1 Changing inFolderID from ",
                        //   inFolderID,
                        //   " to ",
                        //   parentFolderID
                        // );

                        // setInFolderID("");

                        // // if (
                        // //   !response.data.currFolder
                        // // ) {
                        // //   console.log(
                        // //     "x2 Changing inFolderID from ",
                        // //     inFolderID,
                        // //     " to NOTHING"
                        // //   );
                        // //   setInFolderID("");
                        // // }
                        // setTotBytes(0);
                        // setInFolderName(
                        //   response.data.currFolder
                        // );
                        // } else {
                        setTotBytes(response.data.bytes);
                        setInFolderName("Root directory");
                        setInFolderID("");
                        // }
                        setFileViewGrid(response.data.fileViewGrid);

                        setFiles(response.data.files);
                        // setParentFolderID("");
                        // setParentFolderName("");

                        setLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                <img
                  src={up}
                  className={`folderBack  ${
                    fileViewGrid ? "" : "folderBackList"
                  }`}
                  alt="Back"
                />
                <p
                  className={`backFolderName ${
                    fileViewGrid ? "" : "backFolderNameList"
                  }`}
                >
                  {"Root directory"}
                </p>
              </div>
            ) : (
              ""
            )}
            {/* files array here */}
            {files
              .sort((a, b) => {
                // Folders come before files
                if (a.folder && !b.folder) {
                  return -1;
                } else if (!a.folder && b.folder) {
                  return 1;
                } else {
                  // Both are either folders or files, sort by fileName
                  return a.fileName.localeCompare(b.fileName);
                }
              })
              .map((file, i) => {
                let firstFileIndex = files
                  .sort((a, b) => {
                    // Folders come before files
                    if (a.folder && !b.folder) {
                      return -1;
                    } else if (!a.folder && b.folder) {
                      return 1;
                    } else {
                      // Both are either folders or files, sort by fileName
                      return a.fileName.localeCompare(b.fileName);
                    }
                  })
                  .findIndex((x) => !x.folder); // Find the index of the first file (not a folder)

                return (
                  <div
                    className={`userDashFileItem ${
                      fileViewGrid
                        ? ""
                        : firstFileIndex === i
                        ? "firstFileListView"
                        : ""
                    } ${file.folder && file.new ? "newFolderPopIn none" : ""} ${
                      file.folder ? `folderItem` : ""
                    } draggable ${fileViewGrid ? "" : "userDashFileItemList"}`}
                    onClick={() => {
                      if (!fileViewGrid) {
                        if (file.folder) {
                          console.log(
                            "x3 Changing inFolderID from ",
                            inFolderID,
                            " to ",
                            file.folderID
                          );
                          setInFolderID(file.folderID);
                          setLoading(true);
                          axios
                            .post(
                              `${serverURL}/get-dash-files`,
                              {
                                fromFolderID: inFolderID,

                                userID:
                                  showTeamDocs && selectedTeamID === "company"
                                    ? "company"
                                    : selectedUser,
                                folderID: inFolderID,
                                teamID:
                                  showTeamDocs && selectedTeamID === "company"
                                    ? ""
                                    : selectedTeamID,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setFolders(
                                  response.data.files.filter((x) => {
                                    return x.folder;
                                  })
                                );
                                // if (
                                //   !response.data
                                //     .currFolder
                                // ) {
                                //   console.log(
                                //     "x4 Changing inFolderID from ",
                                //     inFolderID,
                                //     " to NOTHING"
                                //   );
                                //   setInFolderID("");
                                // }
                                setFileViewGrid(response.data.fileViewGrid);

                                setTotBytes(0);
                                setInFolderName(response.data.currFolder);
                                setFiles(response.data.files);

                                setLoading(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        } else {
                          if (file.folder) {
                            setRenameFolderID(
                              {
                                show: true,
                                folderID: file.folderID,
                                name: file.fileName,
                                visible: file.employeeVis,
                                teamID: file.teamID,
                              }
                              // file.folderID
                            );
                          } else {
                            // is a file
                            setRenameFolderID(
                              {
                                show: true,
                                fileID: file.fileID,
                                name: file.fileName,
                                visible: file.employeeVis,
                                selfUploaded: file.selfUploaded,
                                teamID: file.teamID,
                              }
                              // file.folderID
                            );
                          }
                          // setIndicate({
                          //   show: true,
                          //   message: "Downloading - please wait",
                          //   colour: "orange",
                          //   duration: 4000,
                          // });
                          // // - - - - - - - - - - - - -
                          // axios({
                          //   method: "get",
                          //   url: `${serverURL}/download-file/${file.fileName}`,
                          //   responseType: "blob", // Set the responseType to 'blob' to handle binary data
                          // })
                          //   .then((response) => {
                          //     // Create a URL for the blob data
                          //     const blobUrl = window.URL.createObjectURL(
                          //       response.data
                          //     );

                          //     // Create a temporary link element
                          //     const link = document.createElement("a");
                          //     link.href = blobUrl;

                          //     // Set the download attribute to specify the file name
                          //     link.download = file.fileName;
                          //     // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                          //     // Append the link to the body
                          //     document.body.appendChild(link);

                          //     // Trigger the download by programmatically clicking on the link
                          //     link.click();

                          //     // Clean up: remove the temporary link
                          //     document.body.removeChild(link);
                          //     setShowIndicate({ show: false });
                          //   })
                          //   .catch((error) => {
                          //     // Handle errors
                          //     console.error("Error downloading file:", error);
                          //   });
                        }
                      }
                    }}
                    key={file.fileID || file.folderID}
                    onMouseLeave={() => {
                      if (hoveredFolderID) {
                        setHoveredFolderID("");
                      }
                    }}
                    onMouseEnter={() => {
                      if (file.folder && !file.new) {
                        setHoveredFolderID(file.folderID);
                      }
                    }}
                    draggable
                    onDragStart={(event) => {
                      event.currentTarget.classList.add("dragging"); // Add a class to the dragged element

                      handleDragStart(event, file);
                    }}
                    onDragEnd={(event) => {
                      event.currentTarget.classList.remove("dragging"); // Remove the class when dragging ends
                    }}
                    // desktop
                    onDrop={(event) => {
                      if (file.folder && !inFolderID) {
                        handleDrop(event, file);
                      }
                    }}
                    onDragOver={(event) => {
                      if (file.folder && !inFolderID) {
                        handleDragOver(event);
                      }
                    }}
                    style={
                      {
                        // border: "1px solid black",
                        // margin: "5px",
                        // padding: "5px",
                      }
                    }
                  >
                    <img
                      draggable={false} // Prevent the image from being draggable
                      // onDragStart={(e) =>
                      //   e.preventDefault()
                      // } // Prevent default drag behavior for images
                      src={
                        file.folder
                          ? file.employeeVis || selectedTeamID === "company"
                            ? folderIcon
                            : folderHidden
                          : getFileImg(file.type)
                      }
                      className={`dashUserFileIcon ${
                        selectedTeamID === "company" ? "greyScaleHalf" : ""
                      }  ${
                        file.folder && renameFolderID !== file.folderID
                          ? "folderIconNonRename"
                          : "folderIconRename"
                      } ${
                        !mob && hoveredFolderID === file.folderID && file.folder
                          ? "folderImgHovered"
                          : ""
                      } ${
                        fileViewGrid
                          ? ""
                          : `dashUserFileIconList ${
                              file.folder ? "" : "dashUserFileIconListFile"
                            }`
                      }`}
                      alt={file.type}
                      onClick={() => {
                        if (file.folder) {
                          console.log(
                            "x3 Changing inFolderID from ",
                            inFolderID,
                            " to ",
                            file.folderID
                          );
                          setInFolderID(file.folderID);
                          setLoading(true);
                          axios
                            .post(
                              `${serverURL}/get-dash-files`,
                              {
                                userID:
                                  showTeamDocs && selectedTeamID === "company"
                                    ? "company"
                                    : selectedUser,
                                folderID: inFolderID,
                                teamID:
                                  showTeamDocs && selectedTeamID === "company"
                                    ? ""
                                    : selectedTeamID,
                                fromFolderID: inFolderID,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setFolders(
                                  response.data.files.filter((x) => {
                                    return x.folder;
                                  })
                                );
                                // if (
                                //   !response.data
                                //     .currFolder
                                // ) {
                                //   console.log(
                                //     "x4 Changing inFolderID from ",
                                //     inFolderID,
                                //     " to NOTHING"
                                //   );
                                //   setInFolderID("");
                                // }
                                setFileViewGrid(response.data.fileViewGrid);

                                setTotBytes(0);
                                setInFolderName(response.data.currFolder);
                                setFiles(response.data.files);

                                setLoading(false);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        } else {
                          if (file.folder) {
                            setRenameFolderID(
                              {
                                show: true,
                                folderID: file.folderID,
                                name: file.fileName,
                                visible: file.employeeVis,
                                teamID: file.teamID,
                              }
                              // file.folderID
                            );
                          } else {
                            // is a file
                            setRenameFolderID(
                              {
                                show: true,
                                fileID: file.fileID,
                                name: file.fileName,
                                visible: file.employeeVis,
                                selfUploaded: file.selfUploaded,
                                teamID: file.teamID,
                              }
                              // file.folderID
                            );
                          }
                          // setIndicate({
                          //   show: true,
                          //   message: "Downloading - please wait",
                          //   colour: "orange",
                          //   duration: 4000,
                          // });
                          // // - - - - - - - - - - - - -
                          // axios({
                          //   method: "get",
                          //   url: `${serverURL}/download-file/${file.fileName}`,
                          //   responseType: "blob", // Set the responseType to 'blob' to handle binary data
                          // })
                          //   .then((response) => {
                          //     // Create a URL for the blob data
                          //     const blobUrl = window.URL.createObjectURL(
                          //       response.data
                          //     );

                          //     // Create a temporary link element
                          //     const link = document.createElement("a");
                          //     link.href = blobUrl;

                          //     // Set the download attribute to specify the file name
                          //     link.download = file.fileName;
                          //     // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                          //     // Append the link to the body
                          //     document.body.appendChild(link);

                          //     // Trigger the download by programmatically clicking on the link
                          //     link.click();

                          //     // Clean up: remove the temporary link
                          //     document.body.removeChild(link);
                          //     setShowIndicate({ show: false });
                          //   })
                          //   .catch((error) => {
                          //     // Handle errors
                          //     console.error("Error downloading file:", error);
                          //   });
                        }
                      }}
                    />
                    {/* {!file.employeeVis && !file.folder && file.fileID ? (
                      <img
                        src={privateIcon}
                        alt="Not visible to employee"
                        className={`privateFileIcon ${
                          file.userID === "company" ? "invis" : ""
                        }`}
                      />
                    ) : (
                      ""
                    )} */}
                    {renameFolderID === file.folderID && file.folder ? (
                      <input
                        type="text"
                        ref={folderNameInput}
                        className="folderNameInput"
                        value={newFolderNameValue}
                        placeholder={file.new ? "New folder" : file.fileName}
                        onKeyPress={(e) => {
                          enterFct(e);
                        }}
                        onChange={(e) => {
                          setNewFolderNameValue(e.target.value);
                        }}
                      ></input>
                    ) : (
                      <p
                        className={`dashUserFileName ${
                          file.folder ? "noEllipsis" : "fileNameBox"
                        } ${
                          fileViewGrid
                            ? ""
                            : `dashUserFileNameList ${
                                file.folder ? "" : "dashUserFileNameListFile"
                              }`
                        }`}
                      >
                        {file.folder && file.folderID === "self_"
                          ? `${dateStringer.possession(fName)} uploads`
                          : dateStringer.shorten(
                              dateStringer.removeTsFromFileName(file.fileName),
                              13
                            )}
                        {!file.folder && dateStringer.getExt(file.fileName)}{" "}
                      </p>
                    )}

                    <div
                      className={`fileListRightSection ${
                        fileViewGrid ? "fileListRightSectionGrid" : ""
                      }`}
                    >
                      {" "}
                      {!file.folder ? (
                        <p
                          className={`filesize ${
                            fileViewGrid ? "" : "filesizeList"
                          }`}
                        >
                          {dateStringer.bytes(file.bytes)}
                        </p>
                      ) : (
                        ""
                      )}
                      {renameFolderID !== file.folderID && file.folder && (
                        <p
                          className={`dashUserFileName fileBytes ${
                            fileViewGrid ? "" : "fileBytesList"
                          }`}
                        >
                          {file.folder
                            ? `${file.qty || 0} file${
                                file.qty === 1 ? "" : "s"
                              }`
                            : dateStringer.bytes(file.bytes)}{" "}
                        </p>
                      )}
                      {file.employeeVis || file.folder ? (
                        ""
                      ) : (
                        <img
                          src={privateIcon}
                          alt="Not visible to employee"
                          className={`privateFileIcon ${
                            file.userID === "company" ? "invis" : ""
                          } ${fileViewGrid ? "" : "privateFileIconList"}`}
                        />
                      )}
                    </div>

                    {/* <img
                      src={threeGreyDots}
                      alt="Edit folder name"
                      className={`editFolterNameImg ${
                        !file.employeeVis && !file.folder
                          ? "editFolterNameImgPriv"
                          : ""
                      }`}
                      onClick={() => {
                        if (file.folder) {
                          setRenameFolderID(
                            {
                              show: true,
                              folderID: file.folderID,
                              name: file.fileName,
                              visible: file.employeeVis,
                              teamID: file.teamID,
                            }
                            // file.folderID
                          );
                        } else {
                          // is a file
                          setRenameFolderID(
                            {
                              show: true,
                              fileID: file.fileID,
                              name: file.fileName,
                              visible: file.employeeVis,
                              selfUploaded: file.selfUploaded,
                              teamID: file.teamID,
                            }
                            // file.folderID
                          );
                        }
                      }}
                    /> */}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {showUploadModal ? (
        <div
          className="editProPicUnderlay"
          onClick={() => {
            setShowUploadModal(false);
            if (exceedSizeLimit) {
              setExceedSizeLimit(false);
            }
          }}
        >
          <div
            className="editProPicModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="editProPicModalHeader x2989248423">
              <img
                src={close}
                alt="Close edit profile photo modal"
                className="closeEditProPicModalIcon"
                onClick={() => {
                  setShowUploadModal(false);
                  if (exceedSizeLimit) {
                    setExceedSizeLimit(false);
                  }
                }}
              />
              Upload a file
            </div>
            <div className="editProPicModalContentBox">
              {uploadLoading ? (
                <p className="waitForProPic">Uploading...</p>
              ) : (
                <input
                  type="file"
                  title=" "
                  id="avatar"
                  name="avatar"
                  className="proPicChangeBtnMyAcc"
                  // accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    let file = e.target.files[0];
                    console.log({ file });

                    if (file && file.size > 50000000) {
                      setExceedSizeLimit(true);
                    } else {
                      setExceedSizeLimit(false);
                      setUploadLoading(true);

                      // console.log("target: ", e.target.files);

                      try {
                        const formData = new FormData();
                        formData.append("file", file);
                        formData.append("name", file.name);
                        formData.append("type", file.type);

                        if (showTeamDocs) {
                          formData.append("teamID", selectedTeamID);
                        } else {
                          formData.append("userID", selectedUser);
                        }
                        formData.append(
                          "nowDs",
                          dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          )
                        );

                        const response = await axios.post(
                          `${serverURL}/upload-dash-file`,
                          formData,
                          {
                            withCredentials: true,
                            credentials: "include",
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );

                        if (response.data.message.includes("exceed")) {
                          setExceedSizeLimit(true);
                        }
                        if (response.data.message === "success") {
                          setFiles([...files, response.data.newFile]);
                          setShowUploadModal(false);
                          // Handle success
                          console.log("File uploaded successfully");
                        }
                        if (response.data.message === "2GB limit exceeded") {
                          setIndicate({
                            show: true,
                            message: "2GB employee limit exceeded",
                            colour: "red",
                            duration: 4000,
                          });
                        }
                      } catch (error) {
                        // Handle error
                        console.error("Error uploading file:", error);
                      } finally {
                        setUploadLoading(false);
                      }
                    }
                  }}
                />
              )}

              {!uploadLoading ? (
                <p className="uploadDisclaim">
                  By uploading files to FlowRota, you acknowledge that files are
                  stored in a public cloud and are accessible via a link. Whilst
                  FlowRota does not share links to anyone other than the
                  specific FlowRota user, please note that these files are not
                  encrypted and may be accessible to anyone with the link
                  without authentication. FlowRota uses Google Cloud for its
                  storage and assumes no responsibility for the availability or
                  security of these files once uploaded. We recommend exercising
                  caution when sharing sensitive information through FlowRota's
                  file uploads, and refrain from uploading highly confidential
                  information.{" "}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="editProPicModalFooter">
              <p className={`max25mb ${!exceedSizeLimit ? "invis" : ""}`}>
                Max 50MB file size limit
              </p>

              <p
                className="closeProPicModalBtn"
                onClick={() => {
                  setShowUploadModal(false);
                  if (exceedSizeLimit) {
                    setExceedSizeLimit(false);
                  }
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {renameFolderID.show ? (
        <div
          className={`overlapsRenewalModalUnderlay ${
            sureDeleteFile.show ? "invis" : ""
          }`}
          onClick={() => {
            setRenameFolderID({ show: false });
            setNewFolderNameValue("");
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              {renameFolderID.fileID
                ? "File"
                : renameFolderID.new
                ? "New folder"
                : "Folder settings"}
            </p>
            <p
              className={`overlapsRenTxt folFilName ${
                renameFolderID.new ? "none" : ""
              }`}
            >
              {renameFolderID.fileID
                ? renameFolderID.name
                : renameFolderID.new
                ? ""
                : renameFolderID.name}
            </p>
            {renameFolderID.fileID ? (
              <p
                className={`overlapsRenTxt downloadFileBtn lilacColours ${
                  renameFolderID.new ? "none" : ""
                }`}
                onClick={() => {
                  setIndicate({
                    show: true,
                    message: "Downloading - please wait",
                    colour: "orange",
                    duration: 4000,
                  });
                  // - - - - - - - - - - - - -
                  axios({
                    method: "get",
                    url: `${serverURL}/download-file/${renameFolderID.name}`,
                    responseType: "blob", // Set the responseType to 'blob' to handle binary data
                  })
                    .then((response) => {
                      // Create a URL for the blob data
                      const blobUrl = window.URL.createObjectURL(response.data);

                      // Create a temporary link element
                      const link = document.createElement("a");
                      link.href = blobUrl;

                      // Set the download attribute to specify the file name
                      link.download = renameFolderID.name;
                      // "your-file-name.jpg"; // Replace 'your-file-name.jpg' with the desired file name

                      // Append the link to the body
                      document.body.appendChild(link);

                      // Trigger the download by programmatically clicking on the link
                      link.click();

                      // Clean up: remove the temporary link
                      document.body.removeChild(link);
                      setShowIndicate({ show: false });
                    })
                    .catch((error) => {
                      // Handle errors
                      console.error("Error downloading file:", error);
                    });
                }}
              >
                Download{" "}
                <img
                  src={download}
                  alt="Download"
                  className="downloadIconDocImg"
                />
              </p>
            ) : (
              ""
            )}
            {renameFolderID.folderID && (
              <p className="editFolderRowTitle">Folder name</p>
            )}
            {renameFolderID.folderID && (
              <input
                type="text"
                className="editFolderNameInput"
                placeholder={renameFolderID.name}
                value={newFolderNameValue}
                ref={folderNameInput}
                onChange={(e) => {
                  setNewFolderNameValue(e.target.value);
                }}
              ></input>
            )}
            {selectedUser !== "company" && !renameFolderID.selfUploaded && (
              <div
                className="editFolderRow"
                onClick={() => {
                  setRenameFolderID({
                    show: true,
                    visible: !renameFolderID.visible,
                    visChanged: true,
                    name: renameFolderID.name,
                    folderID: renameFolderID.folderID || "",
                    fileID: renameFolderID.fileID || "",
                    new: renameFolderID.new,
                    teamID: renameFolderID.teamID || "",
                  });
                }}
              >
                Visible to employee{showTeamDocs ? "s" : ""} {/* KNOB BEGIN */}
                <div
                  className={`editDayFxSchedKnob ${
                    mobModal ? "allowSwapsTeamKnob" : ""
                  }`}
                >
                  <div className="knobContainer allowSwapsKnobMyRota marginRight3 x10s00">
                    <div
                      className={`switchKnob ${
                        renameFolderID.visible
                          ? "knobOn allowSwapsKnobOn"
                          : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg allowSwapsKnobBg"></div>
                  </div>
                </div>
                {/*  KNOB END */}
              </div>
            )}
            {!renameFolderID.folderID && !renameFolderID.teamID ? (
              <div
                className={`editFolderRow noHovFold ${
                  !folders[0] ? "invis_" : ""
                }`}
              >
                Move file to folder
                <select
                  className="moveToFolderDrop"
                  onChange={(e) => {
                    setMoveToFolder(e.target.value);
                  }}
                >
                  <option key="--" value="">
                    Select folder
                  </option>

                  {inFolderName && inFolderName !== "Root directory" ? (
                    <option value="root">Root directory</option>
                  ) : (
                    ""
                  )}
                  {folders.map((folder) => {
                    if (folder.fileName !== inFolderName) {
                      return (
                        <option key={folder.folderID}>{folder.fileName}</option>
                      );
                    }
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
            {!renameFolderID.new && (
              <p
                className="delFolBtn"
                onClick={() => {
                  setSureDeleteFile({
                    show: true,
                    fileName: renameFolderID.name,
                    folderID: renameFolderID.folderID || "",
                    fileID: renameFolderID.fileID || "",
                  });
                }}
              >
                Delete {renameFolderID.folderID ? "folder" : "file"}
              </p>
            )}
            {/* <div className="editFolderRow"> */}
            {/* </div> */}
            {/* <p className="overlapsRenTxt">
              {renameFolderID.name}

              {renameFolderID.folderID}
            </p> */}
            <div className="areYouModalBtnsDiv">
              <p
                className={`areYouSureModalYesBtn ${
                  renameFolderID.new && !newFolderNameValue
                    ? "disable opac04"
                    : newFolderNameValue || renameFolderID.name
                    ? ""
                    : "disable opac04"
                }`}
                onClick={() => {
                  // console.log({ newFolderNameValue }, renameFolderID.name);
                  console.log({ renameFolderID });
                  if (renameFolderID.fileID && renameFolderID.visChanged) {
                    axios
                      .post(
                        `${serverURL}/toggle-file-visibility`,
                        {
                          fileID: renameFolderID.fileID,

                          data: renameFolderID.visible,
                        },

                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          let newArr = [];
                          files.map((f) => {
                            if (
                              f.fileID &&
                              f.fileID === renameFolderID.fileID
                            ) {
                              let newObj = f;
                              newObj.employeeVis = renameFolderID.visible;
                              newArr.push(newObj);
                            } else {
                              newArr.push(f);
                            }
                          });

                          setFiles(newArr);
                        }
                      });
                  }
                  if (renameFolderID.fileID) {
                    // move folder here
                    if (moveToFolder) {
                      let folderFilt = folders.filter((x) => {
                        return x.fileName === moveToFolder;
                      });
                      if (folderFilt[0] || moveToFolder === "root") {
                        axios
                          .post(
                            `${serverURL}/move-file-or-folder`,
                            {
                              fileID: renameFolderID.fileID,
                              // folderID: folderFilt[0].folderID,
                              intoFolderID:
                                moveToFolder === "root"
                                  ? ""
                                  : folderFilt[0].folderID,
                              visible: renameFolderID.visible,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                              // cancelToken: cancelSource1.token,
                            }
                          )
                          .then((response1) => {
                            if (response1.data.message === "success") {
                              axios
                                .post(
                                  `${serverURL}/get-dash-files`,
                                  {
                                    userID:
                                      showTeamDocs &&
                                      selectedTeamID === "company"
                                        ? "company"
                                        : selectedUser,
                                    folderID: response1.data.currentFolderID,
                                    teamID:
                                      showTeamDocs &&
                                      selectedTeamID === "company"
                                        ? ""
                                        : selectedTeamID,
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    setFolders(
                                      response.data.files.filter((x) => {
                                        return x.folder;
                                      })
                                    );
                                    setInFolderName(response.data.currFolder);

                                    setFiles(response.data.files);
                                    setTotBytes(response.data.bytes);
                                    setFileViewGrid(response.data.fileViewGrid);
                                  }
                                });
                            } else {
                              console.log("Error moving file or folder");
                            }
                          });
                      }
                    }
                  } else {
                    // dorian
                    if (renameFolderID.new) {
                      axios
                        .post(
                          `${serverURL}/new-folder`,
                          {
                            name: newFolderNameValue,
                            userID: selectedUser,
                            teamID: selectedTeamID,
                            parentFolderID: inFolderID ? inFolderID : "",
                            visible: renameFolderID.visible,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            let newArr = [];
                            files.forEach((x) => {
                              if (x.new) {
                                newArr.push({
                                  folderID: response.data.folderID,
                                  fileName: response.data.name,
                                  folder: true,
                                  employeeVis: renameFolderID.visible,
                                });
                              } else {
                                newArr.push(x);
                              }
                            });

                            setFiles(newArr);
                          }
                        });
                    } else {
                      axios
                        .post(
                          `${serverURL}/edit-folder-name`,
                          {
                            folderID: renameFolderID.folderID,
                            fileID: renameFolderID.fileID,
                            name: newFolderNameValue || renameFolderID.name,
                            visible: renameFolderID.visible,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            let newFiles = [];
                            files.forEach((x) => {
                              if (
                                !x.folder &&
                                renameFolderID.fileID &&
                                renameFolderID.fileID === x.fileID
                              ) {
                                newFiles.push({
                                  folder: false,
                                  fileID: x.fileID,
                                  fileName: x.fileName,
                                  employeeVis: renameFolderID.visible,
                                  ts: x.ts,
                                  ds: x.ds,
                                  folderID: x.folderID,
                                  type: x.type,
                                  bytes: x.bytes,
                                });
                              } else if (
                                x.folder &&
                                renameFolderID.folderID === x.folderID
                              ) {
                                newFiles.push({
                                  folder: true,
                                  folderID: x.folderID,
                                  fileName:
                                    newFolderNameValue || renameFolderID.name,
                                  qty: x.qty,
                                  employeeVis:
                                    selectedTeamID === "company"
                                      ? true
                                      : renameFolderID.visible,
                                });
                              } else {
                                newFiles.push(x);
                              }
                            });
                            setFiles(newFiles);
                            setRenameFolderID({ show: false });
                            setNewFolderNameValue("");
                          }
                        });
                    }
                  }
                  setRenameFolderID({ show: false });
                  setRenameFolderID({ show: false });
                  setMoveToFolder("");
                }}
              >
                Save{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setNewFolderNameValue("");
                  setRenameFolderID({ show: false });
                  setMoveToFolder("");
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureDeleteFile && sureDeleteFile.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            setSureDeleteFile({ show: false });
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <p className="overlapsRenTxt">
              Are you sure you want to permanently delete this{" "}
              {sureDeleteFile.folderID ? "folder" : "file"}?
              <br />
              <br />
              <span className="sureDeleteFilenameSpan">
                {sureDeleteFile.fileName}
              </span>
              {sureDeleteFile.folderID ? <br /> : ""}
              {sureDeleteFile.folderID ? <br /> : ""}
              {sureDeleteFile.folderID
                ? "Any files inside this folder will be deleted."
                : ""}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let fn = sureDeleteFile.fileName;
                  setSureDeleteFile({ show: false });
                  setRenameFolderID({ show: false });
                  axios
                    .post(
                      `${serverURL}/delete-dash-file`,
                      {
                        fileName: fn,
                        folderID: sureDeleteFile.folderID
                          ? sureDeleteFile.folderID
                          : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setFiles(
                          sureDeleteFile.folderID
                            ? files.filter((x) => {
                                return (
                                  !x.folderID ||
                                  x.folderID !== sureDeleteFile.folderID
                                );
                              })
                            : files.filter((x) => {
                                return x.fileName !== fn;
                              })
                        );
                      }
                    });
                }}
              >
                Yes{" "}
                {/* <img src={popup} alt="Info" className="addLeaveModalInfoPopUp" /> */}
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteFile({ show: false });
                  setRenameFolderID({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DashboardDocs;
