import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import axios from "axios";
import serverURL from "../serverURL";
import { DataContext } from "../contexts/DataContext";
import { StyleContext } from "../contexts/StyleContext";
import horizontalBalls from "../img/loaders/horizontalBalls.svg";

import close from "../img/modals/close.svg";
import whitecross from "../img/general/whitecross.svg";
import unclockedCheck from "../img/general/checkboxInstall4.svg";
import unclocked from "../img/general/unclocked.svg";

import time from "../img/general/shiftBlockAbs.svg";
import profilePicturePlaceholder from "../img/general/profilePicturePlaceholder.svg";
import desktopBurger from "../img/general/desktopBurger.svg";
import rotaDur from "../img/general/rotaDur.svg";
import rotaTeam from "../img/general/rotaTeam.svg";
import popup00aaff from "../img/general/popupNavy.svg";

import "../styles/navbar.css";
import dateStringer from "../tools/dateStringer";

const UnclocksModal = () => {
  const {
    setShowUnclocked,
    setOpenManagerShiftModal,
    loadUnclocks,
    setUnclockedQty,
    showEditUser,
    updateUnclocked,
    setIndicate,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
    setShowEditUser,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);

  let formatTag = (tag) => {
    if (tag && tag.length > 11) {
      return `${tag.substr(0, 10)}...`;
    } else return tag;
  };

  const memoVals = useMemo(
    () => ({
      setShowUnclocked,
      setOpenManagerShiftModal,
      loadUnclocks,
      setUnclockedQty,
      showEditUser,
      updateUnclocked,
      mobModal,
      setIndicate,
      modalOpen,
      setModalOpen,
      device,
      setShowEditUser,
    }),
    [
      setShowUnclocked, //
      setOpenManagerShiftModal, //
      loadUnclocks, //
      setUnclockedQty, //
      showEditUser, //
      updateUnclocked, //
      mobModal, //
      setIndicate,
      modalOpen,
      setModalOpen,
      device,
      setShowEditUser,
    ]
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  let [unclocks, setUnclocks] = useState([]);

  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 9) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={profilePicturePlaceholder}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={whitecross}
        alt="White Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclockedCheck}
        alt="Unclocked Check"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={unclocked}
        alt="Unclocked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={desktopBurger}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup00aaff}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
    </div>
  );
  let [multiSelect, setMultiSelect] = useState(false);
  let [selectedShiftIDs, setSelectedShiftIDs] = useState([]);
  let [selectedTilIDs, setSelectedTilIDs] = useState([]);
  let [selectedShiftID, setSelectedShiftID] = useState("");
  let [selectedTilID, setSelectedTilID] = useState("");
  let [initialLoad, setInitialLoad] = useState(false);
  let [showActions, setShowActions] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-header-clocks`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          previewOnly: false,
        },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          console.log(response.data);
          response.data.unclocks.sort((a, b) => {
            return (
              dateStringer.createTimestampFromString(a.start) -
              dateStringer.createTimestampFromString(b.start)
            );
          });
          setUnclocks(response.data.unclocks);
          if (response.data.unclocks.length === 0) {
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: `There are no unclocked shifts`,
                colour: "green",
                duration: 4000,
                ts: new Date().getTime(),
              };
            });
            memoVals.setShowUnclocked((x) => false);
          }
          memoVals.setUnclockedQty((x) => response.data.qty);
          if (dataLoaded) {
            setDataLoaded(false);
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [memoVals.loadUnclocks, memoVals.updateUnclocked]);

  let printMonth = (ds) => {
    let m = ds.substr(6, 2);
    if (m === "00") {
      return "Jan";
    }
    if (m === "01") {
      return "Feb";
    }
    if (m === "02") {
      return "Mar";
    }
    if (m === "03") {
      return "Apr";
    }
    if (m === "04") {
      return "May";
    }
    if (m === "05") {
      return "Jun";
    }
    if (m === "06") {
      return "Jul";
    }
    if (m === "07") {
      return "Aug";
    }
    if (m === "08") {
      return "Sep";
    }
    if (m === "09") {
      return "Oct";
    }
    if (m === "10") {
      return "Nov";
    }
    if (m === "11") {
      return "Dec";
    }
  };

  let actionFct = (action, singleShift, singleTil) => {
    // action: makeWorked, delete, absentPaid, absentUnpaid
    setShowActions(false);
    // setLoading(true);
    setInitialLoad(true);

    axios
      .post(
        `${serverURL}/unclock-item-action`,

        {
          makeWorked: action === "makeWorked" ? true : false,
          deleteItems: action === "delete" ? true : false,
          makeAbsentUnpaid: action === "absentUnpaid" ? true : false,
          makeAbsentPaid: action === "absentPaid" ? true : false,
          shiftIDs: singleShift ? [selectedShiftID] : selectedShiftIDs,
          tilIDs: singleTil ? [selectedTilID] : selectedTilIDs,
        },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (singleShift || singleTil) {
            setCountData({
              unclocked: countData.unclocked - 1,
            });
          } else {
            setCountData({
              unclocked: 0,
            });
          }

          let newArr = [];
          if (singleShift) {
            unclocks.map((c) => {
              if (c.shiftID !== selectedShiftID) {
                newArr.push(c);
              }
            });
          } else if (singleTil) {
            unclocks.map((c) => {
              if (c.tilID !== selectedTilID) {
                newArr.push(c);
              }
            });
          } else {
            unclocks.forEach((c) => {
              if (
                selectedShiftIDs.includes(c.shiftID) ||
                selectedTilIDs.includes(c.tilID)
              ) {
              } else {
                newArr.push(c);
              }
            });
          }

          setUnclocks(newArr);

          setSelectedShiftIDs([]);
          setSelectedTilID([]);
          // setLoadUnclocks(!memoVals.loadUnclocks);
          // console.log(response.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let shortenTeamName = (val) => {
    if (val && val.length > 11) {
      return `${val.substr(0, 8)}...`;
    } else {
      return val;
    }
  };

  let generateUnclocks = unclocks.map((unc, i) => {
    let startDs = unc.start;
    let endDs = unc.end;
    let type = "shift";
    if (unc.tilID) {
      type = unc.tilType;
      startDs = `${unc.ds.substr(0, 11)}H${unc.start.substr(
        0,
        2
      )}M${unc.start.substr(3, 2)}`;
      endDs = `${unc.ds.substr(0, 11)}H${unc.end.substr(0, 2)}M${unc.end.substr(
        3,
        2
      )}`;
    }

    // let dateStrip1 = dateStringer.tsToDayAndDate(
    //   dateStringer.createTimestampFromString(unc.start),
    //   true
    //   // window.innerWidth < 100
    // );
    // let dateStrip = dateStringer.tsToDayAndDate(
    //   dateStringer.createTimestampFromString(unc.start),
    //   dateStrip1.includes("Thu") ||
    //     dateStrip1.includes("Sat") ||
    //     dateStrip1.includes("Wed")
    //     ? // &&
    //       // unc.tilType === "overtime"
    //       false
    //     : true
    //   // window.innerWidth < 100
    // );

    let rows = 3;

    return (
      <div className="reqUnitParent" key={i}>
        <div
          onClick={() => {
            if (multiSelect) {
              if (unc.shiftID) {
                if (selectedShiftIDs.includes(unc.shiftID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.shiftID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.shiftID];
                  setSelectedShiftIDs(newArr);
                }
              }
              if (unc.tilID) {
                if (selectedTilIDs.includes(unc.tilID)) {
                  let newArr = [];
                  selectedTilIDs.forEach((til) => {
                    if (til !== unc.tilID) {
                      newArr.push(til);
                    }
                  });
                  setSelectedTilIDs(newArr);
                } else {
                  let newArr = [...selectedTilIDs, unc.tilID];
                  setSelectedTilIDs(newArr);
                }
              }
            } else {
              if (
                (selectedShiftID && selectedShiftID === unc.shiftID) ||
                (selectedTilID && selectedTilID === unc.tilID)
              ) {
                setSelectedShiftID("");
                setSelectedTilID("");
              } else {
                if (unc.shiftID) {
                  console.log("shiftID: ", unc.shiftID, unc);
                  setSelectedShiftID(unc.shiftID);
                  setSelectedTilID("");
                } else {
                  console.log("tilID: ", unc.tilID, unc);
                  setSelectedShiftID("");
                  setSelectedTilID(unc.tilID);
                }
              }
            }
          }}
          className={`mgrReqItem pend ${
            memoVals.mob ? "borderRadius10x" : ""
          } leaveReqItem shiftItemRows3 mgrReqItemWidth94`}
        >
          {" "}
          <div
            className={`unsubmittedTsBlob annLeaveBlob annLeaveBlobRows4 darkRedBg minHeight96`}
          ></div>
          {/* <div className="reqPlaneDiv opac06">
            <img
              src={shiftBlockNavy}
              alt="Requested leave"
              className={`reqPlaneIcon shIconImgTeamSh ${
                memoVals.mob ? "reqPlaneIconMob" : ""
              }`}
            />
          </div> */}
          <div className="reqColBarDiv divPend"></div>
          <div
            className={`mgrReqItemCol2 ${
              memoVals.mob ? "mgrReqItemCol2Mob" : ""
            }`}
          >
            <div className="mgrReqTopRow">
              <div className="mgrReqName mgrReqName2">
                {/* ------ */}
                <div
                  className={`mgrReqDateStrip pendDateDiv x29898988444 shContentLeftRows3 x848888484233`}
                >
                  <div
                    className={`mgrReqDayLengthSpan reqsLeaveNameAboveCalIcons displayFlexShiftType`}
                  >
                    <img
                      src={desktopBurger}
                      alt="Request duration"
                      className="rotaSunReqs leaveNameBurger leaveNameBurgerUnc"
                    />

                    <p className="shTypeNameStrip">
                      {unc.type === "shift" ? (
                        "Shift"
                      ) : unc.tilType === "til" ? (
                        <>
                          Overtime{" "}
                          <span className="shTypeTilSmallSpan">(in lieu)</span>
                        </>
                      ) : (
                        "Overtime"
                      )}
                    </p>
                  </div>
                  <div
                    className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                  >
                    <img
                      src={rotaDur}
                      alt="Request duration"
                      className="rotaSunReqs"
                    />
                    {`${dateStringer.formatMinsDurationToHours(unc.durMins)}`}{" "}
                  </div>
                  <div
                    className={`mgrReqDayLengthSpan upcomingMgrReqsSpan marginTop6`}
                  >
                    <img
                      src={rotaTeam}
                      alt="Request duration"
                      className="rotaSunReqs"
                    />
                    {unc.teamName}
                  </div>
                </div>

                {/* - - - - - - - - - - - - - - - - - - - - - - - - - */}
              </div>
              <div className="teamShRightBlock">
                <div className="absTimelineVal absTimelineValReqs flexReverse marginRight0">
                  <div className={`shItemTimes`}>
                    <p className="shItemTimeRow">
                      {unc.start.length > 10
                        ? dateStringer.dsToTimeStrip(unc.start, true)
                        : unc.start}
                    </p>
                    <div className="shItemTimeRowSplitter"></div>
                    <p className="shItemTimeRow">
                      {unc.end.length > 10
                        ? dateStringer.dsToTimeStrip(unc.end, true)
                        : unc.end}
                    </p>
                  </div>
                  <div className={`absCalDiv`}>
                    <div className="absCalDivUpper">
                      <div className="absDivCalNip"></div>
                      <div className="absDivCalNip"></div>
                    </div>
                    <div className={`absCalDivLower absCalDivLowerAllTime`}>
                      {/* <p className="calDivUpperThreeLetterDay">{dateStringer.getThreeDayLettersFromDs(unit.startDs)}</p> */}
                      <p className="absCalDivLowerDate absCalDivLowerDateReqs">
                        {parseInt(unc.start.substr(9, 2))}
                      </p>
                      <p className="absCalDivLowerMonth absCalDivLowerMonthReqs unclockDayTxt">
                        {dateStringer.getThreeDayLettersFromDs(unc.start)}
                      </p>{" "}
                      <p className="absCalDivLowerYear">
                        {dateStringer.getMonthThreeLettersFromDs(unc.start)}
                        {/* {unit.startDs.substr(1, 4)} */}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <p className={`teamClockBreakDurStrip bbcada marginTop20`}>
                  {dateStringer.shorten(unc.teamName, 20)}{" "}
                  <img
                    src={rotaTeam}
                    alt="Team"
                    className="teamIconHours opac05"
                  />
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`mgrReqNameTab pendClockName shNameTabRows3 marginLeft3pcx`}
          onClick={() => {
            if (multiSelect) {
              if (unc.shiftID) {
                if (selectedShiftIDs.includes(unc.shiftID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.shiftID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.shiftID];
                  setSelectedShiftIDs(newArr);
                }
              }
              if (unc.tilID) {
                if (selectedTilIDs.includes(unc.tilID)) {
                  let newArr = [];
                  selectedTilIDs.forEach((til) => {
                    if (til !== unc.tilID) {
                      newArr.push(til);
                    }
                  });
                  setSelectedTilIDs(newArr);
                } else {
                  let newArr = [...selectedTilIDs, unc.tilID];
                  setSelectedTilIDs(newArr);
                }
              }
            } else {
              memoVals.setShowEditUser((x) => unc.userID);
            }
          }}
        >
          {multiSelect && (
            <div className="multiSelectUnc">
              {selectedShiftIDs.includes(unc.shiftID) ||
              selectedTilIDs.includes(unc.tilID) ? (
                <img
                  src={unclockedCheck}
                  alt="Selected"
                  className="uncSelCheckImg"
                />
              ) : (
                ""
              )}
            </div>
          )}
          <img
            src={unc.profPicUrl || profilePicturePlaceholder}
            alt={unc.fName}
            className="itemNameTabImg"
          />{" "}
          {unc.fName} {unc.lName}
          {unc.you ? <span className="youSpan">(You)</span> : ""}{" "}
        </div>
        <div
          className={`${
            (selectedShiftID && selectedShiftID === unc.shiftID) ||
            (selectedTilID && selectedTilID === unc.tilID)
              ? "unclockItemMenuDiv"
              : "none"
          }`}
        >
          <div
            className={`unclockMenuL ${loading ? "disableUnclockActions" : ""}`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "makeWorked",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "delete",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Delete{" "}
              {type === "shift"
                ? "shift"
                : `${type === "til" ? "TOIL" : "overtime"}`}
            </p>
            <div className="unclockBtnDivider"></div>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentUnpaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentPaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="unclockMenuR">
            <p
              className="viewUnclockItem colour00aaff_"
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: unc.shiftID ? unc.shiftID : "",
                    tilID: unc.tilID ? unc.tilID : "",
                    fName: unc.fName,
                    lName: unc.lName,
                    openedFromShiftsPage: false,
                    openedFromUnclockedPage: true,
                    type: unc.shiftID ? "shift" : "til",
                  };
                });
              }}
            >
              View{" "}
              <img
                src={popup00aaff}
                className="viewShUnclPopUp"
                alt="View shift"
              />
            </p>
          </div>
        </div>
      </div>
    );

    // - - - - - - - - - - - -= - - - -
    return (
      <div className="unclockParent" key={unc.shiftID || unc.tilID}>
        {/* tilshiftunit css */}
        <div
          className={`teamShiftUnit 
        
        
   
        
        `}
        >
          <div
            onClick={() => {
              if (multiSelect) {
                if (unc.shiftID) {
                  if (selectedShiftIDs.includes(unc.shiftID)) {
                    let newArr = [];
                    selectedShiftIDs.forEach((sh) => {
                      if (sh !== unc.shiftID) {
                        newArr.push(sh);
                      }
                    });
                    setSelectedShiftIDs(newArr);
                  } else {
                    let newArr = [...selectedShiftIDs, unc.shiftID];
                    setSelectedShiftIDs(newArr);
                  }
                }
                if (unc.tilID) {
                  if (selectedTilIDs.includes(unc.tilID)) {
                    let newArr = [];
                    selectedTilIDs.forEach((til) => {
                      if (til !== unc.tilID) {
                        newArr.push(til);
                      }
                    });
                    setSelectedTilIDs(newArr);
                  } else {
                    let newArr = [...selectedTilIDs, unc.tilID];
                    setSelectedTilIDs(newArr);
                  }
                }
              } else {
                if (
                  (selectedShiftID && selectedShiftID === unc.shiftID) ||
                  (selectedTilID && selectedTilID === unc.tilID)
                ) {
                  setSelectedShiftID("");
                  setSelectedTilID("");
                } else {
                  if (unc.shiftID) {
                    console.log("shiftID: ", unc.shiftID, unc);
                    setSelectedShiftID(unc.shiftID);
                    setSelectedTilID("");
                  } else {
                    console.log("tilID: ", unc.tilID, unc);
                    setSelectedShiftID("");
                    setSelectedTilID(unc.tilID);
                  }
                }
              }
            }}
            className={`tilShiftUnit teamTilShiftUnit ${
              memoVals.mob ? "borderRadius10" : ""
            } ${
              unc.tags && unc.tags[0] ? "tagsOnShiftExtend" : ""
            } uncTilShift`}
          >
            <div className={`myHoursLeftWrapper`}>
              <div className="myHoursLeftSec">
                {multiSelect ? (
                  <div className="multiSelectUnc">
                    {selectedShiftIDs.includes(unc.shiftID) ||
                    selectedTilID.includes(unc.tilID) ? (
                      <img
                        src={unclockedCheck}
                        alt="Selected"
                        className="uncSelCheckImg"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <img src={time} alt="My hours" className={`timeHoursImg `} />
                )}
              </div>

              <div className={`reqColBarDiv unclockColDiv`}></div>
              <div
                className={`tilShiftSection1team  ${
                  !memoVals.mob ? "tilShiftSection1teamDesktop" : ""
                }`}
              >
                <p className={`tilShiftUnitName `}>
                  {dateStringer.printedDateFromDs(unc.start)}
                  {/* <span className="ndStRdSpan2">
                    {dateStringer.nd(unc.start ? unc.start.substr(9, 2) : "")}
                  </span> */}
                  <p className="shiftTilNameTxt shiftTilNameTxtHours">
                    {unc.tilType === "til"
                      ? "Lieu"
                      : unc.tilType === "overtime"
                      ? "Overtime"
                      : "Shift"}
                  </p>
                  {/* {dateStringer.nd(unit.data.start.substr(9, 2))} */}

                  {/* {unit.fName} {unit.lName[0]} */}
                </p>
                <div className={`tilShiftDsTeam paddingTop4`}>
                  {/* <p
                    className={`${
                      unit.tilApproved && !unit.wholeTil
                        ? `tilShiftTimes x1972141 ${
                            dateStringer.generateTilShiftString(
                              unit.tilStart || "00:00",
                              unit.tilEnd || "00:00",
                              oldStart || "00:00",
                              oldEnd || "00:00",
                              false,
                              unit.type
                            ).length > 17
                              ? "smallerTxtTimeTilTeamStrip"
                              : ""
                          }`
                        : dateStringer
                            .generateTilShiftString(
                              unit.tilStart || "00:00",
                              unit.tilEnd || "00:00",
                              oldStart || "00:00",
                              oldEnd || "00:00",
                              false,
                              unit.type
                            )
                            .includes("Whole")
                        ? ""
                        : "none"
                    }`}
                  >
                    <img
                      src={rotaClock}
                      alt="Clock"
                      className={`teamHoursRotaClock ${
                        unit.tilApproved && !unit.wholeTil ? "x19749111" : ""
                      }`}
                    />{" "}
                    {dateStringer.generateTilShiftString(
                      unit.tilStart || "00:00",
                      unit.tilEnd || "00:00",
                      oldStart || "00:00",
                      oldEnd || "00:00",
                      false,
                      false,
                      unit.type
                    )}
                  </p> */}

                  <img
                    src={rotaClock}
                    alt="Clock"
                    className="teamHoursRotaClock"
                  />
                  {`${unc.start.substr(12, 2)}:${unc.start.substr(
                    15,
                    2
                  )} - ${unc.end.substr(12, 2)}:${unc.end.substr(15, 2)}`}

                  {/* if not a til, no til string needed, just use normal time string: */}
                </div>
              </div>
            </div>
            <div className="tilShiftRightParent">
              <div className="tilShiftSection2team">
                <p className="tilShiftTypeTxtTeam">
                  {unc.tilType === "overtime"
                    ? "Overtime"
                    : `${unc.tilType !== "til" ? "Shift" : "Shift in lieu"}`}
                </p>
                <div className="hoursAndCostContainer">
                  <p className={`tilShiftDurationTxtTeam`}>
                    {/* {printShiftDurationSubTxt()} */}
                    {dateStringer.formatMinsDurationToHours(
                      unc.type === "shift" ? unc.duration : unc.durMins
                    )}
                  </p>
                </div>
                <p
                  className={`teamClockBreakDurStrip x131453678 ${
                    memoVals.mob || memoVals.fullButNarrow
                      ? "myHoursUnitTeamNameMob"
                      : ""
                  }`}
                >
                  {dateStringer.shorten(
                    unc.teamName,
                    memoVals.mob || memoVals.fullButNarrow ? 39 : 35
                  )}{" "}
                  {/* <img src={teamIcon} alt="Team" className="teamIconHours" /> */}
                </p>{" "}
              </div>
              <div
                className={`itemTongue shiftTongueMgr_ ${
                  !unc.tilType
                    ? ""
                    : unc.tilType === "til"
                    ? "tilTongueMgr_"
                    : "overtimeTongueMgr"
                } overtimeTongueMgr`}
              >
                <p className="tongueTxt leaveTongueTxt hoursTongueMgrTxt">
                  HOURS
                </p>
              </div>
            </div>
          </div>

          <div className={`mgrClockNameDiv teamShiftNameTab x4954984659863`}>
            <img
              src={unc.profPicUrl || profilePicturePlaceholder}
              alt={unc.fName}
              className="itemNameTabImg"
            />{" "}
            {unc.fName} {unc.lName}
          </div>
          {unc.tags && unc.tags[0] ? (
            <div
              className={`${
                unc.tags && unc.tags[0] ? `teamShiftTagsContainer` : "none"
              }`}
            >
              <p className="teamShiftTagItem">{formatTag(unc.tags[0])}</p>
              <p
                className={`${
                  unc.tags[1] && !memoVals.mob ? "teamShiftTagItem" : "none"
                }`}
              >
                {formatTag(unc.tags[1])}
              </p>
              <p
                className={`${
                  unc.tags[memoVals.mob ? 1 : 2]
                    ? "teamShiftTagsSurplusIndicator"
                    : "none"
                }`}
              >
                +{memoVals.mob ? unc.tags.length - 1 : unc.tags.length - 2}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* ---- end of tilShiftunit css */}
        {/* <div
          className={`unclockedUnit ${
            (selectedShiftID && selectedShiftID === unc.shiftID) ||
            (selectedTilID && selectedTilID === unc.tilID) ||
            selectedShiftIDs.includes(unc.shiftID) ||
            selectedTilIDs.includes(unc.tilID)
              ? "selectedUnclockUnit"
              : ""
          }`}
          onClick={() => {
            if (multiSelect) {
              if (unc.shiftID) {
                if (selectedShiftIDs.includes(unc.shiftID)) {
                  let newArr = [];
                  selectedShiftIDs.forEach((sh) => {
                    if (sh !== unc.shiftID) {
                      newArr.push(sh);
                    }
                  });
                  setSelectedShiftIDs(newArr);
                } else {
                  let newArr = [...selectedShiftIDs, unc.shiftID];
                  setSelectedShiftIDs(newArr);
                }
              }
              if (unc.tilID) {
                if (selectedTilIDs.includes(unc.tilID)) {
                  let newArr = [];
                  selectedTilIDs.forEach((til) => {
                    if (til !== unc.tilID) {
                      newArr.push(til);
                    }
                  });
                  setSelectedTilIDs(newArr);
                } else {
                  let newArr = [...selectedTilIDs, unc.tilID];
                  setSelectedTilIDs(newArr);
                }
              }
            } else {
              if (
                (selectedShiftID && selectedShiftID === unc.shiftID) ||
                (selectedTilID && selectedTilID === unc.tilID)
              ) {
                setSelectedShiftID("");
                setSelectedTilID("");
              } else {
                if (unc.shiftID) {
                  console.log("shiftID: ", unc.shiftID, unc);
                  setSelectedShiftID(unc.shiftID);
                  setSelectedTilID("");
                } else {
                  console.log("tilID: ", unc.tilID, unc);
                  setSelectedShiftID("");
                  setSelectedTilID(unc.tilID);
                }
              }
            }
          }}
          key={unc.shiftID || unc.tilID}
        >
          <div className="unclockUnitNameAndTeamDiv">
            <div className="unclockNameAndIconDiv">
              <div className="unclockIconDiv">
                <img
                  src={unclocked}
                  className="unclockedIcon"
                  alt="Unclocked shift"
                />
              </div>
              <p className="unclockNameString">
                {unc.fName} {unc.lName ? unc.lName[0] : ""}
              </p>
            </div>

            <p className="unclockTeamName">
              <img src={teamIcon} alt="Team" className="teamIconAccept" />{" "}
              {dateStringer.shorten(unc.teamName, 22)}
            </p>
          </div>
          <div className="unclockUnitBody">
            {!multiSelect ? (
              ""
            ) : (
              <div className="unclockedSectionLeft0">
                <div
                  className={`${
                    multiSelect ? "multiSelectUnlockRadio" : "none"
                  }`}
                  onClick={() => {}}
                >
                  <img
                    src={unclockedCheck}
                    className={`${
                      selectedShiftIDs.includes(unc.shiftID) ||
                      selectedTilIDs.includes(unc.tilID)
                        ? "unclockTickImg"
                        : "none"
                    }`}
                    alt="Select"
                  />
                  <div
                    className={`${
                      selectedShiftIDs.includes(unc.shiftID) ||
                      selectedTilIDs.includes(unc.tilID)
                        ? "none"
                        : "unselectedUnclockRadio"
                    }`}
                  ></div>
                </div>
              </div>
            )}
            <div
              className={`tilShiftSection1Unclock ${
                multiSelect
                  ? "tilShiftSection1MultiSelected"
                  : `${
                      memoVals.mobModal
                        ? "unclockSection1 x2312121"
                        : "unclockSection1DesktopX"
                    } noBorderLeft`
              }`}
            >
              <p
                className={`unclockedDsString ${
                  printShiftDurationSubTxt() === "0m" ? "noBorder" : ""
                }`}
              >
                <img
                  src={unclockCal}
                  className="unclockCal unclockCal1"
                  alt="Date"
                />
                {dateStringer.tsToDayAndDate(
                  dateStringer.createTimestampFromString(startDs)
                )}{" "}
                {printMonth(startDs)}
                <span
                  className={`${
                    multiSelect
                      ? "unclockedShiftTilTxt"
                      : "unclockedShiftTilTxt"
                  }`}
                >
                  {type === "shift"
                    ? "Shift"
                    : `${type === "overtime" ? "Overtime" : "Lieu shift"}`}
                </span>
              </p>

              <div className="timesAndTagContainer">
                <p className="unclockedShiftTimes">
                  <img src={unclockTime} className="unclockCal" alt="Date" />
                  {startDs.substr(12, 2)}:{startDs.substr(15, 2)} -{" "}
                  {endDs.substr(12, 2)}:{endDs.substr(15, 2)}
                </p>
              </div>
            </div>
            <div className="tilShiftSection2 unclockSection2">
              <p className="unclockedDurString">
                {/* {unit.type === "shift"
              ? "Shift"
              : `${unit.type === "overtime" ? "Overtime" : "Time in Lieu"}`} */}
        {/* {printShiftDurationSubTxt()} */}
        {/* </p> */}
        {/* <p
                className={`unclockedBreakDurStrip  ${
                  printShiftDurationSubTxt() === "0m" ? "none" : ""
                }`}
              >
                {type === "shift"
                  ? `${unc.break || 0}m break`
                  : `${unc.brk || 0}m break`}
              </p> */}
        {/* //     </div> */}
        {/* //   </div> */}
        {/* // </div> */}
        <div
          className={`${
            (selectedShiftID && selectedShiftID === unc.shiftID) ||
            (selectedTilID && selectedTilID === unc.tilID)
              ? "unclockItemMenuDiv"
              : "none"
          }`}
        >
          <div
            className={`unclockMenuL ${loading ? "disableUnclockActions" : ""}`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "makeWorked",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "delete",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Delete{" "}
              {type === "shift"
                ? "shift"
                : `${type === "til" ? "TOIL" : "overtime"}`}
            </p>
            <div className="unclockBtnDivider"></div>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentUnpaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              }`}
              onClick={() => {
                actionFct(
                  "absentPaid",
                  unc.shiftID ? true : false,
                  unc.tilID ? true : false
                );
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="unclockMenuR">
            <p
              className="viewUnclockItem colour00aaff"
              onClick={() => {
                memoVals.setOpenManagerShiftModal((x) => {
                  return {
                    shiftID: unc.shiftID ? unc.shiftID : "",
                    tilID: unc.tilID ? unc.tilID : "",
                    fName: unc.fName,
                    lName: unc.lName,
                    openedFromShiftsPage: false,
                    openedFromUnclockedPage: true,
                    type: unc.shiftID ? "shift" : "til",
                  };
                });
              }}
            >
              View
            </p>
          </div>
        </div>{" "}
      </div>
    );
  });

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowUnclocked((x) => false);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      memoVals.setShowUnclocked((x) => false);
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  return (
    <div
      className={`clockOnModalUnderlay ${memoVals.showEditUser ? "none" : ""}`}
      onClick={() => {
        memoVals.setShowUnclocked((x) => false);
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        <div
          className={`clockOnModalBox whiteBg ${
            memoVals.mobModal
              ? "mobUnclockModalBox slideUp mobModalShoulder"
              : "zoomIn"
          } ${memoVals.device.ios ? "mobUnclockModalBoxIos" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperUnclocked"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="clockOnModalHeader"
            onClick={() => {
              if (memoVals.mobModal) {
                memoVals.setShowUnclocked((x) => false);
              }
            }}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <div className="clockOnModalHeaderSideUnit">
              <img
                src={close}
                alt="Close"
                className="closeClockOnModalImg"
                onClick={() => {
                  memoVals.setShowUnclocked((x) => false);
                }}
              />
            </div>
            <p className="clockOnModalHeaderTitle" onClick={() => {}}>
              Unclocked shifts
            </p>
            <div className="clockOnModalHeaderSideUnit"></div>
            {/* <div className={`clockTicker ${durString ? "" : "clockTickerInvis"}`}>
            {durString}
          </div> */}
          </div>
          <p className="unclocksExplainer">
            Shifts requiring staff to clock on, but no clock activity took
            place.
          </p>
          <div
            className={`${
              memoVals.mobModal ? "mobUnclocksModalBody" : ""
            } unclocksModalContentBox
          `}
          >
            <div
              className={`unclockedContainer ${
                memoVals.mobModal ? "unclockedContainerMob" : ""
              } ${
                showActions
                  ? `${
                      memoVals.mobModal
                        ? "minus140pxForActionsMob"
                        : "minus140pxForActions"
                    }`
                  : ""
              }`}
            >
              {generateUnclocks}
            </div>
          </div>
          <div
            className={`${showActions ? "unclockMultiActionsBox" : "none"} ${
              !memoVals.mobModal ? "desktopMultiActionBox" : ""
            } ${loading ? "disableUnclockActions" : ""}`}
          >
            <p
              className={`unclockActionBtn unclockAbsBtn unclockMarkWorkedBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("makeWorked", false, false);
              }}
            >
              Mark as worked
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockDeleteBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("delete", false, false);
              }}
            >
              Delete selected
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("absentUnpaid", false, false);
              }}
            >
              Mark absent - unpaid
            </p>
            <p
              className={`unclockActionBtn unclockAbsBtn unclockActionBtn ${
                !memoVals.mobModal ? "unclockActionBtnSmallerTxt" : ""
              } paddingLandR15px`}
              onClick={() => {
                actionFct("absentPaid", false, false);
              }}
            >
              Mark absent - paid
            </p>{" "}
          </div>
          <div className="blankModalFooter">
            <div className="backAndDeleteShiftContainer">
              <div
                className={`pubShiftBtn ${
                  multiSelect ? "multiSelectUnclocks" : ""
                }`}
                onClick={() => {
                  setMultiSelect(!multiSelect);
                  setSelectedShiftIDs([]);
                  setSelectedTilIDs([]);
                  setSelectedShiftID("");
                  setSelectedTilID("");
                  setShowActions(false);
                }}
              >
                {multiSelect ? "Cancel" : "Select"}
              </div>
              {!selectedShiftIDs[0] && !selectedTilIDs[0] ? (
                <div
                  className={`pubShiftBtn selectAllBtn ${
                    multiSelect ? "multiSelectUnclocks" : "none"
                  }`}
                  onClick={() => {
                    let sIDs = [];
                    let tIDs = [];
                    unclocks.forEach((cl) => {
                      if (cl.shiftID) {
                        sIDs.push(cl.shiftID);
                      }
                      if (cl.tilID) {
                        tIDs.push(cl.tilID);
                      }
                    });

                    setSelectedShiftIDs(sIDs);
                    setSelectedTilIDs(tIDs);
                  }}
                >
                  Select all
                </div>
              ) : (
                ""
              )}
              <div
                className={`pubShiftBtn ${
                  selectedShiftIDs.length > 0 || selectedTilIDs.length > 0
                    ? "actionsUnclockActive"
                    : "none"
                } navyColours`}
                onClick={() => {
                  setShowActions(!showActions);
                }}
              >
                {" "}
                <img
                  src={whitecross}
                  className={`${
                    showActions ? "showActionsWhiteCrossImg" : "none"
                  }`}
                  alt="Close"
                />
                Actions
              </div>
            </div>
            <p
              className="closeEditShiftBtn"
              onClick={() => {
                memoVals.setShowUnclocked((x) => false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      )}
      {imgPreload}
    </div>
  );
};

export default UnclocksModal;
