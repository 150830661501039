import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 8 sep 24
import axios from "axios";
import { CalendarContext } from "../../contexts/CalendarContext";
import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import serverURL from "../../serverURL";

import dateStringer from "../../tools/dateStringer";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import navyClose from "../../img/general/navyClose.svg";
import remove from "../../img/general/greyBin.svg";
import templateArrow1 from "../../img/general/greyGo.svg";
import templateArrow2 from "../../img/general/whiteGo.svg";
import publishTick1 from "../../img/general/checkboxInstall1.svg";
import publishTick2 from "../../img/general/checkboxInstall2pub.svg";
import ClosedDays from "../account/admin/ClosedDays";

const SaveTemplateModal = ({
  saveTemplateObj,
  setSaveTemplateObj,
  updateInfo,
  setUpdateInfo,
  mobModal,
}) => {
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(
    saveTemplateObj.loading ? false : true
  );

  const { modalOpen, setModalOpen, device } = useContext(DataContext);
  const { checkUserState } = useContext(UserContext);

  useEffect(() => {
    setModalOpen((x) => true);

    return () => {
      setModalOpen((x) => false);
    };
  }, [setModalOpen]);
  let [sureDeleteTemplate, setSureDeleteTemplate] = useState("");

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 6) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={navyClose}
        alt="Navy Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={remove}
        alt="Remove"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={templateArrow1}
        alt="Template Arrow 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={templateArrow2}
        alt="Template Arrow 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={publishTick1}
        alt="Publish Tick 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={publishTick2}
        alt="Publish Tick 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );
  let [savingTemplate, setSavingTemplate] = useState(saveTemplateObj.saving);

  let [templateName, setTemplateName] = useState(
    dateStringer.createShortStripFromDateString(saveTemplateObj.monWeek)
  );

  let [description, setDescription] = useState("");

  let [deleteExisting, setDeleteExisting] = useState(false);
  // ... this means to delete existing shifts when loading a template

  let [qty, setQty] = useState({});
  let [monWeeksToPublish, setMonWeeksToPublish] = useState([]);
  const inputRef = useRef();

  useEffect(() => {
    // clean not needed
    setSavingTemplate(saveTemplateObj.saving);
  }, [saveTemplateObj]);

  let [includeShifts, setIncludeShifts] = useState(true);
  let [includeOvertimes, setIncludeOvertimes] = useState(true);
  let [includeTils, setIncludeTils] = useState(true);
  let [includeOpens, setIncludeOpens] = useState(true);

  let [closedDays, setClosedDays] = useState([]);
  let [templates, setTemplates] = useState([]);
  let [selectedTemplate, setSelectedTemplate] = useState("");
  let [deleteTemplate, setDeleteTemplate] = useState("");

  let [tempDataUsers, setTempDataUsers] = useState([]);
  let [selectedTemplateData, setSelectedTemplateData] = useState({});

  let [usersNoLongerInTeam, setUsersNoLongerInTeam] = useState([]);

  let [previewTemplateData, setPreviewTemplateData] = useState([]); // grouped by name: shifts, tils, overtimes etc
  let [previewOpens, setPreviewOpens] = useState([]);
  let mobileWidthBreak = 820;
  const [width, setWidth] = useState(window.innerWidth);
  let [loadWeeksQty, setLoadWeeksQty] = useState(1);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  let shortenDesciption = (words) => {
    if (words.length > 60) {
      return `${words.substr(0, 55)}...`;
    } else {
      return words;
    }
  };

  useEffect(() => {
    // clean not needed
    if (saveTemplateObj.loading) {
      let temp = templates.filter((obj) => {
        return obj.templateID === selectedTemplate;
      });

      let previewOpensLocal = [{ initial: true }];
      temp[0] &&
        temp[0].dayData.forEach((day) => {
          previewOpensLocal.push({
            dayNum: day.dayNum,
            opens: day.opens,
            order: day.dayNum === 0 ? 99 : day.dayNum,
          });
        });

      previewOpensLocal.sort(function (a, b) {
        return b.order - a.order;
      });
      setPreviewOpens(previewOpensLocal);
      let tempObj = temp[0];
      setSelectedTemplateData(tempObj);
      setDeleteTemplate("");
      let userIDs = [];
      let userObjs = [];

      let dayNumsArr = [0, 1, 2, 3, 4, 5, 6];

      dayNumsArr.forEach((dayN) => {
        temp[0] &&
          temp[0].dayData[dayN].shifts.forEach((item) => {
            if (!userIDs.includes(item.userID)) {
              userIDs.push(item.userID);
              userObjs.push({
                userID: item.userID,
                fName: item.fName,
                lName: item.lName,
              });
            }
          });

        temp[0] &&
          temp[0].dayData[dayN].tils.forEach((item) => {
            if (!userIDs.includes(item.userID)) {
              userIDs.push(item.userID);
              userObjs.push({
                userID: item.userID,
                fName: item.fName,
                lName: item.lName,
              });
            }
          });
        temp[0] &&
          temp[0].dayData[dayN].overtimes.forEach((item) => {
            if (!userIDs.includes(item.userID)) {
              userIDs.push(item.userID);
              userObjs.push({
                userID: item.userID,
                fName: item.fName,
                lName: item.lName,
              });
            }
          });

        setTempDataUsers(userObjs);
      });

      temp[0] &&
        userObjs.forEach((user) => {
          let counter = 0;
          let allDays = [
            ...temp[0].dayData[0].shifts,
            ...temp[0].dayData[0].tils,
            ...temp[0].dayData[0].overtimes,
            ...temp[0].dayData[1].shifts,
            ...temp[0].dayData[1].tils,
            ...temp[0].dayData[1].overtimes,
            ...temp[0].dayData[2].shifts,
            ...temp[0].dayData[2].tils,
            ...temp[0].dayData[2].overtimes,
            ...temp[0].dayData[3].shifts,
            ...temp[0].dayData[3].tils,
            ...temp[0].dayData[3].overtimes,
            ...temp[0].dayData[4].shifts,
            ...temp[0].dayData[4].tils,
            ...temp[0].dayData[4].overtimes,
            ...temp[0].dayData[5].shifts,
            ...temp[0].dayData[5].tils,
            ...temp[0].dayData[5].overtimes,
            ...temp[0].dayData[6].shifts,
            ...temp[0].dayData[6].tils,
            ...temp[0].dayData[6].overtimes,
          ];

          allDays.forEach((d) => {
            //   if()
            // alfie
          });
        });

      let userRows = [];
      userObjs.forEach((usr) => {
        let fName = usr.fName;
        let lName = usr.lName;
        let userID = usr.userID;

        let monData = [];
        let tueData = [];
        let wedData = [];
        let thuData = [];
        let friData = [];
        let satData = [];
        let sunData = [];

        dayNumsArr.forEach((dayN2) => {
          let shifts =
            temp[0] &&
            temp[0].dayData[dayN2].shifts.filter((obj) => {
              return obj.userID === usr.userID;
            });

          let tils =
            temp[0] &&
            temp[0].dayData[dayN2].tils.filter((obj) => {
              return obj.userID === usr.userID;
            });

          let overtimes =
            temp[0] &&
            temp[0].dayData[dayN2].overtimes.filter((obj) => {
              return obj.userID === usr.userID;
            });

          let allItems = [...shifts, ...tils, ...overtimes];
          if (dayN2 === 0) {
            shifts.forEach((item) => {
              sunData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              sunData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              sunData.push({ item, type: "overtime" });
            });
          }
          if (dayN2 === 1) {
            shifts.forEach((item) => {
              monData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              monData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              monData.push({ item, type: "overtime" });
            });
          }
          if (dayN2 === 2) {
            shifts.forEach((item) => {
              tueData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              tueData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              tueData.push({ item, type: "overtime" });
            });
          }
          if (dayN2 === 3) {
            shifts.forEach((item) => {
              wedData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              wedData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              wedData.push({ item, type: "overtime" });
            });
          }
          if (dayN2 === 4) {
            shifts.forEach((item) => {
              thuData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              thuData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              thuData.push({ item, type: "overtime" });
            });
          }
          if (dayN2 === 5) {
            shifts.forEach((item) => {
              friData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              friData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              friData.push({ item, type: "overtime" });
            });
          }
          if (dayN2 === 6) {
            shifts.forEach((item) => {
              satData.push({ item, type: "shift" });
            });
            tils.forEach((item) => {
              satData.push({ item, type: "til" });
            });
            overtimes.forEach((item) => {
              satData.push({ item, type: "overtime" });
            });
          }
        });

        userRows.push({
          fName,
          lName,
          userID,
          monData,
          tueData,
          wedData,
          thuData,
          friData,
          satData,
          sunData,
        });
      });

      setPreviewTemplateData(userRows);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // cleaned
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let [teamName, setTeamName] = useState("");
  let [locationName, setLocationName] = useState("");
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    // inputRef.current.focus();
    // inputRef.current.select();
    if (saveTemplateObj.saving) {
      let monShifts = 0;
      let monOvertimes = 0;
      let monTils = 0;
      let monOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.mon &&
          saveTemplateObj.opens.mon.length) ||
        0;

      let tueShifts = 0;
      let tueOvertimes = 0;
      let tueTils = 0;
      let tueOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.tue &&
          saveTemplateObj.opens.tue.length) ||
        0;

      let wedShifts = 0;
      let wedOvertimes = 0;
      let wedTils = 0;
      let wedOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.wed &&
          saveTemplateObj.opens.wed.length) ||
        0;

      let thuShifts = 0;
      let thuOvertimes = 0;
      let thuTils = 0;
      let thuOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.thu &&
          saveTemplateObj.opens.thu.length) ||
        0;

      let friShifts = 0;
      let friOvertimes = 0;
      let friTils = 0;
      let friOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.fri &&
          saveTemplateObj.opens.fri.length) ||
        0;

      let satShifts = 0;
      let satOvertimes = 0;
      let satTils = 0;
      let satOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.sat &&
          saveTemplateObj.opens.sat.length) ||
        0;

      let sunShifts = 0;
      let sunOvertimes = 0;
      let sunTils = 0;
      let sunOpens =
        (saveTemplateObj.saving &&
          saveTemplateObj.opens &&
          saveTemplateObj.opens.sun &&
          saveTemplateObj.opens.sun.length) ||
        0;

      saveTemplateObj.shifts.forEach((user) => {
        user.monItems.forEach((item) => {
          if (item.type === "shift") {
            monShifts++;
          }
          if (item.type === "overtime") {
            monOvertimes++;
          }
          if (item.type === "til") {
            monTils++;
          }
        });

        user.tueItems.forEach((item) => {
          if (item.type === "shift") {
            tueShifts++;
          }
          if (item.type === "overtime") {
            tueOvertimes++;
          }
          if (item.type === "til") {
            tueTils++;
          }
        });

        user.wedItems.forEach((item) => {
          if (item.type === "shift") {
            wedShifts++;
          }
          if (item.type === "overtime") {
            wedOvertimes++;
          }
          if (item.type === "til") {
            wedTils++;
          }
        });

        user.thuItems.forEach((item) => {
          if (item.type === "shift") {
            thuShifts++;
          }
          if (item.type === "overtime") {
            thuOvertimes++;
          }
          if (item.type === "til") {
            thuTils++;
          }
        });

        user.friItems.forEach((item) => {
          if (item.type === "shift") {
            friShifts++;
          }
          if (item.type === "overtime") {
            friOvertimes++;
          }
          if (item.type === "til") {
            friTils++;
          }
        });

        user.satItems.forEach((item) => {
          if (item.type === "shift") {
            satShifts++;
          }
          if (item.type === "overtime") {
            satOvertimes++;
          }
          if (item.type === "til") {
            satTils++;
          }
        });

        user.sunItems.forEach((item) => {
          if (item.type === "shift") {
            sunShifts++;
          }
          if (item.type === "overtime") {
            sunOvertimes++;
          }
          if (item.type === "til") {
            sunTils++;
          }
        });
      });

      setQty({
        monShifts,
        monOvertimes,
        monTils,
        monOpens,
        tueShifts,
        tueOvertimes,
        tueTils,
        tueOpens,
        wedShifts,
        wedOvertimes,
        wedTils,
        wedOpens,
        thuShifts,
        thuOvertimes,
        thuTils,
        thuOpens,
        friShifts,
        friOvertimes,
        friTils,
        friOpens,
        satShifts,
        satOvertimes,
        satTils,
        satOpens,
        sunShifts,
        sunOvertimes,
        sunTils,
        sunOpens,
      });
    }

    if (saveTemplateObj.loading) {
      axios
        .post(
          `${serverURL}/load-templates`,
          {
            teamID: saveTemplateObj.teamID,
            locationID: saveTemplateObj.locationID,
          },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setTemplates(response.data.templates);
            setDataLoaded(true);
            setLocationName(response.data.locationName);
            setTeamName(response.data.teamName);
            let userIDs = [];
            // response.data.templates.forEach((day) => {});
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [saveTemplateObj]);

  let [saved, setSaved] = useState(false);

  let generateTemplates = templates.map((temp) => {
    return (
      <div
        key={temp.templateID}
        className={`templateOption ${
          selectedTemplate === temp.templateID ? "selectedTemplateUnit" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (temp.templateID === selectedTemplate) {
            setSelectedTemplate("");
          } else {
            let originalData = previewTemplateData;
            let originalOpens = previewOpens;
            setPreviewTemplateData([]);
            setPreviewOpens([]);
            setSelectedTemplate(temp.templateID);
          }

          setDeleteTemplate("");
        }}
      >
        <div className="templateOptionLeft">
          <p className="templateUnitName">{temp.name}</p>
          <p className="templateUnitDescription">
            {shortenDesciption(temp.description)}
          </p>
        </div>
        <div className="templateOptionRight">
          <img
            src={
              selectedTemplate === temp.templateID
                ? templateArrow2
                : templateArrow1
            }
            className={`${
              mobModal ? "tempArrowImgDownWide" : "tempArrowImgDownMob"
            } ${deleteTemplate === temp.templateID ? "none" : ""}`}
            alt="select"
          />

          <img
            src={remove}
            className={`removeTemplateImg ${
              selectedTemplate === temp.templateID
                ? "deleteSelectedBorderBin"
                : ""
            }`}
            alt="remove template"
            onClick={(e) => {
              e.stopPropagation();
              setSureDeleteTemplate(temp.templateID);
            }}
          />
        </div>
      </div>
    );
  });

  let shortenName = (name) => {
    if (name.length > 8) {
      return `${name.substr(0, 6)}...`;
    } else {
      return name;
    }
  };

  let openItemsQtr = () => {
    let count = 0;
    previewOpens.forEach((op) => {
      if (!op.initial) {
        if (op.opens.length > count) {
          count = op.opens.length;
        }
      }
    });

    return count;
  };

  let generateOpensRow =
    previewOpens[0] &&
    previewOpens.map((op, i) => {
      if (op.initial) {
        return (
          <div className="a1previewOpensRowCell">
            <p className="templateOpenShiftsTitle">Open shifts</p>
          </div>
        );
      } else {
        let generateOpenItems = op.opens[0] ? (
          op.opens.map((open, ind) => {
            // if (ind !== 0) {
            return (
              <div key={ind} className="templateItem">
                <p
                  className={`templateStart ${
                    dateStringer.dsToTimeStrip(open.start).length > 4
                      ? "tempTimeSmaller"
                      : ""
                  }`}
                >
                  {dateStringer.dsToTimeStrip(open.start)}
                </p>{" "}
                <span className="templateBetweenLine">-</span>
                <p
                  className={`templateEnd ${
                    dateStringer.dsToTimeStrip(open.end).length > 4
                      ? "tempTimeSmaller"
                      : ""
                  }`}
                >
                  {dateStringer.dsToTimeStrip(open.end)}
                </p>
                <p className={`templateType templateOpenTxt`}>OPEN</p>
              </div>
            );
            // }
          })
        ) : (
          <div className="dudTemplateItem">-</div>
        );

        return (
          //   <div>
          <div className="previewOpenCell">{generateOpenItems}</div>
          //   </div>
        );
      }
    });

  let generateUserRows = previewTemplateData.map((user) => {
    let generateUserItems = (user, day) => {
      let obj = [];
      if (day === "mon") {
        obj = user.monData;
      }
      if (day === "tue") {
        obj = user.tueData;
      }
      if (day === "wed") {
        obj = user.wedData;
      }
      if (day === "thu") {
        obj = user.thuData;
      }
      if (day === "fri") {
        obj = user.friData;
      }
      if (day === "sat") {
        obj = user.satData;
      }
      if (day === "sun") {
        obj = user.sunData;
      }

      if (!obj[0]) {
        return (
          <div className={`templateUserDateCell`}>
            <div className="dudTemplateItem">-</div>
          </div>
        );
      }
      return obj.map((item, i) => {
        return (
          <div key={i} className="templateItem">
            <p
              className={`templateStart ${
                dateStringer.dsToTimeStrip(item.item.start).length > 4
                  ? "tempTimeSmaller"
                  : ""
              }`}
            >
              {dateStringer.dsToTimeStrip(item.item.start)}
            </p>{" "}
            <span className="templateBetweenLine">-</span>
            <p
              className={`templateEnd ${
                dateStringer.dsToTimeStrip(item.item.end).length > 4
                  ? "tempTimeSmaller"
                  : ""
              }`}
            >
              {dateStringer.dsToTimeStrip(item.item.end)}
            </p>
            <p
              className={`templateType ${
                item.type !== "shift" ? "tilTypeStripInLoadTemplate" : ""
              }`}
            >{`${
              item.type === "shift"
                ? "SHIFT"
                : item.type === "til"
                ? "LIEU"
                : "OVER"
            }`}</p>
          </div>
        );
      });
    };

    let dayItemsQty = (uid) => {
      // curry
      let count = 0;
      let userArr = previewTemplateData.filter((item) => {
        return item.userID === uid;
      });
      userArr.forEach((usr) => {
        let arrOfArr = [
          usr.monData,
          usr.tueData,
          usr.wedData,
          usr.thuData,
          usr.friData,
          usr.satData,
          usr.sunData,
        ];
        arrOfArr.forEach((arr) => {
          if (arr.length > count) {
            count = arr.length;
          }
        });
      });

      return count;
    };

    return (
      <div
        key={user.userID}
        className={`templateUserRow ${
          dayItemsQty(user.userID) === 2 && "templateUserRow2items"
        }  ${dayItemsQty(user.userID) === 3 && "templateUserRow3items"}  ${
          dayItemsQty(user.userID) === 4 && "templateUserRow4items"
        }  ${dayItemsQty(user.userID) === 5 && "templateUserRow5items"}  ${
          dayItemsQty(user.userID) === 6 && "templateUserRow6items"
        }`}
      >
        <div className="templateNameCell">
          <p className="templateUserRowfName">{shortenName(user.fName)}</p>
          <p className="templateUserRowlName">{shortenName(user.lName)}</p>
          {/* <p className="templateUserRowlName">{dayItemsQty(user.userID)}</p> */}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "mon")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "tue")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "wed")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "thu")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "fri")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "sat")}
        </div>
        <div className={`templateUserDateCell`}>
          {generateUserItems(user, "sun")}
        </div>
      </div>
    );
  });

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      setSaveTemplateObj((x) => {
        return {};
      });
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // master return
  return (
    <div
      className="bulkEditLimitUnderlay"
      onClick={() => {
        setSaveTemplateObj((x) => {
          return {};
        });
      }}
    >
      {loading ? (
        <div
          className={`shiftLoadingBox ${
            mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : closedDays[0] ? (
        <div
          className={`${saved ? "templateSavedModal" : "none"}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="tempSavedTxt">
            Shifts have been loaded onto the following closed days.
          </p>

          <p className="followingStaffTempTitle">
            Be sure you to review the following days in order to ensure staff
            are not scheduled to work on days the company is closed.
          </p>

          {closedDays[0] ? (
            <div className="noLongerInTeamDiv">
              {closedDays.map((item) => {
                return (
                  <p className="userNotInTeamItem">
                    {dateStringer.printedDateFromDs(item)}
                  </p>
                );
              })}
            </div>
          ) : (
            ""
          )}

          <p
            className="tempSavedCloseBtn"
            onClick={() => {
              setClosedDays([]);
            }}
          >
            Understood
          </p>
        </div>
      ) : (
        <div
          className={`${saved ? "templateSavedModal" : "none"}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="tempSavedTxt">
            Template {saveTemplateObj.saving ? "saved" : "loaded"}{" "}
            {saveTemplateObj.saving ? "" : <br />}
            {saveTemplateObj.saving
              ? ""
              : `${loadWeeksQty === 1 ? "on" : "on"} to ${
                  loadWeeksQty === 1 ? "one" : loadWeeksQty
                } week${loadWeeksQty === 1 ? "" : "s"}`}
          </p>

          {usersNoLongerInTeam[0] ? (
            <p className="followingStaffTempTitle">
              The following staff are in this template but are no longer in this
              team
            </p>
          ) : (
            ""
          )}
          {usersNoLongerInTeam[0] ? (
            <div className="noLongerInTeamDiv">
              {usersNoLongerInTeam.map((item) => {
                return <p className="userNotInTeamItem">{item}</p>;
              })}
            </div>
          ) : (
            ""
          )}
          <p
            className={`${
              saveTemplateObj.loading ? "tempLoadedNotLimitsTxt" : "none"
            }`}
          >
            <span className="limitsNoteSpan">Note: </span> leave limits are not
            loaded from templates.
          </p>
          {saveTemplateObj.loading ? (
            <div className="publishTemplatedNow">
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setSaveTemplateObj((x) => {
                    return {};
                  });
                }}
              >
                Don't publish
              </p>
              <p
                className="tempSavedCloseBtnPublish"
                onClick={() => {
                  // teamID = saveTemplateObj.teamID
                  // monWeeksToPublish
                  // lottie
                  setLoading(true);
                  setSaveTemplateObj((x) => {
                    return {};
                  });

                  axios
                    .post(
                      `${serverURL}/publish-team-week`,
                      {
                        teamID: saveTemplateObj.teamID,
                        locationID: saveTemplateObj.locationID,
                        monWeek: saveTemplateObj.monWeek,
                        data: true,

                        loadWeeksQty,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setUpdateInfo((x) => !updateInfo);
                        setLoading(false);
                        setSaved(true);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Publish now
              </p>
            </div>
          ) : (
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setSaveTemplateObj((x) => {
                  return {};
                });
              }}
            >
              Close
            </p>
          )}
        </div>
      )}
      {!loading ? (
        <div
          className={`${
            width < mobileWidthBreak
              ? "bulkEditLimitModalBox"
              : "bulkEditLimitModalBox saveTemplateWideModalBox"
          } ${saved ? "none" : ""} ${loading ? "loadingSaveTemp" : ""} ${
            mobModal
              ? `mobModalShoulder bulkEditLimitModalBoxMob ${
                  device.ios ? "paddingBottom20" : ""
                }`
              : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {mobModal && (
            <div
              className="modalSwiper modalSwiperSaveTemplate"
              onTouchStart={handleDown}
              onTouchMove={mobModal ? handleMove : null}
              onTouchEnd={mobModal ? handleUp : null}
              onMouseDown={mobModal ? handleDown : null}
              onMouseMove={mobModal ? handleMove : null}
              onMouseUp={mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className="bulkEditLimitModalHeader"
            onTouchStart={handleDown}
            onTouchMove={mobModal ? handleMove : null}
            onTouchEnd={mobModal ? handleUp : null}
            onMouseDown={mobModal ? handleDown : null}
            onMouseMove={mobModal ? handleMove : null}
            onMouseUp={mobModal ? handleUp : null}
          >
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon"
              onClick={() => {
                setSaveTemplateObj((x) => {
                  return {};
                });
              }}
            />
            <p className="bulkEditLimitModalHeaderTitle" onClick={() => {}}>
              {savingTemplate ? "Save template" : "Load a template"}
            </p>
            <img
              src={navyClose}
              alt="Close"
              className="closeBulkEditLimitModalIcon bulkEditCloseBlank"
            />{" "}
          </div>

          <div
            className={`bulkEditTextDiv x238943893598345 ${
              !savingTemplate ? "loadTempDivWithMutliWeek" : ""
            }`}
            onClick={() => {}}
          >
            <p
              className={`${
                savingTemplate ? "saveTempTxtInstruction" : "none"
              }`}
            >
              Save week commencing {/* <br /> */}
              <span className="templateDataSpan">
                Mon{" "}
                {dateStringer.createShortStripFromDateString(
                  saveTemplateObj.monWeek
                )}
              </span>{" "}
              as a template{" "}
              {saveTemplateObj.locationName ? (
                <>
                  for{" "}
                  <span className="templateDataSpan">
                    {saveTemplateObj.locationName}
                  </span>
                </>
              ) : (
                <>
                  for{" "}
                  <span className="templateDataSpan">
                    {saveTemplateObj.teamName}
                  </span>
                </>
              )}
              .{" "}
              {saveTemplateObj.locationName && checkUserState.permissions !== 1
                ? "When this template is loaded, only the shifts within teams you are authorised to manage will be created."
                : ""}
            </p>
            <p
              className={`${
                savingTemplate ? "none" : "saveTempTxtInstruction"
              }`}
            >
              Load a template to commence on{" "}
              <span className="templateDataSpan">
                Mon{" "}
                {dateStringer.createShortStripFromDateString(
                  saveTemplateObj.monWeek
                )}
              </span>{" "}
              for{" "}
              <span className="templateDataSpan">
                {teamName || locationName}
              </span>
              .
              {checkUserState.permissions !== 9 && locationName
                ? " When loading a template for a location, only the shifts within teams you are authorised to manage will be created."
                : ""}
            </p>
            {!savingTemplate ? (
              <div className="loadMultiWeeks">
                <p className="applyTempTxt">Weeks</p>
                <div className="applyWeeksContainer">
                  <p
                    className={`applyTempPlusMinus ${
                      loadWeeksQty === 1 ? "invis" : ""
                    }`}
                    onClick={() => {
                      if (loadWeeksQty > 1) {
                        setLoadWeeksQty(loadWeeksQty - 1);
                      }
                    }}
                  >
                    -
                  </p>
                  <input
                    type="number"
                    className="loadWeeksNumberInput"
                    value={loadWeeksQty}
                    min="1"
                    max="12"
                  />
                  <p
                    className={`applyTempPlusMinus ${
                      loadWeeksQty === 4 ? "invis" : ""
                    }`}
                    onClick={() => {
                      if (loadWeeksQty < 4) {
                        setLoadWeeksQty(loadWeeksQty + 1);
                      }
                    }}
                  >
                    +
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className={`${
              width < mobileWidthBreak
                ? "saveTempPageMob"
                : `saveTempPageWide ${
                    saveTemplateObj.loading ? "" : "flexReverse"
                  }`
            }`}
          >
            <div className="saveTemplateModalUnit">
              <div
                className={`${
                  saveTemplateObj.loading ? "templateSelectDiv" : "none"
                }`}
              >
                <p
                  className={`selectTemplateTxt ${
                    mobModal ? "selectTemplateTxtMob" : ""
                  }`}
                >
                  Select template
                </p>{" "}
                <div
                  className={`templateSelectBox ${
                    mobModal ? "mobileMaxHeightTemplateBox" : ""
                  }`}
                >
                  {generateTemplates}
                  <p
                    className={`${
                      templates.length > 0 ? "none" : "noSavedTempsTxt"
                    }`}
                  >
                    There are no saved templates.
                    <br />
                    <br />
                    Click{" "}
                    <span
                      className="clickHereToSaveTempInsteadSpan"
                      onClick={() => {
                        setSaveTemplateObj((x) => {
                          return {
                            teamID: saveTemplateObj.teamID,
                            monWeek: saveTemplateObj.monWeek,
                            saving: true,
                            loading: false,
                            shifts: saveTemplateObj.shifts,
                            opens: saveTemplateObj.opens,
                          };
                        });
                      }}
                    >
                      here
                    </span>{" "}
                    to save the current week as a template instead.
                  </p>
                </div>
              </div>
              <div
                className={`${
                  saveTemplateObj.saving ? "templateTableDiv" : "none"
                }`}
              >
                <div className="templateTableTopRow">
                  <p className="templateTopRowCellTitle">Day</p>
                  <p
                    className={`templateTopRowCellTitle ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    Shifts
                  </p>
                  <p
                    className={`templateTopRowCellTitle ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    Overtimes
                  </p>
                  <p
                    className={`templateTopRowCellTitle ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    Lieu
                  </p>
                  <p
                    className={`templateTopRowCellTitle ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    Opens
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Mon</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.monShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.monOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.monTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.monOpens}
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Tue</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.tueShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.tueOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.tueTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.tueOpens}
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Wed</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.wedShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.wedOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.wedTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.wedOpens}
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Thu</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.thuShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.thuOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.thuTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.thuOpens}
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Fri</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.friShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.friOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.friTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.friOpens}
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Sat</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.satShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.satOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.satTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.satOpens}
                  </p>
                </div>

                <div className="templateTableTopRow">
                  <p className="templateTopRowCell">Sun</p>
                  <p
                    className={`templateTopRowCell ${
                      !includeShifts ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.sunShifts}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOvertimes ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.sunOvertimes}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeTils ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.sunTils}
                  </p>
                  <p
                    className={`templateTopRowCell ${
                      !includeOpens ? "fadeTempRow" : ""
                    }`}
                  >
                    {qty.sunOpens}
                  </p>
                </div>
              </div>
              <div
                className={`${
                  saveTemplateObj.saving ? "templateIncludeRow" : "none"
                }`}
              >
                <p className="includeCell">Save</p>
                <div className="includeKnobContainer">
                  {/* KNOB BEGIN */}
                  <div
                    className="includeTypesSaveTempHover"
                    onClick={() => {
                      setIncludeShifts(!includeShifts);
                    }}
                  >
                    <div className={`knobContainer templateKnob `}>
                      <div
                        className={`switchKnob ${
                          includeShifts ? "knobOn " : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
                <div className="includeKnobContainer">
                  {/* KNOB BEGIN */}
                  <div
                    className="includeTypesSaveTempHover"
                    onClick={() => {
                      setIncludeOvertimes(!includeOvertimes);
                    }}
                  >
                    <div className={`knobContainer templateKnob `}>
                      <div
                        className={`switchKnob ${
                          includeOvertimes ? "knobOn " : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
                <div className="includeKnobContainer">
                  {/* KNOB BEGIN */}
                  <div
                    className="includeTypesSaveTempHover"
                    onClick={() => {
                      setIncludeTils(!includeTils);
                    }}
                  >
                    <div className={`knobContainer templateKnob `}>
                      <div
                        className={`switchKnob ${
                          includeTils ? "knobOn " : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
                <div className="includeKnobContainer">
                  {/* KNOB BEGIN */}
                  <div
                    className="includeTypesSaveTempHover"
                    onClick={() => {
                      setIncludeOpens(!includeOpens);
                    }}
                  >
                    <div className={`knobContainer templateKnob `}>
                      <div
                        className={`switchKnob ${
                          includeOpens ? "knobOn " : "knobOff"
                        }`}
                      ></div>
                      <div className="knobBg"></div>
                    </div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>
            </div>
            <div
              className={`${
                width >= mobileWidthBreak
                  ? `saveTempMobWideDivider`
                  : "saveTempMobDivider"
              } ${templates.length === 0 ? "invis" : ""}`}
            ></div>
            <div
              className={`${
                savingTemplate
                  ? "none"
                  : `loadTemplatemodalUnit ${
                      mobModal ? "loadTemplatemodalUnitMob" : ""
                    }`
              }`}
            >
              {/* <p
              className={`${
                selectedTemplate ? "whatThisTempLooksLikeTxt" : "none"
              }`}
            >
              What this template looks like...
            </p> */}
              <p
                className={`${
                  selectedTemplate ? "none" : "selectTemplateFirstTxt"
                }`}
              >
                Select a template{" "}
                {width < mobileWidthBreak ? "above" : "to the left"}
              </p>
              <div
                className={`${
                  selectedTemplate ? "tempLookLikeDayRow" : "none"
                }`}
              >
                <div className="tempLookLikeA1"></div>
                <div className="tempLookLikeDayCell">M</div>
                <div className="tempLookLikeDayCell">T</div>
                <div className="tempLookLikeDayCell">W</div>
                <div className="tempLookLikeDayCell">T</div>
                <div className="tempLookLikeDayCell">F</div>
                <div className="tempLookLikeDayCell">S</div>
                <div className="tempLookLikeDayCell">S</div>
              </div>
              <div
                className={`${
                  selectedTemplate
                    ? `templateSelectBox ${
                        mobModal ? "templateSelectBoxMob" : ""
                      }`
                    : "none"
                }`}
              >
                {openItemsQtr() > 0 ? (
                  <div
                    className={`templateOpensRow ${
                      openItemsQtr() === 1 && "opensRow1"
                    }  ${openItemsQtr() === 2 && "opensRow2"} ${
                      openItemsQtr() === 3 && "opensRow3"
                    }  ${openItemsQtr() === 4 && "opensRow4"}  ${
                      openItemsQtr() === 5 && "opensRow5"
                    }  ${openItemsQtr() === 6 && "opensRow6"}  ${
                      openItemsQtr() === 7 && "opensRow7"
                    }  ${openItemsQtr() === 8 && "opensRow8"}  ${
                      openItemsQtr() === 9 && "opensRow9"
                    }`}
                  >
                    {saveTemplateObj.loading &&
                      selectedTemplate &&
                      generateOpensRow}
                  </div>
                ) : (
                  ""
                )}
                {saveTemplateObj.loading &&
                  selectedTemplate &&
                  generateUserRows}
              </div>
            </div>
            <div
              className={`${savingTemplate ? "saveTemplateModalUnit" : "none"}`}
            >
              <p className="nameTemplateTxt">Name your template</p>
              <textarea
                ref={inputRef}
                className="templateNameInput"
                value={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
              ></textarea>
              <p className="nameTemplateTxt">Describe it (optional)</p>
              <textarea
                className={`templateNameInput ${
                  width >= mobileWidthBreak
                    ? "descriptionBoxInWideSaveTempModal"
                    : ""
                } resizable`}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              ></textarea>
            </div>
          </div>

          <div
            className={`bulkModalFooter ${
              !mobModal ? "desktopBulkModalFooter" : ""
            }`}
          >
            <div className="saveTempFooterRightDiv">
              <p
                className={`saveBulkModalBtn ${
                  !templateName ||
                  (!includeOpens &&
                    !includeShifts &&
                    !includeTils &&
                    !includeOvertimes) ||
                  loading ||
                  (saveTemplateObj.loading && !selectedTemplate)
                    ? "fadeBulkSaveBtn"
                    : ""
                }`}
                onClick={() => {
                  if (
                    !loading &&
                    templateName &&
                    saveTemplateObj.monWeek &&
                    saveTemplateObj.saving
                  ) {
                    setLoading(true);
                    axios
                      .post(
                        `${serverURL}/save-template-week`,
                        {
                          teamID: saveTemplateObj.teamID,
                          locationID: saveTemplateObj.locationID,

                          monWeek: saveTemplateObj.monWeek,
                          includeShifts,
                          includeOpens,
                          includeOvertimes,
                          includeTils,
                          name: templateName,
                          description,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setSaved(true);
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }

                  if (saveTemplateObj.loading && selectedTemplate && !loading) {
                    setLoading(true);
                    axios
                      .post(
                        `${serverURL}/load-template-onto-mon-week`,
                        {
                          teamID: saveTemplateObj.teamID,
                          locationID: saveTemplateObj.locationID,
                          monWeek: saveTemplateObj.monWeek,
                          deleteExisting,
                          templateID: selectedTemplate,
                          loadWeeksQty,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setUpdateInfo((x) => !updateInfo);
                          setUsersNoLongerInTeam(response.data.noLongerOnTeam);
                          setLoading(false);
                          setSaved(true);
                          setClosedDays(
                            response.data.closedDaysThatWereGeneratedWithin
                          );
                          setMonWeeksToPublish(response.data.monWeeks);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }
                }}
              >
                {savingTemplate ? "Save" : "Load"}{" "}
              </p>
              <div
                className={`pubShiftBtn replaceExistingBtn ${
                  !saveTemplateObj.saving
                    ? `${deleteExisting ? "pubImmedBg" : ""}`
                    : "invis"
                }`}
                onClick={() => {
                  setDeleteExisting(!deleteExisting);
                }}
              >
                <img
                  src={deleteExisting ? publishTick2 : publishTick1}
                  className="replaceTickImg"
                  alt="Publish"
                />
                Replace existing
              </div>
            </div>
            <p
              className="cancelBulkModalBtn"
              onClick={() => {
                setSaveTemplateObj((x) => {
                  return {};
                });
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
      {sureDeleteTemplate ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setSureDeleteTemplate("");
            }}
          >
            <p className="overlapsRenTxt">
              Are you sure you want to delete this template?
              <br /> <br />
              You cannot undo this action.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureDeleteTemplate("");
                  axios
                    .post(
                      `${serverURL}/delete-template`,
                      {
                        templateID: sureDeleteTemplate,
                        teamID: saveTemplateObj.teamID,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setTemplates(response.data.templates);
                        setSelectedTemplate("");
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteTemplate("");
                }}
              >
                No
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SaveTemplateModal;
