import React, { useContext, useEffect, useState, useMemo } from "react";
// cleaned 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios not needed

import { NavLink } from "react-router-dom";
import axios from "axios";
import serverURL from "../serverURL";

import "../styles/navbar.css";

import { DataContext } from "../contexts/DataContext";
import { UserContext } from "../contexts/UserContext";
import { RequestContext } from "../contexts/RequestContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { StyleContext } from "../contexts/StyleContext";
import dateStringer from "../tools/dateStringer";

import whiteComment from "../img/general/whiteComment.svg";

const Upcoming = ({ desktop, desktopShow, setUpcQty }) => {
  const { currencySymbol } = useContext(UserContext);

  const {
    setShowMyShiftModal,
    setShowUserReqFromNav,
    setShowReqMgrModal,
    setShowEditShiftModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      currencySymbol,
      setShowMyShiftModal,
      setShowUserReqFromNav,
      setShowEditShiftModal,
      setShowReqMgrModal, //
    }),
    [
      currencySymbol,
      setShowMyShiftModal,
      setShowUserReqFromNav,
      setShowEditShiftModal,
      setShowReqMgrModal,
    ]
  );

  let [items, setItems] = useState([]);
  let [loadedAll, setLoadedAll] = useState(false);
  let shortenTag = (t) => {
    if (t && t.length > 11) {
      return `${t.substr(0, 9)}...`;
    } else {
      return t;
    }
  };

  let shortenPHol = (val) => {
    if (val && val.length > 18) {
      return `${val.substr(0, 16)}...`;
    } else {
      return val;
    }
  };
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    if (!desktop || (desktop && desktopShow && !items[0])) {
      axios
        .post(
          `${serverURL}/get-upcoming`,
          {
            ds: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            if (response.data.output[0]) {
              setItems(response.data.output);
              setUpcQty(response.data.output ? response.data.output.length : 0);
            } else {
              setLoadedAll(true);
              axios
                .post(
                  `${serverURL}/get-upcoming`,
                  {
                    ds: dateStringer.createStringFromTimestamp(
                      new Date().getTime()
                    ),

                    loadMore: true,
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  if (response.data.message === "success") {
                    setItems(response.data.output);
                  }
                });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [desktop, desktopShow]);
  return (
    <div className={`${desktop ? "upcomingScrollBox" : ""}`}>
      {items.map((item) => {
        let dayBlob = () => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let in7days = new Date(today.getTime());
          in7days.setDate(in7days.getDate() + 6);
          let item00ds = `${item.startDs.substr(0, 11)}H00M00`;
          let daysAway = Math.round(
            (dateStringer.createTimestampFromString(item00ds) -
              today.getTime()) /
              86400000
          );

          let getStr = () => {
            let itemDateObj = new Date(item.ts);

            if (daysAway < 7) {
              if (itemDateObj.getDay() === 0) {
                return "Sun";
              }
              if (itemDateObj.getDay() === 1) {
                return "Mon";
              }
              if (itemDateObj.getDay() === 2) {
                return "Tue";
              }
              if (itemDateObj.getDay() === 3) {
                return "Wed";
              }
              if (itemDateObj.getDay() === 4) {
                return "Thu";
              }
              if (itemDateObj.getDay() === 5) {
                return "Fri";
              }
              if (itemDateObj.getDay() === 6) {
                return "Sat";
              }
            } else {
              return `${daysAway}d`;
              // let month = "";
              // if (itemDateObj.getMonth() === 0) {
              //   month = "Jan";
              // }
              // if (itemDateObj.getMonth() === 1) {
              //   month = "Feb";
              // }
              // if (itemDateObj.getMonth() === 2) {
              //   month = "Mar";
              // }
              // if (itemDateObj.getMonth() === 3) {
              //   month = "Apr";
              // }
              // if (itemDateObj.getMonth() === 4) {
              //   month = "May";
              // }
              // if (itemDateObj.getMonth() === 5) {
              //   month = "Jun";
              // }
              // if (itemDateObj.getMonth() === 6) {
              //   month = "Jul";
              // }
              // if (itemDateObj.getMonth() === 7) {
              //   month = "Aug";
              // }
              // if (itemDateObj.getMonth() === 8) {
              //   month = "Sep";
              // }
              // if (itemDateObj.getMonth() === 9) {
              //   month = "Oct";
              // }
              // if (itemDateObj.getMonth() === 10) {
              //   month = "Nov";
              // }
              // if (itemDateObj.getMonth() === 11) {
              //   month = "Dev";
              // }

              // return `${itemDateObj.getDate()} ${month}`;
            }
          };

          return daysAway === 0 ? "Today" : daysAway < 0 ? "Now" : getStr();
        };

        let type = "Shift";
        if (item.type === "overtime") {
          type = "Overtime";
        }
        if (item.type === "til") {
          type = "Shift in Lieu";
        }
        if (item.type === "leave") {
          type = "Leave";
        }

        if (item.type === "absence") {
          type = "Absence";
        }
        if (item.type === "specDay") {
          type = shortenPHol(item.name); // honey
        }
        if (item.type === "pHol") {
          type = `${
            memoVals.currencySymbol === "£" ? "Bank holiday" : "Public holiday"
          }`;
        }

        let tilStrip = item.tilApproved
          ? dateStringer.generateTilShiftString(
              item.tilStart,
              item.tilEnd,
              `${item.startDs.substr(12, 2)}:${item.startDs.substr(15, 2)}`,
              `${item.endDs.substr(12, 2)}:${item.endDs.substr(15, 2)}`,
              false,
              "&",
              item.type,
              false,
              true
            )
          : "";

        return (
          <div
            className="upcItemParent"
            onClick={() => {
              if (item.type === "shift") {
                if (item.userID) {
                  memoVals.setShowEditShiftModal((x) => {
                    return {
                      itemID: item.shiftID,
                      itemType: item.type,
                      itemIsNew: false,
                      userID: item.userID,
                    };
                  });
                } else {
                  memoVals.setShowMyShiftModal((x) => {
                    return {
                      unitType: "shift",
                      unitID: item.shiftID,
                    };
                  });
                }
              }

              if (item.type === "til" || item.type === "overtime") {
                if (item.userID) {
                  memoVals.setShowEditShiftModal((x) => {
                    return {
                      itemID: item.tilID,
                      itemType: item.type,
                      itemIsNew: false,
                      userID: item.userID,
                    };
                  });
                } else {
                  memoVals.setShowMyShiftModal((x) => {
                    return {
                      unitType: item.type,
                      unitID: item.tilID,
                    };
                  });
                }
              }
              if (item.reqID) {
                if (item.userID) {
                  memoVals.setShowReqMgrModal((x) => item.reqID);
                } else {
                  memoVals.setShowUserReqFromNav((x) => item.reqID);
                }
              }
            }}
          >
            <div
              className={`upcItemL ${
                dayBlob() === "Today" ? "upcItemLToday" : ""
              } ${dayBlob().length >= 6 ? "upcItemLsmall" : ""} ${
                item.type === "overtime" || item.type === "til"
                  ? "tilOvertimeUpcItemRBlob_"
                  : ""
              } ${item.type === "absence" ? "absenceUpcItemRBlob" : ""} ${
                item.type === "leave" ? "leaveUpcItemRBlob" : ""
              } ${
                item.type === "specDay" || item.type === "pHol"
                  ? "pHolUpcItemRBlob"
                  : ""
              } ${item.absent ? "absentUpcItemL" : ""}`}
            >
              {dayBlob()}
            </div>

            <div
              className={`upcItemR ${
                dayBlob() === "Today" || dayBlob() === "Now"
                  ? "todayWhiteBorder"
                  : ""
              } ${
                item.type === "overtime" || item.type === "til"
                  ? "tilOvertimeUpcItemR_"
                  : ""
              } ${item.type === "absence" ? "absenceUpcItemR" : ""} ${
                item.type === "leave" ? "leaveUpcItemR" : ""
              } ${
                item.type === "specDay" || item.type === "pHol"
                  ? "pHolUpcItemR"
                  : ""
              } ${
                desktopShow && (item.shiftID || item.reqID || item.tilID)
                  ? "pointerUpcItem"
                  : ""
              } ${item.absent ? "absentUpcItemR" : ""} ${
                item.userID ? "upcItemRUser" : ""
              }`}
            >
              <div
                className={`upcItemRightLeft ${
                  item.type === "pHol" || item.type === "specDay"
                    ? "specDayRightLeft"
                    : ""
                }`}
              >
                <div className="upcomingDatesParent">
                  <div className="upcomingDateToken">
                    <p className="upcTokenTop">
                      {parseInt(item.startDs.substr(9, 2))}
                      {item.reqStartHalf ||
                      (item.totDays === 0.5 && item.reqEndHalf) ? (
                        <div className="upcTokenHalf">½</div>
                      ) : (
                        ""
                      )}
                    </p>
                    <p className="upcTokenBottom">
                      {dateStringer.getMonthThreeLettersFromDs(item.startDs)}
                    </p>
                  </div>
                  {(item.type === "leave" && item.totDays > 1) ||
                  (item.type === "absence" && item.totDays > 1) ? (
                    <div
                      className={`upcTokenDivider ${
                        item.ongoing ? "invis" : ""
                      }`}
                    >
                      -
                    </div>
                  ) : (
                    ""
                  )}
                  {!item.ongoing &&
                  ((item.type === "leave" && item.totDays > 1) ||
                    (item.type === "absence" && item.totDays > 1)) ? (
                    <div className="upcomingDateToken">
                      <p className="upcTokenTop">
                        {parseInt(item.endDs.substr(9, 2))}
                        {item.reqEndHalf ||
                        (item.totDays === 0.5 && item.reqStartHalf) ? (
                          <div className="upcTokenHalf">½</div>
                        ) : (
                          ""
                        )}
                      </p>
                      <p className="upcTokenBottom">
                        {dateStringer.getMonthThreeLettersFromDs(item.endDs)}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {item.type === "leave" || item.type === "absence" ? (
                    <div className="upcCentralReq">
                      {item.userID ? (
                        <p className="upcUserRow upcUserRowReq">
                          {item.fName} {item.lName}
                        </p>
                      ) : (
                        ""
                      )}
                      <div
                        className={`upcLeaveDur ${
                          !item.alreadyStarted ? "notYetStartedUpcoming" : ""
                        }`}
                      >
                        {item.alreadyStarted
                          ? item.ongoing
                            ? "On-going"
                            : `${
                                item.daysLeft === 0.5 ? "Half a" : item.daysLeft
                              } day${
                                item.daysLeft <= 1 && item.daysLeft ? "" : "s"
                              } remaining`
                          : `${
                              item.totDays === 0.5
                                ? "Half a day"
                                : `${item.totDays} day${
                                    item.totDays === 1 ? "" : "s"
                                  }`
                            }`}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.type === "shift" ||
                  item.type === "til" ||
                  item.type === "overtime" ? (
                    <div
                      className={`upcShiftInfo ${
                        item.userID ? "upcShiftInfoUser" : ""
                      }`}
                    >
                      {item.userID ? (
                        <div className="upcUserRow">
                          {item.fName} {item.lName}
                        </div>
                      ) : (
                        ""
                      )}
                      {tilStrip ? (
                        <p className="upcShiftInfoTop">{tilStrip}</p>
                      ) : (
                        <p className="upcShiftInfoTop">
                          {dateStringer.dsToTimeStrip(item.startDs, false)} -{" "}
                          {dateStringer.dsToTimeStrip(item.endDs, false)}
                        </p>
                      )}
                      <div className="upcShiftInfoBottom">
                        <p className="upcShiftTime">
                          {dateStringer.formatMinsDurationToHours(item.durMins)}
                        </p>
                        {item.tags && item.tags[0] ? (
                          <p className="upcTagsSpan">
                            {shortenTag(item.tags[0])}{" "}
                            {item.tags.length > 1
                              ? `+${item.tags.length - 1}`
                              : ""}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className={`upcItemRightRight ${
                  item.type === "pHol" || item.type === "specDay"
                    ? "pHolSpecDayWidthUpcRight"
                    : ""
                }`}
              >
                <p
                  className={`upcItemRightRightTop ${
                    type === "Shift in Lieu" ? "tilTxtSize" : ""
                  }`}
                >
                  {type === "Leave"
                    ? item.type === "absence"
                      ? "Absence"
                      : type
                    : type}{" "}
                  {item.notes > 0 ? (
                    <img
                      src={whiteComment}
                      className="upcomingCommentImg"
                      alt="Notes"
                    />
                  ) : (
                    ""
                  )}
                </p>
                <p className="upcItemRightRightBottom">
                  {item.type === "leave" || item.type === "absence"
                    ? item.leaveTypeName
                    : item.type === "pHol"
                    ? item.name
                    : item.type === "specDay"
                    ? item.companyClosed
                      ? "Closed"
                      : ""
                    : dateStringer.shorten(item.teamName, 24)}{" "}
                  {item.shiftID && item.type === "specDay" ? "- Paid" : ""}
                </p>
                {item.type === "pHol" ? (
                  <p className="upcItemRightRightBottom pHolStatus">
                    {item.companyClosed ? "Closed" : "Open"}{" "}
                    {item.shiftID && item.type === "pHol" && item.companyClosed
                      ? "- Paid"
                      : ""}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      })}
      {items[0] && !loadedAll ? (
        <p
          className="loadUpcomingMoreBtn"
          onClick={() => {
            setLoadedAll(true);
            axios
              .post(
                `${serverURL}/get-upcoming`,
                {
                  ds: dateStringer.createStringFromTimestamp(
                    new Date().getTime()
                  ),

                  loadMore: true,
                },

                {
                  withCredentials: true,
                  credentials: "include",
                }
              )
              .then((response) => {
                if (response.data.message === "success") {
                  setItems(response.data.output);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          Load more
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default Upcoming;
