// CSS for this modal is in calendar.css + navbar.css -- navbar because the modal is loaded within the navbar

import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
  useMemo,
} from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done 7 sep 24

import axios from "axios";
import dateStringer from "../../../tools/dateStringer";

import { DataContext } from "../../../contexts/DataContext";
import { UserContext } from "../../../contexts/UserContext";

import serverURL from "../../../serverURL";
import { StyleContext } from "../../../contexts/StyleContext";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import close from "../../../img/modals/close.svg";
import editGrey from "../../../img/general/editGrey.svg";
import greyClose from "../../../img/general/greyClose.svg";
import whiteTick from "../../../img/general/whiteTick.svg";
import crossWhite from "../../../img/general/crossWhite.svg";
import edit from "../../../img/general/edit.svg";
import cancelTil from "../../../img/general/cancelTil.svg";
import bin1 from "../../../img/general/bin1.svg";
import gps1 from "../../../img/general/gps1.svg";
import gps2 from "../../../img/general/gps2.svg";
import greyCross from "../../../img/general/greyCross.svg";
import editReq from "../../../img/general/editReq.svg";
import like from "../../../img/general/like.svg";
import liked from "../../../img/general/liked.svg";
import liked2 from "../../../img/general/liked2.svg";
import acknowledgeArr from "../../../img/general/acknowledgeArr.svg";
import arrGrey from "../../../img/general/arrGrey.svg";
import checkbox1 from "../../../img/general/navyTick.svg";
import noteImg from "../../../img/general/noteWhite.svg";
import returnArrow from "../../../img/general/returnArrow.svg";
import popup from "../../../img/general/popup.svg";
import whitebin from "../../../img/general/whitebin.svg";
import schedLimitPlus from "../../../img/general/schedLimitPlus.svg";
import schedLimitMinus from "../../../img/general/schedLimitMinus.svg";
import rotaClock from "../../../img/general/rotaClock.svg";
import checkeredFlag from "../../../img/general/checkeredFlag.svg";
import ruler from "../../../img/general/ruler.svg";
import pin from "../../../img/general/pin.svg";
import map from "../../../img/general/map.svg";
import rotaCoffee from "../../../img/general/rotaCoffee.svg";

const ClockItemModal = ({ id, mgrMode }) => {
  let [isMgr, setIsMgr] = useState(mgrMode);
  const {
    clockOnID,
    setClockOnID,
    setIndicate,
    setDeletedClockOnID,
    setShowMyShiftModal,
    showEditUser,
    setShowEditUser,
    reloadManagerShift,
    setReloadManagerShift,
    setOpenManagerShiftModal,
    showMyShiftModal,
    setChangedClockCard,
    reloadTs,
    setReloadTs,
    modalOpen,
    setModalOpen,
    device,
    countData,
    setCountData,
    setShowViewTimesheet,
  } = useContext(DataContext);
  const { mobModal } = useContext(StyleContext);
  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      clockOnID,
      setClockOnID,
      setIndicate,
      setDeletedClockOnID,
      setShowMyShiftModal,
      showEditUser,
      setShowEditUser,
      reloadManagerShift,
      setReloadManagerShift,
      setOpenManagerShiftModal,
      showMyShiftModal,
      setChangedClockCard,
      reloadTs,
      setReloadTs,
      mobModal,
      checkUserState,
      modalOpen,
      setModalOpen,
      device,
      setShowViewTimesheet,
    }),
    [
      clockOnID, //
      setClockOnID, //
      setIndicate, //
      setDeletedClockOnID, //
      setShowMyShiftModal, //
      showEditUser, //
      setShowEditUser, //
      reloadManagerShift, //
      setReloadManagerShift, //
      setOpenManagerShiftModal, //
      showMyShiftModal, //
      setChangedClockCard, //
      reloadTs, //
      setReloadTs, //
      mobModal, //
      checkUserState, //
      modalOpen,
      setModalOpen,
      device,
      setShowViewTimesheet,
    ]
  );
  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 30) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let imgPreload = (
    <div className="imagesHidden">
      {" "}
      <img
        src={map}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaCoffee}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={pin}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={ruler}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={close}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaClock}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={checkeredFlag}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitMinus}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={schedLimitPlus}
        alt="Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="Edit Grey"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyClose}
        alt="Grey Close"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteTick}
        alt="White Tick"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={crossWhite}
        alt="Cross White"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={edit}
        alt="Edit"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={cancelTil}
        alt="Remove Tag"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={bin1}
        alt="Bin 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps1}
        alt="GPS 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={gps2}
        alt="GPS 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={greyCross}
        alt="Grey Cross"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editReq}
        alt="Edit Request"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={like}
        alt="Like"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked}
        alt="Liked"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liked2}
        alt="Liked 2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={acknowledgeArr}
        alt="Acknowledge Arr"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={arrGrey}
        alt="Grey Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={checkbox1}
        alt="Checkbox 1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={noteImg}
        alt="Note"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={returnArrow}
        alt="Return Arrow"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup}
        alt="Popup"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whitebin}
        alt="White Bin"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  let [editClocked, setEditClocked] = useState(false);

  let [newDateData, setNewDateData] = useState({});
  let [newlyAddedTags, setNewlyAddedTags] = useState([]);
  let [changed, setChanged] = useState(false);
  let [justApproved, setJustApproved] = useState(false);
  let [hoveredLikeCommentID, setHoveredLikeCommentID] = useState("");
  let [clockCardDoesNotExist, setClockCardDoesNotExist] = useState(false);
  let [toggleEditDate, setToggleEditDate] = useState(false);
  let [editDateInput, setEditDateInput] = useState("");

  let [editDecision, setEditDecision] = useState(false);
  // score = mins early or late
  let [showInvalidShiftModal, setShowInvalidShiftModal] = useState(false);
  let [sureDelete, setSureDelete] = useState(false);
  let [showMgrOption, setShowMgrOption] = useState(false);
  let score = (score, type) => {
    if (type === "time") {
      if (score === 0) {
        return "On time";
      }
      if (score < 0) {
        return `${dateStringer.formatMinsDurationToHours(
          score - score - score
        )} early`;
      }
      if (score > 0) {
        return `${dateStringer.formatMinsDurationToHours(score)} late`;
      }
    }

    if (type === "break") {
      if (score === 0) {
        return "As scheduled";
      }
      if (score < 0) {
        return `${dateStringer.formatMinsDurationToHours(
          score - score - score
        )} under`;
      }
      if (score > 0) {
        return `${dateStringer.formatMinsDurationToHours(score)} over`;
      }
    }
  };

  let [showExcessModal, setShowExcessModal] = useState({
    show: false,
    short: false,
    over: true,
    diff: 0,
  });
  let [excessToil, setExcessToil] = useState(true);
  let [showBrkGPS, setShowBrkGPS] = useState(false);
  let [newOn, setNewOn] = useState("");
  let [newOff, setNewOff] = useState("");
  let [newBrk1Start, setNewBrk1Start] = useState("");
  let [newBrk1End, setNewBrk1End] = useState("");
  let [newBrk2Start, setNewBrk2Start] = useState("");
  let [newBrk2End, setNewBrk2End] = useState("");
  let [newBrk3Start, setNewBrk3Start] = useState("");
  let [newBrk3End, setNewBrk3End] = useState("");

  let [b2, setB2] = useState(0);
  let [b2a, setB2a] = useState(0);
  let [b2b, setB2b] = useState(0);
  let [b2c, setB2c] = useState(0);

  let [newClockedDurMins, setNewClockedDurMins] = useState(0);

  let [newStartTs, setNewStartTs] = useState(0);
  let [newEndTs, setNewEndTs] = useState(0);

  let [newB1StartTs, setNewB1StartTs] = useState(0);
  let [newB1EndTs, setNewB1EndTs] = useState(0);

  let [newB2StartTs, setNewB2StartTs] = useState(0);
  let [newB2EndTs, setNewB2EndTs] = useState(0);
  let [, setB2Enabled] = useState(false);

  let [newB3StartTs, setNewB3StartTs] = useState(0);
  let [newB3EndTs, setNewB3EndTs] = useState(0);

  let [b1overlap, setB1overlap] = useState(false);
  let [b2overlap, setB2overlap] = useState(false);
  let [b3overlap, setB3overlap] = useState(false);

  let [addNote, setAddNote] = useState(false);
  let [note, setNote] = useState("");

  let [showDistanceModal, setShowDistanceModal] = useState({
    show: false,
    zoneName: "",
    distance: 0,
    item: "", // clockOn, b1start, b1end, b2start, b2end, b3start, b3end, clockOff
  });

  let showDistanceString = (item) => {
    if (item === "clockOn") {
      return "clocked on";
    }

    if (item === "b1start") {
      return "clocked off for their first break";
    }

    if (item === "b1end") {
      return "clocked back on from their first break";
    }

    if (item === "b2start") {
      return "clocked off for their second break";
    }

    if (item === "b2end") {
      return "clocked back on from their second break";
    }

    if (item === "b3start") {
      return "clocked off for their third break";
    }

    if (item === "b3end") {
      return "clocked back on from their third break";
    }

    if (item === "clockOff") {
      return "clocked off";
    }
  };

  let noteRef = useRef();
  let clockBodyRef = useRef();

  let [viewTimeline, setViewTimeline] = useState(false);

  useEffect(() => {
    // clean not needed
    if (noteRef.current) {
      noteRef.current.focus();
    }

    if (addNote && state.clockNotes.length < 2) {
      clockBodyRef.current.scrollTop = clockBodyRef.current.scrollHeight;
    }
  }, [addNote]);

  let shortenZone = (val) => {
    if (val) {
      if (val.length > 15) {
        return `${val.substr(0, 14)}...`;
      } else {
        return val;
      }
    }
  };

  useEffect(() => {
    // clean not needed
    if (newStartTs && newEndTs) {
      if (state.b1Enabled && newB1StartTs && newB1EndTs) {
        if (
          newB1StartTs < newStartTs ||
          newB1EndTs > newEndTs ||
          newB1StartTs > newEndTs ||
          newB1EndTs < newStartTs
        ) {
          setB1overlap(true);
        } else {
          setB1overlap(false);
        }
      } else {
        setB1overlap(false);
      }

      if (state.b2Enabled && newB2StartTs && newB2EndTs) {
        if (
          newB2StartTs < newStartTs ||
          newB2EndTs > newEndTs ||
          newB2StartTs > newEndTs ||
          newB2EndTs < newStartTs
        ) {
          setB2overlap(true);
        } else {
          if (newB1StartTs && newB1EndTs) {
            if (newB2StartTs < newB1EndTs) {
              setB2overlap(true);
            } else {
              setB2overlap(false);
            }
          } else {
            setB2overlap(false);
          }
        }
      } else {
        setB2overlap(false);
      }

      if (state.b3Enabled && newB3StartTs && newB3EndTs) {
        if (
          newB3StartTs < newStartTs ||
          newB3EndTs > newEndTs ||
          newB3StartTs > newEndTs ||
          newB3EndTs < newStartTs
        ) {
          setB3overlap(true);
        } else {
          if (newB2StartTs && newB2EndTs) {
            if (newB3StartTs < newB2EndTs) {
              setB3overlap(true);
            } else {
              setB3overlap(false);
            }
          } else {
            setB3overlap(false);
          }
        }
      } else {
        setB3overlap(false);
      }
    } else {
      setB1overlap(false);
      setB1overlap(false);
      setB1overlap(false);
    }
  }, [
    newStartTs,
    newEndTs,
    newB1StartTs,
    newB1EndTs,
    newB2StartTs,
    newB2EndTs,
    newB3StartTs,
    newB3EndTs,
  ]);

  useEffect(() => {
    // clean not needed
    if (newOn && newOff) {
      let newStartDs = `${state.startDs.substr(0, 11)}H${newOn.substr(
        0,
        2
      )}M${newOn.substr(3, 2)}`;
      let newEndDs = `${state.startDs.substr(0, 11)}H${newOff.substr(
        0,
        2
      )}M${newOff.substr(3, 2)}`;

      let startTs = dateStringer.createTimestampFromString(newStartDs);
      let endTs = dateStringer.createTimestampFromString(newEndDs);

      if (endTs <= startTs) {
        let dateObj = new Date(endTs);
        dateObj.setDate(dateObj.getDate() + 1);
        endTs = dateObj.getTime();
      }

      setNewStartTs(startTs);
      setNewEndTs(endTs);
    }
  }, [newOn, newOff]);

  let [showLikedCommentIDs, setShowCommentLikedIDs] = useState([]);
  useEffect(() => {
    // clean not needed

    let br1 = 0;
    let br2 = 0;
    let br3 = 0;

    let ds = state.startDs.substr(0, 12);

    if (newBrk1Start && newBrk1End) {
      let ds1 = `${ds}${newBrk1Start.substr(0, 2)}M${newBrk1Start.substr(
        3,
        2
      )}`;
      let ds2 = `${ds}${newBrk1End.substr(0, 2)}M${newBrk1End.substr(3, 2)}`;

      let ts1 = dateStringer.createTimestampFromString(ds1);
      let ts2 = dateStringer.createTimestampFromString(ds2);

      let startingDs = `${state.startDs.substr(0, 11)}H${newOn.substr(
        0,
        2
      )}M${newOn.substr(3, 2)}`;
      let newStartTs = dateStringer.createTimestampFromString(startingDs);
      if (ts1 < newStartTs) {
        let startObj = new Date(ts1);
        startObj.setDate(startObj.getDate() + 1);
        ts1 = startObj.getTime();
      }

      if (ts2 < ts1) {
        let dsObj = new Date(ts2);
        dsObj.setDate(dsObj.getDate() + 1);
        ts2 = dsObj.getTime();
      }

      setNewB1StartTs(ts1);
      setNewB1EndTs(ts2);

      br1 = (ts2 - ts1) / 1000 / 60;
    }

    if (newBrk2Start && newBrk2End) {
      let ds1 = `${ds}${newBrk2Start.substr(0, 2)}M${newBrk2Start.substr(
        3,
        2
      )}`;
      let ds2 = `${ds}${newBrk2End.substr(0, 2)}M${newBrk2End.substr(3, 2)}`;

      let ts1 = dateStringer.createTimestampFromString(ds1);
      let ts2 = dateStringer.createTimestampFromString(ds2);

      let startingDs = `${state.startDs.substr(0, 11)}H${newOn.substr(
        0,
        2
      )}M${newOn.substr(3, 2)}`;
      let newStartTs = dateStringer.createTimestampFromString(startingDs);
      if (ts1 < newStartTs) {
        let startObj = new Date(ts1);
        startObj.setDate(startObj.getDate() + 1);
        ts1 = startObj.getTime();
      }

      if (ts2 < ts1) {
        let dsObj = new Date(ts2);
        dsObj.setDate(dsObj.getDate() + 1);
        ts2 = dsObj.getTime();
      }

      setNewB2StartTs(ts1);
      setNewB2EndTs(ts2);

      br2 = (ts2 - ts1) / 1000 / 60;
    }

    if (newBrk3Start && newBrk3End) {
      let ds1 = `${ds}${newBrk3Start.substr(0, 2)}M${newBrk3Start.substr(
        3,
        2
      )}`;
      let ds2 = `${ds}${newBrk3End.substr(0, 2)}M${newBrk3End.substr(3, 2)}`;

      let ts1 = dateStringer.createTimestampFromString(ds1);
      let ts2 = dateStringer.createTimestampFromString(ds2);

      let startingDs = `${state.startDs.substr(0, 11)}H${newOn.substr(
        0,
        2
      )}M${newOn.substr(3, 2)}`;
      let newStartTs = dateStringer.createTimestampFromString(startingDs);
      if (ts1 < newStartTs) {
        let startObj = new Date(ts1);
        startObj.setDate(startObj.getDate() + 1);
        ts1 = startObj.getTime();
      }

      if (ts2 < ts1) {
        let dsObj = new Date(ts2);
        dsObj.setDate(dsObj.getDate() + 1);
        ts2 = dsObj.getTime();
      }
      setNewB3StartTs(ts1);
      setNewB3EndTs(ts2);

      br3 = (ts2 - ts1) / 1000 / 60;
    }

    setB2(br1 + br2 + br3);
    setB2a(br1);
    setB2b(br2);
    setB2c(br3);
  }, [
    newBrk1Start,
    newBrk1End,
    newBrk2Start,
    newBrk2End,
    newBrk3Start,
    newBrk3End,
    editClocked,
  ]);

  let [updateClock, setUpdateClock] = useState(false);

  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      clockOffTs: 0,
      createdByClock: false,
      startDs: "",
      endDs: "",
      break1startDs: "",
      break2startDs: "",
      break3startDs: "",

      break1startTs: 0,
      break2startTs: 0,
      break3startTs: 0,

      break1endTs: 0,
      break2endTs: 0,
      break3endTs: 0,
      type: "",
      assocValid: false,
      approvedValid: false,
      break1endDs: "",
      break2endDs: "",
      break3endDs: "",
      teamName: "",
      break1durMins: 0,
      break1secs: 0,
      break2durMins: 0,
      break2secs: 0,

      break3durMins: 0,
      break3secs: 0,
      clockOnTs: 0,
      clockProfPicUrl: "",
      userID: "",
      teamID: "",
      unlinked: false,
      jobTitle: "",
      b1Enabled: false,
      b2Enabled: false,
      b3Enabled: false,
      b: 0,
      clockedDurMins: 0,
      assocShiftID: "",
      assocTilID: "",
      approvedShiftID: "",
      approvedTilID: "",
      approved: false,
      declined: false,
      scheduledBreak: 0,
      scheduledStartDs: "",
      scheduledEndDs: "",
      scheduledDurMins: 0,
      tilMinsToDeduct: 0,
      payClocked: false,
      fName: "",
      lName: "",
      clockNotes: [],
      onScore: 0,
      offScore: 0,
      brkScore: 0,

      clockOnLon: "",
      clockOnLat: "",
      clockOnDis: "",
      clockOnAcc: "",
      clockOnZone: "",

      break1startLon: "",
      break1startLat: "",
      break1startDis: "",
      break1startAcc: "",
      break1startZone: "",

      break1endLon: "",
      break1endLat: "",
      break1endDis: "",
      break1endAcc: "",
      break1endZone: "",

      break2startLon: "",
      break2startLat: "",
      break2startDis: "",
      break2startAcc: "",
      break2startZone: "",

      break2endLon: "",
      break2endLat: "",
      break2endDis: "",
      break2endAcc: "",
      break2endZone: "",

      break3startLon: "",
      break3startLat: "",
      break3startDis: "",
      break3startAcc: "",
      break3startZone: "",

      break3endLon: "",
      break3endLat: "",
      break3endDis: "",
      break3endAcc: "",
      break3endZone: "",

      clockOffLon: "",
      clockOffLat: "",
      clockOffDis: "",
      clockOffAcc: "",
      clockOffZone: "",

      primaryClockOnID: "",
      secondaryClockOnID: "",
      tags: [],

      terminal: false,
      clockOnPicUrl: "",
      break1startTerminal: false,
      break1startPicUrl: "",
      break1endTerminal: false,
      break1endPicUrl: "",
      break2startTerminal: false,
      break2startPicUrl: "",
      break2endTerminal: false,
      break2endPicUrl: "",
      break3startTerminal: false,
      break3startPicUrl: "",
      break3endTerminal: false,
      break3endPicUrl: "",
      clockOffTerminal: false,
      clockOffPicUrl: "",

      toilOffset: 0,

      timesheetID: "",
      tsStartDs: "",
      tsEndDs: "",
    }
  );

  useEffect(() => {
    if (clockBodyRef.current) {
      clockBodyRef.current.scrollTop = 0;
    }
  }, [clockOnID]);

  let [availableTags, setAvailableTags] = useState([]);

  let [showCannotManageOwnShift, setShowCannotManageOwnShift] = useState(false);

  let [cannotManage, setCannotManage] = useState(false);
  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/get-single-clock-on-data`,
        { clockOnID: id, nonMgr: isMgr ? false : true },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "cannot manage self-clock") {
          setCannotManage(true);
          setState({ teamName: response.data.teamName });
          setDataLoaded(true);
        } else {
          if (response.data.message === "success") {
            if (
              response.data.data.clockOffTs &&
              response.data.data.clockOffTs < new Date().getTime() &&
              new Date().getTime() - response.data.data.clockOffTs < 2000
            ) {
              memoVals.setIndicate((x) => {
                return {
                  show: true,
                  message: "Clocked off",
                  colour: "green",
                  duration: 4000,
                };
              });
            }

            let br = 0;
            if (response.data.data.break1secs) {
              br += response.data.data.break1secs / 60;
            }

            if (response.data.data.break2secs) {
              br += response.data.data.break2secs / 60;
            }

            if (response.data.data.break3secs) {
              br += response.data.data.break3secs / 60;
            }

            let startTs = dateStringer.createTimestampFromString(
              response.data.data.startDs
            );
            let endTs = dateStringer.createTimestampFromString(
              response.data.data.endDs
            );

            let brMs = br * 60 * 1000;
            let totalMs = endTs - startTs - brMs;

            let onScore = 0;
            let offScore = 0;
            let brkScore = 0;
            if (response.data.scheduledStartDs) {
              let schedStartTs = dateStringer.createTimestampFromString(
                response.data.scheduledStartDs
              );
              let clockStartTs = dateStringer.createTimestampFromString(
                response.data.data.startDs
              );

              onScore = (clockStartTs - schedStartTs) / 1000 / 60;

              let schedEndTs = dateStringer.createTimestampFromString(
                response.data.scheduledEndDs
              );
              let clockEndTs = dateStringer.createTimestampFromString(
                response.data.data.endDs
              );

              offScore = (clockEndTs - schedEndTs) / 1000 / 60;

              // let clockedBreakSecs = (response.data.data.break1secs || 0) + (response.data.data.break2secs || 0) + (response.data.data.break3secs || 0)
              brkScore = br - response.data.scheduledBreak;
            }

            setAvailableTags(response.data.availableTags);
            setState({
              createdByClock: response.data.createdByClock,
              startDs: response.data.data.startDs,
              endDs: response.data.data.endDs,
              break1startDs: response.data.data.break1startDs,
              break2startDs: response.data.data.break2startDs,
              break3startDs: response.data.data.break3startDs,
              type: response.data.data.type,
              assocValid: response.data.shiftValid,
              approvedValid: response.data.approvedValid,
              break1endDs: response.data.data.break1endDs,
              break2endDs: response.data.data.break2endDs,
              break3endDs: response.data.data.break3endDs,
              teamName: response.data.teamName,
              clockOffTs: response.data.data.clockOffTs,
              break1durMins: response.data.data.break1durMins,
              break2durMins: response.data.data.break2durMins,
              break3durMins: response.data.data.break3durMins,
              break1secs: response.data.data.break1secs,
              break2secs: response.data.data.break2secs,
              break3secs: response.data.data.break3secs,
              break1startTs: response.data.data.break1startTs,
              break2startTs: response.data.data.break2startTs,
              break3startTs: response.data.data.break3startTs,
              break1endTs: response.data.data.break1endTs,
              break2endTs: response.data.data.break2endTs,
              break3endTs: response.data.data.break3endTs,
              clockOnTs: response.data.data.startTs,
              clockProfPicUrl: response.data.clockProfPicUrl,
              userID: response.data.userID,
              teamID: response.data.data.teamID,
              unlinked: response.data.unlinked,
              jobTitle: response.data.jobTitle,
              b1Enabled: response.data.data.break1startDs ? true : false,
              b2Enabled: response.data.data.break2startDs ? true : false,
              b3Enabled: response.data.data.break3startDs ? true : false,
              b: br,
              clockedDurMins: Math.floor(totalMs / 1000 / 60),
              assocShiftID: response.data.data.assocShiftID,
              assocTilID: response.data.data.assocTilID,
              approvedShiftID: response.data.data.approvedShiftID,
              approvedTilID: response.data.data.approvedTilID,
              approved: response.data.data.approved,
              declined: response.data.data.declined,
              scheduledBreak: response.data.scheduledBreak,
              scheduledStartDs: response.data.scheduledStartDs,
              scheduledEndDs: response.data.scheduledEndDs,
              scheduledDurMins: response.data.scheduledDurMins,
              tilMinsToDeduct: response.data.tilMinsToDeduct || 0,
              payClocked: response.data.data.payClocked,
              fName: response.data.fName,
              lName: response.data.lName,
              clockNotes: response.data.clockNotes,
              onScore,
              offScore,
              brkScore,

              clockOnLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOnLon,
              clockOnLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOnLat,
              clockOnDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOnDis,
              clockOnAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOnAcc,
              clockOnZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOnZone,

              break1startLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startLon,
              break1startLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startLat,
              break1startDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startDis,
              break1startAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startAcc,
              break1startZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startZone,

              break1endLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endLon,
              break1endLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endLat,
              break1endDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endDis,
              break1endAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endAcc,
              break1endZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endZone,

              break2startLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startLon,
              break2startLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startLat,
              break2startDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startDis,
              break2startAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startAcc,
              break2startZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startZone,

              break2endLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endLon,
              break2endLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endLat,
              break2endDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endDis,
              break2endAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endAcc,
              break2endZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endZone,

              break3startLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startLon,
              break3startLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startLat,
              break3startDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startDis,
              break3startAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startAcc,
              break3startZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startZone,

              break3endLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endLon,
              break3endLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endLat,
              break3endDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endDis,
              break3endAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endAcc,
              break3endZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endZone,

              clockOffLon:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffLon,
              clockOffLat:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffLat,
              clockOffDis:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffDis,
              clockOffAcc:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffAcc,
              clockOffZone:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffZone,

              terminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.terminal,
              clockOnPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOnPicUrl,
              break1startTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startTerminal,
              break1startPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1startPicUrl,
              break1endTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endTerminal,
              break1endPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break1endPicUrl,
              break2startTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startTerminal,
              break2startPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2startPicUrl,
              break2endTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endTerminal,
              break2endPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break2endPicUrl,
              break3startTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startTerminal,
              break3startPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3startPicUrl,
              break3endTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endTerminal,
              break3endPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.break3endPicUrl,
              clockOffTerminal:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffTerminal,
              clockOffPicUrl:
                response.data.data &&
                response.data.data.gps &&
                response.data.data.gps.clockOffPicUrl,

              tags: response.data.data.tags || [],
              primaryClockOnID: response.data.data.primaryClockOnID,
              secondaryClockOnID: response.data.data.secondaryClockOnID,
              primaryClock: response.data.primaryClock
                ? response.data.primaryClock[0]
                : [],
              primaryDurSecs: response.data.primaryDurSecs,
              secondaryClock: response.data.secondaryClock
                ? response.data.secondaryClock[0]
                : [],

              toilOffset: response.data.toilOffset,

              timesheetID: response.data.timesheet
                ? response.data.timesheet.timesheetID
                : "",
              tsStartDs: response.data.timesheet
                ? response.data.timesheet.startDs
                : "",
              tsEndDs: response.data.timesheet
                ? response.data.timesheet.endDs
                : "",
            });

            setTimeout(() => {
              if (dataLoaded) {
                setDataLoaded(false);
              }
              setDataLoaded(true);
            }, 250);

            if (!isMgr && response.data.canManage) {
              setShowMgrOption(response.data.mgr);
            }

            if (response.data.message === "doesNotExist") {
              memoVals.setClockOnID((x) => false);
            }
          } else {
            if (response.data.message === "doesNotExist") {
              setClockCardDoesNotExist(true);
            } else {
              memoVals.setClockOnID((x) => false);
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [updateClock, isMgr]);

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        if (clockBodyRef.current && clockOnID.notes) {
          clockBodyRef.current.scrollTo({
            top: clockBodyRef.current.scrollHeight, // Scroll to the bottom of the div
            behavior: "smooth", // Smooth scroll behavior
          });
        }
      }, 150);
    }
  }, [loading]);

  state.clockNotes.sort((a, b) => {
    return b.tsStamped - a.tsStamped;
  });
  let generateClockNotes = state.clockNotes
    .sort((a, b) => {
      return a.tsStamped - b.tsStamped;
    })
    .map((note) => {
      let nameVal = `${note.fName} ${note.lName}`;
      return (
        <div className="shiftNotesObj">
          <div className="noteObjNameAndDs">
            <p className="noteObjName">
              {nameVal && nameVal.length > 14
                ? `${note.fName} ${
                    note.lName && note.lName[0] ? note.lName[0] : ""
                  }`
                : nameVal}
            </p>
            <p className="noteObjFsDs">
              {dateStringer.printedDateWithTimeFromDs(note.ds)}
            </p>
          </div>
          <p className="noteObjNote">{note.note}</p>
          <div className="deleteNoteRow marginBottom10">
            {note.likedUserObjects &&
            note.likedUserObjects[0] &&
            note.likedUserObjects.length > 0 ? (
              <div className="likedUsersSection">
                {note.likedUserObjects[0] ? (
                  <p className="ackTitle">Acknowledged:</p>
                ) : (
                  ""
                )}
                {note.likedUserObjects[0] ? (
                  <div
                    className="likedArrMapDiv"
                    onClick={() => {
                      if (showLikedCommentIDs.includes(note.noteID)) {
                        setShowCommentLikedIDs(
                          showLikedCommentIDs.filter((x) => {
                            return x !== note.noteID;
                          })
                        );
                      } else {
                        setShowCommentLikedIDs([
                          ...showLikedCommentIDs,
                          note.noteID,
                        ]);
                      }
                    }}
                  >
                    {note.likedUserObjects.map((liked, i) => {
                      if (i < 8) {
                        return liked.profPicUrl ? (
                          <img
                            src={liked.profPicUrl}
                            alt={liked.fName}
                            className="ackUsrIcon"
                          />
                        ) : (
                          <p className="ackUsrInitials">
                            {liked.fName[0]}
                            {liked.lName[0]}
                          </p>
                        );
                      }
                    })}{" "}
                    {note.likedUserObjects.length > 7 ? (
                      <p className="ackUsrExcess">
                        +{note.likedUserObjects.length - 8}
                      </p>
                    ) : (
                      ""
                    )}
                    {showLikedCommentIDs.includes(note.noteID) ? (
                      ""
                    ) : (
                      <img
                        src={acknowledgeArr}
                        alt="Show acknowledged"
                        className="showAckExpandIcon"
                        onClick={() => {
                          setShowCommentLikedIDs([
                            ...showLikedCommentIDs,
                            note.noteID,
                          ]);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
                {showLikedCommentIDs.includes(note.noteID) ? (
                  <div className="showLikedNamesCom">
                    <img
                      onClick={() => {
                        setShowCommentLikedIDs(
                          showLikedCommentIDs.filter((x) => {
                            return x !== note.noteID;
                          })
                        );
                      }}
                      src={arrGrey}
                      alt="Acknowledged"
                      className="ackArrClose"
                    />
                    <div className="ackNamesList">
                      {note.likedUserObjects.map((x) => {
                        if (x.userID === memoVals.checkUserState.userID) {
                          return <p className="ackNameItem">Me</p>;
                        } else {
                          return (
                            <p className="ackNameItem">
                              {x.fName} {x.lName}
                            </p>
                          );
                        }
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <p></p>
            )}
            {/* <div className="clockNoteAckArr">
            {note.likedUserIDs &&
              note.likedUserIDs[0] &&
              note.likedUserIDs.map((liker) => {
                return liker.substr(0, 3);
              })}
          </div> */}

            {note.userID === memoVals.checkUserState.userID ? (
              <img
                src={bin1}
                alt="Remove note"
                className="binNoteImg"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/add-clock-note`,
                      {
                        noteID: note.noteID,
                        deleteNote: true,
                        clockOnID: clockOnID.clockOnID,
                        data: note,
                        ds: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setAddNote(false);
                        setNote("");
                        setState({
                          clockNotes: response.data.clockNotes,
                        });
                        setChanged(true);

                        setTimeout(() => {
                          if (clockBodyRef.current) {
                            clockBodyRef.current.scrollTo({
                              top: clockBodyRef.current.scrollHeight, // Scroll to the bottom of the div
                              behavior: "smooth", // Smooth scroll behavior
                            });
                          }
                        }, 150);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              />
            ) : (
              <img
                src={
                  hoveredLikeCommentID === note.noteID &&
                  note.likedUserIDs &&
                  note.likedUserIDs[0] &&
                  note.likedUserIDs.includes(memoVals.checkUserState.userID)
                    ? liked2
                    : note.likedUserIDs &&
                      note.likedUserIDs[0] &&
                      note.likedUserIDs.includes(memoVals.checkUserState.userID)
                    ? liked
                    : like
                }
                alt="Acknowledge note"
                className={`binNoteImg ${
                  note.likedUserIDs &&
                  note.likedUserIDs[0] &&
                  note.likedUserIDs.includes(memoVals.checkUserState.userID)
                    ? "x18429814581"
                    : ""
                }`}
                onMouseEnter={() => {
                  setHoveredLikeCommentID(note.noteID);

                  console.log(
                    "---",
                    note.likedUserIDs &&
                      note.likedUserIDs[0] &&
                      note.likedUserIDs.includes(memoVals.checkUserState.userID)
                      ? "I HAVE LIKED IT"
                      : "I HAVE NOT LIKED IT"
                  );

                  console.log(
                    memoVals.checkUserState.userID,
                    "...",
                    note.likedUserIDs
                  );
                }}
                onMouseLeave={() => {
                  setHoveredLikeCommentID("");
                }}
                onClick={() => {
                  let undo =
                    note.likedUserIDs &&
                    note.likedUserIDs[0] &&
                    note.likedUserIDs.includes(memoVals.checkUserState.userID);
                  axios
                    .post(
                      `${serverURL}/acknowledge-clock-card-comment`,
                      {
                        clockOnID: clockOnID.clockOnID,
                        noteID: note.noteID,
                        undo,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        if (undo) {
                          let newNotesArr = [];

                          state.clockNotes.forEach((n) => {
                            if (n.noteID === note.noteID) {
                              // amend it
                              let newObj = n;
                              let arr = [];
                              let arr2 = [];
                              // if (n.likedUserIDs && n.likedUserIDs[0]) {
                              (n.likedUserIDs || []).forEach((uid) => {
                                if (uid !== memoVals.checkUserState.userID) {
                                  arr.push(uid);
                                }
                              });
                              // }
                              newObj["likedUserIDs"] = arr;

                              (n.likedUserObjects || []).forEach((obj) => {
                                if (
                                  obj.userID !== memoVals.checkUserState.userID
                                ) {
                                  arr2.push(obj);
                                }
                              });

                              newObj["likedUserObjects"] = arr2;

                              // n.likedUserIDs.filter((x) => {
                              //   return x.userID !== memoVals.checkUserState.userID;
                              // });

                              // n.likedUserIDs.filter(
                              //   (x) => {
                              //     return x.userID !== memoVals.checkUserState.userID;
                              //   }
                              // );

                              newNotesArr.push(newObj);
                            } else {
                              newNotesArr.push(n);
                            }

                            setState({ clockNotes: newNotesArr });
                          });
                        } else {
                          let newNotesArr = [];

                          state.clockNotes.forEach((n) => {
                            if (n.noteID === note.noteID) {
                              // amend it
                              let newObj = n;
                              newObj["likedUserIDs"] = [
                                ...(n.likedUserIDs || []),
                                memoVals.checkUserState.userID,
                              ];
                              let objs =
                                n.likedUserObjects && n.likedUserObjects[0]
                                  ? [
                                      {
                                        profPicUrl: response.data.profPicUrl,
                                        fName: response.data.fName,
                                        lName: response.data.lName,
                                        userID: memoVals.checkUserState.userID,
                                      },
                                      ...n.likedUserObjects,
                                    ]
                                  : [
                                      {
                                        profPicUrl: response.data.profPicUrl,
                                        fName: response.data.fName,
                                        lName: response.data.lName,
                                        userID: memoVals.checkUserState.userID,
                                      },
                                    ];

                              newObj["likedUserObjects"] = objs;
                              newNotesArr.push(newObj);
                            } else {
                              newNotesArr.push(n);
                            }

                            setState({ clockNotes: newNotesArr });
                          });
                        }
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              />
            )}
          </div>
        </div>

        // return (
        //   <div className="clockCardNoteRow">
        //     <div className="clockCardNameAndDateDiv">
        //       <p className="clockCardNoteName">
        //         {note.fName} {note.lName}
        //       </p>
        //       <p className="clockCardNoteTime">
        //         {dateStringer.createShortStripFromDateString(note.ds)}
        //       </p>
        //     </div>
        //     <p className="clockCardNoteValue">{note.note}</p>
        //   </div>
      );
    });

  let closeModal = (deleting) => {
    if (changed || deleting || justApproved || clockOnID.openedOnClockOff) {
      let changedObj = {
        justCreated: clockOnID.openedOnClockOff,
        justApproved,
        deleting,
        startDs: state.startDs,
        endDs: state.endDs,
        totBrkMins: state.b,
        teamName: state.teamName,
        teamID: state.teamID,
        clockOnID: clockOnID.clockOnID,
        approved: state.approved,
        declined: state.declined,
        type: state.type,
        fName: state.fName,
        lName: state.lName,
        jobTitle: state.jobTitle,
        order: 0,
        scheduledStart: state.scheduledStartDs,
        scheduledEnd: state.scheduledEndDs,
        scheduledBreak: state.scheduledBreak,
        scheduled: state.scheduledStartDs,
        unlinked: state.unlinked,
        userID: state.userID,
        createdByClock: state.createdByClock,
        clockOnTs: dateStringer.createTimestampFromString(state.startDs),
        picUrl: state.clockProfPicUrl,
        notes: state.clockNotes.length,
      };

      memoVals.setChangedClockCard((x) => changedObj);
      memoVals.setReloadTs((x) => !memoVals.reloadTs);
      memoVals.setClockOnID((x) => false);
    } else {
      memoVals.setClockOnID((x) => false);
    }
  };

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      closeModal();
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let overlapsMidnight = (startDs, endDs) => {
    if (
      startDs &&
      typeof startDs === "string" &&
      endDs &&
      typeof endDs === "string"
    ) {
      let startObj = new Date(dateStringer.createTimestampFromString(startDs));
      startObj.setHours(0, 0, 0, 0);

      let endObj = new Date(dateStringer.createTimestampFromString(endDs));
      endObj.setHours(0, 0, 0, 0);

      // Calculate the difference in days
      let daysDiff = Math.round(
        (startObj.getTime() - endObj.getTime()) / 86400000
      );

      if (daysDiff === 0) {
        return ""; // Same day
      } else if (daysDiff < 0) {
        return `+${Math.abs(daysDiff)}d`; // startObj is after todayObj by at least 1 day
      } else {
        return `-${Math.abs(daysDiff)}d`; // startObj is before todayObj by at least 1 day
      }
    }
  };

  let enterFct = (e) => {
    if ((e.key === "Enter" || e === "add") && note) {
      setAddNote(false);

      axios
        .post(
          `${serverURL}/add-clock-note`,
          {
            clockOnID: clockOnID.clockOnID,
            data: note,
            ds: dateStringer.createStringFromTimestamp(new Date().getTime()),
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setNote("");
            setChanged(true);

            setState({
              clockNotes: response.data.clockNotes,
            });
            setTimeout(() => {
              if (clockBodyRef.current) {
                clockBodyRef.current.scrollTo({
                  top: clockBodyRef.current.scrollHeight, // Scroll to the bottom of the div
                  behavior: "smooth", // Smooth scroll behavior
                });
              }
            }, 150);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  // Create a ref to hold the modal element
  const modalRefTimeline = useRef(null);

  const [isDraggingTimeline, setIsDraggingTimeline] = useState(false);
  const [dragStartTimeline, setDragStartTimeline] = useState({ x: 0, y: 0 });
  const [modalPositionTimeline, setModalPositionTimeline] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionTimeline, setDragPositionTimeline] = useState(0);

  const handleDownTimeline = (e) => {
    setIsDraggingTimeline(true);
    setDragStartTimeline({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveTimeline = (e) => {
    if (isDraggingTimeline) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartTimeline.y;

      if (deltaY > 0) {
        setDragPositionTimeline(deltaY);
        setModalPositionTimeline({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUpTimeline = () => {
    setIsDraggingTimeline(false);

    if (dragPositionTimeline > 140) {
      setViewTimeline(false);
    } else {
      setModalPositionTimeline({ bottom: "0%", left: "0%" });
    }
  };

  let [showImageModal, setShowImageModal] = useState({ show: false });
  let [image404, setImage404] = useState(false);
  let [sureDeleteClockPhoto, setShowSureDeletePhoto] = useState(false);
  // let [showImageModal, setShowImageModal] = useState({ show: false });

  const shiftLeaveRef = useRef(null);

  const [isDraggingLeave, setIsDraggingLeave] = useState(false);
  const [dragStartLeave, setDragStartLeave] = useState({ x: 0, y: 0 });
  const [modalPositionLeave, setModalPositionLeave] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPositionLeave, setDragPositionLeave] = useState(0);

  const handleDownLeave = (e) => {
    setIsDraggingLeave(true);
    setDragStartLeave({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMoveLeave = (e) => {
    if (isDraggingLeave) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStartLeave.y;

      if (deltaY > 0) {
        setDragPositionLeave(deltaY);
        setModalPositionLeave({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };
  const handleUpLeave = () => {
    setIsDraggingLeave(false);

    if (dragPositionLeave > 10) {
      if (loading) {
      } else {
        setShowExcessModal({ show: false });
      }
    } else {
      setModalPositionLeave({ bottom: "0%", left: "0%" });
    }
  };

  // master return

  let [photoLoading, setPhotoLoading] = useState(false);

  return (
    <div
      className={`editShiftModalUnderlay ${
        memoVals.showEditUser ? "none" : ""
      }`}
      onClick={() => {
        if (changed) {
          memoVals.setReloadManagerShift((x) => !memoVals.reloadManagerShift);
        }
        closeModal();
      }}
    >
      {showInvalidShiftModal || clockCardDoesNotExist ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setClockCardDoesNotExist(false);
            setShowInvalidShiftModal(false);
          }}
        >
          <div className="formCreatedModal">
            {clockCardDoesNotExist ? (
              <p className="overlapsRenTxt">
                This clock card no longer exists.
              </p>
            ) : (
              <p className="overlapsRenTxt">
                The{" "}
                {state.type === "shift"
                  ? "shift"
                  : `${
                      state.type === "til" ? "time in lieu" : "overtime"
                    }`}{" "}
                associated with this clock card no longer exists because it was
                changed or removed by {isMgr ? "you or another" : "your"}{" "}
                manager.
                <br />
                <br />
                Currently, {isMgr ? state.fName : "you"} will not be paid for
                the time {isMgr ? "they" : "you"} have clocked with this clock
                card.
                <br />
                <br />
                {isMgr && (
                  <span>
                    {" "}
                    Re-link this card if you want to create a{" "}
                    {state.type === "til"
                      ? "time in lieu shift"
                      : `${
                          state.type === "overtime" ? "overtime shift" : "shift"
                        }`}{" "}
                    that {state.fName} will be paid for - based on this card's
                    clocked times.
                  </span>
                )}
              </p>
            )}
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  setClockCardDoesNotExist(false);
                  setShowInvalidShiftModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <div
        className={`${
          showInvalidShiftModal || clockCardDoesNotExist
            ? "invalidClockShiftModalUnderlay"
            : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();
          setClockCardDoesNotExist(false);
          setShowInvalidShiftModal(false);
        }}
      >
        <div
          className="invalidClockShiftModalBody"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {clockCardDoesNotExist && (
            <p className="invalidClockShiftTxt">
              This clock card no longer exists.
            </p>
          )}

          {!clockCardDoesNotExist && (
            <p className="invalidClockShiftTxt">
              The{" "}
              {state.type === "shift"
                ? "shift"
                : `${state.type === "til" ? "time in lieu" : "overtime"}`}{" "}
              associated with this clock card no longer exists because it was
              changed or removed by {isMgr ? "you or another" : "your"} manager.
              <br />
              <br />
              Currently, {isMgr ? state.fName : "you"} will not be paid for the
              time {isMgr ? "they" : "you"} have clocked with this clock card.
              <br />
              <br />
              {isMgr && (
                <span>
                  {" "}
                  Re-link this card if you want to create a{" "}
                  {state.type === "til"
                    ? "time in lieu shift"
                    : `${
                        state.type === "overtime" ? "overtime shift" : "shift"
                      }`}{" "}
                  that {state.fName} will be paid for - based on this card's
                  clocked times.
                </span>
              )}
            </p>
          )}

          <p
            className="closeInvalidShiftModalBtn"
            onClick={() => {
              setShowInvalidShiftModal(false);
              setClockCardDoesNotExist(false);
              // memoVals.setClockOnID(x => false);
              memoVals.setReloadManagerShift(
                (x) => !memoVals.reloadManagerShift
              );
            }}
          >
            Understood
          </p>
        </div>
      </div> */}

      {loading ? (
        <div
          className={`shiftLoadingBox ${
            memoVals.mobModal ? "shiftLoadingBoxMob" : "shiftLoadingBoxDesktop"
          }`}
        >
          <img
            src={horizontalBalls}
            alt="Loading"
            className={`shiftLoadingBallsImg ${
              memoVals.mobModal ? "shiftLoadingBallsImgMob" : "zoomIn"
            }`}
          />
        </div>
      ) : (
        state.startDs &&
        !clockCardDoesNotExist && (
          <div
            className={`editShiftModalBox ${!state.startDs ? "none" : ""} ${
              memoVals.mobModal
                ? "clockItemMobModalBox slideUp mobModalShoulder"
                : "zoomIn"
            } ${isMgr ? "mgrModal" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPosition}
            ref={modalRef}
          >
            {/* header of add admin user - inherited from Add user to team modal in EditTeam.js */}
            {memoVals.mobModal && (
              <div className="modalSwiper modalSwiperClockCard"></div>
            )}
            <div
              className="clockCardHeader"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
              onClick={() => {
                if (memoVals.mobModal) {
                  if (changed) {
                    memoVals.setReloadManagerShift(
                      (x) => !memoVals.reloadManagerShift
                    );
                  }
                  closeModal();
                }
              }}
            >
              <div className={`shiftCloseHolder`}>
                <img
                  src={close}
                  alt="Close"
                  className="closeManageShiftModalBtnImg"
                  onClick={() => {
                    if (changed) {
                      memoVals.setReloadManagerShift(
                        (x) => !memoVals.reloadManagerShift
                      );
                    }
                    closeModal();
                  }}
                />
              </div>
              <p
                className="addUserToTeamTitle"
                onClick={() => {
                  console.log(state);
                }}
              >
                {isMgr
                  ? `${dateStringer.possession(state.fName)} clock card`
                  : "My clock card"}
              </p>

              <div className="clockCardTopRightPlacer">&nbsp;</div>
            </div>
            {/* end of header */}
            {/* subheader bubble bar */}
            <div className="shiftBubblesBar">
              <div className={`shiftTeamSubBubble shiftTeamSubBubbleMgrBg `}>
                {dateStringer.shorten(state.teamName, 50)}
              </div>
            </div>
            {/* end of subheader bubble bar */}
            {/* modal body */}{" "}
            <p
              className={`shiftPublishedStatus ${
                state.unlinked ||
                (!state.approved && !state.declined) ||
                state.declined
                  ? "unpubBg"
                  : ""
              } ${state.unlinked ? "makeCursP" : ""} ${
                state.declined ? "declinedClockTab" : ""
              }`}
              onClick={() => {
                if (
                  !state.assocValid &&
                  !state.approvedValid &&
                  (state.declined || state.approved)
                ) {
                  setShowInvalidShiftModal(true);
                }
              }}
            >
              {state.unlinked
                ? "Unlinked"
                : `${
                    state.approved
                      ? "Approved"
                      : `${state.declined ? "Declined" : "Not yet approved"}`
                  }`}
            </p>
            <div
              ref={clockBodyRef}
              className={`editShiftModalBody ${
                memoVals.mobModal ? "mobClockModalBody" : ""
              } ${memoVals.device.ios ? "mobClockModalBodyIos" : ""}`}
            >
              {/* new row - - - - - - - - - - - - - - - - */}
              {isMgr ? (
                <div className="editShiftRowEmployeeNameAndPic flexStart">
                  {state.clockProfPicUrl ? (
                    <img
                      src={state.clockProfPicUrl}
                      alt={state.fName}
                      className="managerShiftProPicImg"
                      onClick={() => {
                        memoVals.setShowEditUser((x) => state.userID);
                      }}
                    />
                  ) : (
                    <div
                      className="managerShiftInitialsPlaceholder"
                      onClick={() => {
                        memoVals.setShowEditUser((x) => state.userID);
                      }}
                    >
                      {state.fName[0]}
                      {state.lName[0]}
                    </div>
                  )}

                  <div className="shiftEditTitle employeeNameBar employeeNameBarClock">
                    <div className="shiftEditTitleTxt">Employee</div>
                    <div className="shiftEditValue">
                      {state.fName} {state.lName}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* new row - - - - - - - - - - - - - - - - */}
              {/* new row */}
              <div className="myClockRow noBorder">
                <div className="myShiftRowLeft clockCardDateLeft">
                  <p className="myShiftRowLeftTitle">Date</p>{" "}
                  {toggleEditDate ? (
                    <input
                      type="date"
                      className="addShiftDateInput editClockDateInput"
                      value={editDateInput}
                      onChange={(e) => {
                        setEditDateInput(e.target.value);
                        let dateInput = e.target.value;
                        let ds =
                          dateStringer.createStringFromDateInput(dateInput);

                        console.log({ ds });
                        if (
                          ds[0] === "Y" &&
                          ds[1] === "2" &&
                          ds[2] === "0" &&
                          !ds.includes("NaN")
                        ) {
                          // console.log("new ds: ", ds);
                          // let dateObj = new Date(
                          //   dateStringer.createTimestampFromString(ds)
                          // );

                          let changeDateOfDs = (newDs, toChangeDs) => {
                            if (
                              newDs &&
                              newDs[0] === "Y" &&
                              toChangeDs &&
                              toChangeDs[0] === "Y"
                            ) {
                              let dateObj = new Date(
                                dateStringer.createTimestampFromString(newDs)
                              );
                              let date = dateObj.getDate();
                              let month = dateObj.getMonth();
                              let year = dateObj.getFullYear();

                              let newDateObj = new Date(
                                dateStringer.createTimestampFromString(
                                  toChangeDs
                                )
                              );
                              newDateObj.setFullYear(year);
                              newDateObj.setMonth(month);
                              newDateObj.setDate(date);

                              return dateStringer.createStringFromTimestamp(
                                newDateObj.getTime()
                              );
                            }
                          };

                          let b1m = 0;
                          let b2m = 0;
                          let b3m = 0;
                          if (state.break1startDs) {
                            let b1end = dateStringer.createTimestampFromString(
                              state.break1endDs
                            );
                            let b1start =
                              dateStringer.createTimestampFromString(
                                state.break1startDs
                              );
                            b1m = (b1end - b1start) / 1000 / 60;
                          }

                          if (state.break2startDs) {
                            let b2end = dateStringer.createTimestampFromString(
                              state.break2endDs
                            );
                            let b2start =
                              dateStringer.createTimestampFromString(
                                state.break2startDs
                              );
                            b2m = (b2end - b2start) / 1000 / 60;
                          }

                          if (state.break3startDs) {
                            let b3end = dateStringer.createTimestampFromString(
                              state.break3endDs
                            );
                            let b3start =
                              dateStringer.createTimestampFromString(
                                state.break3startDs
                              );
                            b3m = (b3end - b3start) / 1000 / 60;
                          }

                          console.log(
                            "break1startDs: ",
                            ds,
                            state.break1startDs
                          );
                          let obj = {
                            startDs: changeDateOfDs(ds, state.startDs),
                            endDs: changeDateOfDs(ds, state.endDs),
                            break1startDs: changeDateOfDs(
                              ds,
                              state.break1startDs
                            ),
                            break1endDs: changeDateOfDs(ds, state.break1endDs),
                            break2startDs: changeDateOfDs(
                              ds,
                              state.break2startDs
                            ),
                            break2endDs: changeDateOfDs(ds, state.break2endDs),
                            break3startDs: changeDateOfDs(
                              ds,
                              state.break3startDs
                            ),
                            break3endDs: changeDateOfDs(ds, state.break3endDs),
                            break1durMins: b1m,
                            break2durMins: b2m,
                            break3durMins: b3m,
                            break1secs: state.break1secs,
                            break2secs: state.break2secs,
                            break3secs: state.break3secs,
                          };

                          console.log(obj);

                          setNewDateData(obj);

                          //     newStartDs,
                          //     newEndDs,
                          //     newBrk1StartDs,
                          //     newBrk1EndDs,
                          //     newBrk2StartDs,
                          //     newBrk2EndDs,
                          //     newBrk3StartDs,
                          //     newBrk3EndDs,

                          // startDs: response.data.data.startDs,
                          // endDs: response.data.data.endDs,
                          // break1startDs: response.data.data.break1startDs,
                          // break2startDs: response.data.data.break2startDs,
                          // break3startDs: response.data.data.break3startDs,
                          // type: response.data.data.type,
                          // assocValid: response.data.shiftValid,
                          // approvedValid: response.data.approvedValid,
                          // break1endDs: response.data.data.break1endDs,
                          // break2endDs: response.data.data.break2endDs,
                          // break3endDs: response.data.data.break3endDs,
                          // teamName: response.data.teamName,
                          // break1durMins: response.data.data.break1durMins,
                          // break2durMins: response.data.data.break2durMins,
                          // break3durMins: response.data.data.break3durMins,
                        }
                      }}
                    ></input>
                  ) : (
                    <p>
                      {dateStringer.printedDateFromDs(state.startDs)}&nbsp;
                      {state.startDs.substr(1, 4)}
                    </p>
                  )}
                </div>
                <div
                  className={`${
                    isMgr ? "myShiftRowRight" : "myShiftRowRight invis"
                  }`}
                >
                  {toggleEditDate ? (
                    <p
                      className="saveClockCardDateBtn"
                      onClick={() => {
                        console.log("UPDATE CLOCK CARD DATE");
                        console.log({ newDateData });
                        axios
                          .post(
                            `${serverURL}/update-clock-card`,
                            {
                              clockOnID: clockOnID.clockOnID,
                              newStartDs: newDateData.startDs,
                              newEndDs: newDateData.endDs,
                              newBrk1StartDs: newDateData.break1startDs,
                              newBrk1EndDs: newDateData.break1endDs,
                              newBrk2StartDs: newDateData.break2startDs,
                              newBrk2EndDs: newDateData.break2endDs,
                              newBrk3StartDs: newDateData.break3startDs,
                              newBrk3EndDs: newDateData.break3endDs,
                            },
                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setUpdateClock(!updateClock);
                              setChanged(true);
                              setToggleEditDate(false);
                            }

                            if (response.data.message.includes("passed")) {
                              setToggleEditDate(false);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });

                        // setToggleEditDate(false);
                      }}
                    >
                      Save
                    </p>
                  ) : (
                    <img
                      src={editGrey}
                      alt="Edit"
                      className="editClockDateImg none"
                      onClick={() => {
                        if (isMgr) {
                          let dateInput =
                            dateStringer.createDateInputFromDateString(
                              state.startDs
                            );
                          setEditDateInput(dateInput);
                          setToggleEditDate(true);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
              {/* end of row */}
              {/* new row */}
              <div
                className={`myClockRow clockedDataRowBg ${
                  !state.break1startDs && !state.scheduledStartDs
                    ? "clocked1"
                    : ""
                }`}
              >
                {viewTimeline ? (
                  <div
                    className="clockTimelineUnderlay"
                    onClick={() => {
                      setViewTimeline(false);
                    }}
                  >
                    <div
                      className={`clockTimelineModalBox ${
                        memoVals.mobModal
                          ? `clockTimelineModalBoxMob slideUp ${
                              memoVals.device.ios
                                ? "clockTimelineModalBoxMobIos"
                                : ""
                            }`
                          : "zoomIn"
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      style={modalPositionTimeline}
                      ref={modalRefTimeline}
                    >
                      {memoVals.mobModal && (
                        <div className="modalSwiper modalSwiperClockTimeline"></div>
                      )}
                      <div
                        className={`clockTimelineModalHeader ${
                          !memoVals.mobModal ? "desktopClockTimeLineHeader" : ""
                        }`}
                        onClick={() => {
                          if (memoVals.mobModal) {
                            setViewTimeline(false);
                          }
                        }}
                        onTouchStart={handleDownTimeline}
                        onTouchMove={
                          memoVals.mobModal ? handleMoveTimeline : null
                        }
                        onTouchEnd={memoVals.mobModal ? handleUpTimeline : null}
                        onMouseDown={
                          memoVals.mobModal ? handleDownTimeline : null
                        }
                        onMouseMove={
                          memoVals.mobModal ? handleMoveTimeline : null
                        }
                        onMouseUp={memoVals.mobModal ? handleUpTimeline : null}
                      >
                        <div className="clockTimelineHeaderSideUnit">
                          <img
                            src={close}
                            alt="Close tiemline"
                            onClick={() => {
                              setViewTimeline(false);
                            }}
                            className="closeClockTimelineImg"
                          />
                        </div>
                        <div className="clockTimelineHeaderTitle">
                          {state.fName}
                          {state.fName[state.fName.length - 1] === "s"
                            ? "'"
                            : "'s"}{" "}
                          clocked timeline
                        </div>
                      </div>

                      <div
                        className={`clockItemGPSscrollBox ${
                          !memoVals.mobModal
                            ? "clockItemGPSscrollBoxDesktop"
                            : ""
                        }`}
                      >
                        <div className="ccTimelineRun">
                          {/* <p className="clockedGPStitle">Clock data</p> */}
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}

                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.startDs && (
                            <div className="cTimelineRow cTimelineRowFirst">
                              {" "}
                              <div className="cTimelineRowBlob cTimelineRowBlobFirst"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  <img
                                    src={checkeredFlag}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  Clocked on{" "}
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.startDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.clockOnLat || state.terminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state.terminal
                                  ? "Terminal"
                                  : state.clockOnLat
                                  ? "GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.clockOnPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);
                                      setShowImageModal({
                                        show: true,
                                        url: state.clockOnPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.startDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.clockOnLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.clockOnZone) ||
                                    `${parseFloat(state.clockOnLat).toFixed(
                                      5
                                    )}, ${parseFloat(state.clockOnLon).toFixed(
                                      5
                                    )}`}{" "}
                                </div>
                              )}
                              {state.clockOnLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.clockOnDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.clockOnLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.clockOnAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.clockOnAcc || 0)}m accuracy
                                </div>
                              )}
                              {state.clockOnLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.clockOnLat},${state.clockOnLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {state.break1startDs ? (
                            <div
                              className={`clockActiveDividerTxt ${
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.startDs,
                                  state.break1startDs
                                ) < 0
                                  ? "warnRedMinus"
                                  : ""
                              }`}
                            >
                              <span className="clockActDivPart clockActDivPartPeriod">
                                First period - paid:
                              </span>{" "}
                              {/* {dateStringer.secsToString(
                  state.period1secs < 0 ? 0 : state.period1secs
                )} */}
                              {dateStringer.calcMinsBetweenTwoDateStrings(
                                state.startDs,
                                state.break1startDs
                              ) < 0
                                ? "-"
                                : ""}
                              {dateStringer.formatMinsDurationToHours(
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.startDs,
                                  state.break1startDs
                                )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break1startDs && (
                            <div className="cTimelineRow cTimelineRowBrkBorder">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  First break started{" "}
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.break1startDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.break1startLat ||
                                    state.break1startTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.break1startTerminal
                                    ? "Terminal"
                                    : state.break1startLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.break1startPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.break1startPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.break1startDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.break1startLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.break1startZone) ||
                                    `${parseFloat(state.break1startLat).toFixed(
                                      5
                                    )}, ${parseFloat(
                                      state.break1startLon
                                    ).toFixed(5)}`}{" "}
                                </div>
                              )}
                              {state.break1startLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.break1startDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.break1startLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.break1startAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.break1startAcc || 0)}m
                                  accuracy
                                </div>
                              )}
                              {state.break1startLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.break1startLat},${state.break1startLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break1endDs ? (
                            <div
                              className={`clockActiveDividerTxtBrk cTimelineRowBrkBorder ${
                                state.break1secs < 0 ? "warnRedMinus" : ""
                              }`}
                            >
                              {" "}
                              <span className="clockActDivPart">
                                First break - unpaid:
                              </span>{" "}
                              {state.break1secs / 60 < 0 ? "-" : ""}
                              {dateStringer.formatMinsDurationToHours(
                                state.break1secs / 60
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break1endDs && (
                            <div className="cTimelineRow">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  First break ended
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.break1endDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.break1endLat ||
                                    state.break1endTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.break1endTerminal
                                    ? "Terminal"
                                    : state.break1endLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.break1endPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.break1endPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.break1endDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.break1endLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.break1endZone) ||
                                    `${parseFloat(state.break1endLat).toFixed(
                                      5
                                    )}, ${parseFloat(
                                      state.break1endLon
                                    ).toFixed(5)}`}{" "}
                                </div>
                              )}
                              {state.break1endLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.break1endDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.break1endLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.break1endAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.break1endAcc || 0)}m
                                  accuracy
                                </div>
                              )}
                              {state.break1endLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.break1endLat},${state.break1endLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {state.break2startDs ? (
                            <div
                              className={`clockActiveDividerTxt ${
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.break1endDs,
                                  state.break2startDs
                                ) < 0
                                  ? "warnRedMinus"
                                  : ""
                              }`}
                            >
                              <span className="clockActDivPart clockActDivPartPeriod">
                                Second period - paid:
                              </span>{" "}
                              {/* {dateStringer.secsToString(
                  state.period1secs < 0 ? 0 : state.period1secs
                )} */}
                              {dateStringer.calcMinsBetweenTwoDateStrings(
                                state.break1endDs,
                                state.break2startDs
                              ) < 0
                                ? "-"
                                : ""}
                              {dateStringer.formatMinsDurationToHours(
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.break1endDs,
                                  state.break2startDs
                                )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break2startDs && (
                            <div className="cTimelineRow cTimelineRowBrkBorder">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  Second break started
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.break2startDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.break2startLat ||
                                    state.break2startTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.break2startTerminal
                                    ? "Terminal"
                                    : state.break2startLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.break2startPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.break2startPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.break2startDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.break2startLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.break2startZone) ||
                                    `${parseFloat(state.break2startLat).toFixed(
                                      5
                                    )}, ${parseFloat(
                                      state.break2startLon
                                    ).toFixed(5)}`}{" "}
                                </div>
                              )}
                              {state.break2startLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.break2startDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.break2startLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.break2startAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.break2startAcc || 0)}m
                                  accuracy
                                </div>
                              )}
                              {state.break2startLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.break2startLat},${state.break2startLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break2endDs ? (
                            <div
                              className={`clockActiveDividerTxtBrk cTimelineRowBrkBorder ${
                                state.break2secs < 0 ? "warnRedMinus" : ""
                              }`}
                            >
                              {" "}
                              <span className="clockActDivPart">
                                Second break - unpaid:
                              </span>{" "}
                              {state.break2secs / 60 < 0 ? "-" : ""}
                              {dateStringer.formatMinsDurationToHours(
                                state.break2secs / 60
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break2endDs && (
                            <div className="cTimelineRow">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  Second break ended
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.break2endDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.break2endLat ||
                                    state.break2endTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.break2endTerminal
                                    ? "Terminal"
                                    : state.break2endLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.break2endPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.break2endPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.break2endDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.break2endLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.break2endZone) ||
                                    `${parseFloat(state.break2endLat).toFixed(
                                      5
                                    )}, ${parseFloat(
                                      state.break2endLon
                                    ).toFixed(5)}`}{" "}
                                </div>
                              )}
                              {state.break2endLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.break2endDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.break2endLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.break2endAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.break2endAcc || 0)}m
                                  accuracy
                                </div>
                              )}
                              {state.break2endLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.break2endLat},${state.break2endLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {state.break3startDs ? (
                            <div
                              className={`clockActiveDividerTxt ${
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.break2endDs,
                                  state.break3startDs
                                ) < 0
                                  ? "warnRedMinus"
                                  : ""
                              }`}
                            >
                              <span className="clockActDivPart clockActDivPartPeriod">
                                Third period - paid:
                              </span>{" "}
                              {/* {dateStringer.secsToString(
                  state.period1secs < 0 ? 0 : state.period1secs
                )} */}
                              {dateStringer.calcMinsBetweenTwoDateStrings(
                                state.break2endDs,
                                state.break3startDs
                              ) < 0
                                ? "-"
                                : ""}
                              {dateStringer.formatMinsDurationToHours(
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.break2endDs,
                                  state.break3startDs
                                )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break3startDs && (
                            <div className="cTimelineRow cTimelineRowBrkBorder">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  Third break started
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.break3startDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.break3startLat ||
                                    state.break3startTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.break3startTerminal
                                    ? "Terminal"
                                    : state.break3startLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.break3startPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.break3startPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.break3startDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.break3startLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.break3startZone) ||
                                    `${parseFloat(state.break3startLat).toFixed(
                                      5
                                    )}, ${parseFloat(
                                      state.break3startLon
                                    ).toFixed(5)}`}{" "}
                                </div>
                              )}
                              {state.break3startLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.break3startDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.break3startLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.break3startAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.break3startAcc || 0)}m
                                  accuracy
                                </div>
                              )}
                              {state.break3startLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.break3startLat},${state.break3startLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break3endDs ? (
                            <div
                              className={`clockActiveDividerTxtBrk cTimelineRowBrkBorder ${
                                state.break3secs < 0 ? "warnRedMinus" : ""
                              }`}
                            >
                              {" "}
                              <span className="clockActDivPart">
                                Third break - unpaid:
                              </span>{" "}
                              {state.break3secs / 60 < 0 ? "-" : ""}
                              {dateStringer.formatMinsDurationToHours(
                                state.break3secs / 60
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.break3endDs && (
                            <div className="cTimelineRow">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  Third break ended
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(
                                  state.break3endDs,
                                  true
                                )}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.break3endLat ||
                                    state.break3endTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.break3endTerminal
                                    ? "Terminal"
                                    : state.break3endLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.break3endPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.break3endPicUrl,
                                        string: "Clocked on at terminal",
                                        ds: state.break3endDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.break3endLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.break3endZone) ||
                                    `${parseFloat(state.break3endLat).toFixed(
                                      5
                                    )}, ${parseFloat(
                                      state.break3endLon
                                    ).toFixed(5)}`}{" "}
                                </div>
                              )}
                              {state.break3endLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.break3endDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.break3endLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.break3endAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.break3endAcc || 0)}m
                                  accuracy
                                </div>
                              )}
                              {state.break3endLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.break3endLat},${state.break3endLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {state.endDs ? (
                            <div
                              className={`clockActiveDividerTxt ${
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.break3endDs ||
                                    state.break2endDs ||
                                    state.break1endDs ||
                                    state.startDs,
                                  state.endDs
                                ) < 0
                                  ? "warnRedMinus"
                                  : ""
                              }`}
                            >
                              <span className="clockActDivPart clockActDivPartPeriod">
                                Fourth period - paid:
                              </span>{" "}
                              {/* {dateStringer.secsToString(
                  state.period1secs < 0 ? 0 : state.period1secs
                )} */}
                              {dateStringer.calcMinsBetweenTwoDateStrings(
                                state.break3endDs ||
                                  state.break2endDs ||
                                  state.break1endDs ||
                                  state.startDs,
                                state.endDs
                              ) < 0
                                ? "-"
                                : ""}
                              {dateStringer.formatMinsDurationToHours(
                                dateStringer.calcMinsBetweenTwoDateStrings(
                                  state.break3endDs ||
                                    state.break2endDs ||
                                    state.break1endDs ||
                                    state.startDs,
                                  state.endDs
                                )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = ==  */}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {state.endDs && (
                            <div className="cTimelineRow cTimelineRowBrkBorder clockedOffFinalRow">
                              {" "}
                              <div className="cTimelineRowBlob"></div>
                              <div className="cTimelineRowDataRow cTimelineRowDataRowMain">
                                <p className="cTimelineTitle">
                                  {" "}
                                  <img
                                    src={checkeredFlag}
                                    alt="Clocked on time"
                                    className="cTimelineTitleImg"
                                  />
                                  Clocked off
                                </p>
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={rotaClock}
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {dateStringer.dsToTimeStrip(state.endDs, true)}{" "}
                              </div>
                              <div className="cTimelineRowDataRow">
                                <img
                                  src={
                                    state.clockOffLat || state.clockOffTerminal
                                      ? gps2
                                      : gps1
                                  }
                                  className="cTimelineDataImg"
                                  alt="Clocked on"
                                />{" "}
                                {state
                                  ? state.clockOffTerminal
                                    ? "Terminal"
                                    : state.clockOffLat
                                    ? "GPS recorded"
                                    : "No GPS recorded"
                                  : "No GPS recorded"}{" "}
                                {state.clockOffPicUrl && (
                                  <span
                                    className="clockPicBtn2 pinkColours"
                                    onClick={() => {
                                      setPhotoLoading(true);

                                      setShowImageModal({
                                        show: true,
                                        url: state.clockOffPicUrl,
                                        string: "Clocked off at terminal",
                                        ds: state.endDs,
                                      });
                                      if (image404) {
                                        setImage404(false);
                                      }
                                    }}
                                  >
                                    Photo{" "}
                                    <img
                                      src={popup}
                                      alt="Photo"
                                      className="clockPicPopUp2"
                                    />
                                  </span>
                                )}
                              </div>
                              {state.clockOffLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={pin}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {shortenZone(state.clockOffZone) ||
                                    `${parseFloat(state.clockOffLat).toFixed(
                                      5
                                    )}, ${parseFloat(state.clockOffLon).toFixed(
                                      5
                                    )}`}{" "}
                                </div>
                              )}
                              {state.clockOffLat && (
                                <div className="cTimelineRowDataRow">
                                  <img
                                    src={ruler}
                                    className="cTimelineDataImg"
                                    alt="Clocked on"
                                  />{" "}
                                  {dateStringer.convertMetresToKm(
                                    state.clockOffDis || 0
                                  )}{" "}
                                  away
                                </div>
                              )}
                              {state.clockOffLat && (
                                <div
                                  className={`cTimelineRowDataRow cTimelineAcc ${
                                    state.clockOffAcc > 60 ? "ba0000" : ""
                                  }`}
                                >
                                  {Math.round(state.clockOffAcc || 0)}m accuracy
                                </div>
                              )}
                              {state.clockOffLat && (
                                <a
                                  target="_blank"
                                  href={`https://www.google.com/maps/search/?api=1&query=${state.clockOffLat},${state.clockOffLon}`}
                                  className="viewCCinMapsBtn pinkColours"
                                >
                                  View in Maps{" "}
                                  <img
                                    src={map}
                                    alt="Maps"
                                    className="mapsIconCC"
                                  />
                                </a>
                              )}
                            </div>
                          )}
                          {/* ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~ */}
                          {/*  */}
                          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */}
                          {/* {state.startDs ? (
                            <div className="clockedDataTimeRow none brkLeftBordGreen">
                              <p className="clockedDataTimeRowL">Clocked on</p>
                              <p className="clockedDataTimeRowR">
                                {dateStringer.dsToTimeStrip(
                                  state.startDs,
                                  true
                                )}{" "}
                                <p className="clockRowDs">
                                  {dateStringer.printedDateFromDs(
                                    state.startDs
                                  )}{" "}
                                </p>
                              </p>
                            </div>
                          ) : (
                            ""
                          )} */}
                          {/*}  - - - - - - - - - - - - - - - - -  */}

                          {/* <div
                            className={`cTimelineRowFinal_ ${
                              (state.break1startDs && !state.break1endDs) ||
                              (state.break2startDs && !state.break2endDs) ||
                              (state.break3startDs && !state.break3endDs)
                                ? "cTimelineRowFinalBrk_"
                                : ""
                            }`}
                          >
                            <p className="actClockStatus">
                              {(state.break1startDs && !state.break1endDs) ||
                              (state.break2startDs && !state.break2endDs) ||
                              (state.break3startDs && !state.break3endDs)
                                ? "Not yet clocked back on"
                                : ""}
                            </p>
                          </div> */}
                        </div>
                        {/*  */}
                      </div>

                      <div
                        className={`clockTimelineModalFooter ${
                          !memoVals.mobModal
                            ? "clockTimelineModalFooterDesktop"
                            : ""
                        }`}
                      >
                        <p></p>
                        <p
                          className="closeClockTimelimeModalBtn"
                          onClick={() => {
                            setViewTimeline(false);
                          }}
                        >
                          Close
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="myShiftRowLeft">
                  <div className="clockedTimesTitleDiv">
                    <div className="clockedTitleTxtSolo">
                      Clocked times{" "}
                      <p
                        className={`viewClockTimelineBtnSpan ${
                          isMgr ? "lilacColours" : ""
                        }`}
                        onClick={() => {
                          setViewTimeline(true);
                          setModalPositionTimeline({ bottom: 0, left: 0 });
                        }}
                      >
                        View timeline{" "}
                        <img
                          src={popup}
                          className="viewClockTimelinePopUpImg"
                          alt="Timeline"
                        />
                      </p>
                    </div>{" "}
                    {!editClocked && isMgr && (
                      <img
                        src={editGrey}
                        alt="Edit"
                        className={`${
                          !editClocked && isMgr ? "editClockedImg" : "none"
                        }`}
                        onClick={() => {
                          if (isMgr) {
                            let newOn = `${state.startDs.substr(
                              12,
                              2
                            )}:${state.startDs.substr(15, 2)}`;
                            let newOff = `${state.endDs.substr(
                              12,
                              2
                            )}:${state.endDs.substr(15, 2)}`;

                            setNewOn(newOn);
                            setNewOff(newOff);

                            let b1 = 0;
                            let b2 = 0;
                            let b3 = 0;
                            if (state.break1startDs) {
                              let startTs =
                                dateStringer.createTimestampFromString(
                                  state.break1startDs
                                );
                              let endTs =
                                dateStringer.createTimestampFromString(
                                  state.break1endDs
                                );

                              b1 = (endTs - startTs) / 1000 / 60;

                              let newBrk1Start = `${state.break1startDs.substr(
                                12,
                                2
                              )}:${state.break1startDs.substr(15, 2)}`;
                              let newBrk1End = `${state.break1endDs.substr(
                                12,
                                2
                              )}:${state.break1endDs.substr(15, 2)}`;

                              setState({ b1Enabled: true });

                              setNewBrk1Start(newBrk1Start);
                              setNewBrk1End(newBrk1End);
                            }

                            if (state.break2startDs) {
                              setState({ b2Enabled: true });
                              let startTs =
                                dateStringer.createTimestampFromString(
                                  state.break2startDs
                                );
                              let endTs =
                                dateStringer.createTimestampFromString(
                                  state.break2endDs
                                );

                              b2 = (endTs - startTs) / 1000 / 60;
                              let newBrk2Start = `${state.break2startDs.substr(
                                12,
                                2
                              )}:${state.break2startDs.substr(15, 2)}`;
                              let newBrk2End = `${state.break2endDs.substr(
                                12,
                                2
                              )}:${state.break2endDs.substr(15, 2)}`;
                              setNewBrk2Start(newBrk2Start);
                              setNewBrk2End(newBrk2End);
                            }

                            if (state.break3startDs) {
                              setState({ b3Enabled: true });
                              let startTs =
                                dateStringer.createTimestampFromString(
                                  state.break3startDs
                                );
                              let endTs =
                                dateStringer.createTimestampFromString(
                                  state.break3endDs
                                );

                              b3 = (endTs - startTs) / 1000 / 60;
                              let newBrk3Start = `${state.break3startDs.substr(
                                12,
                                2
                              )}:${state.break3startDs.substr(15, 2)}`;
                              let newBrk3End = `${state.break3endDs.substr(
                                12,
                                2
                              )}:${state.break3endDs.substr(15, 2)}`;
                              setNewBrk3Start(newBrk3Start);
                              setNewBrk3End(newBrk3End);
                            }

                            let startTs =
                              dateStringer.createTimestampFromString(
                                state.startDs
                              );
                            let endTs = dateStringer.createTimestampFromString(
                              state.endDs
                            );
                            if (endTs <= startTs) {
                              let dateObj = new Date(endTs);
                              dateObj.setDate(dateObj.getDate());

                              let netMins =
                                (dateObj.getTime() - startTs) / 1000 / 60 -
                                b1 -
                                b2 -
                                b3;
                              setNewClockedDurMins(netMins);
                            } else {
                              setNewClockedDurMins(state.clockedDurMins);
                            }

                            setEditClocked(true);
                          }
                        }}
                      />
                    )}
                    {editClocked && (
                      <p
                        className={`savedEditClockedBtn ${
                          !newOn ||
                          !newOff ||
                          (state.break1startDs &&
                            (!newBrk1Start || !newBrk1End)) ||
                          (state.break2startDs &&
                            (!newBrk2Start || !newBrk2End)) ||
                          (state.break3startDs &&
                            (!newBrk3Start || !newBrk3End)) ||
                          b1overlap ||
                          b2overlap ||
                          b3overlap ||
                          (state.b1Enabled && (!newBrk1Start || !newBrk1End)) ||
                          (state.b2Enabled && (!newBrk2Start || !newBrk2End)) ||
                          (state.b3Enabled && (!newBrk3Start || !newBrk3End))
                            ? "invalidClockSave"
                            : ""
                        }`}
                        onClick={() => {
                          console.log({ b1overlap, b2overlap, b3overlap });
                          if (
                            !newOn ||
                            !newOff ||
                            (state.break1startDs &&
                              (!newBrk1Start || !newBrk1End)) ||
                            (state.break2startDs &&
                              (!newBrk2Start || !newBrk2End)) ||
                            (state.break3startDs &&
                              (!newBrk3Start || !newBrk3End)) ||
                            b1overlap ||
                            b2overlap ||
                            b3overlap ||
                            (state.b1Enabled &&
                              (!newBrk1Start || !newBrk1End)) ||
                            (state.b2Enabled &&
                              (!newBrk2Start || !newBrk2End)) ||
                            (state.b3Enabled && (!newBrk3Start || !newBrk3End))
                          ) {
                          } else {
                            let newStartDs =
                              dateStringer.createStringFromTimestamp(
                                newStartTs
                              );
                            let newEndDs =
                              dateStringer.createStringFromTimestamp(newEndTs);

                            let newBrk1StartDs =
                              state.b1Enabled &&
                              dateStringer.createStringFromTimestamp(
                                newB1StartTs
                              );
                            let newBrk1EndDs =
                              state.b1Enabled &&
                              dateStringer.createStringFromTimestamp(
                                newB1EndTs
                              );

                            let newBrk2StartDs =
                              state.b2Enabled &&
                              dateStringer.createStringFromTimestamp(
                                newB2StartTs
                              );
                            let newBrk2EndDs =
                              state.b2Enabled &&
                              dateStringer.createStringFromTimestamp(
                                newB2EndTs
                              );

                            let newBrk3StartDs =
                              state.b3Enabled &&
                              dateStringer.createStringFromTimestamp(
                                newB3StartTs
                              );
                            let newBrk3EndDs =
                              state.b3Enabled &&
                              dateStringer.createStringFromTimestamp(
                                newB3EndTs
                              );
                            setEditClocked(false);
                            axios
                              .post(
                                `${serverURL}/update-clock-card`,
                                {
                                  clockOnID: clockOnID.clockOnID,
                                  newStartDs,
                                  newEndDs,
                                  newBrk1StartDs,
                                  newBrk1EndDs,
                                  newBrk2StartDs,
                                  newBrk2EndDs,
                                  newBrk3StartDs,
                                  newBrk3EndDs,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setUpdateClock(!updateClock);
                                  setChanged(true);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        Save
                      </p>
                    )}
                  </div>
                  <div className="assocSchedRow clockedRowSched">
                    <div className="assocItemDataRow assocTimeRow">
                      <p className="assocItemTitle clockedOnOffTitle">On</p>
                      {!editClocked && (
                        <p className="assocItemValue">
                          {dateStringer.dsToTimeStrip(state.startDs, false)}
                        </p>
                      )}
                      {!editClocked ? (
                        <div className="ccOffDs">
                          {dateStringer.printedDateFromDs(state.startDs)}{" "}
                          {isMgr ? (
                            <img
                              src={schedLimitMinus}
                              alt="Go back one day"
                              className="ccOffDsBtn"
                              onClick={() => {
                                axios
                                  .post(
                                    `${serverURL}/edit-active-clock-record`,
                                    {
                                      clockOnID: clockOnID.clockOnID,
                                      code: "clockOn",
                                      value: `${state.startDs.substr(
                                        12,
                                        2
                                      )}:${state.startDs.substr(15, 2)}`,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                      minus24: true,
                                      fromClockCard: true,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setChanged(true);
                                      setUpdateClock(!updateClock);
                                    }
                                  });
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {isMgr ? (
                            <img
                              src={schedLimitPlus}
                              alt="Go back one day"
                              className="ccOffDsBtn"
                              onClick={() => {
                                axios
                                  .post(
                                    `${serverURL}/edit-active-clock-record`,
                                    {
                                      clockOnID: clockOnID.clockOnID,
                                      code: "clockOn",
                                      value: `${state.startDs.substr(
                                        12,
                                        2
                                      )}:${state.startDs.substr(15, 2)}`,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                      plus24: true,
                                      fromClockCard: true,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setChanged(true);

                                      setUpdateClock(!updateClock);
                                    }
                                  });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {editClocked && (
                        <input
                          type="time"
                          className={`clockedTimeInput ${
                            !newOn ? "invalidClockInput" : ""
                          }`}
                          value={newOn}
                          onChange={(e) => {
                            setNewOn(e.target.value);
                          }}
                        ></input>
                      )}
                      {/* <p className="earlyLateClockTxt">3m early</p> */}
                    </div>{" "}
                    <div className="assocItemDataRow assocTimeRow">
                      <p className="assocItemTitle clockedOnOffTitle offClock">
                        Off
                      </p>
                      {!editClocked && (
                        <p className="assocItemValue">
                          {dateStringer.dsToTimeStrip(state.endDs, false)}{" "}
                          {overlapsMidnight(state.startDs, state.endDs) && (
                            <span className="endIsNextDaySpan">
                              {overlapsMidnight(state.startDs, state.endDs)}
                            </span>
                          )}
                        </p>
                      )}
                      {!editClocked ? (
                        <div className="ccOffDs">
                          {dateStringer.printedDateFromDs(state.endDs)}{" "}
                          {isMgr ? (
                            <img
                              src={schedLimitMinus}
                              alt="Go back one day"
                              className="ccOffDsBtn"
                              onClick={() => {
                                axios
                                  .post(
                                    `${serverURL}/edit-active-clock-record`,
                                    {
                                      clockOnID: clockOnID.clockOnID,
                                      code: "clockOff",
                                      value: `${state.endDs.substr(
                                        12,
                                        2
                                      )}:${state.endDs.substr(15, 2)}`,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                      minus24: true,
                                      fromClockCard: true,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setChanged(true);

                                      setUpdateClock(!updateClock);
                                    }
                                  });
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {isMgr ? (
                            <img
                              src={schedLimitPlus}
                              alt="Go back one day"
                              className="ccOffDsBtn"
                              onClick={() => {
                                axios
                                  .post(
                                    `${serverURL}/edit-active-clock-record`,
                                    {
                                      clockOnID: clockOnID.clockOnID,
                                      code: "clockOff",
                                      value: `${state.endDs.substr(
                                        12,
                                        2
                                      )}:${state.endDs.substr(15, 2)}`,
                                      nowDs:
                                        dateStringer.createStringFromTimestamp(
                                          new Date().getTime()
                                        ),
                                      plus24: true,
                                      fromClockCard: true,
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      setChanged(true);

                                      setUpdateClock(!updateClock);
                                    }
                                  });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {editClocked && (
                        <input
                          type="time"
                          className={`clockedTimeInput ${
                            !newOff ? "invalidClockInput" : ""
                          }`}
                          value={newOff}
                          onChange={(e) => {
                            setNewOff(e.target.value);
                          }}
                        ></input>
                      )}
                    </div>
                    <div className="assocItemDataRow assocDateRow">
                      <p className="assocItemTitle clockedOnOffTitle">Break</p>
                      <p className="assocItemValue">
                        {!editClocked
                          ? dateStringer.secsToString(
                              (state.break1secs || 0) +
                                (state.break2secs || 0) +
                                (state.break3secs || 0)
                            )
                          : dateStringer.secsToString(
                              ((b2a || 0) + (b2b || 0) + (b2c || 0)) * 60
                            )}
                        {/* {editClocked
                        ? dateStringer.formatMinsDurationToHours(b2)
                        : state.b === 0
                        ? "None"
                        : `${dateStringer.formatMinsDurationToHours(
                            editClocked ? b2 : state.b
                          )}`} */}
                        {/* &nbsp;
                    {scheduledStartDs.substr(1, 4)} */}
                      </p>
                      {/* <p className="earlyLateClockTxt">3m over</p> */}
                    </div>
                  </div>
                </div>
                <div className="myShiftRowRight"></div>
              </div>
              {/* end of row */}
              {/* end of row */}
              {/* new row */}
              {!state.createdByClock && state.assocValid && (
                <div
                  className={`myClockRow clockedScoreRow ${
                    state.assocValid ? "" : "none"
                  } ${editClocked ? "none" : ""} ${
                    (!editClocked && !state.break1startDs) ||
                    (editClocked && !state.b1Enabled)
                      ? "clock2"
                      : ""
                  }`}
                >
                  <div
                    className={`clockedScoreOnOff ${
                      !state.tilMinsToDeduct
                        ? state.onScore > 0
                          ? "colourRed"
                          : "colourGreen"
                        : ""
                    }`}
                    onClick={() => {
                      console.log(state.tilMinsToDeduct);
                    }}
                  >
                    {score(state.onScore, "time")}
                  </div>
                  <div
                    onClick={() => {
                      console.log(state.tilMinsToDeduct);
                    }}
                    className={`clockedScoreOnOff offScore90 ${
                      !state.tilMinsToDeduct
                        ? state.offScore < 0
                          ? "colourRed"
                          : "colourGreen"
                        : ""
                    }`}
                  >
                    {score(state.offScore, "time")}
                  </div>
                  <div className={`clockedScoreBrk `}>
                    {score(state.brkScore, "break")}
                  </div>
                </div>
              )}
              {/* end of row */}
              {/* new row */}
              {state.b1Enabled && (
                <div
                  className={`${
                    state.break1secs > 0 || state.b1Enabled
                      ? "myClockRow clock3"
                      : "none"
                  } ${
                    (!editClocked && !state.break2startDs) ||
                    (editClocked && !state.b2Enabled)
                      ? "clock2"
                      : ""
                  } noMinHeight`}
                >
                  <div className="myShiftRowLeft font16px">
                    <div className="myShiftRowLeftTitle">
                      {editClocked && !state.b2Enabled && (
                        <img
                          src={cancelTil}
                          alt="Remove"
                          className="removeClockBrkImg"
                          onClick={() => {
                            setState({ b1Enabled: false });
                            setB1overlap(false);
                          }}
                        />
                      )}
                      <p
                        className={`${
                          editClocked && !state.b2Enabled
                            ? "breakClockTitleDivSmaller"
                            : "breakClockTitleDiv"
                        }`}
                      >
                        {state.break2secs === 0 ||
                        (!state.b2Enabled && !state.b3Enabled)
                          ? "Break"
                          : "First break"}{" "}
                      </p>
                      <span className="brkClockedSpan">- clocked</span>
                      {b1overlap && (
                        <span className="overlapBrkClockSpan">
                          Invalid break
                        </span>
                      )}
                    </div>

                    <div className="clockBrkDurMins">
                      <p className="clockBreakFixed">
                        {editClocked
                          ? dateStringer.secsToString(b2a * 60)
                          : dateStringer.secsToString(state.break1secs)}
                        {/* {dateStringer.formatMinsDurationToHours(
                        editClocked ? b2a : state.break1durMins
                      )}{" "} */}
                      </p>
                      {!editClocked && (
                        <span className="clockBreakBetweenSpan"> &nbsp; </span>
                      )}
                      <span className="clockBreakTimeStrip">
                        {!editClocked &&
                          `${
                            state.break1startDs &&
                            dateStringer.dsToTimeStrip(
                              state.break1startDs,
                              false
                            )
                          }`}
                        {editClocked && (
                          <input
                            type="time"
                            className={`clockedTimeInput ${
                              !newBrk1Start ? "invalidClockInput" : ""
                            }`}
                            value={newBrk1Start}
                            onChange={(e) => {
                              setNewBrk1Start(e.target.value);
                            }}
                          ></input>
                        )}{" "}
                        -{" "}
                        {!editClocked &&
                          `${
                            state.break1startDs &&
                            dateStringer.dsToTimeStrip(state.break1endDs, false)
                          }`}
                        {editClocked && (
                          <input
                            type="time"
                            className={`clockedTimeInput ${
                              !newBrk1End ? "invalidClockInput" : ""
                            }`}
                            value={newBrk1End}
                            onChange={(e) => {
                              setNewBrk1End(e.target.value);
                            }}
                          ></input>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
              )}
              {/* end of row */}
              {/* new row */}
              {state.b2Enabled && (
                <div
                  className={`${
                    state.break2secs > 0 || state.b2Enabled
                      ? "myClockRow clock3"
                      : "none"
                  } ${
                    (!editClocked && !state.break3startDs) ||
                    (editClocked && !state.b3Enabled)
                      ? "clock2"
                      : ""
                  } noMinHeight`}
                >
                  <div className="myShiftRowLeft font16px">
                    <div className="myShiftRowLeftTitle">
                      {" "}
                      {editClocked && !state.b3Enabled && (
                        <img
                          src={cancelTil}
                          alt="Remove"
                          className="removeClockBrkImg"
                          onClick={() => {
                            setState({ b2Enabled: false });
                            setB2overlap(false);
                          }}
                        />
                      )}
                      <p
                        className={`${
                          editClocked && !state.b3Enabled
                            ? "breakClockTitleDivSmaller"
                            : "breakClockTitleDiv"
                        }`}
                      >
                        Second break{" "}
                      </p>
                      <span className="brkClockedSpan">- clocked</span>
                      {b2overlap && (
                        <span className="overlapBrkClockSpan">
                          Invalid break
                        </span>
                      )}
                    </div>{" "}
                    <div className="clockBrkDurMins">
                      {" "}
                      <p className="clockBreakFixed">
                        {editClocked
                          ? dateStringer.secsToString(b2b * 60)
                          : dateStringer.secsToString(state.break2secs)}

                        {/* {dateStringer.formatMinsDurationToHours(
                        editClocked ? b2b : state.break2durMins
                      )}{" "} */}
                      </p>
                      {!editClocked && (
                        <span className="clockBreakBetweenSpan"> &nbsp; </span>
                      )}
                      <span className="clockBreakTimeStrip">
                        {!editClocked &&
                          `${
                            state.break2startDs &&
                            dateStringer.dsToTimeStrip(
                              state.break2startDs,
                              false
                            )
                          }`}
                        {editClocked && (
                          <input
                            type="time"
                            className={`clockedTimeInput ${
                              !newBrk2Start ? "invalidClockInput" : ""
                            }`}
                            value={newBrk2Start}
                            onChange={(e) => {
                              setNewBrk2Start(e.target.value);
                            }}
                          ></input>
                        )}{" "}
                        -{" "}
                        {!editClocked &&
                          `${
                            state.break2startDs &&
                            dateStringer.dsToTimeStrip(state.break2endDs, false)
                          }`}
                        {editClocked && (
                          <input
                            type="time"
                            className={`clockedTimeInput ${
                              !newBrk2End ? "invalidClockInput" : ""
                            }`}
                            value={newBrk2End}
                            onChange={(e) => {
                              setNewBrk2End(e.target.value);
                            }}
                          ></input>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
              )}
              {/* end of row */}
              {/* new row */}
              {state.b3Enabled && (
                <div
                  className={`${
                    state.break3secs > 0 || state.b3Enabled
                      ? "myClockRow clock3 clock2"
                      : "none"
                  } noMinHeight`}
                >
                  <div className="myShiftRowLeft font16px">
                    <div className="myShiftRowLeftTitle">
                      {editClocked && (
                        <img
                          src={cancelTil}
                          alt="Remove"
                          className="removeClockBrkImg"
                          onClick={() => {
                            setState({ b3Enabled: false });
                            setB3overlap(false);
                          }}
                        />
                      )}
                      <p
                        className={`${
                          editClocked
                            ? "breakClockTitleDivSmaller"
                            : "breakClockTitleDiv"
                        }`}
                      >
                        Third break{" "}
                      </p>
                      <span className="brkClockedSpan">- clocked</span>
                      {b3overlap && (
                        <span className="overlapBrkClockSpan">
                          Invalid break
                        </span>
                      )}
                    </div>{" "}
                    <div className="clockBrkDurMins">
                      <p className="clockBreakFixed">
                        {editClocked
                          ? dateStringer.secsToString(b2c * 60)
                          : dateStringer.secsToString(state.break3secs)}

                        {/* {dateStringer.formatMinsDurationToHours(
                        editClocked ? b2c : state.break3durMins
                      )}{" "} */}
                      </p>
                      {!editClocked && (
                        <span className="clockBreakBetweenSpan"> &nbsp; </span>
                      )}
                      <div className="clockBreakTimeStrip">
                        {!editClocked &&
                          `${
                            state.break3startDs &&
                            dateStringer.dsToTimeStrip(
                              state.break3startDs,
                              false
                            )
                          }`}
                        {editClocked && (
                          <input
                            type="time"
                            className={`clockedTimeInput ${
                              !newBrk3Start ? "invalidClockInput" : ""
                            }`}
                            value={newBrk3Start}
                            onChange={(e) => {
                              setNewBrk3Start(e.target.value);
                            }}
                          ></input>
                        )}{" "}
                        -{" "}
                        {!editClocked &&
                          `${
                            state.break3startDs &&
                            dateStringer.dsToTimeStrip(state.break3endDs, false)
                          }`}
                        {editClocked && (
                          <input
                            type="time"
                            className={`clockedTimeInput ${
                              !newBrk3End ? "invalidClockInput" : ""
                            }`}
                            value={newBrk3End}
                            onChange={(e) => {
                              setNewBrk3End(e.target.value);
                            }}
                          ></input>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
              )}
              {/* end of row */}
              {/* new row */}
              {state.assocValid && !state.createdByClock && (
                <div className="myClockRow">
                  <div className="myShiftRowLeft font16px">
                    <p className="myShiftRowLeftTitle">
                      Scheduled start &amp; end
                    </p>{" "}
                    <p className={`schedClockDay ${editClocked ? "none" : ""}`}>
                      {dateStringer.printedDateFromDs(state.scheduledStartDs)}
                    </p>
                    <div className="assocSchedRow clockedRowSched">
                      <div className="assocItemDataRow assocTimeRow">
                        <p className="assocItemTitle">Start</p>
                        <p className="assocItemValue x08493">
                          {dateStringer.dsToTimeStrip(
                            state.scheduledStartDs,
                            false
                          )}
                        </p>
                      </div>{" "}
                      <div className="assocItemDataRow assocTimeRow">
                        <p className="assocItemTitle">End</p>
                        <p className="assocItemValue x08493">
                          {dateStringer.dsToTimeStrip(
                            state.scheduledEndDs,
                            false
                          )}
                        </p>
                      </div>
                      <div className="assocItemDataRow assocDateRow">
                        <p className="assocItemTitle">Break</p>
                        <p className="assocItemValue x08493">
                          {dateStringer.formatMinsDurationToHours(
                            state.scheduledBreak
                          )}
                          {/* &nbsp;
                    {scheduledStartDs.substr(1, 4)} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="myShiftRowRight"></div>
                </div>
              )}
              {/* end of row */}
              {editClocked && !state.b3Enabled && (
                <div
                  className={`addBrkRowDiv ${
                    state.scheduledStartDs && !state.createdByClock
                      ? "pullAddBreakBtnUpDueToSched"
                      : ""
                  }`}
                >
                  <p
                    className="addBrkClockBtn"
                    onClick={() => {
                      if (
                        !state.b3Enabled &&
                        state.b2Enabled &&
                        state.b1Enabled
                      ) {
                        setState({ b3Enabled: true });
                        setNewBrk3Start("");
                        setNewBrk3End("");
                      }

                      if (
                        !state.b3Enabled &&
                        !state.b2Enabled &&
                        state.b1Enabled
                      ) {
                        setState({ b2Enabled: true });
                        setNewBrk2Start("");
                        setNewBrk2End("");
                      }

                      if (
                        !state.b3Enabled &&
                        !state.b2Enabled &&
                        !state.b1Enabled
                      ) {
                        setState({ b1Enabled: true });
                        setNewBrk1Start("");
                        setNewBrk1End("");
                      }
                    }}
                  >
                    Add{state.b1Enabled && !state.b3Enabled ? " another " : " "}
                    break
                  </p>
                </div>
              )}

              {/* new row */}
              <div className="myClockRow clockPayRow">
                <div className="clockPayRowL">
                  <p className="myShiftRowLeftTitle">Clocked duration</p>
                  <div className="clockPayRowVal">
                    {dateStringer.formatMinsDurationToHours(
                      editClocked ? newClockedDurMins : state.clockedDurMins
                    )}{" "}
                  </div>
                  {!isMgr &&
                  !state.toilOffset &&
                  state.scheduledDurMins &&
                  state.clockedDurMins < state.scheduledDurMins ? (
                    <div className="clockPayRowVal ccShortTxt">
                      <span className="makeBold colourBa0000">
                        {dateStringer.formatMinsDurationToHours(
                          state.scheduledDurMins - state.clockedDurMins
                        )}
                      </span>{" "}
                      short of the scheduled duration
                    </div>
                  ) : (
                    ""
                  )}
                  {!isMgr &&
                  !state.toilOffset &&
                  state.scheduledDurMins &&
                  state.clockedDurMins > state.scheduledDurMins ? (
                    <div className="clockPayRowVal ccShortTxt ccShortTxt2">
                      <span className="makeBold colour00aaff">
                        {dateStringer.formatMinsDurationToHours(
                          state.clockedDurMins - state.scheduledDurMins
                        )}
                      </span>{" "}
                      over the scheduled duration
                    </div>
                  ) : (
                    ""
                  )}
                  {isMgr &&
                  !state.toilOffset &&
                  state.scheduledDurMins &&
                  state.clockedDurMins > state.scheduledDurMins ? (
                    <div className="clockPayRowVal ccShortTxt ccShortTxt2">
                      <span className="makeBold colour00aaff">
                        {dateStringer.formatMinsDurationToHours(
                          state.clockedDurMins - state.scheduledDurMins
                        )}
                      </span>{" "}
                      over the scheduled duration
                    </div>
                  ) : (
                    ""
                  )}
                  {isMgr &&
                  !state.toilOffset &&
                  state.scheduledDurMins &&
                  state.clockedDurMins < state.scheduledDurMins ? (
                    <div className="clockPayRowVal ccShortTxt">
                      <span className="makeBold colourBa0000">
                        {dateStringer.formatMinsDurationToHours(
                          state.scheduledDurMins - state.clockedDurMins
                        )}
                      </span>{" "}
                      short of the scheduled duration
                    </div>
                  ) : (
                    ""
                  )}

                  {state.toilOffset ? (
                    <div className="clockPayRowVal ccShortTxt ccToilOffset">
                      <span
                        className={`makeBold ${
                          state.toilOffset < 0 ? "colourBa0000" : "colour00aaff"
                        }`}
                      >
                        {dateStringer.formatMinsDurationToHours(
                          state.toilOffset
                        )}{" "}
                        {state.toilOffset < 0 ? "deducted from" : "added to"}
                      </span>{" "}
                      {isMgr ? dateStringer.possession(state.fName) : "your"}{" "}
                      TOIL balance
                    </div>
                  ) : (
                    ""
                  )}
                </div>{" "}
                {state.scheduledStartDs && !state.createdByClock && (
                  <div className="clockPayRowR">
                    <p className="myShiftRowLeftTitle ">Scheduled duration</p>
                    <p className="clockPayRowVal x08493">
                      {dateStringer.formatMinsDurationToHours(
                        state.scheduledDurMins
                      )}
                    </p>
                    {state.tilMinsToDeduct ? (
                      <p className="minusAppToil">
                        Minus{" "}
                        <span className="minusAppToilSpan">
                          {dateStringer.formatMinsDurationToHours(
                            state.tilMinsToDeduct
                          )}
                        </span>{" "}
                        <br /> approved TOIL
                      </p>
                    ) : (
                      ""
                    )}
                    {(state.approvedValid || state.assocValid) &&
                      !state.unlinked && (
                        <p
                          className={`${
                            (state.approvedValid || state.assocValid) &&
                            !state.unlinked
                              ? "viewAssocShiftBtn"
                              : "viewAssocShiftBtn fadeViewAssoc"
                          } viewAssocClockItemInline`}
                          onClick={() => {
                            if (clockOnID.fromShiftManager) {
                              if (changed) {
                                memoVals.setReloadManagerShift(
                                  (x) => !memoVals.reloadManagerShift
                                );
                              }
                              memoVals.setClockOnID((x) => false);
                            } else {
                              if (!state.unlinked) {
                                if (state.assocValid) {
                                  if (state.assocShiftID) {
                                    memoVals.setClockOnID((x) => false);
                                    if (isMgr) {
                                      memoVals.setOpenManagerShiftModal((x) => {
                                        return {
                                          shiftID: state.assocShiftID,
                                          tilID: "",
                                          fName: "",
                                          lName: "",
                                          openedFromShiftsPage: true,
                                          type: "shift",
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType: "shift",
                                          unitID: state.assocShiftID,
                                        };
                                      });
                                    }
                                  } else if (state.assocTilID) {
                                    memoVals.setClockOnID((x) => false);
                                    if (isMgr) {
                                      memoVals.setOpenManagerShiftModal((x) => {
                                        return {
                                          shiftID: "",
                                          tilID: state.assocTilID,
                                          fName: "",
                                          lName: "",
                                          openedFromShiftsPage: true,
                                          type: state.type,
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType: state.type,
                                          unitID: state.assocTilID,
                                        };
                                      });
                                    }
                                  } else {
                                    setShowInvalidShiftModal(true);
                                  }
                                } else if (state.approvedValid) {
                                  // show approved til or shift
                                  if (state.approvedShiftID) {
                                    // show approved shift
                                    memoVals.setClockOnID((x) => false);

                                    if (isMgr) {
                                      memoVals.setOpenManagerShiftModal((x) => {
                                        return {
                                          shiftID: state.approvedShiftID,
                                          tilID: "",
                                          fName: "",
                                          lName: "",
                                          openedFromShiftsPage: true,
                                          type: "shift",
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType: "shift",
                                          unitID: state.approvedShiftID,
                                        };
                                      });
                                    }
                                  } else if (state.approvedTilID) {
                                    memoVals.setClockOnID((x) => false);

                                    if (isMgr) {
                                      memoVals.setOpenManagerShiftModal((x) => {
                                        return {
                                          shiftID: "",
                                          tilID: state.approvedTilID,
                                          fName: "",
                                          lName: "",
                                          openedFromShiftsPage: true,
                                          type: state.type,
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType: state.type,
                                          unitID: state.approvedTilID,
                                        };
                                      });
                                    }
                                  } else {
                                    setShowInvalidShiftModal(true);
                                  }
                                } else {
                                  setShowInvalidShiftModal(true);
                                }
                              } else {
                                setShowInvalidShiftModal(true);
                              }
                            }
                          }}
                        >
                          View{" "}
                          {state.type === "shift"
                            ? "shift"
                            : `${
                                state.type === "til"
                                  ? `${state.unlinked ? "TOIL" : "TOIL"}`
                                  : "overtime"
                              }`}
                        </p>
                      )}
                  </div>
                )}
              </div>
              {/* end of row */}
              {/* new row */}
              {state.timesheetID ? (
                <div className="myClockRow clockPayRow">
                  <div className="clockPayRowL">
                    <p className="myShiftRowLeftTitle">Associated timesheet</p>
                    <div className="clockPayRowVal">
                      {dateStringer.printedDateFromDs(state.tsStartDs)}{" "}
                      {state.tsStartDs !== state.tsEndDs ? "-" : ""}{" "}
                      {state.tsStartDs !== state.tsEndDs
                        ? dateStringer.printedDateFromDs(state.tsEndDs)
                        : ""}
                    </div>
                    <p
                      className={`viewAssocShiftBtn viewAssocClockItemInline`}
                      onClick={() => {
                        closeModal();
                        memoVals.setShowViewTimesheet((x) => {
                          return {
                            show: true,
                            timesheetID: state.timesheetID,
                            isMgr,
                          };
                        });
                      }}
                    >
                      View timesheet
                    </p>
                  </div>{" "}
                </div>
              ) : (
                ""
              )}
              {/* end of row */}
              {/* new row */}
              <div className="myClockRow clockPayRow">
                <div className="clockPayRowL">
                  <p className="myShiftRowLeftTitle">Tags</p>
                  <p className="clockPayRowVal">
                    {!state.tags[0] && (state.approved || state.declined) ? (
                      <p className="noClockTagsTxt">No tags</p>
                    ) : (
                      ""
                    )}
                    {state.tags[0] ? (
                      <div className="clockItemTagsContainer">
                        {state.tags.map((t) => {
                          return (
                            <div
                              className={`teamTagIemX x13145361 ${
                                newlyAddedTags.includes(t)
                                  ? "newlyAddedClockTag"
                                  : ""
                              }`}
                            >
                              {t}{" "}
                              {!state.approved && !state.declined ? (
                                <img
                                  src={greyCross}
                                  className="removeTeamTagImgX"
                                  alt="Remove tag"
                                  onClick={() => {
                                    axios
                                      .post(
                                        `${serverURL}/add-or-remove-clock-tag`,
                                        {
                                          tag: t,
                                          clockOnID: clockOnID.clockOnID,
                                          remove: true,
                                        },

                                        {
                                          withCredentials: true,
                                          credentials: "include",
                                        }
                                      )
                                      .then((response) => {
                                        if (
                                          response.data.message === "success"
                                        ) {
                                          setState({
                                            tags: response.data.tags.sort(
                                              (a, b) => {
                                                return a.localeCompare(b);
                                              }
                                            ),
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        console.error(err);
                                      });
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {!availableTags[0] ? (
                      <p className="noAvailClockTags">
                        No tags are available{"  "}
                        <a
                          href="https://flowrota.com/support/no-available-tags"
                          target="_blank"
                          className="moreInfoAvailTagsBtn x31414x13"
                        >
                          Info{" "}
                          <img
                            src={popup}
                            alt="More info"
                            className="moreInfoTagsAvailPopupImg"
                          />
                        </a>
                      </p>
                    ) : (
                      <select
                        className={`clockTagSelector clockCardTagSel ${
                          !availableTags[0] ? "invis" : ""
                        } ${state.approved || state.declined ? "none" : ""}`}
                        onChange={(e) => {
                          if (e.target.value !== "-") {
                            let val = e.target.value;
                            axios
                              .post(
                                `${serverURL}/add-or-remove-clock-tag`,
                                {
                                  tag: val,
                                  clockOnID: clockOnID.clockOnID,
                                  remove: false,
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  setNewlyAddedTags([...newlyAddedTags, val]);
                                  setState({
                                    tags: response.data.tags.sort((a, b) => {
                                      return a.localeCompare(b);
                                    }),
                                  });
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        {" "}
                        <option value="-">-- Add tag</option>
                        {availableTags.map((tag) => {
                          if (!state.tags.includes(tag)) {
                            return <option value={tag}>{tag}</option>;
                          } else return;
                        })}
                      </select>
                    )}
                    {/* <p
                    className="addTagToTeamBtn"
                    onClick={() => {
                      // setAddTag(true);
                    }}
                  >
                    Add tag{" "}
                    <img
                      src={whitePlus}
                      alt="Add tag"
                      className="addTagInlineImgTeam"
                    />
                  </p> */}
                    {/* <p className="addTagClockBtn">Add tag <img src={white</p> */}
                  </p>
                </div>
              </div>
              {/* -- -- -- -- -- - - --  - --- -- -- - - --  - --- -- -- - - --  - --- -- -- - - --  - -- end of row */}
              {/* new row */}
              {state.secondaryClockOnID &&
              state.secondaryClock.startDs &&
              state.secondaryClock.endDs ? (
                <div className="otherClockCardRow">
                  <div className="myClockRow noBorder">
                    <div className="clockPayRowL">
                      {/* <p className="myShiftRowLeftTitle">Clocked duration</p> */}
                      <p className="clockPayRowVal x3895724">
                        {/* Another clock card is associated to this one.{" "} */}
                        This card is 1 of 2 as {state.fName} clocked additional
                        overtime after the scheduled end time of their shift.
                      </p>
                    </div>
                  </div>
                  {/* <div className="clockPayRowR">
                    <p className="x1283983">View original shift's clock card</p>
                    <p className="x83198193">View clock card</p>
                  </div> */}
                  <div className="otherClockDivContainer">
                    <div className="otherClockLeft">
                      <p className="subClockTitle">
                        <span className="ccTitleSpan">1</span> This clock card
                      </p>{" "}
                      <p className="subClockStartEndTitle">On</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.startDs)}
                      </p>
                      <div className="subClockDivider"></div>
                      <p className="subClockDurStrip">
                        {" "}
                        {dateStringer.formatMinsDurationToHours(
                          editClocked ? newClockedDurMins : state.clockedDurMins
                        )}
                      </p>
                      <p className="subClockStartEndTitle">Off</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.endDs)}
                      </p>
                      <p className="subClockStatus">
                        {state.approved
                          ? "Approved"
                          : state.declined
                          ? "Declined"
                          : "Awaiting approval"}
                      </p>
                    </div>
                    <div
                      className="otherClockRight"
                      onClick={() => {
                        setClockOnID((x) => {
                          return {
                            clockOnID: state.secondaryClockOnID,
                          };
                        });

                        setUpdateClock(!updateClock);
                      }}
                    >
                      <p className="subClockTitle">
                        <span className="ccTitleSpan">2</span> Clocked overtime
                      </p>
                      <p className="subClockStartEndTitle">On</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(
                          state.secondaryClock.startDs
                        )}
                      </p>
                      <div className="subClockDivider"></div>
                      <p className="subClockDurStrip">
                        {" "}
                        {dateStringer.formatMinsDurationToHours(
                          dateStringer.calcMinsBetweenTwoDateStrings(
                            state.secondaryClock.startDs,
                            state.secondaryClock.endDs
                          )
                        )}
                      </p>
                      <p className="subClockStartEndTitle">Off</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.secondaryClock.endDs)}
                      </p>
                      <p className="subClockStatus">
                        {state.secondaryClock.approved
                          ? "Approved"
                          : state.secondaryClock.declined
                          ? "Declined"
                          : "Awaiting approval"}
                      </p>

                      {/* tail clock details here */}
                    </div>
                  </div>
                  {/* <div
                    className={`${
                      isMgr
                        ? "joinedClockCardsSelectDiv"
                        : "joinedClockCardsSelectDivMyCard"
                    }`}
                  >
                    <div className="joinedClockSelect">
                      <img
                        src={downGreyArr}
                        alt="Selected clock card"
                        className="selClockCardArr"
                      />
                    </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}
              {/* end of row */}
              {/* new row */}
              {state.primaryClockOnID &&
              state.primaryClock.startDs &&
              state.primaryClock.endDs ? (
                <div className="otherClockCardRow">
                  <div className="myClockRow noBorder">
                    <div className="clockPayRowL">
                      {/* <p className="myShiftRowLeftTitle">Clocked duration</p> */}
                      <p className="clockPayRowVal x3895724">
                        {/* Another clock card is associated to this one.{" "} */}
                        This card is 2 of 2 as {state.fName} clocked additional
                        overtime after the scheduled end time of their shift.
                      </p>
                    </div>
                  </div>
                  {/* <div className="clockPayRowR">
                    <p className="x1283983">View original shift's clock card</p>
                    <p className="x83198193">View clock card</p>
                  </div> */}
                  <div className="otherClockDivContainer">
                    <div
                      className="otherClockLeft x123893"
                      onClick={() => {
                        setClockOnID((x) => {
                          return {
                            clockOnID: state.primaryClockOnID,
                          };
                        });

                        setUpdateClock(!updateClock);
                      }}
                    >
                      <p className="subClockTitle">
                        <span className="ccTitleSpan">1</span> Main shift
                      </p>{" "}
                      <p className="subClockStartEndTitle">On</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.primaryClock.startDs)}
                        {/* {dateStringer.dsToTimeStrip(state.primaryClock.startDs)} */}
                      </p>
                      <div className="subClockDivider"></div>
                      <p className="subClockDurStrip">
                        {" "}
                        {dateStringer.formatMinsDurationToHours(
                          state.primaryDurSecs / 60
                        )}
                      </p>
                      <p className="subClockStartEndTitle">Off</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.primaryClock.endDs)}
                      </p>
                      <p className="subClockStatus">
                        {state.primaryClock.approved
                          ? "Approved"
                          : state.primaryClock.declined
                          ? "Declined"
                          : "Awaiting approval"}
                      </p>
                    </div>
                    <div className="otherClockRight x398142">
                      <p className="subClockTitle">
                        <span className="ccTitleSpan">2</span> Clocked overtime
                        (this)
                      </p>
                      <p className="subClockStartEndTitle">On</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.startDs)}
                      </p>
                      <div className="subClockDivider"></div>
                      <p className="subClockDurStrip">
                        {" "}
                        {dateStringer.formatMinsDurationToHours(
                          dateStringer.calcMinsBetweenTwoDateStrings(
                            state.startDs,
                            state.endDs
                          )
                        )}
                      </p>
                      <p className="subClockStartEndTitle">Off</p>
                      <p className="subClockStartEndValue">
                        {dateStringer.dsToTimeStrip(state.endDs)}
                      </p>
                      <p className="subClockStatus">
                        {state.approved
                          ? "Approved"
                          : state.declined
                          ? "Declined"
                          : "Awaiting approval"}
                      </p>

                      {/* tail clock details here */}
                    </div>
                  </div>
                  {/* <div
                    className={`${
                      isMgr
                        ? "joinedClockCardsSelectDiv x8298828282114"
                        : "joinedClockCardsSelectDivMyCard x8298828282114"
                    }`}
                  >
                    <div className="joinedClockSelect joinedClockSelect2">
                      <img
                        src={downGreyArr}
                        alt="Selected clock card"
                        className="selClockCardArr"
                      />
                    </div>
                  </div> */}
                </div>
              ) : (
                ""
              )}
              {/* end of row */}
              {/*    https://www.google.com/maps/search/?api=1&query=50.9340356,-0.2863771 */}
              {/* new row */}
              {(typeof state.clockOnLon === "number" &&
                state.clockOnLon !== 0) ||
              (typeof state.break1startLon === "number" &&
                state.break1startLon !== 0) ||
              (typeof state.break1startLat === "number" &&
                state.break1startLat !== 0) ||
              (typeof state.break1endLon === "number" &&
                state.break1endLon !== 0) ||
              (typeof state.break1endLat === "number" &&
                state.break1endLat !== 0) ||
              (typeof state.break2startLon === "number" &&
                state.break2startLon !== 0) ||
              (typeof state.break2startLat === "number" &&
                state.break2startLat !== 0) ||
              (typeof state.break2endLon === "number" &&
                state.break2endLon !== 0) ||
              (typeof state.break2endLat === "number" &&
                state.break2endLat !== 0) ||
              (typeof state.break3startLon === "number" &&
                state.break3startLon !== 0) ||
              (typeof state.break3startLat === "number" &&
                state.break3startLat !== 0) ||
              (typeof state.break3endLon === "number" &&
                state.break3endLon !== 0) ||
              (typeof state.break3endLat === "number" &&
                state.break3endLat !== 0) ||
              (typeof state.clockOffLon === "number" &&
                state.clockOffLon !== 0) ? (
                // &&             state.clockOffLon !== 0
                <div className="myClockRow clockGPSrow">
                  <div className="clockedGPSheaderRow">
                    <p className="myShiftRowLeftTitle">Clocked GPS</p>

                    <div
                      className={`clockGPSToggleBrksBtn ${
                        (typeof state.break1startLat === "number" &&
                          state.break1startLat !== 0) ||
                        (typeof state.break1endLon === "number" &&
                          state.break1endLon !== 0) ||
                        (typeof state.break1endLat === "number" &&
                          state.break1endLat !== 0) ||
                        (typeof state.break2startLon === "number" &&
                          state.break2startLon !== 0) ||
                        (typeof state.break2startLat === "number" &&
                          state.break2startLat !== 0) ||
                        (typeof state.break2endLon === "number" &&
                          state.break2endLon !== 0) ||
                        (typeof state.break2endLat === "number" &&
                          state.break2endLat !== 0) ||
                        (typeof state.break3startLon === "number" &&
                          state.break3startLon !== 0) ||
                        (typeof state.break3startLat === "number" &&
                          state.break3startLat !== 0) ||
                        (typeof state.break3endLon === "number" &&
                          state.break3endLon !== 0) ||
                        (typeof state.break3endLat === "number" &&
                          state.break3endLat !== 0)
                          ? ""
                          : "invis"
                      }`}
                      onClick={() => {
                        setShowBrkGPS(!showBrkGPS);
                      }}
                    >
                      Show breaks{" "}
                      <div
                        className={`showBrkCheckBoxDiv ${
                          !showBrkGPS ? "showBrkOpac" : ""
                        }`}
                      >
                        {showBrkGPS ? (
                          <img
                            src={checkbox1}
                            alt="Checked"
                            className={`showBrkGpsCheckboxImg ${
                              showBrkGPS ? "" : "none"
                            }`}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="clockedRowGps">
                    {/* - - - - - - - - - - - - - - */}
                    <div className="clockRowGpsRow">
                      <div className="clockRowGpsRowLeft">
                        {state.clockOnZone ? (
                          <div className="clockGPSRowImgAndTitle">
                            <img
                              src={gps2}
                              className="gpsRowGpsIcon"
                              alt="GPS"
                            />
                            <p className="clockedOnZoneTxtTitle">Clocked on</p>
                          </div>
                        ) : (
                          <p className="clockedOnZoneTxtTitleUnknown">
                            Clocked on
                          </p>
                        )}
                        {state.clockOnZone ? (
                          <p className="clockedOnZoneTxt">
                            {dateStringer.convertMetresToKm(state.clockOnDis)}{" "}
                            <span className="gpsAwayFromSpan">
                              away from {state.clockOnZone}
                            </span>
                          </p>
                        ) : (
                          <div className="clockGPSRowImgAndTitle">
                            <img
                              src={gps1}
                              className="gpsRowGpsIcon"
                              alt="GPS"
                            />
                            <p
                              className={`clockedOnZoneTxtTitle coordSize ${
                                JSON.stringify(state.clockOnLat || "").length +
                                  JSON.stringify(state.clockOnLon || "")
                                    .length >
                                20
                                  ? "xSmallCoords"
                                  : ""
                              }`}
                            >
                              {state.clockOnLat || "No GPS info"}
                              {typeof state.clockOnLat === "number"
                                ? ","
                                : ""}{" "}
                              {state.clockOnLon}
                            </p>
                          </div>
                        )}
                        <p className="clockedOnZoneTxtAcc">
                          {!isNaN(state.clockOnAcc) && state.clockOnAcc !== null
                            ? `${Math.round(state.clockOnAcc)}m accuracy`
                            : "Accuracy unknown"}
                        </p>
                      </div>

                      <div className="clockRowGpsRowRight">
                        {typeof state.clockOnLat === "number" ? (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${state.clockOnLat},${state.clockOnLon}`}
                            className="viewMapBtn"
                            target="_blank"
                          >
                            Maps{" "}
                            <img src={popup} className="viewInMapsPopUpImg" />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}
                    {showBrkGPS ? (
                      <div className="breakGpsRowAni">
                        <div className="clockRowGpsRowLeft">
                          {state.break1startZone ? (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps2}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p className="clockedOnZoneTxtTitle">
                                Break 1 start
                              </p>
                            </div>
                          ) : (
                            <p className="clockedOnZoneTxtTitleUnknown">
                              Break 1 start
                            </p>
                          )}
                          {state.break1startZone ? (
                            <p className="clockedOnZoneTxt">
                              {dateStringer.convertMetresToKm(
                                state.break1startDis
                              )}{" "}
                              <span className="gpsAwayFromSpan">
                                away from {state.break1startZone}
                              </span>
                            </p>
                          ) : (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps1}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p
                                className={`clockedOnZoneTxtTitle coordSize ${
                                  JSON.stringify(state.break1startLat || "")
                                    .length +
                                    JSON.stringify(state.break1startLon || "")
                                      .length >
                                  20
                                    ? "xSmallCoords"
                                    : ""
                                }`}
                              >
                                {state.break1startLat || "No GPS info"}
                                {typeof state.break1startLat === "number"
                                  ? ","
                                  : ""}{" "}
                                {state.break1startLon}
                              </p>
                            </div>
                          )}
                          <p className="clockedOnZoneTxtAcc">
                            {!isNaN(state.break1startAcc) &&
                            state.break1startAcc !== null
                              ? `${Math.round(state.break1startAcc)}m accuracy`
                              : "Accuracy unknown"}
                          </p>
                        </div>

                        <div className="clockRowGpsRowRight">
                          {typeof state.break1startLat === "number" ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${state.break1startLat},${state.break1startLon}`}
                              className="viewMapBtn"
                              target="_blank"
                            >
                              Maps{" "}
                              <img src={popup} className="viewInMapsPopUpImg" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}
                    {showBrkGPS ? (
                      <div className="breakGpsRowAni">
                        <div className="clockRowGpsRowLeft">
                          {state.break1endZone ? (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps2}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p className="clockedOnZoneTxtTitle">
                                Break 1 end
                              </p>
                            </div>
                          ) : (
                            <p className="clockedOnZoneTxtTitleUnknown">
                              Break 1 end
                            </p>
                          )}
                          {state.break1endZone ? (
                            <p className="clockedOnZoneTxt">
                              {dateStringer.convertMetresToKm(
                                state.break1endDis
                              )}{" "}
                              <span className="gpsAwayFromSpan">
                                away from {state.break1endZone}
                              </span>
                            </p>
                          ) : (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps1}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p
                                className={`clockedOnZoneTxtTitle coordSize ${
                                  JSON.stringify(state.break1endLat || "")
                                    .length +
                                    JSON.stringify(state.break1endLon || "")
                                      .length >
                                  20
                                    ? "xSmallCoords"
                                    : ""
                                }`}
                              >
                                {state.break1endLat || "No GPS info"}
                                {typeof state.break1endLat === "number"
                                  ? ","
                                  : ""}{" "}
                                {state.break1endLon}
                              </p>
                            </div>
                          )}
                          <p className="clockedOnZoneTxtAcc">
                            {!isNaN(state.break1endAcc) &&
                            state.break1endAcc !== null
                              ? `${Math.round(state.break1endAcc)}m accuracy`
                              : "Accuracy unknown"}
                          </p>
                        </div>

                        <div className="clockRowGpsRowRight">
                          {typeof state.break1endLat === "number" ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${state.break1endLat},${state.break1endLon}`}
                              className="viewMapBtn"
                              target="_blank"
                            >
                              Maps{" "}
                              <img src={popup} className="viewInMapsPopUpImg" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}
                    {showBrkGPS ? (
                      <div className="breakGpsRowAni">
                        <div className="clockRowGpsRowLeft">
                          {state.break2startZone ? (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps2}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p className="clockedOnZoneTxtTitle">
                                Break 2 start
                              </p>
                            </div>
                          ) : (
                            <p className="clockedOnZoneTxtTitleUnknown">
                              Break 2 start
                            </p>
                          )}
                          {state.break2startZone ? (
                            <p className="clockedOnZoneTxt">
                              {dateStringer.convertMetresToKm(
                                state.break2startDis
                              )}{" "}
                              <span className="gpsAwayFromSpan">
                                away from {state.break2startZone}
                              </span>
                            </p>
                          ) : (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps1}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p
                                className={`clockedOnZoneTxtTitle coordSize ${
                                  JSON.stringify(state.break2startLat || "")
                                    .length +
                                    JSON.stringify(state.break2startLon || "")
                                      .length >
                                  20
                                    ? "xSmallCoords"
                                    : ""
                                }`}
                              >
                                {state.break2startLat || "No GPS info"}
                                {typeof state.break2startLat === "number"
                                  ? ","
                                  : ""}{" "}
                                {state.break2startLon}
                              </p>
                            </div>
                          )}
                          <p className="clockedOnZoneTxtAcc">
                            {!isNaN(state.break2startAcc) &&
                            state.break2startAcc !== null
                              ? `${Math.round(state.break2startAcc)}m accuracy`
                              : "Accuracy unknown"}
                          </p>
                        </div>

                        <div className="clockRowGpsRowRight">
                          {typeof state.break2startLat === "number" ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${state.break2startLat},${state.break2startLon}`}
                              className="viewMapBtn"
                              target="_blank"
                            >
                              Maps{" "}
                              <img src={popup} className="viewInMapsPopUpImg" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}
                    {showBrkGPS ? (
                      <div className="breakGpsRowAni">
                        <div className="clockRowGpsRowLeft">
                          {state.break2endZone ? (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps2}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p className="clockedOnZoneTxtTitle">
                                Break 2 end
                              </p>
                            </div>
                          ) : (
                            <p className="clockedOnZoneTxtTitleUnknown">
                              Break 2 end
                            </p>
                          )}
                          {state.break2endZone ? (
                            <p className="clockedOnZoneTxt">
                              {dateStringer.convertMetresToKm(
                                state.break2endDis
                              )}{" "}
                              <span className="gpsAwayFromSpan">
                                away from {state.break2endZone}
                              </span>
                            </p>
                          ) : (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps1}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p
                                className={`clockedOnZoneTxtTitle coordSize ${
                                  JSON.stringify(state.break2endLat || "")
                                    .length +
                                    JSON.stringify(state.break2endLon || "")
                                      .length >
                                  20
                                    ? "xSmallCoords"
                                    : ""
                                }`}
                              >
                                {state.break2endLat || "No GPS info"}
                                {typeof state.break2endLat === "number"
                                  ? ","
                                  : ""}{" "}
                                {state.break2endLon}
                              </p>
                            </div>
                          )}
                          <p className="clockedOnZoneTxtAcc">
                            {!isNaN(state.break2endAcc) &&
                            state.break2endAcc !== null
                              ? `${Math.round(state.break2endAcc)}m accuracy`
                              : "Accuracy unknown"}
                          </p>
                        </div>

                        <div className="clockRowGpsRowRight">
                          {typeof state.break2endLat === "number" ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${state.break2endLat},${state.break2endLon}`}
                              className="viewMapBtn"
                              target="_blank"
                            >
                              Maps{" "}
                              <img src={popup} className="viewInMapsPopUpImg" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}

                    {showBrkGPS ? (
                      <div className="breakGpsRowAni">
                        <div className="clockRowGpsRowLeft">
                          {state.break3startZone ? (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps2}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p className="clockedOnZoneTxtTitle">
                                Break 3 start
                              </p>
                            </div>
                          ) : (
                            <p className="clockedOnZoneTxtTitleUnknown">
                              Break 3 start
                            </p>
                          )}
                          {state.break3startZone ? (
                            <p className="clockedOnZoneTxt">
                              {dateStringer.convertMetresToKm(
                                state.break3startDis
                              )}{" "}
                              <span className="gpsAwayFromSpan">
                                away from {state.break3startZone}
                              </span>
                            </p>
                          ) : (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps1}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p
                                className={`clockedOnZoneTxtTitle coordSize ${
                                  JSON.stringify(state.break3startLat || "")
                                    .length +
                                    JSON.stringify(state.break3startLon || "")
                                      .length >
                                  20
                                    ? "xSmallCoords"
                                    : ""
                                }`}
                              >
                                {state.break3startLat || "No GPS info"}
                                {typeof state.break3startLat === "number"
                                  ? ","
                                  : ""}{" "}
                                {state.break3startLon}
                              </p>
                            </div>
                          )}
                          <p className="clockedOnZoneTxtAcc">
                            {!isNaN(state.break3startAcc) &&
                            state.break3startAcc !== null
                              ? `${Math.round(state.break3startAcc)}m accuracy`
                              : "Accuracy unknown"}
                          </p>
                        </div>

                        <div className="clockRowGpsRowRight">
                          {typeof state.break3startLat === "number" ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${state.break3startLat},${state.break3startLon}`}
                              className="viewMapBtn"
                              target="_blank"
                            >
                              Maps{" "}
                              <img src={popup} className="viewInMapsPopUpImg" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}
                    {showBrkGPS ? (
                      <div className="breakGpsRowAni">
                        <div className="clockRowGpsRowLeft">
                          {state.break3endZone ? (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps2}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p className="clockedOnZoneTxtTitle">
                                Break 3 end
                              </p>
                            </div>
                          ) : (
                            <p className="clockedOnZoneTxtTitleUnknown">
                              Break 3 end
                            </p>
                          )}
                          {state.break3endZone ? (
                            <p className="clockedOnZoneTxt">
                              {dateStringer.convertMetresToKm(
                                state.break3endDis
                              )}{" "}
                              <span className="gpsAwayFromSpan">
                                away from {state.break3endZone}
                              </span>
                            </p>
                          ) : (
                            <div className="clockGPSRowImgAndTitle">
                              <img
                                src={gps1}
                                className="gpsRowGpsIcon"
                                alt="GPS"
                              />
                              <p
                                className={`clockedOnZoneTxtTitle coordSize ${
                                  JSON.stringify(state.break3endLat || "")
                                    .length +
                                    JSON.stringify(state.break3endLon || "")
                                      .length >
                                  20
                                    ? "xSmallCoords"
                                    : ""
                                }`}
                              >
                                {state.break3endLat || "No GPS info"}
                                {typeof state.break3endLat === "number"
                                  ? ","
                                  : ""}{" "}
                                {state.break3endLon}
                              </p>
                            </div>
                          )}
                          <p className="clockedOnZoneTxtAcc">
                            {!isNaN(state.break3endAcc) &&
                            state.break3endAcc !== null
                              ? `${Math.round(state.break3endAcc)}m accuracy`
                              : "Accuracy unknown"}
                          </p>
                        </div>

                        <div className="clockRowGpsRowRight">
                          {typeof state.break3endLat === "number" ? (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${state.break3endLat},${state.break3endLon}`}
                              className="viewMapBtn"
                              target="_blank"
                            >
                              Maps{" "}
                              <img src={popup} className="viewInMapsPopUpImg" />
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* - - - - - - - - - - - - - - */}

                    {/* - - - - - - - - - - - - - - */}
                    <div className="clockRowGpsRow noBorder">
                      <div className="clockRowGpsRowLeft">
                        {state.clockOffZone ? (
                          <div className="clockGPSRowImgAndTitle">
                            <img
                              src={gps2}
                              className="gpsRowGpsIcon"
                              alt="GPS"
                            />
                            <p className="clockedOnZoneTxtTitle">Clocked off</p>
                          </div>
                        ) : (
                          <p className="clockedOnZoneTxtTitleUnknown">
                            Clocked off
                          </p>
                        )}
                        {state.clockOffZone ? (
                          <p className="clockedOnZoneTxt">
                            {dateStringer.convertMetresToKm(state.clockOffDis)}{" "}
                            <span className="gpsAwayFromSpan">
                              away from {state.clockOffZone}
                            </span>
                          </p>
                        ) : (
                          <div className="clockGPSRowImgAndTitle">
                            <img
                              src={gps1}
                              className="gpsRowGpsIcon"
                              alt="GPS"
                            />
                            <p
                              className={`clockedOnZoneTxtTitle coordSize ${
                                JSON.stringify(state.clockOffLat || "").length +
                                  JSON.stringify(state.clockOffLon || "")
                                    .length >
                                20
                                  ? "xSmallCoords"
                                  : ""
                              }`}
                            >
                              {/* {state.clockOffLat}, {state.clockOffLon} */}
                              {state.clockOffLat || "No GPS info"}
                              {typeof state.clockOffLat === "number"
                                ? ","
                                : ""}{" "}
                              {state.clockOffLon}
                            </p>
                          </div>
                        )}
                        <p className="clockedOnZoneTxtAcc">
                          {!isNaN(state.clockOffAcc) &&
                          state.clockOffAcc !== null
                            ? `${Math.round(state.clockOffAcc)}m accuracy`
                            : "Accuracy unknown"}
                        </p>
                      </div>

                      <div className="clockRowGpsRowRight">
                        {typeof state.clockOffLat === "number" ? (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${state.clockOffLat},${state.clockOffLon}`}
                            className="viewMapBtn"
                            target="_blank"
                          >
                            Maps{" "}
                            <img src={popup} className="viewInMapsPopUpImg" />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* - - - - - - - - - - - - - - */}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* end of row */}
              {/* new row */}
              <div className="myClockRow clockPayRow noBorder">
                <div className="clockNoteDiv">
                  <div className="clockCardNoteTitleAndAddNoteBtnDiv">
                    <p className="myShiftRowLeftTitle clockNotesTitle">Notes</p>
                    <p></p>
                  </div>

                  <div className="clockCardNotesArr">
                    {state.clockNotes && state.clockNotes[0] ? (
                      generateClockNotes
                    ) : (
                      <p className="noShiftNotes">No notes yet</p>
                    )}
                    {addNote && (
                      <div className="addClockNoteInputContainer">
                        <textarea
                          className="addClockNoteTextInput"
                          ref={noteRef}
                          value={note}
                          onKeyPress={(e) => {
                            enterFct(e);
                          }}
                          onChange={(e) => {
                            if (e.key !== "Enter") {
                              setNote(e.target.value);
                            }
                          }}
                          placeholder="Add note"
                        ></textarea>

                        <div className="clockNoteSaveAndClearBtns">
                          <p
                            className={`saveClockNoteBtn ${
                              !note ? "fadeSaveBtnClockNote" : ""
                            }`}
                            onClick={() => {
                              enterFct("add");
                            }}
                          >
                            Add
                          </p>
                        </div>
                      </div>
                    )}
                    {!addNote && (
                      <div
                        className={`addClockNoteBtn addMyShNote blueColours `}
                        onClick={() => {
                          setAddNote(true);
                          setTimeout(() => {
                            if (clockBodyRef.current) {
                              clockBodyRef.current.scrollTo({
                                top: clockBodyRef.current.scrollHeight, // Scroll to the bottom of the div
                                behavior: "smooth", // Smooth scroll behavior
                              });
                            }
                          }, 150);
                        }}
                      >
                        Add note{" "}
                        <img
                          src={noteImg}
                          alt="Add note"
                          className={`addNoteInlineImg `}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* end of row */}
            </div>
            {/* end of modal body */}
            {/* modal footer */}
            <div
              className={`editShiftModalFooter ${
                memoVals.mobModal ? "mobClockFooter" : ""
              }`}
            >
              <div className="deleteAndShiftHolder">
                <p
                  className={`deleteShiftBtn deleteClockBtn width38px ${
                    !isMgr && state.approved && !state.unlinked ? "none" : ""
                  }`}
                  onClick={() => {
                    setSureDelete(true);
                  }}
                >
                  <img src={whitebin} className="clockWhiteBin" alt="Delete" />
                  {/* Delete */}
                </p>

                {showMgrOption && !isMgr ? (
                  <div
                    className="manageClockOption"
                    onClick={() => {
                      console.log("OPen clock modal");
                      console.log(clockOnID.clockOnID);

                      setIsMgr(true);
                    }}
                  >
                    Amend
                    <img
                      src={edit}
                      alt="Manage clock card"
                      className="manageClockOptionPen"
                    />
                  </div>
                ) : (
                  ""
                )}

                {!editDecision &&
                (state.approved || state.declined) &&
                isMgr ? (
                  <p
                    className="editClockDecisionBtn"
                    onClick={() => {
                      setEditDecision(true);
                    }}
                  >
                    <img src={editReq} alt="Edit" className="editClockDecImg" />
                  </p>
                ) : (
                  ""
                )}

                {editDecision ? (
                  <p
                    className="backEditDecision"
                    onClick={() => {
                      setEditDecision(false);
                    }}
                  >
                    <img
                      src={returnArrow}
                      alt="Back"
                      className="backeditdecisionImg"
                    />
                  </p>
                ) : (
                  ""
                )}

                {editDecision && state.declined ? (
                  <p
                    className="approveClockBtn"
                    onClick={() => {
                      setEditDecision(false);

                      if (isMgr) {
                        if (
                          state.scheduledDurMins &&
                          state.clockedDurMins < state.scheduledDurMins
                        ) {
                          // is short
                          setShowExcessModal({
                            show: true,
                            short: true,
                            over: false,
                            diff: state.scheduledDurMins - state.clockedDurMins,
                          });
                        } else if (
                          state.scheduledDurMins &&
                          state.clockedDurMins > state.scheduledDurMins
                        ) {
                          // is over
                          setShowExcessModal({
                            show: true,
                            short: false,
                            over: true,
                            diff: state.clockedDurMins - state.scheduledDurMins,
                          });
                        } else {
                          axios
                            .post(
                              `${serverURL}/approve-clock-card`,
                              {
                                clockOnID: clockOnID.clockOnID,
                                nowDs: dateStringer.createStringFromTimestamp(
                                  new Date().getTime()
                                ),
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                // setReloadTeamClockOns(!reloadTeamClockOns);
                                setEditClocked(false);
                                setChanged(true);
                                setJustApproved(true);
                                setUpdateClock(!updateClock);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }
                    }}
                  >
                    Approve{" "}
                    <img
                      src={whiteTick}
                      alt="Approve"
                      className="approveClockTickImg"
                    />
                  </p>
                ) : (
                  ""
                )}

                {editDecision && state.approved ? (
                  <p
                    className="declineClockBtn"
                    onClick={() => {
                      setEditDecision(false);
                      if (isMgr) {
                        axios
                          .post(
                            `${serverURL}/decline-clock-card`,
                            {
                              clockOnID: clockOnID.clockOnID,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // setReloadTeamClockOns(!reloadTeamClockOns);
                              setEditClocked(false);

                              setChanged(true);
                              setJustApproved(true);
                              setUpdateClock(!updateClock);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Decline{" "}
                    <img
                      src={crossWhite}
                      alt="Approve"
                      className="approveClockTickImg x34981"
                    />
                  </p>
                ) : (
                  ""
                )}
                {!state.approved &&
                  !state.declined &&
                  !state.unlinked &&
                  isMgr && (
                    <div className="approveAndDeclineClockBtns">
                      <p
                        className="approveClockBtn"
                        onClick={() => {
                          if (isMgr) {
                            if (
                              state.scheduledDurMins &&
                              state.clockedDurMins < state.scheduledDurMins
                            ) {
                              // is short
                              setShowExcessModal({
                                show: true,
                                short: true,
                                over: false,
                                diff:
                                  state.scheduledDurMins - state.clockedDurMins,
                              });
                            } else if (
                              state.scheduledDurMins &&
                              state.clockedDurMins > state.scheduledDurMins
                            ) {
                              // is over
                              setShowExcessModal({
                                show: true,
                                short: false,
                                over: true,
                                diff:
                                  state.clockedDurMins - state.scheduledDurMins,
                              });
                            } else {
                              axios
                                .post(
                                  `${serverURL}/approve-clock-card`,
                                  {
                                    clockOnID: clockOnID.clockOnID,
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message === "success") {
                                    // setReloadTeamClockOns(!reloadTeamClockOns);
                                    setCountData({
                                      clocks: countData.clocks - 1,
                                    });
                                    setEditClocked(false);
                                    setChanged(true);
                                    setJustApproved(true);
                                    setUpdateClock(!updateClock);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }
                          }
                        }}
                      >
                        Approve{" "}
                        <img
                          src={whiteTick}
                          alt="Approve"
                          className="approveClockTickImg"
                        />
                      </p>{" "}
                      <p
                        className="declineClockBtn"
                        onClick={() => {
                          if (isMgr) {
                            axios
                              .post(
                                `${serverURL}/decline-clock-card`,
                                {
                                  clockOnID: clockOnID.clockOnID,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                },

                                {
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                if (response.data.message === "success") {
                                  // setReloadTeamClockOns(!reloadTeamClockOns);
                                  setEditClocked(false);
                                  setCountData({
                                    clocks: countData.clocks - 1,
                                  });
                                  setChanged(true);
                                  setJustApproved(true);
                                  setUpdateClock(!updateClock);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                        }}
                      >
                        Decline{" "}
                        <img
                          src={crossWhite}
                          alt="Approve"
                          className="approveClockTickImg x34981"
                        />
                      </p>
                    </div>
                  )}

                {(!state.approved && !state.declined && !state.assocValid) ||
                state.declined ? (
                  ""
                ) : (
                  <p
                    className={`${
                      (state.approvedValid || state.assocValid) &&
                      !state.unlinked
                        ? "viewAssocShiftBtn"
                        : "viewAssocShiftBtn fadeViewAssoc"
                    } ${
                      ((state.approvedValid || state.assocValid) &&
                        !state.unlinked &&
                        !state.approved &&
                        !state.declined) ||
                      editDecision
                        ? "none"
                        : ""
                    }`}
                    onClick={() => {
                      if (clockOnID.fromShiftManager) {
                        memoVals.setClockOnID((x) => false);
                      } else {
                        if (!state.unlinked) {
                          if (state.assocValid) {
                            if (state.assocShiftID) {
                              memoVals.setClockOnID((x) => false);

                              if (isMgr) {
                                if (memoVals.showMyShiftModal.unitType) {
                                  memoVals.setShowMyShiftModal((x) => false);
                                }
                                memoVals.setOpenManagerShiftModal((x) => {
                                  return {
                                    shiftID: state.assocShiftID,
                                    tilID: "",
                                    fName: "",
                                    lName: "",
                                    openedFromShiftsPage: true,
                                    type: "shift",
                                  };
                                });
                              } else {
                                memoVals.setShowMyShiftModal((x) => {
                                  return {
                                    unitType: "shift",
                                    unitID: state.assocShiftID,
                                  };
                                });
                              }
                            } else if (state.assocTilID) {
                              memoVals.setClockOnID((x) => false);

                              if (isMgr) {
                                memoVals.setOpenManagerShiftModal((x) => {
                                  return {
                                    shiftID: "",
                                    tilID: state.assocTilID,
                                    fName: "",
                                    lName: "",
                                    openedFromShiftsPage: true,
                                    type: state.type,
                                  };
                                });
                              } else {
                                memoVals.setShowMyShiftModal((x) => {
                                  return {
                                    unitType: state.type,
                                    unitID: state.assocTilID,
                                  };
                                });
                              }
                            } else {
                              setShowInvalidShiftModal(true);
                            }
                          } else if (state.approvedValid) {
                            // show approved til or shift
                            if (state.approvedShiftID) {
                              // show approved shift
                              memoVals.setClockOnID((x) => false);

                              if (isMgr) {
                                memoVals.setOpenManagerShiftModal((x) => {
                                  return {
                                    shiftID: state.approvedShiftID,
                                    tilID: "",
                                    fName: "",
                                    lName: "",
                                    openedFromShiftsPage: true,
                                    type: "shift",
                                  };
                                });
                              } else {
                                memoVals.setShowMyShiftModal((x) => {
                                  return {
                                    unitType: "shift",
                                    unitID: state.approvedShiftID,
                                  };
                                });
                              }
                            } else if (state.approvedTilID) {
                              // show approved til

                              memoVals.setClockOnID((x) => false);

                              if (isMgr) {
                                memoVals.setOpenManagerShiftModal((x) => {
                                  return {
                                    shiftID: "",
                                    tilID: state.approvedTilID,
                                    fName: "",
                                    lName: "",
                                    openedFromShiftsPage: true,
                                    type: state.type,
                                  };
                                });
                              } else {
                                memoVals.setShowMyShiftModal((x) => {
                                  return {
                                    unitType: state.type,
                                    unitID: state.approvedTilID,
                                  };
                                });
                              }
                            } else {
                              setShowInvalidShiftModal(true);
                            }
                          } else {
                            setShowInvalidShiftModal(true);
                          }
                        } else {
                          setShowInvalidShiftModal(true);
                        }
                      }
                      memoVals.setReloadManagerShift(
                        (x) => !memoVals.reloadManagerShift
                      );
                    }}
                  >
                    View{" "}
                    {state.type === "shift"
                      ? "shift"
                      : `${
                          state.type === "til" ? "time in lieu" : "overtime"
                        }`}{" "}
                  </p>
                )}
                {state.unlinked && isMgr && !editDecision && (
                  <p
                    className="viewAssocShiftBtn relinkBtn"
                    onClick={() => {
                      if (isMgr) {
                        axios
                          .post(
                            `${serverURL}/approve-clock-card`,
                            {
                              clockOnID: clockOnID.clockOnID,
                              relinked: true,
                              nowDs: dateStringer.createStringFromTimestamp(
                                new Date().getTime()
                              ),
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              // setReloadTeamClockOns(!reloadTeamClockOns);

                              setChanged(true);

                              setUpdateClock(!updateClock);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }
                    }}
                  >
                    Re-link
                  </p>
                )}
              </div>
              <p
                className={`closeEditShiftBtn `}
                onClick={() => {
                  if (changed) {
                    memoVals.setReloadManagerShift(
                      (x) => !memoVals.reloadManagerShift
                    );
                  }
                  closeModal();
                }}
              >
                Close
              </p>

              {sureDelete ? (
                <div className="addAbsenceModalUnderlay">
                  <div className="formCreatedModal">
                    <p className="overlapsRenTxt">
                      Are you sure you want to delete this clock card?
                    </p>
                    <div className="areYouModalBtnsDiv">
                      <p
                        className="areYouSureModalYesBtn"
                        onClick={() => {
                          closeModal(true);
                          setSureDelete(false);
                          axios
                            .post(
                              `${serverURL}/delete-single-clock-on`,
                              { clockOnID: clockOnID.clockOnID },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                memoVals.setReloadManagerShift(
                                  (x) => !memoVals.reloadManagerShift
                                );

                                memoVals.setDeletedClockOnID(
                                  (x) => response.data.clockOnID
                                );
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        Yes
                      </p>
                      <p
                        className="areYouModalNoBtn"
                        onClick={() => {
                          setSureDelete(false);
                        }}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )
      )}
      {/* end of modal footer */}

      {/*end of modalBox  */}

      {/* end of choose available tags modal */}

      {showDistanceModal.show ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={() => {
            setShowDistanceModal({ show: false });
          }}
        >
          {" "}
          <div
            className="formCreatedModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="overlapsRenTxt">
              {state.fName} {showDistanceString(showDistanceModal.item)}{" "}
              {showDistanceModal.distance > 1000
                ? dateStringer.convertMetresToKm(
                    Math.round(showDistanceModal.distance),
                    true
                  )
                : `${Math.round(showDistanceModal.distance)}m`}{" "}
              away from {showDistanceModal.zoneName}
            </p>
            <p
              className="closeSwapsNoLongerBtn"
              onClick={() => {
                setShowDistanceModal({ show: false });
              }}
            >
              Close
            </p>
            {/* <div className="areYouModalBtnsDiv">
              <p className="areYouSureModalYesBtn">Yes</p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDelete(false);
                }}
              >
                Cancel
              </p>
            </div> */}
          </div>
        </div>
      ) : (
        ""
      )}
      {showCannotManageOwnShift ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={() => {
            closeModal();
          }}
        >
          <div
            className="overlapsRenewalModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <div className="overlapsRenTxt">
              You are unable to manage your own clock cards.
              <br />
              <br />
              For permissions to amend or approve your own clock cards, ask a
              FlowRota administrator to enable you to approve your own clock
              cards.
              <br />
              <br />
              Share this{" "}
              <a
                className="makeBold cursorPointer x8499282"
                href="https://flowrota.com/support/manager-self-allowances-enable"
                target="_blank"
              >
                guidance link
              </a>{" "}
              with them.
            </div>
            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  // open my shift
                  setIsMgr(false);
                  setShowCannotManageOwnShift(false);
                  setUpdateClock(!updateClock);
                }}
              >
                View clock card
              </p>{" "}
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  closeModal();
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showImageModal.show ? (
        <div
          className="overlapsRenewalModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowImageModal({ show: false });
            setImage404(false);
          }}
        >
          <div
            className={`overlapsRenewalModalBox showIamgeModal ${
              !memoVals.mobModal && image404 ? "showIamgeModal404Desktop" : ""
            } ${memoVals.mobModal ? "slideUp" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            {photoLoading && !image404 && (
              <img
                src={horizontalBalls}
                alt="loading"
                className="picLoadingImg"
              />
            )}
            {image404 && !photoLoading ? (
              <div className="img404">
                <div className="img404SideLine"></div>
                <p className="img404txt">
                  This terminal photo has been deleted by a manager, or it has
                  been deleted because it is older than 90 days.
                  <br />
                  <br />
                  Images older than 90 days are deleted automatically as part of
                  FlowRota’s personal data retention policy.
                </p>
              </div>
            ) : (
              <img
                src={`${
                  showImageModal.url
                }?cacheBuster=${new Date().getTime()}`}
                alt={showImageModal.string}
                className="clockImgCapt"
                onLoad={() => {
                  setPhotoLoading(false);
                }}
                onError={(e) => {
                  e.target.onerror = null; // prevents infinite loop in case fallback also fails
                  // e.target.src = fallbackImageUrl; // set the fallback image URL
                  setImage404(true);
                }}
              ></img>
            )}
            {!image404 && !photoLoading && isMgr && showMgrOption && (
              <p
                className="deleteClockPhotoBtn"
                onClick={() => {
                  setShowSureDeletePhoto(showImageModal.url);
                }}
              >
                Delete photo
              </p>
            )}
            {sureDeleteClockPhoto ? (
              <div
                className="editUserUnderlay"
                onClick={() => {
                  setShowSureDeletePhoto(false);
                }}
              >
                <div
                  className="overlapsRenewalModalBox"
                  onClick={(e) => {
                    // console.log({ namesOfInvalid });
                    e.stopPropagation();
                  }}
                >
                  <p className="overlapsRenTxt">
                    Are you sure you want to delete this photo taken at the
                    clock terminal?
                  </p>
                  <div className="areYouModalBtnsDiv">
                    <p
                      className="areYouSureModalYesBtn"
                      onClick={() => {
                        setShowSureDeletePhoto(false);

                        axios
                          .post(
                            `${serverURL}/delete-terminal-photo`,
                            {
                              url: sureDeleteClockPhoto,
                            },

                            {
                              withCredentials: true,
                              credentials: "include",
                            }
                          )
                          .then((response) => {
                            if (response.data.message === "success") {
                              setImage404(true);
                            }
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                    >
                      Yes
                    </p>
                    <p
                      className="areYouModalNoBtn"
                      onClick={() => {
                        setShowSureDeletePhoto(false);
                      }}
                    >
                      No
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <p className="showImageModalTxt">
              {showImageModal.string} -{" "}
              {dateStringer.dsToTimeStrip(showImageModal.ds)},{" "}
              {dateStringer.printedDateFromDs(showImageModal.ds)}
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setShowImageModal({ show: false });
                  setImage404(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {imgPreload}
      {cannotManage ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              You cannot manage your own clock cards.
              <br />
              <br />
              Only another manager from your primary team or a FlowRota admin
              can approve them.
              <br />
              <br />
              If you require permission to approve your own clock cards, a
              FlowRota admin can grant it by following{" "}
              <a
                href="https://flowrota.com/support/self-approve-permissions"
                target="_blank"
                className="colour00aaff"
              >
                this guide
              </a>
              .
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  closeModal(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showExcessModal.show && (
        <div
          className="availableTagsUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setShowExcessModal({ show: false });
          }}
        >
          <div
            className={`availableTagsModal ccExcessModal ${
              memoVals.mobModal
                ? `mobModalShoulder ${
                    memoVals.device.ios ? "availableTagsModalIos" : ""
                  }`
                : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={modalPositionLeave}
            ref={shiftLeaveRef}
          >
            {memoVals.mobModal && (
              <div
                className="modalSwiper modalSwiperAddTags"
                onTouchStart={handleDownLeave}
                onTouchMove={memoVals.mobModal ? handleMoveLeave : null}
                onTouchEnd={memoVals.mobModal ? handleUpLeave : null}
                onMouseDown={memoVals.mobModal ? handleDownLeave : null}
                onMouseMove={memoVals.mobModal ? handleMoveLeave : null}
                onMouseUp={memoVals.mobModal ? handleUpLeave : null}
              ></div>
            )}
            <div
              className="addTagModalHeader"
              onTouchStart={handleDownLeave}
              onTouchMove={memoVals.mobModal ? handleMoveLeave : null}
              onTouchEnd={memoVals.mobModal ? handleUpLeave : null}
              onMouseDown={memoVals.mobModal ? handleDownLeave : null}
              onMouseMove={memoVals.mobModal ? handleMoveLeave : null}
              onMouseUp={memoVals.mobModal ? handleUpLeave : null}
            >
              <img
                className="closeAddTagModalImg"
                src={close}
                alt="Close"
                onClick={() => {
                  setShowExcessModal({ show: false });
                }}
              />
              <p className="addTagModalHeaderTitle">
                Clocked {showExcessModal.over ? "overtime" : "shortfall"}
              </p>
              <p className="phantomModalHeaderUnit">&nbsp;</p>
            </div>
            <p className="ccShortFallTxt">
              {state.fName} has clocked{" "}
              <span className="makeBold">
                {dateStringer.formatMinsDurationToHours(showExcessModal.diff)}{" "}
                {showExcessModal.over ? "over" : "short of"}
              </span>{" "}
              their scheduled duration.
            </p>

            <div className="excessPrefDiv excessPrefDivCC">
              <div
                className="excessPrefDivRow excessPrefDivRowCC"
                onClick={() => {
                  setExcessToil(!excessToil);
                }}
              >
                <p
                  className={`excessPrefRowTxt excessPrefRowTxtCC ${
                    excessToil && "colour143051"
                  }`}
                >
                  {showExcessModal.over
                    ? "Add to TOIL balance"
                    : "Deduct from TOIL balance"}
                </p>{" "}
                <div
                  className={`excessPrefDivRowCheckbox ${
                    excessToil ? "whiteBg" : ""
                  }`}
                >
                  <img
                    src={checkbox1}
                    alt="Preference"
                    className={`excessPrevCheckImg  excessPrevCheckImgCC ${
                      excessToil ? "" : "hidden"
                    }`}
                  />
                </div>{" "}
              </div>
            </div>
            <div className="areYouModalBtnsDiv x3985985983">
              <p
                className="xr959595939"
                onClick={() => {
                  setShowExcessModal({ show: false });
                  axios
                    .post(
                      `${serverURL}/approve-clock-card`,
                      {
                        clockOnID: clockOnID.clockOnID,
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                        excessToil: excessToil
                          ? showExcessModal.over
                            ? showExcessModal.diff
                            : showExcessModal.diff * -1
                          : false,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        // setReloadTeamClockOns(!reloadTeamClockOns);
                        setEditClocked(false);
                        setChanged(true);
                        setJustApproved(true);
                        setUpdateClock(!updateClock);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Approve
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setShowExcessModal({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
    // end of underlay
  );
};

export default ClockItemModal;
