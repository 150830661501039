import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
// cleaned wef 24 jul 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import "../../styles/leaveRequests.css";

import dateStringer from "../../tools/dateStringer";
import Initials from "../Initials";

import { DataContext } from "../../contexts/DataContext";
import { UserContext } from "../../contexts/UserContext";
import { RequestContext } from "../../contexts/RequestContext";
import { CalendarContext } from "../../contexts/CalendarContext";
import { StyleContext } from "../../contexts/StyleContext";

import serverURL from "../../serverURL";

import close from "../../img/modals/close.svg";
import shiftBlock from "../../img/general/shiftBlock.svg";
import shiftBlockAbs from "../../img/general/shiftBlockAbs.svg";

import rotaDur from "../../img/general/rotaDur.svg";
import rotaTeam from "../../img/general/rotaTeam.svg";
import editNavy from "../../img/general/editNavy.svg";
import rotaDate from "../../img/general/rotaDate.svg";
import rotaPlane from "../../img/general/rotaPlane.svg";
import streamCross from "../../img/general/streamCross.svg";
import rotaReq from "../../img/general/rotaReq.svg";
import allowArr from "../../img/general/allowArr.svg";
import popup from "../../img/general/popupNavy.svg";
import popupWhite from "../../img/general/popup.svg";
import rotaTag from "../../img/general/rotaTag.svg";
import rotaCross from "../../img/general/rotaCross.svg";
import greenTick from "../../img/general/greenTick.svg";
import plane2navy from "../../img/general/plane2navy.svg";
import desktopBurger from "../../img/general/desktopBurger.svg";

import rotaCoffee from "../../img/general/rotaCoffee.svg";
import rotaClock from "../../img/general/rotaClock.svg";

import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

const ScheduledToday = () => {
  const { mobModal } = useContext(StyleContext);
  const {
    setOpenManagerShiftModal,
    setShowReqModalInViewer,
    showReqModalInViewer,
    showEditUser,
    setShowEditUser,
    showMyShiftModal,

    setShowMyShiftModal,
    modalOpen,
    setModalOpen,
    device,
    viewAbsenceUserTimeline,
    setViewAbsenceUserTimeline,
    showReqMgrModal,
    setShowReqMgrModal,
    setShowEditShiftModal,
    showEditShiftModal,
    showUserReqFromNav,
    setShowUserReqFromNav,
    //
    showUserHoursModal,
    setShowUserHoursModal,
    setSeeUserID,
    seeUserID,

    //
    showScheduledTodayModal,
    setShowScheduledTodayModal,
    setShowActiveClocks,
    setClockOnID,
    clockOnID,
  } = useContext(DataContext);

  const { checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      showEditUser,
      showEditShiftModal,
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showReqMgrModal,
      setShowEditShiftModal,
      modalOpen,
      setModalOpen,
      device,
      viewAbsenceUserTimeline,
      setViewAbsenceUserTimeline,
      setShowReqMgrModal,
      setShowUserReqFromNav,
      showUserReqFromNav,
      setSeeUserID,
      seeUserID,

      //
      showUserHoursModal,
      setShowUserHoursModal,

      //
      showScheduledTodayModal,
      setShowScheduledTodayModal,
      checkUserState,
      setShowActiveClocks,
      setClockOnID,
      clockOnID,
    }),
    [
      setOpenManagerShiftModal, //
      setShowReqModalInViewer, //
      showReqModalInViewer, //
      showEditUser,
      showEditShiftModal,
      setShowEditUser, //
      showMyShiftModal, //
      setShowMyShiftModal, //
      mobModal, //
      showReqMgrModal,
      setShowEditShiftModal,
      modalOpen,
      setModalOpen,

      device,
      viewAbsenceUserTimeline,
      setViewAbsenceUserTimeline,
      setShowReqMgrModal,
      setShowUserReqFromNav,
      showUserReqFromNav,
      setSeeUserID,
      seeUserID,

      //
      showUserHoursModal,
      setShowUserHoursModal,

      //
      showScheduledTodayModal,
      setShowScheduledTodayModal,
      checkUserState,
      setShowActiveClocks,
      setClockOnID,
      clockOnID,
    ]
  );
  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);
  let [selectedTeam, setSelectedTeam] = useState(
    memoVals.showScheduledTodayModal.teamID || ""
  );

  useEffect(() => {
    if (dataLoaded && imagesLoaded === 19) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);
  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={greenTick}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popupWhite}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={shiftBlockAbs}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={desktopBurger}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaCross}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTag}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={shiftBlock}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDur}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={allowArr}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={popup}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaTeam}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={editNavy}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaDate}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaPlane}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={streamCross}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={rotaReq}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />{" "}
      <img
        src={plane2navy}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaClock}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={rotaCoffee}
        alt="Request Loader"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    memoVals.setModalOpen((x) => true);

    return () => {
      memoVals.setModalOpen((x) => false);
    };
  }, [memoVals.setModalOpen]);

  useEffect(() => {
    // cleaned
    const handlePopstate = () => {
      window.history.pushState(null, document.title, window.location.href);
      memoVals.setShowEditShiftModal((x) => {
        return { show: false };
      });
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopstate);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  let closeModal = () => {
    memoVals.setShowScheduledTodayModal((x) => {
      return { show: false };
    });
    // if (memoVals.showUserHoursModal.fromEditUser) {
    //   memoVals.setShowEditUser((x) => memoVals.showUserHoursModal.userID);
    // }
  };

  let [ds, setDs] = useState(
    memoVals.showScheduledTodayModal.ds
      ? memoVals.showScheduledTodayModal.ds
      : dateStringer.createStringFromTimestamp(new Date().getTime())
  );

  let [dayStrip, setDayStrip] = useState("");
  let [teams, setTeams] = useState([]);
  let [mgrOfTeam, setMgrOfTeam] = useState(false);
  let [beforeToday, setBeforeToday] = useState(false);
  let [clockedOn, setClockedOn] = useState([]);
  let [onBreak, setOnBreak] = useState([]);
  let [clockedOff, setClockedOff] = useState([]);
  let [didNotClockOn, setDidNotClockOn] = useState([]);
  let [scheduledToWork, setScheduledToWork] = useState([]);
  let [absent, setAbsent] = useState([]);
  let [onLeave, setOnLeave] = useState([]);
  let [totUsers, setTotUsers] = useState(0);
  let [dueToWork, setDueToWork] = useState(0);

  useEffect(() => {
    let dsTs = dateStringer.createTimestampFromString(ds);

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (dsTs < today.getTime()) {
      setBeforeToday(true);
    } else {
      setBeforeToday(false);
    }

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    if (
      ds.substr(0, 11) ===
      dateStringer.createStringFromTimestamp(today.getTime()).substr(0, 11)
    ) {
      setDayStrip("Today");
    } else if (
      ds.substr(0, 11) ===
      dateStringer.createStringFromTimestamp(yesterday.getTime()).substr(0, 11)
    ) {
      setDayStrip("Yesterday");
    } else if (
      ds.substr(0, 11) ===
      dateStringer.createStringFromTimestamp(tomorrow.getTime()).substr(0, 11)
    ) {
      setDayStrip("Tomorrow");
    } else {
      setDayStrip(
        `${dateStringer.printedDateFromDs(ds, true, false, false, true)}`
      );
      //   ds, withYear, full, suffix, shortenYr, withoutDay)
    }

    const cancelSource1 = axios.CancelToken.source();

    axios
      .post(
        `${serverURL}/today-scheduled`,
        {
          ds,
          teamID: selectedTeam,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (response.data.teams && response.data.teams[0]) {
            setTeams(response.data.teams);
            setSelectedTeam(response.data.selectedTeam);
          }
          if (response.data.clockedOn) {
            setClockedOn(response.data.clockedOn);
          }
          if (response.data.onBreak) {
            setOnBreak(response.data.onBreak);
          }
          if (response.data.clockedOff) {
            setClockedOff(response.data.clockedOff);
          }
          if (response.data.didNotClockOn) {
            setDidNotClockOn(response.data.didNotClockOn);
          }
          if (response.data.scheduledToWork) {
            setScheduledToWork(response.data.scheduledToWork);
          }
          if (response.data.absent) {
            setAbsent(response.data.absent);
          }
          if (response.data.onLeave) {
            setOnLeave(response.data.onLeave);
          }
          if (selectedTeam) {
            setMgrOfTeam(response.data.mgrOfTeam);
            setDueToWork(response.data.dueToWork || 0);
            setTotUsers(response.data.userCount || 0);
          }

          setDataLoaded(true);
          if (selectedTeam && loading) {
            setTimeout(() => {
              setLoading(false);
            }, 100);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [ds, selectedTeam]);

  // Create a ref to hold the modal element
  const modalRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [modalPosition, setModalPosition] = useState({
    bottom: "0%",
    left: "0%",
  });

  let [dragPosition, setDragPosition] = useState(0);

  const handleDown = (e) => {
    setIsDragging(true);
    setDragStart({
      // x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    });
  };

  const handleMove = (e) => {
    if (isDragging) {
      // const deltaX = (e.clientX || e.touches[0].clientX) - dragStart.x;
      const deltaY = (e.clientY || e.touches[0].clientY) - dragStart.y;

      if (deltaY > 0) {
        setDragPosition(deltaY);
        setModalPosition({
          bottom: `calc(0% - ${deltaY}px)`,
          // left: `calc(0% - ${deltaX}px)`,
        });
      }
    }
  };

  const handleUp = () => {
    setIsDragging(false);

    if (dragPosition > 30) {
      closeModal();
    } else {
      setModalPosition({ bottom: "0%", left: "0%" });
    }
  };

  let [showClocked, setShowClocked] = useState(true);
  let [showOnBreak, setShowOnBreak] = useState(false);
  let [showClockedOff, setShowClockedOff] = useState(false);
  let [showDidNotClockOn, setShowDidNotClockOn] = useState(false);
  let [showScheduledToWork, setShowScheduledToWork] = useState(false);
  let [showOnLeave, setShowOnLeave] = useState(false);
  let [showAbsent, setShowAbsent] = useState(false);

  useEffect(() => {
    if (onBreak.length) {
      const intervalId = setInterval(() => {
        setOnBreak((prevOnBreak) =>
          prevOnBreak.map((item) => ({
            ...item,
            brk: item.brk + 1,
          }))
        );
      }, 1000);

      return () => clearInterval(intervalId); // Clean up interval on unmount
    }
  }, [onBreak]);

  useEffect(() => {
    if (clockedOn.length) {
      const intervalId2 = setInterval(() => {
        setClockedOn((prevClockedOn) =>
          prevClockedOn.map((item) => ({
            ...item,
            elapsedSecs: item.elapsedSecs + 1,
          }))
        );
      }, 1000);

      return () => clearInterval(intervalId2); // Clean up interval on unmount
    }
  }, [clockedOn]);

  useEffect(() => {
    if (showClocked) {
      setShowClocked(false);
    }
    if (showOnBreak) {
      setShowOnBreak(false);
    }
    if (showClockedOff) {
      setShowClockedOff(false);
    }
    if (showDidNotClockOn) {
      setShowDidNotClockOn(false);
    }
    if (showScheduledToWork) {
      setShowScheduledToWork(false);
    }
    if (showOnLeave) {
      setShowOnLeave(false);
    }
    if (showAbsent) {
      setShowAbsent(false);
    }
  }, [ds, selectedTeam]);

  function expandDiv(x, collapse) {
    const element = document.querySelector(x);

    if (element) {
      if (!collapse) {
        // Ensure content is loaded before measuring
        requestAnimationFrame(() => {
          const fullHeight = element.scrollHeight + "px";

          // Set height to trigger the animation
          element.style.height = fullHeight;

          // After animation completes, set height to auto for responsiveness
          element.addEventListener(
            "transitionend",
            () => {
              element.style.height = "auto";
            },
            { once: true }
          );
        });
      } else {
        // Set height explicitly to enable collapse
        element.style.height = element.scrollHeight + "px";

        // Allow height to transition to 0
        requestAnimationFrame(() => {
          element.style.height = "0";
        });
      }
    }
  }

  let [block, setBlock] = useState(false);

  // MASTER RETURN
  return (
    <div
      className={`filtTagsUnderlay ${
        memoVals.showReqMgrModal ||
        memoVals.showUserReqFromNav ||
        memoVals.showEditUser ||
        memoVals.showMyShiftModal.unitID ||
        (memoVals.showEditShiftModal.itemID &&
          !memoVals.showUserHoursModal.fromShift) ||
        memoVals.seeUserID.userID ||
        memoVals.showUserHoursModal.show ||
        memoVals.clockOnID ||
        memoVals.showReqMgrModal
          ? "hidden"
          : ""
      }`}
      onClick={() => {
        closeModal();
      }}
    >
      <div className="tagsFiltDropper">
        <div
          className={`filtTagsModal noMarginBottom ${
            memoVals.mobModal
              ? "filtTagsModalMob mobModalShoulder"
              : "userHoursModalBody zoomIn schedTodayDesktopBody"
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={modalPosition}
          ref={modalRef}
        >
          {" "}
          {memoVals.mobModal && (
            <div
              className="modalSwiper modalSwiperViewUserhours"
              onTouchStart={handleDown}
              onTouchMove={memoVals.mobModal ? handleMove : null}
              onTouchEnd={memoVals.mobModal ? handleUp : null}
              onMouseDown={memoVals.mobModal ? handleDown : null}
              onMouseMove={memoVals.mobModal ? handleMove : null}
              onMouseUp={memoVals.mobModal ? handleUp : null}
            ></div>
          )}
          <div
            className={`userHoursHeader `}
            onTouchStart={handleDown}
            onTouchMove={memoVals.mobModal ? handleMove : null}
            onTouchEnd={memoVals.mobModal ? handleUp : null}
            onMouseDown={memoVals.mobModal ? handleDown : null}
            onMouseMove={memoVals.mobModal ? handleMove : null}
            onMouseUp={memoVals.mobModal ? handleUp : null}
          >
            <img
              src={close}
              onClick={() => {
                closeModal();
              }}
              alt="Close"
              className="closeMyShiftModalImg"
            />
            <p
              className={`toilHeaderTxt`}
              onClick={() => {
                if (memoVals.mob) {
                  closeModal();
                }
              }}
            >
              Your colleagues
              <img src={rotaTeam} alt="Scheduled" className="userHoursPPimg" />
            </p>
          </div>
          <div className="schedTodayTopRow">
            <div className="schedTodayTopRowDs">
              <img
                src={allowArr}
                alt="Change date"
                className={`schedTodayTopRowArr ${
                  loading ? "disableSchedTodayArr" : ""
                }`}
                onClick={() => {
                  setLoading(true);

                  let dateObj = new Date(
                    dateStringer.createTimestampFromString(ds)
                  );
                  dateObj.setDate(dateObj.getDate() - 1);
                  dateObj.setHours(0, 0, 0, 0);
                  setDs(
                    dateStringer.createStringFromTimestamp(dateObj.getTime())
                  );
                }}
              />
              <p className="schedTodayTopRowTxt">
                {/* {dateStringer.printedDateFromDs(ds)} */}
                {dayStrip}
              </p>
              <img
                src={allowArr}
                alt="Change date"
                className={`schedTodayTopRowArr schedTodayTopRowArrFlip ${
                  loading ? "disableSchedTodayArr" : ""
                }`}
                onClick={() => {
                  setLoading(true);

                  let dateObj = new Date(
                    dateStringer.createTimestampFromString(ds)
                  );
                  dateObj.setDate(dateObj.getDate() + 1);
                  dateObj.setHours(0, 0, 0, 0);
                  setDs(
                    dateStringer.createStringFromTimestamp(dateObj.getTime())
                  );
                }}
              />
            </div>
            <select
              className="schedTodayTopRowTeamSelector"
              onChange={(e) => {
                setLoading(true);
                setSelectedTeam(e.target.value);
              }}
            >
              {teams
                .sort((a, b) => {
                  return a.teamName.localeCompare(b.teamName);
                })
                .map((team, i) => {
                  return (
                    <option value={team.teamID} key={i}>
                      {team.teamID === selectedTeam
                        ? dateStringer.shorten(team.teamName, 19)
                        : team.teamName}
                    </option>
                  );
                })}
            </select>
          </div>
          <div
            className={`tagsFiltScrollBox paddingBottom10 schedTodayScrollBox`}
          >
            {loading || !dataLoaded ? (
              <img
                src={horizontalBalls}
                alt="Loading"
                className="viewAbsHorizBalls"
              />
            ) : (
              <div className="schedTodayContentBox">
                <div className="schedTodayUserCount">
                  <span className="colour00aaff">{dueToWork}&nbsp;</span> out of{" "}
                  {totUsers} staff{" "}
                  {beforeToday ? (totUsers === 1 ? "was" : "were") : ""} due to
                  work{" "}
                  {dayStrip === "Today"
                    ? "today"
                    : dayStrip === "Yesterday"
                    ? "yesterday"
                    : dayStrip === "Tomorrow"
                    ? "tomorrow"
                    : "on this day"}
                </div>

                {dayStrip === "Today" && (
                  <div className="schedTodayRow">
                    <div
                      className={`schedTodayRowUpper `}
                      onClick={() => {
                        if (!block) {
                          setBlock(true);
                          setShowClocked(!showClocked);
                          if (!showClocked) {
                            expandDiv(".schedTodayRowLowerClocked");
                          } else {
                            expandDiv(".schedTodayRowLowerClocked", true);
                          }
                          setTimeout(() => {
                            setBlock(false);
                          }, 501);
                        }
                      }}
                    >
                      <div className="schedTodayRowUpperTitle">
                        Clocked on{" "}
                        <p
                          className={`schedTodayBlob ${
                            clockedOn.length > 0 ? "colour00aaff" : "none"
                          }`}
                        >
                          {clockedOn.length > 9 ? "9+" : clockedOn.length}
                        </p>
                      </div>
                      <div className="schedTodayRowUpperRight">
                        <div className="schedTodayImageDiv">
                          {clockedOn.map((x, i) => {
                            if (i < 4) {
                              if (!x.profPicUrl) {
                                return (
                                  <div className="schedTodayPPimg">
                                    {x.fName[0]}
                                    {x.lName[0]}
                                  </div>
                                );
                              }
                              return (
                                <img
                                  key={i}
                                  src={
                                    x.profPicUrl || profilePicturePlaceholder
                                  }
                                  alt={x.fName}
                                  className="schedTodayPPimg"
                                />
                              );
                            }
                          })}
                          {clockedOn.length > 4 ? (
                            <div className="schedTodayPlusPP">
                              +{clockedOn.length - 4}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <img
                          src={allowArr}
                          alt="Open clocked on"
                          className={`schedTodayArrImg ${
                            showClocked ? "schedTodayArrImgActive" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {dayStrip === "Today" && (
                  <div
                    className={`schedTodayRowLowerClocked  ${
                      !showClocked && "none"
                    }`}
                  >
                    {clockedOn
                      .sort((a, b) => {
                        return a.startTs - b.startTs;
                      })
                      .map((clock, i) => {
                        return (
                          <div className="schedTodayClockItem zoomIn" key={i}>
                            <div className="schedTodayClockItemJtName">
                              <div className="clockedOnPulsateParent">
                                <div className="clockedOnPulsater"></div>
                                <img
                                  src={rotaClock}
                                  alt="Clockedon"
                                  className="schedTodayMasterIcon"
                                />
                              </div>
                              <img
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    memoVals.setShowEditUser(
                                      (x) => clock.userID
                                    );
                                  } else {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: clock.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }
                                }}
                                src={
                                  clock.profPicUrl || profilePicturePlaceholder
                                }
                                alt={clock.fName}
                                className="schedTodayClockItemJtNamePP"
                              />
                              <div className="schedTodayClockJtNameRight">
                                <p className="schedTodayClockJtNameUpper">
                                  {clock.fName} {clock.lName}
                                </p>
                                <p className="schedTodayClockJtNameLower">
                                  {clock.jobTitle}
                                </p>
                              </div>
                            </div>
                            <div className="schedTodayClockedRow ">
                              <div className="schedTodayAssocRowTitle">
                                Clocked on{" "}
                                <p className="elapBrkTitle">Payable duration</p>
                              </div>
                              <div className="schedTodayAssocRowValue2 colour00aaff">
                                <img
                                  src={rotaClock}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg4"
                                />{" "}
                                <p className="schedTodayClockedValWidth">
                                  {dateStringer.dsToTimeStrip(clock.startDs)}
                                </p>
                                <img
                                  src={rotaCoffee}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg3"
                                />{" "}
                                {dateStringer.secsToString(
                                  clock.elapsedSecs || 0
                                )}
                              </div>
                            </div>
                            {clock.assocType && (
                              <div className="schedTodayAssocRow">
                                <p className="schedTodayAssocRowTitle">
                                  Scheduled{" "}
                                  {clock.assocType === "shift"
                                    ? "shift"
                                    : clock.assocType === "til"
                                    ? "overtime (in lieu)"
                                    : "overtime"}
                                </p>
                                <div className="schedTodayAssocRowValue">
                                  <img
                                    src={rotaClock}
                                    alt="Scheduled times"
                                    className="schedTodayAssocRowImg"
                                  />{" "}
                                  {dateStringer.dsToTimeStrip(
                                    clock.scheduledStartDs
                                  )}{" "}
                                  -{" "}
                                  {dateStringer.dsToTimeStrip(
                                    clock.scheduledEndDs
                                  )}{" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Scheduled times"
                                    className="schedTodayAssocRowImg schedTodayAssocRowImg2"
                                  />
                                  {dateStringer.formatMinsDurationToHours(
                                    clock.scheduledBreak || 0
                                  )}
                                </div>
                                {clock.canManage && (
                                  <p
                                    className="schedTodayViewShBtn"
                                    onClick={() => {
                                      if (mgrOfTeam) {
                                        memoVals.setShowEditShiftModal((x) => {
                                          return {
                                            itemID:
                                              clock.assocShiftID ||
                                              clock.assocTilID,
                                            itemType: clock.assocType,

                                            itemIsNew: false,
                                            userID: clock.userID,
                                          };
                                        });
                                      } else {
                                        memoVals.setShowMyShiftModal((x) => {
                                          return {
                                            unitType: clock.assocType,
                                            unitID:
                                              clock.assocShiftID ||
                                              clock.assocTilID,
                                            otherUser:
                                              clock.userID ===
                                              memoVals.checkUserState.userID
                                                ? false
                                                : true,
                                          };
                                        });
                                      }
                                    }}
                                  >
                                    View{" "}
                                    {clock.assocType === "shift"
                                      ? "shift"
                                      : "overtime"}{" "}
                                    <img
                                      src={popup}
                                      alt="View shift"
                                      className="schedTodayPopup"
                                    />
                                  </p>
                                )}
                              </div>
                            )}{" "}
                          </div>
                        );
                      })}
                  </div>
                )}
                {mgrOfTeam && showClocked && dayStrip === "Today" && (
                  <div className="manClParent">
                    <div
                      className="manageCl lilacColours zoomIn"
                      onClick={() => {
                        memoVals.setShowActiveClocks((x) => true);
                        closeModal();
                      }}
                    >
                      Manage clocked on{" "}
                      <img
                        src={popupWhite}
                        alt="Manage clocked on"
                        className="manageClPopup"
                      />
                    </div>
                  </div>
                )}

                {dayStrip === "Today" && (
                  <div className="schedTodayRow">
                    <div
                      className="schedTodayRowUpper"
                      onClick={() => {
                        if (!block) {
                          setBlock(true);

                          setShowOnBreak(!showOnBreak);
                          if (!showOnBreak) {
                            expandDiv(".schedTodayRowLowerBrk");
                          } else {
                            expandDiv(".schedTodayRowLowerBrk", true);
                          }
                          setTimeout(() => {
                            setBlock(false);
                          }, 501);
                        }
                      }}
                    >
                      <div className="schedTodayRowUpperTitle">
                        On break
                        <p
                          className={`schedTodayBlob ${
                            onBreak.length > 0 ? "colour00aaff" : "none"
                          }`}
                        >
                          {onBreak.length > 9 ? "9+" : onBreak.length}
                        </p>
                      </div>
                      <div className="schedTodayRowUpperRight">
                        <div className="schedTodayImageDiv">
                          {onBreak.map((x, i) => {
                            if (i < 4) {
                              if (!x.profPicUrl) {
                                return (
                                  <div className="schedTodayPPimg">
                                    {x.fName[0]}
                                    {x.lName[0]}
                                  </div>
                                );
                              }
                              return (
                                <img
                                  key={i}
                                  src={
                                    x.profPicUrl || profilePicturePlaceholder
                                  }
                                  alt={x.fName}
                                  className="schedTodayPPimg"
                                />
                              );
                            }
                          })}
                          {onBreak.length > 4 ? (
                            <div className="schedTodayPlusPP">
                              +{onBreak.length - 4}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <img
                          src={allowArr}
                          alt="Open clocked on"
                          className={`schedTodayArrImg ${
                            showOnBreak ? "schedTodayArrImgActive" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {dayStrip === "Today" && (
                  <div
                    className={`schedTodayRowLowerBrk ${
                      !showOnBreak && "none"
                    }`}
                  >
                    {onBreak
                      .sort((a, b) => {
                        return a.startTs - b.startTs;
                      })
                      .map((clock, i) => {
                        return (
                          <div className="schedTodayClockItem zoomIn" key={i}>
                            <div className="schedTodayClockItemJtName">
                              <div className="clockedOnPulsateParent">
                                <div className="clockedOnPulsater clockedOnPulsater2"></div>
                                <img
                                  src={rotaCoffee}
                                  alt="Clockedon"
                                  className="schedTodayMasterIcon"
                                />
                              </div>
                              <img
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    memoVals.setShowEditUser(
                                      (x) => clock.userID
                                    );
                                  } else {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: clock.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }
                                }}
                                src={
                                  clock.profPicUrl || profilePicturePlaceholder
                                }
                                alt={clock.fName}
                                className="schedTodayClockItemJtNamePP"
                              />
                              <div className="schedTodayClockJtNameRight">
                                <p className="schedTodayClockJtNameUpper">
                                  {clock.fName} {clock.lName}
                                </p>
                                <p className="schedTodayClockJtNameLower">
                                  {clock.jobTitle}
                                </p>
                              </div>
                            </div>
                            <div className="schedTodayClockedRow ">
                              <div className="schedTodayAssocRowTitle">
                                Break number
                                <p className="elapBrkTitle2">Break start</p>
                              </div>
                              <div className="schedTodayAssocRowValue2">
                                <img
                                  src={rotaCoffee}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg4"
                                />{" "}
                                <p className="schedTodayClockedValWidth">
                                  {clock.breakNum}
                                </p>
                                <img
                                  src={rotaClock}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg5"
                                />{" "}
                                <span className="colour00aaff">
                                  {dateStringer.dsToTimeStrip(
                                    clock.breakStartDs
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="schedTodayClockedRow ">
                              <div className="schedTodayAssocRowTitle">
                                Clocked on{" "}
                                <p className="elapBrkTitle">Unpaid break</p>
                              </div>
                              <div className="schedTodayAssocRowValue2">
                                <img
                                  src={rotaClock}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg4"
                                />{" "}
                                <p className="schedTodayClockedValWidth">
                                  {dateStringer.dsToTimeStrip(clock.startDs)}
                                </p>
                                <img
                                  src={rotaCoffee}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg3"
                                />{" "}
                                <span className="colour00aaff">
                                  {dateStringer.secsToString(clock.brk || 0)}
                                </span>
                              </div>
                            </div>
                            {clock.assocType && (
                              <div className="schedTodayAssocRow">
                                <p className="schedTodayAssocRowTitle">
                                  Scheduled{" "}
                                  {clock.assocType === "shift"
                                    ? "shift"
                                    : clock.assocType === "til"
                                    ? "overtime (in lieu)"
                                    : "overtime"}
                                </p>
                                <div className="schedTodayAssocRowValue">
                                  <img
                                    src={rotaClock}
                                    alt="Scheduled times"
                                    className="schedTodayAssocRowImg"
                                  />{" "}
                                  {dateStringer.dsToTimeStrip(
                                    clock.scheduledStartDs
                                  )}{" "}
                                  -{" "}
                                  {dateStringer.dsToTimeStrip(
                                    clock.scheduledEndDs
                                  )}{" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Scheduled times"
                                    className="schedTodayAssocRowImg schedTodayAssocRowImg2"
                                  />
                                  {dateStringer.formatMinsDurationToHours(
                                    clock.scheduledBreak || 0
                                  )}
                                </div>
                                {clock.canManage && (
                                  <p
                                    className="schedTodayViewShBtn"
                                    onClick={() => {
                                      if (mgrOfTeam) {
                                        memoVals.setShowEditShiftModal((x) => {
                                          return {
                                            itemID:
                                              clock.assocShiftID ||
                                              clock.assocTilID,
                                            itemType: clock.assocType,

                                            itemIsNew: false,
                                            userID: clock.userID,
                                          };
                                        });
                                      } else {
                                        memoVals.setShowMyShiftModal((x) => {
                                          return {
                                            unitType: clock.assocType,
                                            unitID:
                                              clock.assocShiftID ||
                                              clock.assocTilID,
                                            otherUser:
                                              clock.userID ===
                                              memoVals.checkUserState.userID
                                                ? false
                                                : true,
                                          };
                                        });
                                      }
                                    }}
                                  >
                                    View{" "}
                                    {clock.assocType === "shift"
                                      ? "shift"
                                      : "overtime"}{" "}
                                    <img
                                      src={popup}
                                      alt="View shift"
                                      className="schedTodayPopup"
                                    />
                                  </p>
                                )}
                              </div>
                            )}{" "}
                          </div>
                        );
                      })}
                    {mgrOfTeam && showOnBreak && dayStrip === "Today" && (
                      <div className="manClParent showOnBrkManageClocked">
                        <div
                          className="manageCl lilacColours zoomIn"
                          onClick={() => {
                            memoVals.setShowActiveClocks((x) => true);
                            closeModal();
                          }}
                        >
                          Manage clocked on{" "}
                          <img
                            src={popupWhite}
                            alt="Manage clocked on"
                            className="manageClPopup"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {(dayStrip === "Today" || beforeToday) && (
                  <div className="schedTodayRow">
                    <div
                      className="schedTodayRowUpper"
                      onClick={() => {
                        if (!block) {
                          setBlock(true);
                          setShowClockedOff(!showClockedOff);
                          if (!showClockedOff) {
                            expandDiv(".schedTodayRowLowerClockedOff");
                          } else {
                            expandDiv(".schedTodayRowLowerClockedOff", true);
                          }
                          setTimeout(() => {
                            setBlock(false);
                          }, 501);
                        }
                      }}
                    >
                      <div className="schedTodayRowUpperTitle">
                        Clocked off
                        <p
                          className={`schedTodayBlob ${
                            clockedOff.length > 0 ? "colourBbcada" : "none"
                          }`}
                        >
                          {clockedOff.length > 9 ? "9+" : clockedOff.length}
                        </p>
                      </div>
                      <div className="schedTodayRowUpperRight">
                        <div className="schedTodayImageDiv">
                          {clockedOff.map((x, i) => {
                            if (i < 4) {
                              if (!x.profPicUrl) {
                                return (
                                  <div className="schedTodayPPimg">
                                    {x.fName[0]}
                                    {x.lName[0]}
                                  </div>
                                );
                              }
                              return (
                                <img
                                  key={i}
                                  src={
                                    x.profPicUrl || profilePicturePlaceholder
                                  }
                                  alt={x.fName}
                                  className="schedTodayPPimg"
                                />
                              );
                            }
                          })}
                          {clockedOff.length > 4 ? (
                            <div className="schedTodayPlusPP">
                              +{clockedOff.length - 4}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <img
                          src={allowArr}
                          alt="Open clocked on"
                          className={`schedTodayArrImg ${
                            showClockedOff ? "schedTodayArrImgActive" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {(dayStrip === "Today" || beforeToday) && (
                  <div
                    className={`schedTodayRowLowerClockedOff ${
                      !showClockedOff && "none"
                    }`}
                  >
                    {clockedOff
                      .sort((a, b) => {
                        return a.startTs - b.startTs;
                      })
                      .map((clock, i) => {
                        return (
                          <div className="schedTodayClockItem zoomIn" key={i}>
                            <div className="schedTodayClockItemJtName">
                              <div className="clockedOnPulsateParent">
                                <img
                                  src={greenTick}
                                  alt="x"
                                  className="clockedOnFinTickImg"
                                />
                                <img
                                  src={rotaClock}
                                  alt="Clockedon"
                                  className="schedTodayMasterIcon schedTodayMasterIcon2"
                                />
                              </div>
                              <img
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    memoVals.setShowEditUser(
                                      (x) => clock.userID
                                    );
                                  } else {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: clock.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }
                                }}
                                src={
                                  clock.profPicUrl || profilePicturePlaceholder
                                }
                                alt={clock.fName}
                                className="schedTodayClockItemJtNamePP"
                              />
                              <div className="schedTodayClockJtNameRight">
                                <p className="schedTodayClockJtNameUpper">
                                  {clock.fName} {clock.lName}
                                </p>
                                <p className="schedTodayClockJtNameLower">
                                  {clock.jobTitle}
                                </p>
                              </div>
                            </div>
                            {(clock.userID === memoVals.checkUserState.userID ||
                              clock.canManage) && (
                              <p
                                className={`schedTodayViewShBtn schedTodayViewShBtn2 ${
                                  clock.canManage
                                    ? "lilacColours"
                                    : "lightBlueColours"
                                }`}
                                onClick={() => {
                                  if (clock.canManage) {
                                    // open clock card manager
                                    memoVals.setClockOnID((x) => {
                                      return {
                                        clockOnID: clock.clockOnID,
                                        isMgr: true,
                                      };
                                    });
                                  } else {
                                    // show my clock card
                                    memoVals.setClockOnID((x) => {
                                      return {
                                        clockOnID: clock.clockOnID,
                                        isMgr: false,
                                      };
                                    });
                                  }
                                }}
                              >
                                View clock card
                                <img
                                  src={popupWhite}
                                  alt="View shift"
                                  className="schedTodayPopup"
                                />
                              </p>
                            )}
                            <div className="schedTodayClockedRow ">
                              <div className="schedTodayAssocRowTitle">
                                Clocked on&nbsp;&nbsp;
                                <p className="elapBrkTitle3">Clocked off</p>
                              </div>
                              <div className="schedTodayAssocRowValue2">
                                <img
                                  src={rotaClock}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg4"
                                />{" "}
                                <p className="schedTodayClockedValWidth">
                                  {dateStringer.dsToTimeStrip(clock.startDs)}
                                </p>
                                <img
                                  src={rotaClock}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg6"
                                />{" "}
                                <span className="colour00aaff">
                                  {dateStringer.dsToTimeStrip(clock.endDs)}
                                </span>
                              </div>
                            </div>
                            <div className="schedTodayClockedRow ">
                              <div className="schedTodayAssocRowTitle">
                                Breaks&nbsp;&nbsp;&nbsp;&nbsp;
                                <p className="elapBrkTitle4">Break duration</p>
                              </div>
                              <div className="schedTodayAssocRowValue2">
                                <img
                                  src={rotaCoffee}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg4"
                                />{" "}
                                <p className="schedTodayClockedValWidth">
                                  {clock.brkQty === 0 ? "-" : clock.brkQty}
                                </p>
                                <img
                                  src={rotaCoffee}
                                  alt="Scheduled times"
                                  className="schedTodayAssocRowImg schedTodayAssocRowImg7"
                                />{" "}
                                {!clock.brk
                                  ? "-"
                                  : dateStringer.secsToString(clock.brk || 0)}
                              </div>
                            </div>
                            {clock.assocType && (
                              <div className="schedTodayAssocRow">
                                <p className="schedTodayAssocRowTitle">
                                  Scheduled{" "}
                                  {clock.assocType === "shift"
                                    ? "shift"
                                    : clock.assocType === "til"
                                    ? "overtime (in lieu)"
                                    : "overtime"}
                                </p>
                                <div className="schedTodayAssocRowValue">
                                  <img
                                    src={rotaClock}
                                    alt="Scheduled times"
                                    className="schedTodayAssocRowImg"
                                  />{" "}
                                  {dateStringer.dsToTimeStrip(
                                    clock.scheduledStartDs
                                  )}{" "}
                                  -{" "}
                                  {dateStringer.dsToTimeStrip(
                                    clock.scheduledEndDs
                                  )}{" "}
                                  <img
                                    src={rotaCoffee}
                                    alt="Scheduled times"
                                    className="schedTodayAssocRowImg schedTodayAssocRowImg2"
                                  />
                                  {dateStringer.formatMinsDurationToHours(
                                    clock.scheduledBreak || 0
                                  )}
                                </div>
                                {clock.canManage && (
                                  <p
                                    className="schedTodayViewShBtn"
                                    onClick={() => {
                                      if (mgrOfTeam) {
                                        memoVals.setShowEditShiftModal((x) => {
                                          return {
                                            itemID:
                                              clock.assocShiftID ||
                                              clock.assocTilID,
                                            itemType: clock.assocType,

                                            itemIsNew: false,
                                            userID: clock.userID,
                                          };
                                        });
                                      } else {
                                        memoVals.setShowMyShiftModal((x) => {
                                          return {
                                            unitType: clock.assocType,
                                            unitID:
                                              clock.assocShiftID ||
                                              clock.assocTilID,
                                            otherUser:
                                              clock.userID ===
                                              memoVals.checkUserState.userID
                                                ? false
                                                : true,
                                          };
                                        });
                                      }
                                    }}
                                  >
                                    View{" "}
                                    {clock.assocType === "shift"
                                      ? "shift"
                                      : "overtime"}{" "}
                                    <img
                                      src={popup}
                                      alt="View shift"
                                      className="schedTodayPopup"
                                    />
                                  </p>
                                )}
                              </div>
                            )}{" "}
                          </div>
                        );
                      })}
                  </div>
                )}

                {/*
                 */}
                {!beforeToday && (
                  <div className="schedTodayRow">
                    <div
                      className="schedTodayRowUpper"
                      onClick={() => {
                        if (!block) {
                          setBlock(true);

                          setShowScheduledToWork(!showScheduledToWork);
                          if (!showScheduledToWork) {
                            expandDiv(".schedTodayRowLowerSched");
                          } else {
                            expandDiv(".schedTodayRowLowerSched", true);
                          }
                          setTimeout(() => {
                            setBlock(false);
                          }, 501);
                        }
                      }}
                    >
                      <div className="schedTodayRowUpperTitle">
                        {dayStrip === "Today" ? "Scheduled" : "Scheduled"}{" "}
                        <p
                          className={`schedTodayBlob ${
                            scheduledToWork.length > 0 ? "colourBbcada" : "none"
                          }`}
                        >
                          {scheduledToWork.length > 9
                            ? "9+"
                            : scheduledToWork.length}
                        </p>
                      </div>
                      <div className="schedTodayRowUpperRight">
                        <div className="schedTodayImageDiv">
                          {scheduledToWork.map((x, i) => {
                            if (i < 4) {
                              if (!x.profPicUrl) {
                                return (
                                  <div className="schedTodayPPimg">
                                    {x.fName[0]}
                                    {x.lName[0]}
                                  </div>
                                );
                              }
                              return (
                                <img
                                  key={i}
                                  src={
                                    x.profPicUrl || profilePicturePlaceholder
                                  }
                                  alt={x.fName}
                                  className="schedTodayPPimg"
                                />
                              );
                            }
                          })}
                          {scheduledToWork.length > 4 ? (
                            <div className="schedTodayPlusPP">
                              +{scheduledToWork.length - 4}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <img
                          src={allowArr}
                          alt="Open clocked on"
                          className={`schedTodayArrImg ${
                            showScheduledToWork ? "schedTodayArrImgActive" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!beforeToday && (
                  <div
                    className={`schedTodayRowLowerSched ${
                      !showScheduledToWork && "none"
                    }`}
                  >
                    {scheduledToWork
                      .sort((a, b) => {
                        return a.startTs - b.startTs;
                      })
                      .map((sh, i) => {
                        return (
                          <div className="schedTodayClockItem zoomIn" key={i}>
                            <div className="schedTodayClockItemJtName">
                              <img
                                src={shiftBlock}
                                alt="Clockedon"
                                className="schedTodayMasterIconSolo"
                              />
                              <img
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    memoVals.setShowEditUser((x) => sh.userID);
                                  } else {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: sh.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }
                                }}
                                src={sh.profPicUrl || profilePicturePlaceholder}
                                alt={sh.fName}
                                className="schedTodayClockItemJtNamePP"
                              />
                              <div className="schedTodayClockJtNameRight">
                                <p className="schedTodayClockJtNameUpper">
                                  {sh.fName} {sh.lName}
                                </p>
                                <p className="schedTodayClockJtNameLower">
                                  {sh.jobTitle}
                                </p>
                              </div>
                            </div>

                            <div className="dvStateHighlightedNote dvStateHighlightedNoteSchedToday">
                              <div className={`dvShiftNameRow`}>
                                <img
                                  src={shiftBlock}
                                  className="dvShPP"
                                  alt="Shift"
                                />{" "}
                                <p className={`dvShName userShItemTxtTitle`}>
                                  {sh.type === "shift"
                                    ? "Shift"
                                    : sh.type === "til"
                                    ? "Overtime (time in lieu)"
                                    : "Overtime (paid)"}
                                </p>
                              </div>

                              <div className={`dvShiftNameRow `}>
                                <img
                                  src={rotaClock}
                                  className="dvShPP"
                                  alt={sh.fName}
                                />{" "}
                                <p className="dvShName colour143051_">
                                  {dateStringer.dsToTimeStrip(sh.startDs)} -{" "}
                                  {dateStringer.dsToTimeStrip(sh.endDs)}
                                </p>
                              </div>
                              <div className={`dvShiftNameRow `}>
                                <img
                                  src={rotaCoffee}
                                  className="dvShPP"
                                  alt={sh.fName}
                                />{" "}
                                <p className="dvShName colour143051_">
                                  {dateStringer.formatMinsDurationToHours(
                                    sh.scheduledBreak || 0
                                  )}
                                </p>
                              </div>
                              {sh.tags && sh.tags.length > 0 && (
                                <div className={`dvShiftNameRow `}>
                                  <img
                                    src={rotaTag}
                                    className="dvShPP"
                                    alt="Tags"
                                  />{" "}
                                  <div className="myHoursTagsInline">
                                    {/* {unit.data.tags.map((tag, tagInd) => {
                          return ( */}
                                    <div
                                      className={`shTagInline myRotaTag x23784575 ${
                                        sh.tags.length === 1 ? "noBorder" : ""
                                      }`}
                                    >
                                      {dateStringer.shorten(sh.tags[0], 25)}
                                    </div>
                                    {sh.tags.length > 1 && (
                                      <div
                                        className={`shTagInline myRotaTag x23784575 noBorder`}
                                      >
                                        +{sh.tags.length - 1}
                                      </div>
                                    )}
                                    {/* );
                        })} */}
                                  </div>
                                </div>
                              )}

                              <p
                                className="schedTodayViewShBtn"
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    if (sh.canManage) {
                                      memoVals.setShowEditShiftModal((x) => {
                                        return {
                                          itemID: sh.shiftID || sh.tilID,
                                          itemType: sh.type,
                                          itemIsNew: false,
                                          userID: sh.userID,
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType: sh.type,
                                          unitID: sh.shiftID || sh.tilID,
                                          otherUser:
                                            sh.userID ===
                                            memoVals.checkUserState.userID
                                              ? false
                                              : true,
                                        };
                                      });
                                    }
                                  } else {
                                    memoVals.setShowMyShiftModal((x) => {
                                      return {
                                        unitType: sh.type,
                                        unitID: sh.shiftID || sh.tilID,
                                        otherUser:
                                          sh.userID ===
                                          memoVals.checkUserState.userID
                                            ? false
                                            : true,
                                      };
                                    });
                                  }
                                }}
                              >
                                View{" "}
                                {sh.type === "shift" ? "shift" : "overtime"}{" "}
                                <img
                                  src={popup}
                                  alt="View shift"
                                  className="schedTodayPopup"
                                />
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                {/*  */}
                {(beforeToday || dayStrip === "Today") && (
                  <div className="schedTodayRow">
                    <div
                      className="schedTodayRowUpper"
                      onClick={() => {
                        if (!block) {
                          setBlock(true);

                          setShowDidNotClockOn(!showDidNotClockOn);
                          if (!showDidNotClockOn) {
                            expandDiv(".schedTodayRowLowerNoShow");
                          } else {
                            expandDiv(".schedTodayRowLowerNoShow", true);
                          }
                          setTimeout(() => {
                            setBlock(false);
                          }, 501);
                        }
                      }}
                    >
                      <div className="schedTodayRowUpperTitle">
                        No shows
                        <p
                          className={`schedTodayBlob ${
                            didNotClockOn.length > 0 ? "colourba0000" : "none"
                          }`}
                        >
                          {didNotClockOn.length > 9
                            ? "9+"
                            : didNotClockOn.length}
                        </p>
                      </div>
                      <div className="schedTodayRowUpperRight">
                        <div className="schedTodayImageDiv">
                          {didNotClockOn.map((x, i) => {
                            if (i < 4) {
                              if (!x.profPicUrl) {
                                return (
                                  <div className="schedTodayPPimg">
                                    {x.fName[0]}
                                    {x.lName[0]}
                                  </div>
                                );
                              }
                              return (
                                <img
                                  key={i}
                                  src={
                                    x.profPicUrl || profilePicturePlaceholder
                                  }
                                  alt={x.fName}
                                  className="schedTodayPPimg"
                                />
                              );
                            }
                          })}
                          {didNotClockOn.length > 4 ? (
                            <div className="schedTodayPlusPP">
                              +{didNotClockOn.length - 4}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <img
                          src={allowArr}
                          alt="Open clocked on"
                          className={`schedTodayArrImg ${
                            showDidNotClockOn ? "schedTodayArrImgActive" : ""
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(beforeToday || dayStrip === "Today") && (
                  <div
                    className={`schedTodayRowLowerNoShow ${
                      !showDidNotClockOn && "none"
                    }`}
                  >
                    {didNotClockOn
                      .sort((a, b) => {
                        return a.startTs - b.startTs;
                      })
                      .map((sh, i) => {
                        return (
                          <div className="schedTodayClockItem zoomIn" key={i}>
                            <div className="schedTodayClockItemJtName">
                              <img
                                src={shiftBlockAbs}
                                alt="Clockedon"
                                className="schedTodayMasterIconSolo"
                              />
                              <img
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    memoVals.setShowEditUser((x) => sh.userID);
                                  } else {
                                    memoVals.setSeeUserID((x) => {
                                      return {
                                        userID: sh.userID,
                                        openedFromUserRota: false,
                                      };
                                    });
                                  }
                                }}
                                src={sh.profPicUrl || profilePicturePlaceholder}
                                alt={sh.fName}
                                className="schedTodayClockItemJtNamePP"
                              />
                              <div className="schedTodayClockJtNameRight">
                                <p className="schedTodayClockJtNameUpper">
                                  {sh.fName} {sh.lName}
                                </p>
                                <p className="schedTodayClockJtNameLower">
                                  {sh.jobTitle}
                                </p>
                              </div>
                            </div>

                            <div className="dvStateHighlightedNote dvStateHighlightedNoteSchedToday">
                              <div className={`dvShiftNameRow`}>
                                <img
                                  src={shiftBlock}
                                  className="dvShPP"
                                  alt="Shift"
                                />{" "}
                                <p className={`dvShName userShItemTxtTitle`}>
                                  {sh.type === "shift"
                                    ? "Shift"
                                    : sh.type === "til"
                                    ? "Overtime (time in lieu)"
                                    : "Overtime (paid)"}
                                </p>
                              </div>

                              <div className={`dvShiftNameRow `}>
                                <img
                                  src={rotaCross}
                                  className="dvShPP"
                                  alt={sh.fName}
                                />{" "}
                                <p className="dvShName colour143051_">
                                  {sh.fName} did not clock on
                                </p>
                              </div>
                              <div className={`dvShiftNameRow `}>
                                <img
                                  src={rotaClock}
                                  className="dvShPP"
                                  alt={sh.fName}
                                />{" "}
                                <p className="dvShName colour143051_">
                                  {dateStringer.dsToTimeStrip(sh.startDs)} -{" "}
                                  {dateStringer.dsToTimeStrip(sh.endDs)}
                                </p>
                              </div>
                              <div className={`dvShiftNameRow `}>
                                <img
                                  src={rotaCoffee}
                                  className="dvShPP"
                                  alt={sh.fName}
                                />{" "}
                                <p className="dvShName colour143051_">
                                  {dateStringer.formatMinsDurationToHours(
                                    sh.scheduledBreak || 0
                                  )}
                                </p>
                              </div>
                              {sh.tags && sh.tags.length > 0 && (
                                <div className={`dvShiftNameRow `}>
                                  <img
                                    src={rotaTag}
                                    className="dvShPP"
                                    alt="Tags"
                                  />{" "}
                                  <div className="myHoursTagsInline">
                                    <div
                                      className={`shTagInline myRotaTag x23784575 ${
                                        sh.tags.length === 1 ? "noBorder" : ""
                                      }`}
                                    >
                                      {dateStringer.shorten(sh.tags[0], 25)}
                                    </div>
                                    {sh.tags.length > 1 && (
                                      <div
                                        className={`shTagInline myRotaTag x23784575 noBorder`}
                                      >
                                        +{sh.tags.length - 1}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}

                              <p
                                className="schedTodayViewShBtn"
                                onClick={() => {
                                  if (mgrOfTeam) {
                                    if (sh.canManage) {
                                      memoVals.setShowEditShiftModal((x) => {
                                        return {
                                          itemID: sh.shiftID || sh.tilID,
                                          itemType: sh.type,
                                          itemIsNew: false,
                                          userID: sh.userID,
                                        };
                                      });
                                    } else {
                                      memoVals.setShowMyShiftModal((x) => {
                                        return {
                                          unitType: sh.type,
                                          unitID: sh.shiftID || sh.tilID,
                                          otherUser:
                                            sh.userID ===
                                            memoVals.checkUserState.userID
                                              ? false
                                              : true,
                                        };
                                      });
                                    }
                                  } else {
                                    memoVals.setShowMyShiftModal((x) => {
                                      return {
                                        unitType: sh.type,
                                        unitID: sh.shiftID || sh.tilID,
                                        otherUser:
                                          sh.userID ===
                                          memoVals.checkUserState.userID
                                            ? false
                                            : true,
                                      };
                                    });
                                  }
                                }}
                              >
                                View{" "}
                                {sh.type === "shift" ? "shift" : "overtime"}{" "}
                                <img
                                  src={popup}
                                  alt="View shift"
                                  className="schedTodayPopup"
                                />
                              </p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
                {/*  */}
                <div className="schedTodayRow">
                  <div
                    className="schedTodayRowUpper"
                    onClick={() => {
                      if (!block) {
                        setBlock(true);

                        setShowOnLeave(!showOnLeave);
                        if (!showOnLeave) {
                          expandDiv(".schedTodayRowLowerLeave");
                        } else {
                          expandDiv(".schedTodayRowLowerLeave", true);
                        }
                        setTimeout(() => {
                          setBlock(false);
                        }, 501);
                      }
                    }}
                  >
                    <div className="schedTodayRowUpperTitle">
                      On leave
                      <p
                        className={`schedTodayBlob ${
                          onLeave.length > 0 ? "colourBbcada" : "none"
                        }`}
                      >
                        {onLeave.length > 9 ? "9+" : onLeave.length}
                      </p>
                    </div>
                    <div className="schedTodayRowUpperRight">
                      <div className="schedTodayImageDiv">
                        {onLeave.map((x, i) => {
                          if (i < 4) {
                            if (!x.profPicUrl) {
                              return (
                                <div className="schedTodayPPimg">
                                  {x.fName[0]}
                                  {x.lName[0]}
                                </div>
                              );
                            }
                            return (
                              <img
                                key={i}
                                src={x.profPicUrl || profilePicturePlaceholder}
                                alt={x.fName}
                                className="schedTodayPPimg"
                              />
                            );
                          }
                        })}
                        {onLeave.length > 4 ? (
                          <div className="schedTodayPlusPP">
                            +{onLeave.length - 4}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <img
                        src={allowArr}
                        alt="Open clocked on"
                        className={`schedTodayArrImg ${
                          showOnLeave ? "schedTodayArrImgActive" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`schedTodayRowLowerLeave ${
                    !showOnLeave && "none"
                  }`}
                >
                  {onLeave
                    .sort((a, b) => {
                      return a.startTs - b.startTs;
                    })
                    .map((sh, i) => {
                      return (
                        <div className="schedTodayClockItem zoomIn" key={i}>
                          <div className="schedTodayClockItemJtName">
                            <img
                              src={sh.annLeave ? plane2navy : rotaReq}
                              alt="Clockedon"
                              className="schedTodayMasterIconSolo"
                            />
                            <img
                              onClick={() => {
                                if (mgrOfTeam) {
                                  memoVals.setShowEditUser((x) => sh.userID);
                                } else {
                                  memoVals.setSeeUserID((x) => {
                                    return {
                                      userID: sh.userID,
                                      openedFromUserRota: false,
                                    };
                                  });
                                }
                              }}
                              src={sh.profPicUrl || profilePicturePlaceholder}
                              alt={sh.fName}
                              className="schedTodayClockItemJtNamePP"
                            />
                            <div className="schedTodayClockJtNameRight">
                              <p className="schedTodayClockJtNameUpper">
                                {sh.fName} {sh.lName}
                              </p>
                              <p className="schedTodayClockJtNameLower">
                                {sh.jobTitle}
                              </p>
                            </div>
                          </div>

                          <div className="dvStateHighlightedNote dvStateHighlightedNoteSchedToday">
                            <div className={`dvShiftNameRow`}>
                              <img
                                src={desktopBurger}
                                className="dvShPP"
                                alt="Shift"
                              />{" "}
                              <p className={`dvShName userShItemTxtTitle`}>
                                {sh.typeName}
                              </p>
                            </div>

                            <div className={`dvShiftNameRow `}>
                              <img
                                src={rotaDate}
                                className="dvShPP"
                                alt={sh.fName}
                              />{" "}
                              <p className="dvShName colour143051_">
                                {dateStringer.printedDateFromDs(sh.firstDs)}{" "}
                                {sh.firstDs.substr(0, 11) !==
                                  sh.lastDs.substr(0, 11) && "-"}{" "}
                                {sh.firstDs.substr(0, 11) !==
                                  sh.lastDs.substr(0, 11) &&
                                  dateStringer.printedDateFromDs(
                                    sh.lastDs,
                                    true,
                                    false,
                                    false,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className={`dvShiftNameRow `}>
                              <img
                                src={rotaDur}
                                className="dvShPP"
                                alt={sh.fName}
                              />{" "}
                              <p className="dvShName colour143051_">
                                {dateStringer.formatMinsDurationToHours(
                                  sh.mins
                                )}
                                {sh.halfDay && (
                                  <span className="schedTodayHalfSpan">
                                    Half day
                                  </span>
                                )}
                              </p>
                            </div>

                            {sh.canManage && (
                              <p
                                className="schedTodayViewShBtn"
                                onClick={() => {
                                  // show req mngr here
                                  memoVals.setShowReqMgrModal((x) => sh.reqID);
                                }}
                              >
                                View leave
                                <img
                                  src={popup}
                                  alt="View shift"
                                  className="schedTodayPopup"
                                />
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/*  */}
                <div className="schedTodayRow">
                  <div
                    className="schedTodayRowUpper"
                    onClick={() => {
                      if (!block) {
                        setBlock(true);

                        setShowAbsent(!showAbsent);
                        if (!showAbsent) {
                          expandDiv(".schedTodayRowLowerAbs");
                        } else {
                          expandDiv(".schedTodayRowLowerAbs", true);
                        }
                        setTimeout(() => {
                          setBlock(false);
                        }, 501);
                      }
                    }}
                  >
                    <div className="schedTodayRowUpperTitle">
                      Absent
                      <p
                        className={`schedTodayBlob ${
                          absent.length > 0 ? "colourba0000" : "none"
                        }`}
                      >
                        {absent.length > 9 ? "9+" : absent.length}
                      </p>
                    </div>
                    <div className="schedTodayRowUpperRight">
                      <div className="schedTodayImageDiv">
                        {absent.map((x, i) => {
                          if (i < 4) {
                            if (!x.profPicUrl) {
                              return (
                                <div className="schedTodayPPimg">
                                  {x.fName[0]}
                                  {x.lName[0]}
                                </div>
                              );
                            }
                            return (
                              <img
                                key={i}
                                src={x.profPicUrl || profilePicturePlaceholder}
                                alt={x.fName}
                                className="schedTodayPPimg"
                              />
                            );
                          }
                        })}
                        {absent.length > 4 ? (
                          <div className="schedTodayPlusPP">
                            +{absent.length - 4}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <img
                        src={allowArr}
                        alt="Open clocked on"
                        className={`schedTodayArrImg ${
                          showAbsent ? "schedTodayArrImgActive" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`schedTodayRowLowerAbs ${!showAbsent && "none"}`}
                >
                  {absent
                    .sort((a, b) => {
                      return a.startTs - b.startTs;
                    })
                    .map((sh, i) => {
                      return (
                        <div className="schedTodayClockItem zoomIn" key={i}>
                          <div className="schedTodayClockItemJtName">
                            <img
                              src={rotaCross}
                              alt="Clockedon"
                              className="schedTodayMasterIconSolo"
                            />
                            <img
                              onClick={() => {
                                if (mgrOfTeam) {
                                  memoVals.setShowEditUser((x) => sh.userID);
                                } else {
                                  memoVals.setSeeUserID((x) => {
                                    return {
                                      userID: sh.userID,
                                      openedFromUserRota: false,
                                    };
                                  });
                                }
                              }}
                              src={sh.profPicUrl || profilePicturePlaceholder}
                              alt={sh.fName}
                              className="schedTodayClockItemJtNamePP"
                            />
                            <div className="schedTodayClockJtNameRight">
                              <p className="schedTodayClockJtNameUpper">
                                {sh.fName} {sh.lName}
                              </p>
                              <p className="schedTodayClockJtNameLower">
                                {sh.jobTitle}
                              </p>
                            </div>
                          </div>

                          <div className="dvStateHighlightedNote dvStateHighlightedNoteSchedToday">
                            <div className={`dvShiftNameRow`}>
                              <img
                                src={desktopBurger}
                                className="dvShPP"
                                alt="Shift"
                              />{" "}
                              <p className={`dvShName userShItemTxtTitle`}>
                                {sh.typeName}
                              </p>
                            </div>

                            <div className={`dvShiftNameRow `}>
                              <img
                                src={rotaDate}
                                className="dvShPP"
                                alt={sh.fName}
                              />{" "}
                              <p className="dvShName colour143051_">
                                {dateStringer.printedDateFromDs(sh.firstDs)}{" "}
                                {sh.firstDs.substr(0, 11) !==
                                  sh.lastDs.substr(0, 11) && "-"}{" "}
                                {sh.firstDs.substr(0, 11) !==
                                  sh.lastDs.substr(0, 11) &&
                                  dateStringer.printedDateFromDs(
                                    sh.lastDs,
                                    true,
                                    false,
                                    false,
                                    true
                                  )}
                              </p>
                            </div>
                            <div className={`dvShiftNameRow `}>
                              <img
                                src={rotaDur}
                                className="dvShPP"
                                alt={sh.fName}
                              />{" "}
                              <p className="dvShName colour143051_">
                                {dateStringer.formatMinsDurationToHours(
                                  sh.mins
                                )}
                                {sh.halfDay && (
                                  <span className="schedTodayHalfSpan">
                                    Half day
                                  </span>
                                )}
                              </p>
                            </div>

                            {sh.canManage && (
                              <p
                                className="schedTodayViewShBtn"
                                onClick={() => {
                                  // show req mngr here
                                  memoVals.setShowReqMgrModal((x) => sh.reqID);
                                }}
                              >
                                View absence
                                <img
                                  src={popup}
                                  alt="View shift"
                                  className="schedTodayPopup"
                                />
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/*  */}
              </div>
            )}
          </div>
          <div className="toilFooter">
            <p></p>

            <p
              className="closeToilBtn"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </p>
          </div>
        </div>
      </div>
      {imgPreload}
    </div>
  );
};

export default ScheduledToday;
