import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { DataContext } from "../../../../contexts/DataContext";
import { CalendarContext } from "../../../../contexts/CalendarContext";
import { UserContext } from "../../../../contexts/UserContext";
import dateStringer from "../../../../tools/dateStringer";

import serverURL from "../../../../serverURL";
import { StyleContext } from "../../../../contexts/StyleContext";

const ClockDetails = ({
  isAdmin,
  isSelf,
  unitUserID,
  availableZones,
  zones,
  managesOwnTeams,
  managesOwnTeam,
  editUserState,
  setEditUserState,
  mbrMgrOfTeams,
  setMbrMgrOfTeams,
  allowSelfClockApprove,
  setAllowSelfClockApprove,
}) => {
  const { mob } = useContext(StyleContext);

  const memoVals = useMemo(
    () => ({
      mob,
    }),
    [mob]
  );
  // let generateManagesOwnTeams = managesOwnTeams.map((t) => {
  //   return (
  //     <p className="managesOwnTeamRow" key={t.teamID}>
  //       - {t.teamName}
  //     </p>
  //   );
  // });

  let [showIsSelfNotAdminModal, setShowIsSelfNotAdminModal] = useState(false);

  let detailsContainerRefClock = useRef();
  useEffect(() => {
    if (detailsContainerRefClock.current) {
      detailsContainerRefClock.current.scrollTop = 0;
    }
  }, []);

  let [isAdminModal, setIsAdminModal] = useState({ show: false });

  return (
    <div className="editUserMainBodyContainer">
      <div className="EditUserTeamsContainer" ref={detailsContainerRefClock}>
        {/* new row ---------------- */}
        <div
          className={`editUserRow ${!mob ? "hoverRowColour" : ""} ${
            !isAdmin && isSelf ? "notAdminIsSelf" : ""
          }`}
          onClick={() => {
            if (!isAdmin && isSelf) {
              setShowIsSelfNotAdminModal(true);
            } else {
              setEditUserState({
                clockOnaAllowed: !editUserState.clockOnAllowed,
              });

              axios
                .post(
                  `${serverURL}/toggle-user-allow-clock-on`,
                  {
                    userID: unitUserID,
                    newData: !editUserState.clockOnAllowed,
                    nowTs: new Date().getTime(),
                  },

                  {
                    withCredentials: true,
                    credentials: "include",
                  }
                )
                .then((response) => {
                  setEditUserState({ clockOnAllowed: response.data.data });
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }}
        >
          <p className="editUserEditTitle clockTitleSize">
            Allow clock on / off
          </p>
          <div className="reqClockinValContainer">
            <div className="editUserReqClockOutKnobContainer clockInKnob">
              <div className="knobContainer knobContainerEditUser">
                <div
                  className={`switchKnob ${
                    editUserState.clockOnAllowed ? "knobOn" : "knobOff"
                  }`}
                ></div>
                <div className="knobBg"></div>
              </div>
            </div>
          </div>
        </div>
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div className={`editUserRow`}>
            <p className="editUserEditTitle clockTitleSize">
              Clock terminal pin
            </p>
            <p className="editUserPinVal">{editUserState.pin}</p>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow ${!mob ? "hoverRowColour" : ""} ${
              !isAdmin && isSelf ? "notAdminIsSelf" : ""
            }`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                // console.log(";;", editUserState.editReqClockout,);
                setEditUserState({
                  editReqClockout: !editUserState.editReqClockOut,
                });
                let nowTs = new Date();

                axios
                  .post(
                    `${serverURL}/edit-user-clock-on-bool`,
                    {
                      userID: unitUserID,
                      newData: !editUserState.editReqClockout,
                      nowTs: nowTs.getTime(),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      editReqClockout: response.data.reqClockOn,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <p className="editUserEditTitle clockTitleSize">
              Require clock on / off
            </p>
            <div className="reqClockinValContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.editReqClockout ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow ${!mob ? "hoverRowColour" : ""} ${
              !isAdmin && isSelf ? "notAdminIsSelf" : ""
            }`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                // console.log(";;", editUserState.editReqClockout,);
                setEditUserState({
                  enableGPS: !editUserState.enableGPS,
                });
                let nowTs = new Date();

                axios
                  .post(
                    `${serverURL}/toggle-user-enable-gps`,
                    {
                      userID: unitUserID,
                      data: !editUserState.enableGPS,
                      nowTs: nowTs.getTime(),
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      enableGPS: response.data.enableGPS,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <p className="editUserEditTitle clockTitleSize">
              Request GPS when clocking on / off
            </p>
            <div className="reqClockinValContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.enableGPS ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}

        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow approveClockEditUserRow ${
              !mob ? "hoverRowColour" : ""
            } ${!isAdmin && isSelf ? "notAdminIsSelf" : ""}`}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                setEditUserState({
                  autoApproveClock: !editUserState.autoApproveClock,
                });

                axios
                  .post(
                    `${serverURL}/toggle-auto-approve-clock`,
                    {
                      userID: unitUserID,
                      data: !editUserState.autoApproveClock,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({ autoApproveClock: response.data.data });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div
              className={`autoApproveClockDiv ${
                !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
              }`}
            >
              <p className="editUserEditTitle clockTitleSize">
                Auto-approve clock on / off
              </p>
              <p className="autoApproveExplain">
                If selected, {editUserState.editFName}
                {editUserState.editFName &&
                editUserState.editFName[editUserState.editFName.length - 1] ===
                  "s"
                  ? ""
                  : "'s"}{" "}
                clock cards will be automatically approved when{" "}
                {editUserState.editFName} clocks off, creating a shift that{" "}
                {editUserState.editFName} will be paid for based on the clocked
                times.
              </p>
            </div>
            <div className="reqClockinValContainer autoApproveClockKnobContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.autoApproveClock ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed && managesOwnTeam ? (
          <div
            className={`editUserRow approveClockEditUserRow ${
              !isAdmin && isSelf ? "notAdminIsSelf" : ""
            } approveOwnClocksRow`}
          >
            <div
              className={`autoApproveClockDiv ${
                !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
              }`}
            >
              <p className="editUserEditTitle">
                Allow {editUserState.editFName} to approve and amend their own
                clock cards
              </p>{" "}
              <div className="amendOwnClocksRow">
                {editUserState.editFName} is both a member and manager of the
                following team{mbrMgrOfTeams?.length === 1 ? "" : "s"}. Use the
                settings below to configure whether they can approve and amend
                their own clock cards
                {mbrMgrOfTeams?.length > 1 ? " within each team." : "."}{" "}
                {/* {generateManagesOwnTeams} */}
                <br /> <br />
                {mbrMgrOfTeams?.length > 1 ? "For each team, i" : "I"}f
                unselected, {dateStringer.possession(editUserState.editFName)}{" "}
                clock cards will need to be approved by another manager of{" "}
                {mbrMgrOfTeams?.length > 1 ? "the" : "their"}
                team (or an employee with FlowRota administrator permissions).
              </div>
              {mbrMgrOfTeams?.map((x, i) => {
                return (
                  <div
                    className={`mbrMgrOfClockRow ${
                      i === 0 ? "mbrMgrOfClockRowFirst" : ""
                    }`}
                    key={x.teamID}
                    onClick={() => {
                      if (!isAdmin && isSelf) {
                        setShowIsSelfNotAdminModal(true);
                      } else {
                        //axios here
                        if (editUserState.isAdmin) {
                          setIsAdminModal({
                            show: true,
                            name: editUserState.editFName,
                          });
                        } else {
                          axios
                            .post(
                              `${serverURL}/toggle-allow-self-clock-approve`,
                              {
                                userID: unitUserID,
                                data: allowSelfClockApprove?.includes(x.teamID)
                                  ? false
                                  : true,
                                teamID: x.teamID,
                              },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setAllowSelfClockApprove(response.data.data);
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }
                      }
                    }}
                  >
                    <p className="mbrMgrOfClockRowTitle">{x.teamName}</p>{" "}
                    {/*  KNOB BEGIN */}
                    <div className="editUserReqClockOutKnobContainer clockInKnob selfApproveKnob">
                      <div className="knobContainer knobContainerEditUser allowSelfAppClockKnobContainer">
                        <div
                          className={`switchKnob ${
                            allowSelfClockApprove?.includes(x.teamID) ||
                            editUserState.isAdmin
                              ? "knobOn"
                              : "knobOff"
                          }`}
                        ></div>
                        <div className="knobBg"></div>
                      </div>
                    </div>
                    {/*  KNOB END */}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
        {/*  END OF rowv ----------- */}
        {/* new row ---------------- */}
        {editUserState.clockOnAllowed ? (
          <div
            className={`editUserRow approveClockEditUserRow ${
              !mob ? "hoverRowColour" : ""
            } ${
              !memoVals.mob
                ? "approveClockEditUserRowDesktop"
                : "payClockedRowMob"
            } ${!isAdmin && isSelf ? "notAdminIsSelf" : ""} `}
            onClick={() => {
              if (!isAdmin && isSelf) {
                setShowIsSelfNotAdminModal(true);
              } else {
                setEditUserState({ payClocked: !editUserState.payClocked });

                axios
                  .post(
                    `${serverURL}/toggle-user-pay-clocked`,
                    {
                      userID: unitUserID,
                      newData: !editUserState.payClocked,
                    },

                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    setEditUserState({
                      payClocked: response.data.data,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }}
          >
            <div
              className={`autoApproveClockDiv ${
                !memoVals.mob ? "desktopAutoApproveClockDiv" : ""
              } payClockTimeOnlyDiv`}
            >
              <p className="editUserEditTitle payClockTimeOnlyTitle clockTitleSize">
                Pay clocked time only
              </p>
              <p className="autoApproveExplain">
                If selected, {editUserState.editFName} will be paid for the time
                that they have been clocked on for. Otherwise,{" "}
                {editUserState.editFName} will be paid for the shift's scheduled
                times, regardless of clock on or off times. If{" "}
                {editUserState.editFName} has clocked on without being scheduled
                for a shift, they will be paid for the clocked time by default.
              </p>
            </div>
            <div className="reqClockinValContainer autoApproveClockKnobContainer">
              {/*  KNOB BEGIN */}
              <div className="editUserReqClockOutKnobContainer clockInKnob">
                <div className="knobContainer knobContainerEditUser">
                  <div
                    className={`switchKnob ${
                      editUserState.payClocked ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
              </div>
              {/*  KNOB END */}
            </div>
          </div>
        ) : (
          ""
        )}
        {/*  END OF rowv ----------- */}
      </div>

      {/* ------- IS NOT ADMIN, IS SELF MODAL */}
      <div
        className={`${
          showIsSelfNotAdminModal ? "invalidClockShiftModalUnderlay" : "none"
        }`}
        onClick={(e) => {
          e.stopPropagation();

          setShowIsSelfNotAdminModal(false);
        }}
      >
        <div
          className="invalidClockShiftModalBody"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="invalidClockShiftTxt">
            Sorry, these settings are only editable by system administrators or
            another manager of your primary team.
          </p>

          <p
            className="closeInvalidShiftModalBtn"
            onClick={() => {
              setShowIsSelfNotAdminModal(false);
            }}
          >
            Understood
          </p>
        </div>
      </div>
      {/* -------- END OF IS NOT ADMIN, IS SELF MODAL */}

      {isAdminModal.show ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {isAdminModal.name} is a FlowRota admin with the ability to
              approve any requests, shift changes, and clock cards, including
              their own.
              <br />
              <br />
              You cannot disable {isAdminModal.name} from approving their own
              items unless their admin permissions are removed in the Admin
              panel.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setIsAdminModal({ show: false });
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ClockDetails;
