import React, {
  useState,
  useContext,
  useEffect,
  useReducer,
  useMemo,
  useRef,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";
import { UserContext } from "../../contexts/UserContext";
import { StyleContext } from "../../contexts/StyleContext";
import { DataContext } from "../../contexts/DataContext";

import { NavLink } from "react-router-dom";
import imageCompression from "browser-image-compression";

import serverURL from "../../serverURL";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import MgrAccountNavbar from "./MgrAccountNavbar";

import "../../styles/settings.css";
import horizontalBalls from "../../img/loaders/horizontalBalls.svg";

import close from "../../img/modals/close.svg";
import whiteDown from "../../img/general/whiteDown.svg";
import popup3 from "../../img/general/popup3.svg";
import popup2 from "../../img/general/popup2.svg";
import popup from "../../img/general/popup.svg";

import liveChat from "../../img/general/liveChat.svg";

import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";
import editUserEdit1 from "../../img/general/editUserEdit1.svg";
import userIcon from "../../img/general/userIcon.svg";
import teamIcon from "../../img/general/teamIcon.svg";
import teamIcon2 from "../../img/general/teamIcon2.svg";
import editGrey from "../../img/general/editGrey.svg";
import signout from "../../img/general/signout.svg";
import install from "../../img/general/install.svg";
import adminBurgerMob from "../../img/general/adminBurgerMob.svg";

// content components

import TopBar from "../../components/TopBar";
import NotifSettings from "./NotifSettings";

import dateStringer from "../../tools/dateStringer";

import MyReqsDataBox from "../requests/MyReqsDataBox";

import HoursDataBox from "../calendar/shifts/HoursDataBox";
import appURL from "../../appURL";

const AccountSettings = ({ notifications, allowances }) => {
  // check the user is valid - this fct will update the UserContext
  CheckUser();
  const { checkUserState, currencySymbol, setCheckUserState } =
    useContext(UserContext);

  const { mob, full, fullButNarrow } = useContext(StyleContext);
  const {
    settingsAllowances,
    setSettingsAlowances,
    setPage,
    setShowContact,
    setSeeUserID,
    showReqStats,
    setShowReqStats,
    setShowViewUserTeamModal,
    updateAccount,
    setUpdateAccount,
    showStats,
    setShowStats,
    setIndicate,
    setSideBoxData,
    setUpdateHeaderClocks,
    updateHeaderClocks,
    device,
    setShowMyDocsModal,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      checkUserState,
      currencySymbol,
      mob,
      full,
      fullButNarrow,
      settingsAllowances,
      setSettingsAlowances,
      setPage,
      setShowContact,
      setSeeUserID,
      showReqStats,
      setShowReqStats,
      setShowViewUserTeamModal,
      updateAccount,
      showStats,
      setShowStats,
      setIndicate,
      setSideBoxData,
      setUpdateHeaderClocks,
      updateHeaderClocks,
      setUpdateAccount,
      setShowMyDocsModal,
      device,
    }),
    [
      checkUserState,
      currencySymbol,
      mob,
      full,
      fullButNarrow,
      settingsAllowances,
      setSettingsAlowances,
      setPage,
      setShowContact,
      setSeeUserID,
      showReqStats,
      setShowReqStats,
      setShowViewUserTeamModal,
      updateAccount,
      showStats,
      setShowStats,
      setIndicate,
      setSideBoxData,
      setUpdateHeaderClocks,
      updateHeaderClocks,
      setUpdateAccount,
      setShowMyDocsModal,
      device,
    ]
  );

  let [selectedYear, setSelectedYear] = useState(
    memoVals.checkUserState.currClientYear || new Date().getFullYear()
  );

  let [emailUpdated, setEmailUpdated] = useState(false);
  let [availableYears, setAvailableYears] = useState([]);
  let [availableToil, setAvailableToil] = useState(0);

  let [hourlyRateHistoryExists, setHourlyRateHistoryExists] = useState(false);
  let [mpwHistoryExists, setMpwHistoryExists] = useState(false);

  let [leaveChartYears, setLeaveChartYears] = useState([]);
  let [selectedLeaveChartYear, setSelectedLeaveChartYear] = useState("");
  let [leaveChartDataLoading, setLeaveChartDataLoading] = useState(true);
  let [leaveChartData, setLeaveChartData] = useState({
    taken: 0,
    booked: 0,
    remaining: 0,
    takenVal: 0,
    bookedVal: 0,
    remainingVal: 0,
    daysOrHours: "days",
  });
  const cancelSourceLeaveChart = axios.CancelToken.source();

  useEffect(() => {
    setLeaveChartDataLoading(true);
    axios
      .post(
        `${serverURL}/ann-leave-chart-data`,
        { year: selectedLeaveChartYear },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSourceLeaveChart.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setLeaveChartData(response.data);
          setTimeout(() => {
            setLeaveChartDataLoading(false);
          }, 1400);
        }
      });

    return () => {
      cancelSourceLeaveChart.cancel("Component unmounted");
    };
  }, [selectedLeaveChartYear]);
  let [selectedYearSideBox, setSelectedYearSideBox] = useState(
    memoVals.checkUserState.currClientYear || new Date().getFullYear()
  );
  useEffect(() => {
    if (memoVals.settingsAllowances !== "profile") {
      memoVals.setSettingsAlowances((x) => "profile");
    }

    if (allowances) {
      memoVals.setShowReqStats((x) => true);
    }
  }, []);

  let [my, setMy] = useState(
    `${
      new Date().getMonth() < 10 ? "0" : ""
    }${new Date().getMonth()}${JSON.stringify(new Date().getFullYear()).substr(
      2,
      2
    )}`
  );
  let renderStatsModal = () => {
    if (memoVals.showStats) {
      return (
        <div
          className="statsUnderlay"
          onClick={() => {
            memoVals.setShowStats((x) => false);
          }}
        >
          <div
            className="hoursStatsModalBox"
            onClick={(e) => e.stopPropagation()}
          >
            <HoursDataBox modal={true} my={my} setMy={setMy} />
          </div>
        </div>
      );
    }
  };

  let [proPicLoading, setProPicLoading] = useState(false);

  let [logoutLoading, setLogoutLoading] = useState(false);

  let [notYetVerif, setNotYetVerif] = useState(false);

  let [loading, setLoading] = useState(true);
  let [dataLoaded, setDataLoaded] = useState(false);
  let [imagesLoaded, setImagesLoaded] = useState(0);

  let imgPreload = (
    <div className="imagesHidden">
      <img
        src={close}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={liveChat}
        alt="1"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={adminBurgerMob}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={whiteDown}
        alt="2"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup3}
        alt="3"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={popup2}
        alt="4"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={profilePicturePlaceholder}
        alt="5"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editUserEdit1}
        alt="6"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={userIcon}
        alt="7"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon}
        alt="8"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={teamIcon2}
        alt="9"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={editGrey}
        alt="10"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={signout}
        alt="11"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
      <img
        src={install}
        alt="12"
        className=""
        onLoad={() => {
          setImagesLoaded(imagesLoaded + 1);
        }}
      />
    </div>
  );

  useEffect(() => {
    console.log({ dataLoaded, imagesLoaded });
    if (dataLoaded && imagesLoaded === 14) {
      setLoading(false);
    }
  }, [dataLoaded, imagesLoaded]);

  let [payRoll, setPayRoll] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      startDs: "",
      endDs: "",
      totEarned: 0,
      salary: 0,
      isSalaried: false,
      deductions: 0,
      netSalary: 0,
      overtimeIncome: 0,
    }
  );
  let [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      profPicUrl: "",
      allowSwaps: false,
      email: "",
      mob: "",
      teams: [],
      mgrOf: [],
      pushNotifs: false,
      smsNotifs: false,
      emailNotifs: 1,
      disabledNotifsEmail: [],
      disabledNotifsPush: [],
      yourManagers: [],
      isMgr: false,
      profPicUrl: "",
      fName: "",
      lName: "",
      primaryTeamName: "",
      totalMins: 0,
      regularMins: 0,
      overtimeMins: 0,
      tilEarned: 0,
      tilTaken: 0,
      absenceMins: 0,
      leaveMins: 0,
      totalEarned: 0,
      startDs: "",
      endDs: "",
      currYr: "",
      loading: true,
      dataLoading: true,
      salary: 0,
      salaried: false,
      hourlyRate: 0,
      overtimeRates: [],
      dailyRates: [],
      workingPattern: "",
      mpw: 0,
      fixedSchedule: [],
      fixedVariable: {},
      absenceFromShiftsAndTils: 0,
      absenceFromPeriods: 0,
      lateCount: 0,
      lateMins: 0,
      paidLeave: 0,
      unpaidLeave: 0,
      pin: 0,
      toilLimit: 0,
      reminderMins: 60,
    }
  );

  useEffect(() => {
    if (notifications) {
      memoVals.setSettingsAlowances((x) => "settings");
    }
  }, []);

  let myAccRef = useRef();
  useEffect(() => {
    if (myAccRef && myAccRef.current) {
      myAccRef.current.scrollTop = 0;
    }
  }, [memoVals.settingsAllowances]);

  // useEffect(() => {
  //   if (localStorage.getItem("emailVerified")) {
  //     memoVals.setIndicate((x) => {
  //       return {
  //         show: true,
  //         message: "Email verified",
  //         colour: "green",
  //         duration: 6000,
  //       };
  //     });
  //     localStorage.setItem("emailVerified", false);
  //   }
  // }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params && params.contact) {
      window.history.pushState({}, document.title, window.location.pathname);

      setTimeout(() => {
        memoVals.setShowContact((x) => true);
      }, 800);
    }
    if (params && params.verify && params.userID) {
      window.history.pushState({}, document.title, window.location.pathname);

      axios
        .post(
          `${serverURL}/verify-email`,
          {
            userID: dateStringer.cleanParam(params.userID),
            verifCode: dateStringer.cleanParam(params.verify),
          },

          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          if (response.data.message === "already verified") {
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Email already verified",
                colour: "orange",
                duration: 6000,
              };
            });
          }
          if (response.data.message === "invalid verification code") {
            memoVals.setIndicate((x) => {
              return {
                show: true,
                message: "Verification failed",
                colour: "red",
                duration: 6000,
              };
            });
          }
          if (response.data.message === "success") {
            window.location.href = `${appURL}/account?settings=true`;
            memoVals.setUpdateHeaderClocks(
              (x) => !memoVals.memoVals.updateHeaderClocks
            );
            // localStorage.setItem("emailVerified", true);

            setState({ emailVerified: true });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (params.settings) {
        memoVals.setSettingsAlowances((x) => "settings");
        window.history.pushState({}, document.title, window.location.pathname);
      }
    }
  }, []);
  // useEffect9
  let [hourlyRateTimeline, setHourlyRateTimeline] = useState([]);
  let [showHourlyRateTimeline, setShowHourlyRateTimeline] = useState(false);

  let [mpwTimeline, setMpwTimeline] = useState([]);
  let [showMpwTimeline, setShowMpwTimeline] = useState(false);
  let [sureKill, setSureKill] = useState({ show: false });
  let shortenPTeamName = (val) => {
    if (val && val.length > 22 && memoVals.mob) {
      return `${val.substr(0, 19)}...`;
    } else {
      return val;
    }
  };

  let [currYr, setCurrYr] = useState(new Date().getFullYear());

  let [showEditProPicModal, setShowEditProPicModal] = useState(false);

  let [weeklyEarnings, setWeeklyEarnings] = useState("month");
  let [tokens, setTokens] = useState([]);
  let [netPay, setNetPay] = useState(0);

  let [showEarnings, setShowEarnings] = useState(false);

  useEffect(() => {
    const cancelSource1 = axios.CancelToken.source();
    const cancelSource2 = axios.CancelToken.source();

    memoVals.setPage((x) => "profile");
    let currentYear = new Date().getFullYear();

    axios
      .post(
        `${serverURL}/get-my-account-data`,
        { nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()) },

        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setShowEarnings(response.data.showEarnings);
          console.log("GETTING ACCOUNT DATA SUCCESS");
          setHourlyRateHistoryExists(response.data.hourlyRateHistoryExists);
          setMpwHistoryExists(response.data.mpwHistoryExists);
          setWeeklyEarnings(response.data.weeklyEarnings ? "week" : "month");
          setTokens(response.data.tokens);

          let pr = response.data.payRoll;
          setLeaveChartYears(response.data.leaveChartYears);
          if (
            response.data.leaveChartYears &&
            Array.isArray(response.data.leaveChartYears)
          ) {
            setSelectedLeaveChartYear(response.data.leaveChartYears[1]);
          }
          setPayRoll({
            startDs: response.data.payRollStart,
            endDs: response.data.payRollEnd,
            totEarned: (
              pr.paidShiftCost +
              pr.paidOvertimeCost +
              pr.paidLeaveCost +
              pr.paidAbsenceCost
            ).toFixed(2),
            isSalaried: response.data.isSalaried,
            salaryTotal: parseInt(response.data.salaryTotal).toFixed(2),

            deductions: parseInt(response.data.payRoll.unpaidSalary).toFixed(2),
            overtimeIncome: parseInt(
              response.data.payRoll.paidOvertimeCost
            ).toFixed(2),
            leave: parseInt(response.data.payRoll.paidLeaveCost).toFixed(2),
            absence: parseInt(response.data.payRoll.paidAbsenceCost).toFixed(2),
            shifts: parseInt(response.data.payRoll.paidShiftCost).toFixed(2),
          });

          let minsPerWeek = 0;
          if (response.data.workingPattern === "fixedScheduled") {
            minsPerWeek += response.data.fixedSchedule[0].monDuration;
            minsPerWeek += response.data.fixedSchedule[0].tuesDuration;
            minsPerWeek += response.data.fixedSchedule[0].wedDuration;
            minsPerWeek += response.data.fixedSchedule[0].thursDuration;
            minsPerWeek += response.data.fixedSchedule[0].friDuration;
            minsPerWeek += response.data.fixedSchedule[0].satDuration;
            minsPerWeek += response.data.fixedSchedule[0].sunDuration;
          }

          if (response.data.workingPattern === "fixedVariable") {
            minsPerWeek += response.data.fixedVariable[0].hours * 60;
            minsPerWeek += response.data.fixedVariable[0].mins;
          }

          let yourTeams = [];
          let yourPTeam = [];
          response.data.teams.forEach((team) => {
            if (team.primary) {
              yourPTeam.push(team);
            } else {
              yourTeams.push(team);
            }
          });

          let mgrOf = [];
          let yourPMgrOf = [];
          response.data.mgrOf.forEach((team) => {
            if (team.primary) {
              yourPMgrOf.push(team);
            } else {
              mgrOf.push(team);
            }
          });

          yourTeams.sort((a, b) => {
            return a.teamName.localeCompare(b.teamName);
          });
          mgrOf.sort((a, b) => {
            return a.teamName.localeCompare(b.teamName);
          });

          setState({
            profPicUrl: response.data.profPicUrl,
            jobTitle: response.data.jobTitle,
            allowSwaps: response.data.allowSwaps,
            email: response.data.email,
            mob: response.data.mob,
            teams: [...yourPTeam, ...yourTeams],
            mgrOf: [...yourPMgrOf, ...mgrOf],
            emailNotifs: response.data.emailNotifs,
            pushNotifs: response.data.pushNotifs,
            smsNotifs: response.data.smsNotifs,
            disabledNotifsPush: response.data.disabledNotifsPush || [],
            disabledNotifsEmail: response.data.disabledNotifsEmail || [],
            yourManagers: response.data.yourManagers,
            // isMgr: false,
            isMgr: response.data.isMgr,
            profPicUrl: response.data.profPicUrl,
            fName: response.data.fName,
            lName: response.data.lName,
            // primaryTeamName: "Underwriting Management Team",
            currYr: response.data.currYr,
            primaryTeamName: response.data.primaryTeamName,
            primaryTeamID: response.data.primaryTeamID,
            loading: false,
            salary: response.data.salary,
            salaried: response.data.salaried,
            hourlyRate: response.data.hourlyRate,
            overtimeRates: response.data.overtimeRates,
            dailyRates: response.data.dailyRates,
            workingPattern: response.data.workingPattern,
            fixedSchedule: response.data.fixedSchedule,
            fixedVariable: response.data.fixedVariable,
            mpw: minsPerWeek,
            pin: response.data.pin,
            reminderMins: response.data.reminderMins,
          });

          if (!window.location.href.includes("confirm-email")) {
            setState({ emailVerified: response.data.emailVerified });
          }

          let arr = [];
          currentYear = response.data.currYr.substr(1, 4);
          setCurrYr(parseInt(currentYear));

          response.data.availableYears.forEach((yr) => {
            let val = `Y${yr}${response.data.currYr.substr(5, 12)}`;

            if (yr === response.data.currYr.substr(1, 4)) {
              val = dateStringer.createStringFromTimestamp(
                new Date().getTime()
              );
            }
            arr.push(val);
          });

          setAvailableYears(arr);
          setSelectedYear(response.data.currYr.substr(1, 4));

          if (dataLoaded) {
            setDataLoaded(false);
          }
          setDataLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // axios
    //   .post(
    //     `${serverURL}/get-my-hours-data`,
    //     {
    //       ytdNowDs: dateStringer.createStringFromTimestamp(
    //         new Date().getTime()
    //       ),
    //       year: parseInt(currentYear),
    //     },

    //     {
    //       withCredentials: true,
    //       credentials: "include",
    //       cancelToken: cancelSource2.token,
    //     }
    //   )
    //   .then((response) => {
    //     if (response.data.message === "success") {
    //       setAvailableToil(response.data.availableToil);
    //       setNetPay(response.data.data.netPay);
    //       setState({
    //         totalMins: response.data.data.totalMins || 0,
    //         regularMins: response.data.data.regularMins || 0,
    //         overtimeMins: response.data.data.overtimeMins || 0,
    //         tilEarned: response.data.data.tilEarned || 0,
    //         tilTaken: response.data.data.tilTaken || 0,
    //         absenceMins: response.data.data.absenceMins || 0,
    //         paidAbsence: response.data.data.paidAbsence || 0,
    //         unpaidAbsence: response.data.data.unpaidAbsence || 0,
    //         lateCount: response.data.data.lateCount || 0,
    //         lateMins: response.data.data.lateMins || 0,
    //         paidLeave: response.data.data.paidLeave || 0,
    //         unpaidLeave: response.data.data.unpaidLeave || 0,

    //         absenceFromShiftsAndTils:
    //           response.data.data.absenceFromShiftsAndTils || 0,
    //         leaveMins: response.data.data.leaveMins || 0,
    //         totalEarned: response.data.data.totalEarned || 0,
    //         toilLimit: response.data.toilWeeksLimit || 0,
    //         absenceFromPeriods: response.data.data.absenceFromPeriods || 0,
    //         startDs: response.data.startTs,
    //         endDs: response.data.endTs,
    //         dataLoading: false,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    return () => {
      cancelSource1.cancel("Component unmounted");
      cancelSource2.cancel("Component unmounted");
    };
  }, [memoVals.updateAccount]);

  hourlyRateTimeline.sort((a, b) => {
    return b.startTs - a.startTs;
  });

  let generateSalaryTimeline = hourlyRateTimeline.map((obj) => {
    return (
      <div className="mySalTimelineObj">
        <div className="mySalTimelineRow">
          <div className="mySaltTimeTitleAndValue">
            <p className="mySalTimelineTitle">From</p>
            <p className="mySalTimelineVal">
              {dateStringer.printedDateWithYrFromDWithoutDay(obj.startDate)}
            </p>
          </div>

          <div className="mySaltTimeTitleAndValue textRight">
            <p className="mySalTimelineTitle">Until</p>
            <p className="mySalTimelineVal">
              {dateStringer.printedDateWithYrFromDWithoutDay(obj.endDate)}
            </p>
          </div>
        </div>

        <div className="mySalTimelineRow secondMySalTimelineRow">
          <div className="mySaltTimeTitleAndValue">
            <p className="mySalTimelineTitle">Pay basis</p>
            <p className="mySalTimelineVal2">
              {obj.salaried ? "Salaried" : "Hourly"}
            </p>
          </div>

          <div className="mySaltTimeTitleAndValue textRight">
            <p className="mySalTimelineTitle">
              {" "}
              {obj.salaried ? "Salary" : "Hourly rate"}
            </p>
            <p className="mySalTimelineVal2">
              {obj.salaried
                ? `${memoVals.currencySymbol}${obj.salary}`
                : `${memoVals.currencySymbol}${obj.hourlyRate}`}
            </p>
          </div>
        </div>
      </div>
    );
  });

  let generateMpwTimeline = mpwTimeline.map((obj) => {
    return (
      <div className="mySalTimelineObj">
        <div className="mySalTimelineRow">
          <div className="mySaltTimeTitleAndValue">
            <p className="mySalTimelineTitle">From</p>
            <p className="mySalTimelineVal">
              {dateStringer.printedDateWithYrFromDWithoutDay(obj.start)}
            </p>
          </div>

          <div className="mySaltTimeTitleAndValue textRight">
            <p className="mySalTimelineTitle">Until</p>
            <p className="mySalTimelineVal">
              {dateStringer.printedDateWithYrFromDWithoutDay(obj.end)}
            </p>
          </div>
        </div>

        <div className="mySalTimelineRow secondMySalTimelineRow">
          <div className="mySaltTimeTitleAndValue">
            <p className="mySalTimelineTitle"></p>
            <p className="mySalTimelineVal2"></p>
          </div>

          <div className="mySaltTimeTitleAndValue textRight">
            <p className="mySalTimelineTitle">Hours per week</p>
            <p className="mySalTimelineVal2">
              {dateStringer.formatMinsDurationToHours(obj.mpw)}
            </p>
          </div>
        </div>
      </div>
    );
  });
  // useEffect(() => {
  //   if (state.startDs) {
  //     const cancelSource1 = axios.CancelToken.source();

  //     let dateObj = new Date(
  //       dateStringer.createTimestampFromString(selectedYear)
  //     );
  //     dateObj.setFullYear(dateObj.getFullYear() + 1);
  //     dateObj.setDate(dateObj.getDate() - 1);
  //     dateObj.setHours(0, 0, 0, 0);
  //     console.log({ selectedYear });
  //     axios
  //       .post(
  //         `${serverURL}/get-my-hours-data`,
  //         {
  //           year: parseInt(selectedYear),
  //           ytdNowDs:
  //             currYr === parseInt(selectedYear)
  //               ? dateStringer.createStringFromTimestamp(new Date().getTime())
  //               : "",

  //         },

  //         {
  //           withCredentials: true,
  //           credentials: "include",
  //           cancelToken: cancelSource1.token,
  //         }
  //       )
  //       .then((response) => {
  //         if (response.data.message === "success") {
  //           setAvailableToil(response.data.availableToil);
  //           setNetPay(response.data.data.netPay);
  //           setState({
  //             totalMins: response.data.data.totalMins || 0,
  //             regularMins: response.data.data.regularMins || 0,
  //             overtimeMins: response.data.data.overtimeMins || 0,
  //             tilEarned: response.data.data.tilEarned || 0,
  //             tilTaken: response.data.data.tilTaken || 0,
  //             absenceMins: response.data.data.absenceMins || 0,
  //             paidAbsence: response.data.data.paidAbsence || 0,
  //             unpaidAbsence: response.data.data.unpaidAbsence || 0,
  //             lateCount: response.data.data.lateCount || 0,
  //             lateMins: response.data.data.lateMins || 0,
  //             paidLeave: response.data.data.paidLeave || 0,
  //             unpaidLeave: response.data.data.unpaidLeave || 0,
  //             absenceFromShiftsAndTils:
  //               response.data.data.absenceFromShiftsAndTils || 0,
  //             leaveMins: response.data.data.leaveMins || 0,
  //             totalEarned: response.data.data.totalEarned || 0,
  //             absenceFromPeriods: response.data.data.absenceFromPeriods || 0,
  //             startDs: response.data.startTs,
  //             endDs: response.data.endTs,
  //             dataLoading: false,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //     return () => {
  //       cancelSource1.cancel("Component unmounted");
  //     };
  //   }
  // }, [selectedYear]);

  let [toggleEditEmail, setToggleEditEmail] = useState(false);
  let [newEmail, setNewEmail] = useState("");
  let [emailAlreadyExists, setEmailAlreadyExists] = useState(false);

  // settings: select between Settings and Allowances

  // let toggleBetweenAllowsAndSettings = () => {
  //   if (memoVals.settingsAllowances === "allowances") {
  //     return <AccountSettingsAllowances />;
  //   } else if (memoVals.settingsAllowances === "settings") {
  //     return <AccountSettingsSettings />;
  //   }
  // };

  let generateYourManagers = useMemo(() => {
    return state.yourManagers.map((t) => {
      return (
        <div
          className={`accountSettingsTeamItem `}
          onClick={() => {
            memoVals.setSeeUserID((x) => {
              return {
                userID: t.userID,
                openedFromUserRota: false,
                openedFromMyAAccount: true,
              };
            });
          }}
        >
          <img src={userIcon} alt="User" className="accUserIcon" />
          {`${t.fName} ${t.lName}`}
          {/* {dateStringer.shorten(`${t.fName} ${t.lName}`, mob ? 26 : 50)} */}
          <img src={popup3} alt="Team" className="accountTeamPopUpImg" />
        </div>
      );
    });
  }, [state.yourManagers]);
  let formatJt = (val) => {
    if (memoVals.mob && val && val.length > 24) {
      return `${val.substr(0, 21)}...`;
    } else {
      return val;
    }
  };

  let generateTeams = useMemo(() => {
    return state.teams.map((t) => {
      return (
        <div
          className={`accountSettingsTeamItem ${
            t.primary ? "primaryAccTeam" : ""
          }`}
          onClick={() => {
            memoVals.setShowViewUserTeamModal((x) => t.teamID);
          }}
        >
          <img src={teamIcon2} alt="User" className="accTeamIcon" />

          {dateStringer.shorten(
            `${t.teamName}`,
            memoVals.mob || memoVals.fullButNarrow ? 26 : 50
          )}
          <img src={popup3} alt="Team" className="accountTeamPopUpImg" />
        </div>
      );
    });
  }, [state.teams, memoVals.mob]);

  let generateYears = useMemo(() => {
    return availableYears.map((item) => {
      return <option value={item.substr(1, 4)}>{item.substr(1, 4)}</option>;
    });
  }, [availableYears]);

  let generateMgrOf = useMemo(() => {
    return state.mgrOf.map((t) => {
      return (
        <div
          className={`accountSettingsTeamItem ${
            t.primary ? "primaryAccTeam" : ""
          }`}
          onClick={() => {
            memoVals.setShowViewUserTeamModal((x) => t.teamID);
          }}
        >
          <img src={teamIcon} alt="User" className="accTeamIcon" />
          {dateStringer.shorten(
            `${t.teamName}`,
            memoVals.mob || memoVals.fullButNarrow ? 26 : 50
          )}
          <img src={popup3} alt="Team" className="accountTeamPopUpImg" />
        </div>
      );
    });
  }, [state.mgrOf, memoVals.mob, memoVals.fullButNarrow]);

  let [verifAlreadySent, setVerifAlreadySent] = useState(false);
  let [sureSignout, setSureSignout] = useState(false);

  // master

  return (
    <div className={`settingsBg `}>
      <div className={` ${!memoVals.mob ? "pageLeft240pxDesktop" : "whiteBg"}`}>
        {!memoVals.mob ? <TopBar /> : ""}

        <div
          className={`shiftsHeaderContentContainer myAccountHeader ${
            memoVals.mob ? "noBorderBottom" : ""
          }`}
        >
          <div
            className={`shiftsHeaderLeft ${
              memoVals.mob ? "mobMyAccHeader" : "myAccHeaderCorrector"
            }`}
          >
            <div
              className={`shiftHeaderLeftTitle myHoursTitle
              ${
                !memoVals.mob
                  ? "headerTitleSize hoursSizer myAccTitleDesktop"
                  : "myHoursTitleMob x13211231"
              }
             `}
            >
              My account
            </div>

            <div
              className={`shiftHeaderMenuRow myHoursMenuRow myAccountMenuRow ${
                !memoVals.full ? "myAccountMenuRowMob" : ""
              } ${!memoVals.full && !memoVals.mob ? "x23982983" : ""}`}
            >
              <div
                className={`shiftHeaderMenuItem ${
                  memoVals.settingsAllowances === "profile"
                    ? `shiftHeaderMenuItemSelect`
                    : ""
                }`}
                onClick={() => {
                  memoVals.setSettingsAlowances((x) => "profile");
                  memoVals.setPage((x) => "profile");
                  if (toggleEditEmail) {
                    setToggleEditEmail(false);
                  }
                }}
              >
                <p className="headerMenuTopRow">Profile</p>

                <p
                  className={`headerMenuBottomRow ${
                    memoVals.settingsAllowances === "profile"
                      ? "none"
                      : "x198389191"
                  } `}
                ></p>
                <p
                  className={`headerMenuBottomRow ${
                    memoVals.settingsAllowances === "profile" ? "" : "none"
                  }  `}
                ></p>
              </div>{" "}
              {/* </NavLink> */}
              <div className="subHeadSplit"></div>
              <div
                className={`shiftHeaderMenuItem ${
                  memoVals.settingsAllowances === "settings"
                    ? `shiftHeaderMenuItemSelect`
                    : ""
                }`}
                onClick={() => {
                  memoVals.setSettingsAlowances((x) => "settings");
                  memoVals.setPage((x) => "settings");
                  if (toggleEditEmail) {
                    setToggleEditEmail(false);
                  }
                }}
              >
                <p className="headerMenuTopRow">Settings</p>

                <p
                  className={`headerMenuBottomRow ${
                    memoVals.settingsAllowances === "settings"
                      ? "none"
                      : "x198389191"
                  } `}
                ></p>
                <p
                  className={`headerMenuBottomRow ${
                    memoVals.settingsAllowances === "settings" ? "" : "none"
                  }  `}
                ></p>
              </div>
              <div className="subHeadSplit"></div>
              <div
                className={`shiftHeaderMenuItem `}
                onClick={() => {
                  memoVals.setShowStats((x) => true);

                  axios
                    .post(
                      `${serverURL}/get-my-hours-data`,
                      { my: my, ytd: false },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        console.log(response.data.data);
                        setAvailableToil(response.data.availableToil);
                        setNetPay(response.data.data.netPay);

                        memoVals.setSideBoxData((x) => response.data.data);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                My hours{" "}
                <img
                  src={popup2}
                  alt="Team"
                  className="accountTeamPopUpImg x53263"
                />
              </div>{" "}
              {!memoVals.mob && <div className="subHeadSplit"></div>}
              {!memoVals.mob && (
                <div
                  className={`shiftHeaderMenuItem `}
                  onClick={() => {
                    memoVals.setShowMyDocsModal((x) => true);
                  }}
                >
                  My documents{" "}
                  <img
                    src={popup2}
                    alt="Team"
                    className="accountTeamPopUpImg x53263"
                  />
                </div>
              )}
              {!memoVals.full ? <div className="subHeadSplit"></div> : ""}
              {!memoVals.full ? (
                <div
                  className={`shiftHeaderMenuItem `}
                  onClick={() => {
                    memoVals.setShowReqStats((x) => true);
                  }}
                >
                  {window.innerWidth > 374 ? "My allowances" : "Allowances"}
                  <img
                    src={popup2}
                    alt="Team"
                    className="accountTeamPopUpImg x53263 x374755"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="accountBody">
          <div className={`desktopMaster ${memoVals.mob ? "whiteBg" : ""}`}>
            <div
              className={`desktopMasterMain  ${
                memoVals.mob ? "mob100Witdth" : ""
              } ${!memoVals.full && !memoVals.mob ? "midContentWidth" : ""}`}
            >
              <div
                className={`requestsContainer ${
                  memoVals.mob ? "mobReqsContainerFullWidth" : ""
                }`}
              >
                {loading ? (
                  <img
                    src={horizontalBalls}
                    alt="Loading"
                    className="loadingProfileBallsImg"
                  />
                ) : (
                  ""
                )}
                {memoVals.settingsAllowances === "allowances" ? (
                  <div
                    className={`accountAllowancesDiv ${
                      memoVals.checkUserState.permissions === 3
                        ? `accountAllowancesDivPermissions3 ${
                            memoVals.mob
                              ? "accountAllowancesDivPermissions3Mob"
                              : ""
                          } ${
                            memoVals.device.ios
                              ? "accountAllowancesDivPermissions3Ios"
                              : ""
                          }`
                        : ""
                    }`}
                  >
                    {" "}
                    {/* <MyReqsDataBox modal={true} /> */}
                  </div>
                ) : memoVals.settingsAllowances === "settings" ? (
                  <div
                    className={`accountAllowancesDiv ${
                      memoVals.checkUserState.permissions === 3
                        ? `accountAllowancesDivPermissions3 ${
                            memoVals.mob
                              ? "accountAllowancesDivPermissions3Mob"
                              : ""
                          } ${
                            memoVals.device.ios
                              ? "accountAllowancesDivPermissions3Ios"
                              : ""
                          }`
                        : ""
                    }`}
                  >
                    <div
                      ref={myAccRef}
                      className={`accountSettingsContainer ${
                        loading ? "hideProfile" : ""
                      } ${
                        memoVals.mob
                          ? `accountSettingsContainerMob ${
                              memoVals.device.ios
                                ? "accountSettingsContainerMobIos"
                                : ""
                            } ${
                              memoVals.checkUserState.permissions === 3
                                ? `accountSettingsContainerMobPermissions3 ${
                                    memoVals.device.ios
                                      ? "accountSettingsContainerMobPermissions3Ios"
                                      : ""
                                  }`
                                : ""
                            }`
                          : ""
                      } ${
                        !memoVals.full && !memoVals.mob ? "noBorderRight" : ""
                      }`}
                    >
                      <br />
                      <div className="accountSettingsRow">
                        <div className="accountSettingsRowLeft">
                          <p className="accountSettingsTitle">
                            Email{" "}
                            <span
                              className={`unverifEmailTxt ${
                                !memoVals.mob && state.emailVerified
                                  ? "x928392741"
                                  : ""
                              }`}
                              onClick={() => {
                                if (!state.emailVerified) {
                                  setNotYetVerif(true);
                                }
                              }}
                            >
                              [
                              {!state.emailVerified
                                ? "Not yet verified"
                                : "Verified"}
                              ]
                            </span>
                          </p>
                          {toggleEditEmail ? (
                            <input
                              className="editEmailTxtInput"
                              type="text"
                              value={newEmail}
                              onChange={(e) => {
                                setNewEmail(e.target.value);
                              }}
                            ></input>
                          ) : (
                            <p className="accountSettingsValue">
                              {state.email}
                            </p>
                          )}
                        </div>
                        {toggleEditEmail ? (
                          <div className="saveEmailWrap">
                            <p
                              className={`saveEmailAccBtn ${
                                !newEmail.includes("@") ||
                                !newEmail.includes(".") ||
                                newEmail.length <= 3 ||
                                newEmail.includes(" ")
                                  ? "x28392479211"
                                  : ""
                              }`}
                              onClick={() => {
                                if (newEmail === state.email) {
                                  setNewEmail("");
                                  setToggleEditEmail(false);
                                } else {
                                  axios
                                    .post(
                                      `${serverURL}/edit-user-email-my-account`,
                                      {
                                        data: newEmail,
                                        nowDs:
                                          dateStringer.createStringFromTimestamp(
                                            new Date().getTime()
                                          ),
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (
                                        response.data.message ===
                                        "already exists"
                                      ) {
                                        setEmailAlreadyExists(true);
                                        setNewEmail("");
                                      }
                                      if (response.data.message === "success") {
                                        setEmailUpdated(true);
                                        setState({
                                          email: newEmail,
                                          emailVerified: false,
                                        });
                                        setToggleEditEmail(false);
                                        setNewEmail("");
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }
                              }}
                            >
                              Save
                            </p>
                            <p
                              className="saveEmailCanx"
                              onClick={() => {
                                setToggleEditEmail(false);
                              }}
                            >
                              Cancel
                            </p>
                          </div>
                        ) : (
                          <img
                            onClick={() => {
                              setToggleEditEmail(true);
                            }}
                            src={editGrey}
                            alt="Edit"
                            className="accountSettingsEditImg"
                          />
                        )}
                      </div>
                      <div
                        className="accountSettingsRow clickShiftRow"
                        onClick={() => {
                          axios
                            .post(
                              `${serverURL}/toggle-my-enable-swaps`,
                              { data: !state.allowSwaps },

                              {
                                withCredentials: true,
                                credentials: "include",
                              }
                            )
                            .then((response) => {
                              if (response.data.message === "success") {
                                setState({
                                  allowSwaps: response.data.data,
                                });
                              }
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        <div className="accountSettingsRowLeft tallerSettingsRowLeft">
                          <p className="accountSettingsTitle">Allow swaps</p>
                          <p className="accountSettingsValue accountSettingsInfo">
                            Allow colleagues in your team to request shift swaps
                            with you
                          </p>
                        </div>
                        {/*  KNOB BEGIN */}
                        <div className="knobContainer allowSwapsKnob">
                          <div
                            className={`switchKnob ${
                              state.allowSwaps ? "knobOn" : "knobOff"
                            }`}
                          ></div>
                          <div className="knobBg"></div>
                        </div>
                        {/*  KNOB END */}
                      </div>

                      {!memoVals.full ? (
                        <div className="nonFullNotificationsMenu">
                          <NotifSettings
                            state={state}
                            setState={setState}
                            isMob={true}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className={`accountAllowancesDiv ${
                      memoVals.checkUserState.permissions === 3
                        ? `accountAllowancesDivPermissions3 ${
                            memoVals.mob
                              ? "accountAllowancesDivPermissions3Mob"
                              : ""
                          } ${
                            memoVals.device.ios
                              ? "accountAllowancesDivPermissions3Ios"
                              : ""
                          }`
                        : ""
                    }`}
                  >
                    <div
                      ref={myAccRef}
                      className={`accountSettingsContainer ${
                        loading ? "hideProfile" : ""
                      } ${
                        memoVals.mob
                          ? `accountSettingsContainerMob ${
                              memoVals.device.ios
                                ? "accountSettingsContainerMobIos"
                                : ""
                            } ${
                              memoVals.checkUserState.permissions === 3
                                ? `accountSettingsContainerMobPermissions3 ${
                                    memoVals.device.ios
                                      ? "accountSettingsContainerMobPermissions3Ios"
                                      : ""
                                  }`
                                : ""
                            }`
                          : ""
                      } ${
                        !memoVals.full && !memoVals.mob ? "noBorderRight" : ""
                      }`}
                    >
                      <br />
                      <div
                        className={`accountSettingsProfileRow ${
                          memoVals.mob ? "accountSettingsProfileRowMob" : ""
                        }`}
                      >
                        <div className="profileRowLeft">
                          {state.profPicUrl ? (
                            <img
                              src={state.profPicUrl}
                              alt={state.fName}
                              className={`profileRowProPicImg ${
                                memoVals.mob ? "profileRowProPicImgMob" : ""
                              }`}
                            />
                          ) : (
                            <div className="profileInitsBlank">
                              <img
                                src={profilePicturePlaceholder}
                                className="profileImgProPicPlaceholder"
                                alt={state.fName}
                              />
                              <p className="profImgInits">
                                {state.fName[0]}
                                {state.lName[0]}
                              </p>
                            </div>
                          )}
                          <img
                            src={editUserEdit1}
                            alt="Edit profile picture"
                            className="editmyProPic"
                            onClick={() => {
                              setShowEditProPicModal(true);
                            }}
                          />
                        </div>

                        <div className="profileRowRight">
                          <div className="profileRightRow">
                            {/* <p className="profileRightRowTitle">First name</p> */}
                            {state.loading ? (
                              <div className="profileNamesLoaderDiv">
                                {" "}
                                <div className="profileLoading"></div>{" "}
                                <div className="profileLoading"></div>
                              </div>
                            ) : (
                              <p className="profileRightRowTitle">
                                {state.fName} {state.lName}
                              </p>
                            )}
                          </div>

                          <div className="profileRightRow rightRowLower">
                            {state.loading ? (
                              <div className="profileJTloading"></div>
                            ) : (
                              <p className="profileRightRowValue">
                                {formatJt(state.jobTitle)}
                              </p>
                            )}
                            {/* <p className="profileRightRowValue">{state.lName}</p> */}
                          </div>
                          <div className="pTeamNameDiv">
                            {state.loading ? (
                              <div className="profileJT2loading"></div>
                            ) : (
                              <div
                                className="accountProfilePTeamName primaryAccTeam_ x1212131"
                                onClick={() => {
                                  memoVals.setShowViewUserTeamModal(
                                    (x) => state.primaryTeamID
                                  );
                                }}
                              >
                                {shortenPTeamName(state.primaryTeamName)}{" "}
                                {/* <img
                                  src={popup2}
                                  alt="Team"
                                  className="accountTeamPopUpImg"
                                /> */}
                              </div>
                            )}
                            {state.loading ? (
                              ""
                            ) : (
                              <p className="primaryTeamNameTitleProfile">
                                Primary team
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="signoutRow">
                        <div
                          className={`signoutBtn navyColours ${
                            logoutLoading ? "signoutLoading" : ""
                          }`}
                          onClick={() => {
                            setSureSignout(true);
                          }}
                        >
                          <img
                            src={signout}
                            alt="Allowances"
                            className="signoutIcon"
                          />
                          <p className="allowanceBtnTxtProfile">
                            {logoutLoading ? "Signing out" : "Sign out"}
                          </p>
                        </div>
                      </div>

                      {!state.emailVerified && (
                        <div className="emailNotVerifRow">
                          <p className="myAccNotVerifTxt">
                            Verify your email address
                          </p>
                          <p
                            className="verifNotifBtn lightBlueColours"
                            onClick={() => {
                              axios
                                .post(
                                  `${serverURL}/resend-verify-email`,
                                  {
                                    nowDs:
                                      dateStringer.createStringFromTimestamp(
                                        new Date().getTime()
                                      ),
                                  },

                                  {
                                    withCredentials: true,
                                    credentials: "include",
                                  }
                                )
                                .then((response) => {
                                  if (response.data.message.includes("retry")) {
                                    setVerifAlreadySent(true);
                                  }
                                  if (response.data.message === "success") {
                                    memoVals.setIndicate((x) => {
                                      return {
                                        show: true,
                                        message:
                                          "Check your emails for a verification link",
                                        colour: "green",
                                        duration: 4000,
                                      };
                                    });
                                    setVerifSent(true);
                                  }
                                })
                                .catch((err) => {
                                  console.error(err);
                                });
                            }}
                          >
                            Verify
                          </p>
                        </div>
                      )}
                      <div className="accountSettingsRow x239494255">
                        <div className="myAccLeaveChartYearSelectDiv">
                          {leaveChartYears.map((yr) => {
                            return (
                              <div
                                className={`myAccLeaveChartYrOption ${
                                  selectedLeaveChartYear === yr
                                    ? "myAccLeaveChartYrOptionSelected"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedLeaveChartYear(yr);
                                }}
                                key={yr}
                              >
                                {yr}
                              </div>
                            );
                          })}
                        </div>

                        <div className="myAccLeaveChartRow">
                          <div
                            className={`myAccLeaveChartTaken ${
                              leaveChartData.taken === 0
                                ? "char0"
                                : leaveChartData.taken === 10
                                ? "char10"
                                : leaveChartData.taken === 20
                                ? "char20"
                                : leaveChartData.taken === 30
                                ? "char30"
                                : leaveChartData.taken === 40
                                ? "char40"
                                : leaveChartData.taken === 50
                                ? "char50"
                                : leaveChartData.taken === 60
                                ? "char60"
                                : leaveChartData.taken === 70
                                ? "char70"
                                : leaveChartData.taken === 80
                                ? "char80"
                                : leaveChartData.taken === 90
                                ? "char90"
                                : "char100"
                            }`}
                          ></div>
                          <div
                            className={`myAccLeaveChartBooked ${
                              leaveChartData.booked === 0 &&
                              leaveChartData.taken === 0
                                ? "myAccLeaveChartBookedMinus5Left"
                                : ""
                            }  ${
                              leaveChartData.booked === 0
                                ? "char0"
                                : leaveChartData.booked === 10
                                ? "char10"
                                : leaveChartData.booked === 20
                                ? "char20"
                                : leaveChartData.booked === 30
                                ? "char30"
                                : leaveChartData.booked === 40
                                ? "char40"
                                : leaveChartData.booked === 50
                                ? "char50"
                                : leaveChartData.booked === 60
                                ? "char60"
                                : leaveChartData.booked === 70
                                ? "char70"
                                : leaveChartData.booked === 80
                                ? "char80"
                                : leaveChartData.booked === 90
                                ? "char90"
                                : "char100"
                            }
`}
                          ></div>

                          <div
                            className={`myAccLeaveChartRemaining ${
                              leaveChartData.remaining === 0
                                ? "char0"
                                : leaveChartData.remaining === 10
                                ? "char10"
                                : leaveChartData.remaining === 20
                                ? "char20"
                                : leaveChartData.remaining === 30
                                ? "char30"
                                : leaveChartData.remaining === 40
                                ? "char40"
                                : leaveChartData.remaining === 50
                                ? "char50"
                                : leaveChartData.remaining === 60
                                ? "char60"
                                : leaveChartData.remaining === 70
                                ? "char70"
                                : leaveChartData.remaining === 80
                                ? "char80"
                                : leaveChartData.remaining === 90
                                ? "char90"
                                : "char100"
                            }
`}
                          ></div>
                        </div>

                        <div className="myAccLeaveChartKey">
                          <div className="myAccLeaveChartKeyItem keyItem1">
                            <div className="myAccLeaveChartKeyBlob"></div>
                            <div className="myAccLCKeyTxtDiv">
                              <p className="myAccLCkeyVal">
                                {leaveChartData.daysOrHours === "hours"
                                  ? dateStringer.formatMinsDurationToShortHours(
                                      leaveChartData.takenVal || 0
                                    )
                                  : leaveChartData.takenVal || 0}{" "}
                                {leaveChartData.daysOrHours === "days"
                                  ? "day"
                                  : ""}
                                {leaveChartData.takenVal === 1
                                  ? ""
                                  : leaveChartData.daysOrHours === "days"
                                  ? "s"
                                  : ""}
                              </p>
                              <p className="myAccLCkeyValTxt">taken</p>
                            </div>
                          </div>{" "}
                          {selectedLeaveChartYear >= currYr && (
                            <div className="myAccLeaveChartKeyItem keyItem2">
                              <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobBooked"></div>
                              <div className="myAccLCKeyTxtDiv">
                                <p className="myAccLCkeyVal">
                                  {leaveChartData.daysOrHours === "hours"
                                    ? dateStringer.formatMinsDurationToShortHours(
                                        leaveChartData.bookedVal || 0
                                      )
                                    : leaveChartData.bookedVal || 0}{" "}
                                  {leaveChartData.daysOrHours === "days"
                                    ? "day"
                                    : ""}
                                  {leaveChartData.bookedVal === 1
                                    ? ""
                                    : leaveChartData.daysOrHours === "days"
                                    ? "s"
                                    : ""}
                                </p>
                                <p className="myAccLCkeyValTxt">booked</p>
                              </div>
                            </div>
                          )}
                          <div className="myAccLeaveChartKeyItem keyItem3">
                            <div className="myAccLeaveChartKeyBlob myAccLeaveChartKeyBlobRemaining"></div>
                            <div className="myAccLCKeyTxtDiv">
                              <p className="myAccLCkeyVal">
                                {leaveChartData.daysOrHours === "hours"
                                  ? dateStringer.formatMinsDurationToShortHours(
                                      leaveChartData.remainingVal || 0
                                    )
                                  : leaveChartData.remainingVal || 0}{" "}
                                {leaveChartData.daysOrHours === "days"
                                  ? "day"
                                  : ""}
                                {leaveChartData.remainingVal === 1
                                  ? ""
                                  : leaveChartData.daysOrHours === "days"
                                  ? "s"
                                  : ""}
                              </p>
                              <p className="myAccLCkeyValTxt">remaining</p>
                            </div>
                          </div>
                        </div>
                        <p
                          className={`myAccLeaveAnnLeaveTitle ${
                            leaveChartDataLoading ? "invis" : ""
                          } ${
                            leaveChartData.remainingVal === 0 &&
                            leaveChartData.takenVal === 0 &&
                            leaveChartData.remainingVal === 0
                              ? "c143051"
                              : ""
                          }`}
                        >
                          Annual leave
                        </p>
                      </div>
                      <div className="accountSettingsRow none_">
                        <div className="accountSettingsRowLeft accountTeamsLeft">
                          <p className="accountSettingsTitle yourManagersTitle">
                            Clock pin number
                          </p>
                          <div className="salaryInfoAndHistoryBtnDiv">
                            <p className="accountSettingsValue accountSettingsInfo yourManagersExplain">
                              Your pin number used to clock in at a clock
                              terminal is{" "}
                              <span className="pinSpan">{state.pin}</span>.
                            </p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                      <div className="accountSettingsRow">
                        <div className="accountSettingsRowLeft accountTeamsLeft">
                          <p className="accountSettingsTitle">Your teams</p>
                          {state.teams && state.teams[0] ? (
                            <div className="accountSettingsTeamArray">
                              {generateTeams}
                            </div>
                          ) : (
                            <p className="accountSettingsValue accountSettingsInfo">
                              You are not part of any team
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="accountSettingsRow">
                        <div className="accountSettingsRowLeft accountTeamsLeft">
                          <p className="accountSettingsTitle teamsYouManageMargin">
                            Teams you manage
                          </p>
                          {state.mgrOf && state.mgrOf[0] ? (
                            <div className="accountSettingsTeamArray">
                              {generateMgrOf}
                            </div>
                          ) : (
                            <p className="accountSettingsValue accountSettingsInfo">
                              You don't manage any teams
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="accountSettingsRow">
                        <div className="accountSettingsRowLeft accountTeamsLeft">
                          <p className="accountSettingsTitle yourManagersTitle">
                            Your managers
                          </p>
                          <p className="accountSettingsValue accountSettingsInfo yourManagersExplain">
                            Colleagues who can approve your requests
                          </p>
                          {state.yourManagers && state.yourManagers[0] ? (
                            <div className="accountSettingsTeamArray yourMgrsArr">
                              {generateYourManagers}
                            </div>
                          ) : (
                            <p className="accountSettingsValue accountSettingsInfo">
                              Nobody manages you.
                            </p>
                          )}
                        </div>
                      </div>

                      {showEarnings && (
                        <div className="accountSettingsRow">
                          <div className="accountSettingsRowLeft accountTeamsLeft">
                            <p className="accountSettingsTitle yourManagersTitle">
                              Your pay
                            </p>
                            <div className="salaryInfoAndHistoryBtnDiv">
                              {state.salaried ? (
                                <p className="accountSettingsValue accountSettingsInfo yourManagersExplain">
                                  Your yearly salary is{" "}
                                  {memoVals.currencySymbol}
                                  {state.salary}.
                                </p>
                              ) : (
                                <p className="accountSettingsValue accountSettingsInfo yourManagersExplain">
                                  Your hourly rate is {memoVals.currencySymbol}
                                  {state.hourlyRate}.
                                </p>
                              )}

                              <div
                                className={`myHourlyRateTimelineBtn ${
                                  !hourlyRateHistoryExists && "none"
                                }`}
                                onClick={() => {
                                  setShowHourlyRateTimeline(
                                    !showHourlyRateTimeline
                                  );
                                  axios
                                    .post(
                                      `${serverURL}/get-user-hourly-rate-timeline`,
                                      {
                                        userID: memoVals.checkUserState.userID,
                                      },

                                      {
                                        withCredentials: true,
                                        credentials: "include",
                                      }
                                    )
                                    .then((response) => {
                                      if (response.data.message === "success") {
                                        setHourlyRateTimeline(
                                          response.data.hourlyRateTimeline
                                        );
                                      }
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }}
                              >
                                History{" "}
                                <img
                                  src={whiteDown}
                                  alt="Salary timeline"
                                  className={`mySalaryTimelineDownBtn ${
                                    showHourlyRateTimeline
                                      ? ""
                                      : "hideHrRateTimelineRotation"
                                  }`}
                                />
                              </div>
                            </div>

                            {showHourlyRateTimeline ? (
                              <div className="mySalaryTimelineDiv">
                                {generateSalaryTimeline}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      )}
                      {showEarnings && <br />}
                      <div className="accountSettingsRow">
                        <div className="accountSettingsRowLeft accountTeamsLeft">
                          <p className="accountSettingsTitle yourManagersTitle">
                            Your contracted hours
                          </p>
                          <div className="salaryInfoAndHistoryBtnDiv">
                            {state.workingPattern !== "zero" ? (
                              <p
                                className="accountSettingsValue accountSettingsInfo yourManagersExplain maxWidth60pc"
                                onClick={() => {
                                  console.log({ mpwTimeline });
                                }}
                              >
                                You are contracted to work
                                {memoVals.mob ? <br /> : " "}
                                {dateStringer.formatMinsDurationToHours(
                                  state.mpw
                                )}{" "}
                                per week
                              </p>
                            ) : (
                              <p className="accountSettingsValue accountSettingsInfo yourManagersExplain">
                                You are on a zero-hours contract
                              </p>
                            )}

                            <div
                              className={`myHourlyRateTimelineBtn ${
                                !mpwHistoryExists && "none"
                              }`}
                              onClick={() => {
                                setShowMpwTimeline(!showMpwTimeline);
                                axios
                                  .post(
                                    `${serverURL}/get-mpw-timeline-by-year`,
                                    {
                                      userID: memoVals.checkUserState.userID,
                                      year: "all",
                                    },

                                    {
                                      withCredentials: true,
                                      credentials: "include",
                                    }
                                  )
                                  .then((response) => {
                                    if (response.data.message === "success") {
                                      response.data.timeline.sort((a, b) => {
                                        return b.startTs - a.startTs;
                                      });
                                      setMpwTimeline(response.data.timeline);
                                    }
                                  })
                                  .catch((err) => {
                                    console.error(err);
                                  });
                              }}
                            >
                              History{" "}
                              <img
                                src={whiteDown}
                                alt="Salary timeline"
                                className={`mySalaryTimelineDownBtn ${
                                  showMpwTimeline
                                    ? ""
                                    : "hideHrRateTimelineRotation"
                                }`}
                              />
                            </div>
                          </div>

                          {showMpwTimeline ? (
                            <div className="mySalaryTimelineDiv">
                              {generateMpwTimeline}
                            </div>
                          ) : (
                            ""
                          )}
                          {state.workingPattern === "fixedScheduled" &&
                          !showMpwTimeline ? (
                            <div className="accountFixedSched">
                              <div className="accFSrow">
                                <p className="accFSrowTitle">Monday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].monDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].monStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].monStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].monEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].monEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].monDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].monBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].monBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>

                              <div className="accFSrow">
                                <p className="accFSrowTitle">Tuesday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].tuesDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].tuesStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].tuesStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].tuesEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].tuesEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].tuesDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].tuesBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].monBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>

                              <div className="accFSrow">
                                <p className="accFSrowTitle">Wednesday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].wedDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].wedStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].wedStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].wedEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].wedEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].wedDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].monBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].wedBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>

                              <div className="accFSrow">
                                <p className="accFSrowTitle">Thursday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].thursDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].thursStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].thursStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].thursEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].thursEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].thursDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].monBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].monBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>

                              <div className="accFSrow">
                                <p className="accFSrowTitle">Friday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].friDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].friStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].friStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].friEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].friEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].friDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].monBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].friBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>

                              <div className="accFSrow">
                                <p className="accFSrowTitle">Saturday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].satDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].satStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].satStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].satEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].satEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].satDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].monBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].satBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>

                              <div className="accFSrow">
                                <p className="accFSrowTitle">Sunday</p>
                                {state.fixedSchedule[0] &&
                                state.fixedSchedule[0].sunDuration > 0 ? (
                                  <div className="accFsRowValue">
                                    <p className="accFsRowValueUpper">
                                      {state.fixedSchedule[0].sunStart.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].sunStart.substr(
                                        2,
                                        2
                                      )}{" "}
                                      -{" "}
                                      {state.fixedSchedule[0].sunEnd.substr(
                                        0,
                                        2
                                      )}
                                      :
                                      {state.fixedSchedule[0].sunEnd.substr(
                                        2,
                                        2
                                      )}
                                    </p>
                                    <p className="accFsRowValueLower">
                                      {dateStringer.formatMinsDurationToHours(
                                        state.fixedSchedule[0].sunDuration
                                      )}{" "}
                                      <span className="x199ss">|</span>{" "}
                                      {state.fixedSchedule[0].monBrk === 0
                                        ? "No"
                                        : dateStringer.formatMinsDurationToHours(
                                            state.fixedSchedule[0].sunBrk,
                                            true
                                          )}{" "}
                                      break
                                    </p>
                                  </div>
                                ) : (
                                  <p className="accFsRowNonWorkingDay">-</p>
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <img
                      src={editGrey}
                      alt="Edit"
                      className="accountSettingsEditImg"
                    /> */}
                      </div>
                      <br />
                      {1 + 1 === 3 && (
                        <div className="accountSettingsRow">
                          <div className="accountSettingsRowLeft accountTeamsLeft">
                            <div className="yearlyTotalsDiv">
                              <p className="accountSettingsTitle yourManagersTitle">
                                Yearly totals
                              </p>
                              <select
                                className="yearTotalsYearDropdown"
                                value={selectedYear}
                                onChange={(e) => {
                                  setState({ dataLoading: true });
                                  setSelectedYear(e.target.value);
                                }}
                              >
                                {generateYears}
                              </select>
                            </div>
                            <p className="accountSettingsValue accountSettingsInfo allTheHoursExplain">
                              How many hours you have worked, how much leave you
                              have taken, and how much you have earned each
                              year.
                            </p>
                            <div className="accountHoursDataBox">
                              <div className="profileDataRow thickBorderBottom">
                                <p className="profileDataRowTitle">Period</p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.printedDateWithYrFromDWithoutDay(
                                      state.startDs
                                    )}{" "}
                                    -{" "}
                                    {dateStringer.printedDateWithYrFromDWithoutDay(
                                      state.endDs
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow">
                                <p className="profileDataRowTitle">
                                  Total hours worked
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.totalMins
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Regular hours
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.regularMins
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow thickBorderBottom">
                                <p className="profileDataRowTitle hoursIndent">
                                  Overtime hours
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.overtimeMins
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow">
                                <p className="profileDataRowTitle">
                                  Time off in lieu earned
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.tilEarned
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow">
                                <p className="profileDataRowTitle">
                                  Time off in lieu taken
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.tilTaken
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow thickBorderBottom X329892422">
                                <p className="profileDataRowTitle">
                                  Time off in lieu balance
                                  <br /> <br />
                                  <span className="tilProfileInfoSpan">
                                    Your TOIL balance is an accumulation of your
                                    earned TOIL over the last {state.toilLimit}{" "}
                                    weeks minus TOIL already used (or booked).
                                  </span>
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p
                                    className={`profileDataRowValue ${
                                      availableToil < 0
                                        ? "colourRed"
                                        : availableToil > 0
                                        ? "x1933819981313"
                                        : ""
                                    }`}
                                  >
                                    {dateStringer.formatMinsDurationToHours(
                                      availableToil
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow">
                                <p className="profileDataRowTitle">Absence</p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.absenceMins
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Absence from shifts
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.absenceFromShiftsAndTils
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Late starts
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {state.lateCount === 0
                                      ? "None"
                                      : state.lateCount === 1
                                      ? "Once"
                                      : state.lateCount}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Late duration
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.lateMins
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Absence from periods
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.absenceFromPeriods
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Tot. paid absence
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.paidAbsence
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="profileDataRow thickBorderBottom">
                                <p className="profileDataRowTitle hoursIndent">
                                  Tot. unnpaid absence
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.unpaidAbsence
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle ">Leave</p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.leaveMins
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow">
                                <p className="profileDataRowTitle hoursIndent">
                                  Paid leave
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.paidLeave
                                    )}
                                  </p>
                                )}
                              </div>
                              <div className="profileDataRow noBorder">
                                <p className="profileDataRowTitle hoursIndent">
                                  Unpaid leave{" "}
                                </p>
                                {state.dataLoading ? (
                                  "-"
                                ) : (
                                  <p className="profileDataRowValue">
                                    {dateStringer.formatMinsDurationToHours(
                                      state.unpaidLeave
                                    )}
                                  </p>
                                )}
                              </div>

                              {memoVals.checkUserState.showCosts ? (
                                <div className="profileDataRow thickBorderTop noBorderBottom">
                                  <div className="earnRowLeft">
                                    <p className="earnTitle">Net pay*</p>
                                    <div className="usrPayAsterixSalaryInfo x239321c">
                                      *Includes salary, overtime, unpaid
                                      deductions & paid leave.
                                    </div>
                                  </div>
                                  {state.dataLoading ? (
                                    "-"
                                  ) : (
                                    <p className="profileDataRowValue">
                                      {memoVals.currencySymbol}
                                      {Math.floor(netPay)}
                                    </p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* <img
                      src={editGrey}
                      alt="Edit"
                      className="accountSettingsEditImg"
                    /> */}
                        </div>
                      )}
                      <div className="accountSettingsRow noBorder">
                        <div className="accountSettingsRowLeft accountTeamsLeft">
                          <div className="yearlyTotalsDiv loggedInSessionsDiv">
                            <p className="accountSettingsTitle yourManagersTitle">
                              Logged in sessions
                            </p>

                            <div
                              className={`signoutBtn logOutEverywhereBtn ${
                                logoutLoading ? "signoutLoading" : ""
                              } `}
                              onClick={() => {
                                setLogoutLoading(true);
                                setSureKill({
                                  show: true,
                                  all: true,
                                  ip: "",
                                  lastLoginDs: "",
                                  device: "",
                                  current: true,
                                });
                              }}
                            >
                              {!logoutLoading ? (
                                <img
                                  src={signout}
                                  alt="Allowances"
                                  className="signoutIcon"
                                />
                              ) : (
                                ""
                              )}
                              <p className="allowanceBtnTxtProfile">
                                {logoutLoading
                                  ? "Signing out"
                                  : "Sign out everywhere"}
                              </p>
                            </div>
                          </div>

                          {tokens.map((token, i) => {
                            return (
                              <div className="tokenItem" key={i}>
                                {token.current && (
                                  <div className="tokenItemRow">
                                    <p className="tokenRowTitle"></p>
                                    <p className="tokenRowValue currDev">
                                      Current device
                                    </p>
                                  </div>
                                )}
                                <div className="tokenItemRow">
                                  <p className="tokenRowTitle">IP address</p>
                                  <p className="tokenRowValue">{token.ip}</p>
                                </div>
                                <div className="tokenItemRow">
                                  <p className="tokenRowTitle">Device</p>
                                  <p className="tokenRowValue">
                                    {token.device}
                                  </p>
                                </div>
                                <div className="tokenItemRow">
                                  <p className="tokenRowTitle">Logged in</p>
                                  <p className="tokenRowValue">
                                    {dateStringer.dsToTimeStrip(
                                      token.lastLoginDs
                                    )}
                                    ,{" "}
                                    {dateStringer.printedDateWithYrFromDs(
                                      token.lastLoginDs
                                    )}
                                  </p>
                                </div>
                                <div className="tokenItemRow noBorder">
                                  <p className="tokenRowTitle">Last active</p>
                                  {!token.lastActiveDs ? (
                                    <p className="tokenRowValue">Unknown</p>
                                  ) : (
                                    <p className="tokenRowValue">
                                      {dateStringer.dsToTimeStrip(
                                        token.lastActiveDs
                                      )}
                                      ,{" "}
                                      {dateStringer.printedDateWithYrFromDs(
                                        token.lastActiveDs
                                      )}
                                    </p>
                                  )}
                                </div>
                                <div className="tokenItemRow noBorder">
                                  <p className="tokenRowTitle"></p>
                                  <p
                                    className="killSessionBtn"
                                    onClick={() => {
                                      setSureKill({
                                        show: true,
                                        device: token.device,
                                        lastLoginDs: token.lastLoginDs,
                                        current: token.current,
                                        ip: token.ip,
                                        ind: i,
                                      });
                                    }}
                                  >
                                    End session
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/* <img
                      src={editGrey}
                      alt="Edit"
                      className="accountSettingsEditImg"
                    /> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {memoVals.full ? (
              memoVals.settingsAllowances === "profile" ? (
                <MyReqsDataBox
                  modal={false}
                  profileSideBox={true}
                  selectedYear={selectedYearSideBox}
                  setSelectedYearParent={setSelectedYearSideBox}
                />
              ) : (
                <NotifSettings state={state} setState={setState} />
              )
            ) : (
              // <NotifSettings state={state} setState={setState} />
              ""
            )}
          </div>
        </div>
      </div>
      <Navbar />
      <MgrAccountNavbar />
      {showEditProPicModal ? (
        <div
          className="editProPicUnderlay"
          onClick={() => {
            setShowEditProPicModal(false);
          }}
        >
          <div
            className="editProPicModalBox"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="editProPicModalHeader">
              <img
                src={close}
                alt="Close edit profile photo modal"
                className="closeEditProPicModalIcon"
                onClick={() => {
                  setShowEditProPicModal(false);
                }}
              />
              Change profile photo
            </div>
            <div className="editProPicModalContentBox">
              {proPicLoading ? (
                <p className="waitForProPic">Uploading...</p>
              ) : (
                <input
                  type="file"
                  title=" "
                  id="avatar"
                  name="avatar"
                  className="proPicChangeBtnMyAcc"
                  accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    setProPicLoading(true);
                    let imageFile = e.target.files[0];

                    const options = {
                      maxSizeMB: 0.05,
                      maxWidthOrHeight: 400,
                      useWebWorker: true,
                    };
                    try {
                      const compressedFile = await imageCompression(
                        imageFile,
                        options
                      );

                      let output = compressedFile;

                      let getBase64 = (file) => {
                        return new Promise((resolve) => {
                          let baseURL = "";
                          // Make new FileReader
                          let reader = new FileReader();

                          // Convert the file to base64 text
                          reader.readAsDataURL(file);

                          // on reader load somthing...
                          reader.onload = () => {
                            // Make a fileInfo Object
                            baseURL = reader.result;
                            resolve(baseURL);
                          };
                        });
                      };
                      axios
                        .post(
                          `${serverURL}/upload-profile-picture`,
                          {
                            userID: memoVals.checkUserState.userID,
                            base64: await getBase64(output),
                            remove: false,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((response) => {
                          if (response.data.message === "success") {
                            setState({ profPicUrl: response.data.url });
                            setShowEditProPicModal(false);
                            setProPicLoading(false);
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } catch (error) {
                      setProPicLoading(false);
                      console.log(error);
                    }
                  }}
                />
              )}
            </div>
            <div className="editProPicModalFooter">
              <p
                className="removeProPicBtn"
                onClick={() => {
                  setShowEditProPicModal(false);

                  axios
                    .post(
                      `${serverURL}/upload-profile-picture`,
                      {
                        userID: memoVals.checkUserState.userID,
                        remove: true,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setState({ profPicUrl: response.data.url });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Remove
              </p>
              <p
                className="closeProPicModalBtn"
                onClick={() => {
                  setShowEditProPicModal(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {memoVals.showReqStats ? (
        <div
          className="reqStatsUnderlay"
          onClick={(e) => {
            memoVals.setShowReqStats((x) => false);
          }}
        >
          <MyReqsDataBox
            modal={true}
            profileSideBox={true}
            selectedYear={selectedYearSideBox}
            setSelectedYearParent={setSelectedYearSideBox}
            setShowAllows={setShowReqStats}
          />
        </div>
      ) : (
        ""
      )}
      {renderStatsModal()}
      {/* {toggleBetweenAllowsAndSettings()} */}
      {/* <AccountSettingsSettings/> */}
      {/* <LoaderSplash /> */}
      {emailAlreadyExists ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              The email you have used is already associated with another
              account.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setEmailAlreadyExists(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {emailUpdated ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Your email has been updated but first, you need to verify it.
              <br />
              <br />
              Check your email inbox for {state.email}.<br />
              <br />
              If you haven't received an email, check your junk folder. Add
              notifications@flowrota.com to your address book to prevent future
              FlowRota emails going straight to your junk folder.
            </p>
            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setEmailUpdated(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {notYetVerif ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setNotYetVerif(false);
            }}
          >
            <p className="overlapsRenTxt">
              FlowRota will send you an email with a link to verify your email
              address. <br />
              <br />
              If you have already requested a verification email and haven't
              received it, check your spam folder.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setNotYetVerif(false);
                  axios
                    .post(
                      `${serverURL}/resend-verify-email`,
                      {
                        nowDs: dateStringer.createStringFromTimestamp(
                          new Date().getTime()
                        ),
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Verification email sent to you",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Verify email
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setNotYetVerif(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureKill.show ? (
        <div className="clockOnModalUnderlay">
          <div
            className={`formCreatedModal `}
            onClick={(e) => {
              e.stopPropagation();
              setSureKill({ show: false });
            }}
          >
            <p className="overlapsRenTxt">
              {sureKill.all
                ? "Are you sure you want to sign out of all sessions?"
                : `Are you sure you want to end this session logged in on ${
                    sureKill.device.includes("unknown")
                      ? sureKill.device.toLowerCase()
                      : sureKill.device
                  }?`}
              <br /> <br />
              {sureKill.all
                ? "You will be logged out of your current session."
                : `Your last log in on this device was ${dateStringer.printedDateWithTimeFromDs(
                    sureKill.lastLoginDs
                  )}.`}
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setLogoutLoading(true);
                  axios
                    .post(
                      `${serverURL}/kill-token`,
                      {
                        all: sureKill.all ? true : false,
                        ip: sureKill.ip,
                        device: sureKill.device,
                        lastLoginDs: sureKill.lastLoginDs,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        if (sureKill.current) {
                          window.location.href = "/signin";
                        } else {
                          memoVals.setIndicate((x) => {
                            return {
                              show: true,
                              message: "Session ended",
                              colour: "green",
                              duration: 4000,
                            };
                          });
                          setTokens(
                            tokens.filter((x, i) => {
                              return i !== sureKill.ind;
                            })
                          );
                          setLogoutLoading(false);
                          setSureKill({ show: false });
                        }
                      } else {
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                End session
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureKill({ show: false });
                  if (logoutLoading) {
                    setLogoutLoading(false);
                  }
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {verifAlreadySent ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              A verification email has already been sent to you.
              <br />
              <br />
              Please wait a few moments before trying again. <br />
              <br />
              Check your junk folder if an email doesn't arrive within a few
              minutes.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  setVerifAlreadySent(false);
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {memoVals.mob && memoVals.checkUserState.permissions < 3 && !loading && (
        <p
          className="accMobContactBtn lilacColours"
          onClick={() => {
            memoVals.setShowContact((x) => true);
          }}
        >
          Support{" "}
          <img src={liveChat} alt="Support" className="desktopSuppIcon" />{" "}
        </p>
      )}
      {sureSignout ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            setSureSignout(false);
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to sign out of FlowRota?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  setSureSignout(false);
                  setLogoutLoading(true);
                  axios
                    .post(
                      `${serverURL}/logout`,
                      { someData: 1 },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        window.location.href = "/signin";
                      } else {
                        setLogoutLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureSignout(false);
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {imgPreload}
    </div>
  );
};

export default AccountSettings;
