import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  Fragment,
} from "react";

import { useHistory } from "react-router-dom";

// cleaned wef 24 jul 23
// params cleared 10 sep 23
// preloaded images 19 sep 23
// axios caught 21 sep 23

import axios from "axios";

import { UserContext } from "../../contexts/UserContext";
import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";
import { CalendarContext } from "../../contexts/CalendarContext";

import { NavLink } from "react-router-dom";
import "../../styles/calendar.css";
import CheckUser from "../../tools/CheckUser";

import serverURL from "../../serverURL";
import CalendarSubBar from "./CalendarSubBar";
import UserSchedule from "./UserSchedule";
import TeamSchedule from "./TeamSchedule";
import StaffCalendar from "./StaffCalendar";

import Navbar from "../Navbar";
import dateStringer from "../../tools/dateStringer";

import horizontalBalls from "../../img/loaders/horizontalBalls.svg";
import download from "../../img/general/download.svg";

import navyTick from "../../img/general/navyTick.svg";
import wand from "../../img/general/wand.svg";
import profilePicturePlaceholder from "../../img/general/profilePicturePlaceholder.svg";

import TopBar from "../TopBar";

import "../../styles/calendar.css";
import MgrAccountNavbar from "../account/MgrAccountNavbar";

const Reports = ({ isTeam, isStaffCal }) => {
  const {
    setShowAddAvailModal,
    standalone,
    setSelectedDesktop,
    device,
    setShowEditUser,
    setShowUserPayRollModal,
    setSelectedNav,
  } = useContext(DataContext);
  const { calPage, setCalPage } = useContext(CalendarContext);
  const { mob, vice, wide } = useContext(StyleContext);
  const { currencySymbol } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      setShowAddAvailModal, //
      standalone, //
      calPage, //
      setCalPage, //
      mob, //
      setSelectedDesktop,
      device,
      wide,
      setShowEditUser,
      currencySymbol,
      setShowUserPayRollModal,
      setSelectedNav,
    }),
    [
      setShowAddAvailModal, //
      standalone, //
      calPage, //
      setCalPage, //
      mob, //
      setSelectedDesktop,
      wide,
      setShowEditUser,
      currencySymbol,
      setShowUserPayRollModal,
      setSelectedNav,
      device,
    ]
  );

  useEffect(() => {
    const handlePopstate = () => {
      console.log("BACK BUTTON PRESS * * *");
      window.history.pushState(null, document.title, window.location.href);
    };

    // Add the event listener for "popstate" event
    window.history.pushState(null, document.title, window.location.href);
    if (!mob) {
      window.addEventListener("popstate", handlePopstate);
    } else {
      window.removeEventListener("popstate", handlePopstate);
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [mob]);

  let [lastRegIndex, setLastRegIndex] = useState(9999999);
  let [explain, setExplain] = useState({ title: "" });
  let [disableDownload, setDisableDownload] = useState(false);

  let explainSetter = (x) => {
    if (x.title === "Net pay") {
      setExplain({
        text: (
          <>
            If paid hourly, this amount is the total payable from paid shifts,
            overtime, leave and absence.
            <br />
            <br />
            If paid on a salaried basis, this is the amount owed to the employee
            based on their salary for this period taking into account any
            deductions or additions.
          </>
        ),

        title: x.title,
      });
    }
    if (x.title === "Total hours") {
      setExplain({
        title: x.title,
        text: (
          <>
            The employee's total hours payable.
            <br />
            <br />
            If they are salaried, shifts (including overtime) are valued at
            their hourly rate based on their annual salary.
            <br />
            <br />
            If salaried, this figure should be not be used to determine how much
            they are owed (use the 'net pay' column instead).
          </>
        ),
      });
    }
    if (x.title === "Gross salary") {
      setExplain({
        title: x.title,
        text: (
          <>
            The employee's yearly salary pro-rated to this pay period before
            deductions / additions (if they paid on a salaried basis).
            <br />
            <br />
            If employees are paid the same amount per calendar month, we suggest
            using your payroll software instead - but use this report's salary
            additions and deductions columns to determine any deductions
          </>
        ),
      });
    }

    if (x.title === "Gross non-salary") {
      setExplain({
        title: x.title,
        text: (
          <>
            If the employee is paid on an hourly basis, this figure is
            calculated from the payable duration from shifts, overtime, paid
            leave and paid absence.
            <br />
            <br />
            If they are paid on a salaried basis, this figure is made up from
            the hours from overtime only.
          </>
        ),
      });
    }
    if (x.title === "Salary deductions") {
      setExplain({
        title: x.title,
        text: (
          <>
            Any unpaid absence, unpaid leave and unpaid lateness. Or when a
            shift is paid at a rate of less than 1x (eg: if a shift costs £100,
            at 0.5x, the deduction would be £50).
            <br />
            <br />
            This figure is deducted from the employee's salary.
            <br />
            <br />
            For salaried staff only.
          </>
        ),
      });
    }
    if (x.title === "Salary additions") {
      setExplain({
        title: x.title,
        text: (
          <>
            Hours payable in addition to their salary (ie. overtime items - or
            shifts and leave periods with pay rates higher than 1x). <br />
            <br />
            For example - A shift that costs £100 in salary, when paid at a rate
            of 1.5x the additional amount would be £50.
            <br />
            <br />
            For salaried staff only.
          </>
        ),
      });
    }
    if (x.title === "Overtime pay") {
      setExplain({
        title: x.title,
        text: (
          <>
            Hours worked as overtime that is paid for (and not added to an
            employee's TOIL balance).
          </>
        ),
      });
    }

    if (x.title === "Total regular hours") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours payable from shifts only (excluding overtime,
            paid leave, and paid absence).
          </>
        ),
      });
    }
    if (x.dynamic && x.shift) {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours payable from shifts that are paid at a rate of{" "}
            {x.rate}x.
          </>
        ),
      });
    }
    if (x.dynamic && x.leave) {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's duration of time off taken using their '{x.title}'{" "}
            {x.title.includes("leave") ? "" : "leave "} allowance.
          </>
        ),
      });
    }

    if (x.dynamic && x.absence) {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's absence duration under the absence type of '{x.title}
            '.
          </>
        ),
      });
    }

    if (x.dynamic && x.shift) {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours payable from shifts that are paid at a rate of{" "}
            {x.rate}x.
          </>
        ),
      });
    }
    if (x.title === "Total overtime hours") {
      setExplain({
        title: x.title,
        text: <>An employee's hours payable from overtime only.</>,
      });
    }
    if (x.dynamic && x.overtime) {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours payable from overtime that are paid at a rate of{" "}
            {x.rate}x.
          </>
        ),
      });
    }
    if (x.title === "Day hours") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours payable from shifts (& overtime) that are not
            paid at the night rate.
          </>
        ),
      });
    }
    if (x.title === "Night hours") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours payable from shifts (& overtime) that are paid
            at the night rate.
          </>
        ),
      });
    }

    if (x.title === "TOIL earned") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours from unpaid overtime that can be used to paid
            take time off.
          </>
        ),
      });
    }

    if (x.title === "TOIL used") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's total amount of time off taken using their TOIL
            balance.
          </>
        ),
      });
    }

    if (x.title === "Total leave") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's total leave from annual leave and other leave types -
            excluding absence.
          </>
        ),
      });
    }
    if (x.title === "Paid leave") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's total leave from paid leave types (includes annual
            leave).
          </>
        ),
      });
    }

    if (x.title === "Contracted leave") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's total leave payable based on their contracted hours
            per week at the time of the leave period.
          </>
        ),
      });
    }

    if (x.title === "Overtime leave") {
      setExplain({
        title: x.title,
        text: (
          <>
            Paid leave accrued from overtime (hours worked in excess of an
            employee's contracted hours) across the reference period.{" "}
            <a
              className="payRollLinkInfo"
              target="_blank"
              href="https://flowrota.com/support/pay-accrued-overtime-leave"
            >
              More details here
            </a>
            .<br />
            <br />
            If enabled, accrued overtime leave is paid within leave periods as a
            supplementary amount.
          </>
        ),
      });
    }

    if (x.title === "Unpaid leave") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's total leave taken on an unpaid basis (excludes unpaid
            absence).
          </>
        ),
      });
    }
    if (x.title === "Total absence") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's combined absence from lateness, shifts (partially &
            wholly) and absent periods.
          </>
        ),
      });
    }

    if (x.title === "Total paid absence") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's combined paid absence from paid lateness, paid shifts
            (partially & wholly) and paid absent periods.
          </>
        ),
      });
    }

    if (x.title === "Total unpaid absence") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's combined unpaid absence from unpaid lateness, unpaid
            absent shifts (partially & wholly) and unpaid absent periods.
          </>
        ),
      });
    }

    if (x.title === "Absence periods") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's hours from absence periods only (blocks of absence -
            excludes lateness and absent shifts).
          </>
        ),
      });
    }

    if (x.title === "Shift absence") {
      setExplain({
        title: x.title,
        text: (
          <>An employee's absence from wholly and partially absent shifts.</>
        ),
      });
    }

    if (x.title === "Late starts") {
      setExplain({
        title: x.title,
        text: <>The number of times an employee was late for a shift.</>,
      });
    }

    if (x.title === "Total late duration") {
      setExplain({
        title: x.title,
        text: <>The total duration of an employee's late starts.</>,
      });
    }

    if (x.title === "Paid late duration") {
      setExplain({
        title: x.title,
        text: <>The paid duration of an employee's late starts.</>,
      });
    }

    if (x.title === "Unpaid late duration") {
      setExplain({
        title: x.title,
        text: <>The unpaid duration of an employee's late starts.</>,
      });
    }
    if (x.title === "Absence quantity") {
      setExplain({
        title: x.title,
        text: (
          <>
            The number of instances an employee was absent - includes absent
            periods, absent shifts and late starts.
          </>
        ),
      });
    }

    if (x.title === "Unpaid absence") {
      setExplain({
        title: x.title,
        text: (
          <>
            An employee's unpaid absence - includes unpaid absent periods,
            unpaid shift absence and unpaid late starts.
          </>
        ),
      });
    }
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  CheckUser();
  let [regenNeeded, setRegenNeeded] = useState(true);

  let [reportsPage, setReportsPage] = useState("hours");
  let [loading, setLoading] = useState(true);
  let [loadingReport, setLoadingReport] = useState(false);
  let [users, setUsers] = useState([]);
  let [teams, setTeams] = useState([]);
  let [selectedTeams, setSelectedTeams] = useState([]);
  let [selectedUsers, setSelectedUsers] = useState([]);
  let [showSelectUser, setShowSelectUser] = useState(false);
  let [showSelectTeam, setShowSelectTeam] = useState(false);
  let [hoveredUserID, setHoveredUserID] = useState("");
  let [hoveredUser, setHoveredUser] = useState("");

  let [jtToggle, setJtToggle] = useState(false);
  let now = new Date();
  now.setDate(1);
  now.setHours(0, 0, 0, 0);
  let now2 = new Date();

  // Move to the first day of the next month
  let nextMonth = new Date(now2.getFullYear(), now2.getMonth() + 1, 1);

  // Move one day back to get the last day of the current month
  let lastDayOfCurrentMonth = new Date(nextMonth - 1);

  // Set the time to 01:00 AM
  lastDayOfCurrentMonth.setHours(0, 0, 0, 0);
  let [startDateInput, setStartDateInput] = useState(
    dateStringer.createDateInputFromTimestamp(now.getTime())
  );
  let [endDateInput, setEndDateInput] = useState(
    dateStringer.createDateInputFromTimestamp(lastDayOfCurrentMonth.getTime())
  );

  useEffect(() => {
    memoVals.setSelectedNav((x) => 5.1);

    if (startDateInput && startDateInput.length === 10) {
      let endOfMonth = new Date(
        dateStringer.createTimestampFromString(
          dateStringer.createStringFromDateInput(startDateInput)
        )
      );

      // Move to the first day of the next month
      let nextMonth = new Date(
        endOfMonth.getFullYear(),
        endOfMonth.getMonth() + 1,
        1
      );

      // Move one day back to get the last day of the current month
      let lastDay = new Date(nextMonth - 1);

      // Set the time to 01:00 AM
      lastDay.setHours(0, 0, 0, 0);

      setEndDateInput(
        dateStringer.createDateInputFromTimestamp(lastDay.getTime())
      );
    }
  }, [startDateInput]);

  const cancelSource1 = axios.CancelToken.source();
  let [showOverlapYearStartModal, setShowOverlapYearStartModal] = useState("");
  useEffect(() => {
    if (Array.isArray(teams) && reportsPage === "teams" && !selectedTeams[0]) {
      setSelectedTeams([teams[0].teamID]);
    }
  }, [teams, reportsPage]);
  useEffect(() => {
    if (selectedUsers[0]) {
      setSelectedUsers([]);
    }
    axios
      .post(
        `${serverURL}/init-reports`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          teamIDs: selectedTeams,
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          if (!selectedTeams[0]) {
            setTeams(response.data.teams);
          }

          setUsers(response.data.users);

          setLoading(false);

          // for dev only -- delete this:
          let arr = [];
          response.data.users.forEach((x) => {
            if (!x.archivedSplit) {
              arr.push(x.userID);
            }
          });
          setSelectedUsers(arr);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      cancelSource1.cancel("Component unmounted");
    };
  }, [selectedTeams]);

  useEffect(() => {
    if (noData) {
      setNoData(false);
    }
  }, [selectedUsers, selectedTeams, startDateInput, endDateInput]);

  let produceMonths = () => {
    let boxWidth = window.innerWidth - 500;
    let qty = boxWidth / 77;

    // function isEven(number) {
    //   return number % 2 === 0;
    // }

    // if (!isEven(qty)) {
    //   qty--;
    // }

    let toTheLeft = qty - 2;

    let firstItem = new Date();
    firstItem.setMonth(firstItem.getMonth() - toTheLeft);

    let arr = [];
    for (var i = 0; i < qty; i++) {
      let monthData = dateStringer.getMonthStartAndEnd(firstItem.getTime());
      arr.push({
        startDs: dateStringer.createStringFromTimestamp(monthData.firstDayTs),
        endDs: dateStringer.createStringFromTimestamp(monthData.lastDayTs),
        startDateInput: dateStringer.createDateInputFromTimestamp(
          monthData.firstDayTs
        ),
        endDateInput: dateStringer.createDateInputFromTimestamp(
          monthData.lastDayTs
        ),
      });
      firstItem.setMonth(firstItem.getMonth() + 1);
    }

    return arr.map((x) => {
      return (
        <div
          className={`reportsMonthItem ${
            startDateInput === x.startDateInput &&
            endDateInput === x.endDateInput
              ? "selMonRep"
              : ""
          }`}
          onClick={() => {
            console.log(x.startDateInput, x.endDateInput);
            setStartDateInput(
              dateStringer.createDateInputFromDateString(x.startDs)
            );
          }}
        >
          <p>{dateStringer.printMonthAndYearFromDs(x.startDs, true, true)}</p>
          <div
            className={`selMonBlob ${
              startDateInput === x.startDateInput &&
              endDateInput === x.endDateInput
                ? ""
                : "invis"
            }`}
          ></div>
        </div>
      );
    });
  };

  // const startDateInputter = document.getElementById("startDateInputter");
  // const endDateInputter = document.getElementById("endDateInputter");

  // if (startDateInputter && !memoVals.mob) {
  //   startDateInputter.addEventListener("click", function () {
  //     if (this.getAttribute("type") === "date" && this.showPicker) {
  //       this.showPicker();
  //     }
  //   });
  // }

  // if (endDateInputter && !memoVals.mob) {
  //   endDateInputter.addEventListener("click", function () {
  //     if (this.getAttribute("type") === "time" && this.showPicker) {
  //       this.showPicker();
  //     }
  // });
  // }

  let [itemsData, setItemsData] = useState([]);
  let [dataData, setDataData] = useState([]);
  let [payData, setPayData] = useState([]);

  useEffect(() => {
    if (teams[0]) {
      // already initially loaded
      setRegenNeeded(true);
    }
  }, [selectedTeams, selectedUsers, startDateInput, endDateInput]);

  let [hoursDataUsers, setHoursDataUsers] = useState([]);
  let [payDataUsers, setPayDataUsers] = useState([]);
  let [itemsDataUsers, setItemsDataUsers] = useState([]);
  let [noData, setNoData] = useState(false);

  let [titles, setTitles] = useState([]);
  useEffect(() => {
    if (disableDownload) {
      setDisableDownload(false);
    }
  }, [selectedTeams, selectedUsers, startDateInput, endDateInput]);

  let [teamData, setTeamData] = useState({
    totPayable: 0,
    salaries: 0,
    shifts: 0,
    overtime: 0,
    leave: 0,
    absence: 0,
    shiftPay: 0,
    overtimePay: 0,
    leavePay: 0,
    absencePay: 0,
  });

  let genReport = (passedUsers, passedDs) => {
    let endDateObj = new Date(
      dateStringer.createTimestampFromString(params.ds)
    );
    endDateObj.setMonth(endDateObj.getMonth() + 1);
    endDateObj.setDate(endDateObj.getDate() - 1);
    endDateObj.setHours(23, 59, 0, 0);
    axios
      .post(
        `${serverURL}/report-hours`,
        {
          nowDs: dateStringer.createStringFromTimestamp(new Date().getTime()),
          userIDs:
            params && params.userID
              ? [params.userID]
              : passedUsers
              ? passedUsers
              : selectedUsers,
          startDs:
            params && params.ds && params.userID
              ? params.ds
              : passedDs
              ? `${passedDs.substr(0, 11)}H00M00`
              : dateStringer.createStringFromDateInput(startDateInput),
          endDs:
            params && params.ds && params.userID
              ? dateStringer.createStringFromTimestamp(endDateObj.getTime())
              : passedDs
              ? `${passedDs.substr(0, 11)}H23M59`
              : dateStringer.createStringFromDateInput(endDateInput),
          teamWages: reportsPage === "teams",
        },
        {
          withCredentials: true,
          credentials: "include",
          cancelToken: cancelSource1.token,
        }
      )
      .then((response) => {
        if (response.data.message.includes("overlap")) {
          setShowOverlapYearStartModal(response.data.overlap);
          setNoData(true);
          setLoadingReport(false);
          if (loading) {
            setLoading(false);
          }
        }
        if (response.data.message === "success") {
          setLoadingReport(false);

          // teams
          if (reportsPage === "teams") {
            let totPayable = 0;
            let salaries = 0;
            let shifts = 0;
            let overtime = 0;
            let shiftPay = 0;
            let overtimePay = 0;
            let leave = 0;
            let absence = 0;
            let leavePay = 0;
            let absencePay = 0;

            let userMap = response.data.users || [];
            userMap.forEach((x) => {
              if (x.userTitleData && Array.isArray(x.userTitleData)) {
                let netFilt = x.userTitleData.filter((xx) => {
                  return xx.title === "Net pay";
                });

                if (netFilt[0]) {
                  totPayable += parseFloat(netFilt[0].value);
                }

                let grossSal = x.userTitleData.filter((xx) => {
                  return xx.title === "Gross salary";
                });
                let deductions = x.userTitleData.filter((xx) => {
                  return xx.title === "Salary deductions";
                });
                let additions = x.userTitleData.filter((xx) => {
                  return xx.title === "Salary additions";
                });

                grossSal.forEach((x) => {
                  salaries += parseFloat(x.value);
                });
                additions.forEach((x) => {
                  salaries += parseFloat(x.value);
                });
                deductions.forEach((x) => {
                  salaries -= parseFloat(x.value);
                });

                let shiftFilt = x.userTitleData.filter((xx) => {
                  return xx.title === "Total regular hours";
                });

                if (shiftFilt[0]) {
                  shiftPay += parseFloat(shiftFilt[0].cost);
                  shifts += parseFloat(shiftFilt[0].value);
                }
                let overFilt = x.userTitleData.filter((xx) => {
                  return xx.title === "Total overtime hours";
                });
                if (overFilt[0]) {
                  overtimePay += parseFloat(overFilt[0].cost);
                  overtime += parseFloat(overFilt[0].value);
                }

                let leaveFilt = x.userTitleData.filter((xx) => {
                  return xx.title === "Paid leave";
                });
                if (overFilt[0]) {
                  leavePay += parseFloat(leaveFilt[0].cost);
                  leave += parseFloat(leaveFilt[0].value);
                }

                let absFilt = x.userTitleData.filter((xx) => {
                  return xx.title === "Total paid absence";
                });
                if (absFilt[0]) {
                  absencePay += parseFloat(absFilt[0].cost);
                  absence += parseFloat(absFilt[0].value);
                }
              }
            });

            setTeamData({
              totPayable,
              salaries,
              shifts,
              overtime,
              leave,
              absence,
              shiftPay,
              overtimePay,
              leavePay,
              absencePay,
            });
          } else {
            setTeamData({
              totPayable: 0,
              salaries: 0,
              shifts: 0,
              overtime: 0,
              leave: 0,
              absence: 0,
              shiftPay: 0,
              overtimePay: 0,
              leavePay: 0,
              absencePay: 0,
            });
          }

          if (reportsPage === "teams") {
            const groupedByJobTitle = response.data.users.sort((a, b) => {
              const jobTitleComparison = a.jobTitle.localeCompare(b.jobTitle);
              if (jobTitleComparison !== 0) {
                return jobTitleComparison;
              }
              // If job titles are the same, sort by fName
              return a.fName.localeCompare(b.fName);
            });

            const result = [];
            let currentJobTitle = null;

            groupedByJobTitle.forEach((user, index, array) => {
              if (user.jobTitle !== currentJobTitle) {
                // Add the jtTitle object when encountering a new jobTitle
                currentJobTitle = user.jobTitle;
                result.push({ jtTitle: currentJobTitle });
              }

              // Add the user
              result.push(user);

              // Check if this is the last user for the current jobTitle
              const isLastForJobTitle =
                array[index + 1]?.jobTitle !== currentJobTitle;
              if (isLastForJobTitle) {
                result.push({ finalRow: true });
              }
            });

            if (result && Array.isArray(result)) {
              setHoursDataUsers(result);
            }
          } else {
            setHoursDataUsers(response.data.users);
          }
          if (response.data.users) {
            let shTitles = [];
            let otTitles = [];
            let leaveTitles = [];
            let absTitles = [];

            response.data.users.forEach((x) => {
              if (x.shiftRateArr) {
                x.shiftRateArr.forEach((xx) => {
                  if (!shTitles.includes(xx.rate)) {
                    shTitles.push(xx.rate);
                  }
                });
              }

              if (x.overtimeRateArr) {
                x.overtimeRateArr.forEach((xx) => {
                  if (!otTitles.includes(xx.rate)) {
                    otTitles.push(xx.rate);
                  }
                });
              }

              if (x.leaveRateArr) {
                x.leaveRateArr.forEach((xx) => {
                  if (!leaveTitles.includes(xx.leaveName)) {
                    leaveTitles.push(xx.leaveName);
                  }
                });
              }

              if (x.absenceRateArr) {
                x.absenceRateArr.forEach((xx) => {
                  if (!absTitles.includes(xx.absenceName)) {
                    absTitles.push(xx.absenceName);
                  }
                });
              }
            });

            setTitles(response.data.titles);

            let index = 0;
            for (let i = response.data.titles.length - 1; i >= 0; i--) {
              if (
                response.data.titles[i].dynamic === true &&
                response.data.titles[i].shift === true
              ) {
                index = i;
                break;
              }
            }

            if (index) {
              setLastRegIndex(index);
            }
          }

          if (response.data.users.length === 0) {
            setNoData(true);
          } else {
            setNoData(false);
          }

          let currentUrl = new URL(window.location.href);

          // Remove query parameters by setting search to an empty string
          currentUrl.search = "";

          // Update the URL without the query parameters
          window.history.replaceState({}, document.title, currentUrl.href);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!loading) {
      if (params && params.teamID && params.ds) {
        setSelectedTeams([dateStringer.cleanParam(params.teamID)]);
        let endDateObj = new Date(
          dateStringer.createTimestampFromString(params.ds)
        );
        endDateObj.setMonth(endDateObj.getMonth() + 1);
        endDateObj.setDate(endDateObj.getDate() - 1);
        endDateObj.setHours(23, 59, 0, 0);

        setStartDateInput(
          dateStringer.createDateInputFromDateString(params.ds)
        );
        setTimeout(() => {
          setEndDateInput(
            params.userID
              ? dateStringer.createDateInputFromTimestamp(endDateObj.getTime())
              : dateStringer.createDateInputFromDateString(params.ds)
          );
          if (params.userID) {
            setSelectedUsers([dateStringer.cleanParam(params.userID)]);
          }
          axios
            .post(
              `${serverURL}/init-reports`,
              {
                nowDs: dateStringer.createStringFromTimestamp(
                  new Date().getTime()
                ),
                teamIDs: [dateStringer.cleanParam(params.teamID)],
              },
              {
                withCredentials: true,
                credentials: "include",
                cancelToken: cancelSource1.token,
              }
            )
            .then((response) => {
              if (response.data.message === "success") {
                setUsers(response.data.users);
                // for dev only -- delete this:
                let arr = [];
                response.data.users.forEach((x) => {
                  if (!x.archivedSplit) {
                    arr.push(x.userID);
                  }
                });

                // setSelectedUsers(arr);
                setRegenNeeded(false);
                setLoadingReport(true);

                if (noData) {
                  setNoData(false);
                }
                genReport(arr, params.ds);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }, 500);
      }
    }
  }, [loading]);

  let [wageTeams, setWageTeams] = useState([]);

  let getTeamName = (teamID) => {
    let teamFilt = teams.filter((x) => {
      return x.teamID === teamID;
    });

    if (teamFilt[0]) {
      return teamFilt[0].teamName;
    }
  };

  // master return
  return (
    <div
      className={`calendarComponent  ${memoVals.mob ? "mobCalComponent" : ""} ${
        memoVals.standalone && !memoVals.mob ? "deskopStandaloneRota" : ""
      }`}
    >
      {!memoVals.standalone && <Navbar />}
      <MgrAccountNavbar />

      <div
        className={`userShiftsPage ${
          !memoVals.mob ? "desktopTeamSchedHeight" : "mobTeamShiftBgColourWhite"
        }`}
      >
        {" "}
        <div className="blackCornerBg"></div>
        <div
          className={`userShiftsPageUpperContainer purpleBg ${
            memoVals.mob ? "teamHeaderColoursWhite" : ""
          }`}
        >
          {!memoVals.mob ? <TopBar teamSchedule={true} /> : ""}
          <div
            className={`userShiftsPageUpperContainer ${
              memoVals.mob ? "noBorderBottom" : ""
            }`}
          >
            <div className={`shiftsHeaderContentContainer`}>
              <div
                className={`shiftsHeaderLeft ${
                  memoVals.mob ? "shiftsHeaderMob3" : ""
                } ${memoVals.wide ? "wideTitle" : ""}`}
              >
                <div
                  className={`shiftHeaderLeftTitle
             myHoursTitle ${
               !memoVals.mob ? `headerTitleSize hoursSizer` : ""
             } ${
                    memoVals.mob
                      ? "myHoursTitleMob reportsPayRollTitleMob"
                      : "reportsPayRollTitleDesktop"
                  } 
             `}
                  onClick={() => {
                    console.log(hoursDataUsers);
                  }}
                >
                  {memoVals.mob ? (
                    ""
                  ) : (
                    <span className="staffTitleLeftBlob payRollBlob">.</span>
                  )}{" "}
                  Reports & payroll
                </div>

                <div
                  className={`shiftHeaderMenuRow myHoursMenuRow payRollMenuRow ${
                    memoVals.mob ? "payRollMenuRowMob" : "payRollMenuRowDesktop"
                  }`}
                >
                  <div
                    className={`shiftHeaderMenuItem ${
                      memoVals.mob ? "noHover" : ""
                    } ${
                      reportsPage === "hours" ? "shiftHeaderMenuItemSelect" : ""
                    }`}
                    onClick={() => {
                      setReportsPage("hours");
                      setHoursDataUsers([]);
                      setLoadingReport(false);
                      if (jtToggle) {
                        setJtToggle(false);
                      }
                      setRegenNeeded(true);
                    }}
                  >
                    <p className="headerMenuTopRow">Hours & pay</p>
                    <p
                      className={`headerMenuBottomRow ${
                        reportsPage === "hours" ? "greenMenuBottom" : "invis"
                      } `}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div className="shifHeaderMenuSplitter"></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem ${
                      reportsPage === "teams" ? `shiftHeaderMenuItemSelect` : ""
                    } ${memoVals.mob ? "noHover" : ""}`}
                    onClick={() => {
                      setSelectedTeams([]);
                      setHoursDataUsers([]);

                      setReportsPage("teams");
                      if (jtToggle) {
                        setJtToggle(false);
                      }
                      setRegenNeeded(true);
                    }}
                  >
                    <p className="headerMenuTopRow">Team spend</p>
                    <p
                      className={`headerMenuBottomRow ${
                        reportsPage === "teams" ? "greenMenuBottom" : "invis"
                      } `}
                    ></p>
                  </div>{" "}
                  <div className="subHeadSplit"></div>
                  <div className="shifHeaderMenuSplitter"></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem ${
                      reportsPage === "teams" ? `shiftHeaderMenuItemSelect` : ""
                    } ${memoVals.mob ? "noHover" : ""}`}
                    onClick={() => {
                      setSelectedTeams([]);
                      setHoursDataUsers([]);

                      setReportsPage("locations");

                      setRegenNeeded(true);
                    }}
                  >
                    <p className="headerMenuTopRow">Location spend</p>
                    <p
                      className={`headerMenuBottomRow ${
                        reportsPage === "locations"
                          ? "greenMenuBottom"
                          : "invis"
                      } `}
                    ></p>
                  </div>
                  {/* <div className="subHeadSplit"></div> */}
                  {/* <div className="shifHeaderMenuSplitter"></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem ${
                      reportsPage === "items" ? `shiftHeaderMenuItemSelect` : ""
                    } ${memoVals.mob ? "noHover" : ""}`}
                    onClick={() => {
                      setReportsPage("items");
                    }}
                  >
                    <p className="headerMenuTopRow">Audit trail</p>
                    <p
                      className={`headerMenuBottomRow ${
                        reportsPage === "items" ? "greenMenuBottom" : "invis"
                      } `}
                    ></p>
                  </div> */}
                  {/* <div className="subHeadSplit"></div>
                  <div className="shifHeaderMenuSplitter"></div>{" "}
                  <div
                    className={`shiftHeaderMenuItem ${
                      reportsPage === "pay" ? `shiftHeaderMenuItemSelect` : ""
                    } ${memoVals.mob ? "noHover" : ""}`}
                    onClick={() => {
                      setReportsPage("pay");
                    }}
                  >
                    <p className="headerMenuTopRow">Pay</p>
                    <p
                      className={`headerMenuBottomRow ${
                        reportsPage === "pay" ? "greenMenuBottom" : "invis"
                      } `}
                    ></p>
                  </div> */}
                </div>
              </div>
            </div>
            <div
              className={`shiftsMenuBar ${
                memoVals.mob ? "shiftsMenuBarMob" : ""
              } ${memoVals.mob ? "fontSizeMobTeamHoursMenu" : ""}`}
            ></div>{" "}
          </div>
        </div>
        <div className="adminCurverCropper bgColUpcoming absenceShoulder hardPurpleBg">
          <div className="adminCurve"></div>
        </div>{" "}
        <div
          className={`shiftsPageFilterBarRota noPadLeft teamSchedBar teamShiftsSectionTeamOnly ${
            !memoVals.mob ? "borderLeftGrey" : "mobRotaTopLine"
          } noBorderRight`}
          onClick={() => {
            if (showSelectUser) {
              setShowSelectUser(false);
            }
            if (showSelectTeam) {
              setShowSelectTeam(false);
            }
          }}
        >
          <div className="reportMenuParent">
            <div className="reportUserTeamDrops">
              <div className="mutliSelParent">
                <div
                  className={`reportSelectBox ${
                    showSelectTeam ? "reportSelectBoxOpen" : ""
                  } ${
                    !showSelectTeam && selectedTeams[0] ? "multiActive" : ""
                  } ${memoVals.mob ? "reportSelectBoxTeamMob" : ""} ${
                    reportsPage === "teams" ? "selectedTeamWageDrop" : ""
                  }`}
                  onClick={() => {
                    setShowSelectTeam(!showSelectTeam);
                    if (showSelectUser) {
                      setShowSelectUser(false);
                    }
                  }}
                >
                  {reportsPage === "teams" && selectedTeams[0]
                    ? getTeamName(selectedTeams[0])
                    : !selectedTeams[0]
                    ? memoVals.mob
                      ? reportsPage === "teams"
                        ? "Team"
                        : "Teams"
                      : reportsPage === "teams"
                      ? "Select team"
                      : "Select team/s"
                    : `${selectedTeams.length} team${
                        selectedTeams.length === 1 ? "" : "s"
                      }`}
                </div>

                {showSelectTeam && (
                  <div
                    className="reportMenuUnderlay"
                    onClick={() => {
                      setShowSelectTeam(false);
                    }}
                  >
                    <div
                      className="multiSelectTeamBox"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {reportsPage !== "teams" && (
                        <div
                          className="multiSelectItemRow"
                          onClick={() => {
                            if (
                              selectedTeams.length ===
                              teams.filter((x) => {
                                return !x.archivedSplit && !x.archived;
                              }).length
                            ) {
                              setSelectedTeams([]);
                            } else {
                              let arr = [];
                              teams.forEach((x) => {
                                if (!x.archivedSplit && !x.archived) {
                                  arr.push(x.teamID);
                                }
                              });
                              setSelectedTeams(arr);
                              setShowSelectTeam(false);
                            }
                          }}
                        >
                          <p
                            className={`multiSelectTxt ${
                              selectedTeams.length ===
                              teams.filter((x) => {
                                return !x.archivedSplit && !x.archived;
                              }).length
                                ? "multiSelectTxtSel"
                                : ""
                            }`}
                          >
                            All teams
                          </p>

                          <div
                            className={`multiRadio ${
                              selectedTeams.length ===
                              teams.filter((x) => {
                                return !x.archivedSplit && !x.archived;
                              }).length
                                ? "selMutli"
                                : ""
                            } ${reportsPage === "teams" ? "none" : ""}`}
                          >
                            <img
                              src={navyTick}
                              alt="Selected team"
                              className={`selTeamReportImg ${
                                selectedTeams.length ===
                                teams.filter((x) => {
                                  return !x.archivedSplit && !x.archived;
                                }).length
                                  ? ""
                                  : "invis"
                              }`}
                            />
                          </div>
                        </div>
                      )}
                      {teams.map((team, i) => {
                        if (team.archivedSplit) {
                          return (
                            <p className="archivedSplitRow">Archived teams:</p>
                          );
                        } else {
                          return (
                            <div
                              className={`multiSelectItemRow ${
                                teams.filter((x) => {
                                  return x.archived || x.archivedSplit;
                                }).length === 0 &&
                                i ===
                                  teams.filter((x) => {
                                    return !x.archived && !x.archivedSplit;
                                  }).length -
                                    1
                                  ? "noBorder"
                                  : ""
                              } ${
                                teams.filter((x) => {
                                  return x.archived || x.archivedSplit;
                                }).length > 0 && i === teams.length - 1
                                  ? "noBorder"
                                  : ""
                              }`}
                              onClick={() => {
                                if (reportsPage === "teams") {
                                  setSelectedTeams([team.teamID]);
                                  setShowSelectTeam(false);
                                } else {
                                  if (selectedTeams.includes(team.teamID)) {
                                    setSelectedTeams(
                                      selectedTeams.filter((x) => {
                                        return x !== team.teamID;
                                      })
                                    );
                                  } else {
                                    setSelectedTeams([
                                      ...selectedTeams,
                                      team.teamID,
                                    ]);
                                  }
                                }
                              }}
                            >
                              <p
                                className={`multiSelectTxt ${
                                  selectedTeams.includes(team.teamID)
                                    ? "multiSelectTxtSel"
                                    : ""
                                }`}
                              >
                                {team.teamName}
                              </p>
                              <div
                                className={`multiRadio ${
                                  selectedTeams.includes(team.teamID)
                                    ? "selMutli"
                                    : ""
                                } ${reportsPage === "teams" ? "none" : ""}`}
                              >
                                <img
                                  src={navyTick}
                                  alt="Selected team"
                                  className={`selTeamReportImg ${
                                    !selectedTeams.includes(team.teamID)
                                      ? "invis"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>
              {reportsPage !== "teams" && (
                <div className="mutliSelParent">
                  <div
                    className={`reportSelectBox reportSelectBoxUsers ${
                      showSelectUser ? "reportSelectBoxOpen" : ""
                    } ${
                      !showSelectUser && selectedUsers[0] ? "multiActive" : ""
                    }`}
                    onClick={() => {
                      setShowSelectUser(!showSelectUser);
                      if (showSelectTeam) {
                        setShowSelectTeam(false);
                      }
                    }}
                  >
                    {!selectedUsers[0]
                      ? "Select employee/s"
                      : `${selectedUsers.length} employee${
                          selectedUsers.length === 1 ? "" : "s"
                        }`}
                  </div>

                  {showSelectUser && (
                    <div
                      className="reportMenuUnderlay reportMenuUnderlayUser"
                      onClick={() => {
                        setShowSelectTeam(false);
                      }}
                    >
                      <div
                        className="multiSelectTeamBox"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div
                          className="multiSelectItemRow"
                          onClick={() => {
                            if (
                              selectedUsers.length ===
                              users.filter((x) => {
                                return !x.archivedSplit && !x.archived;
                              }).length
                            ) {
                              setSelectedUsers([]);
                            } else {
                              let arr = [];
                              users.forEach((x) => {
                                if (!x.archivedSplit && !x.archived) {
                                  arr.push(x.userID);
                                }
                              });
                              setSelectedUsers(arr);
                              setShowSelectUser(false);
                            }
                          }}
                        >
                          <p
                            className={`multiSelectTxt ${
                              selectedUsers.length ===
                              users.filter((x) => {
                                return !x.archivedSplit && !x.archived;
                              }).length
                                ? "multiSelectTxtSel"
                                : ""
                            }`}
                          >
                            All employees
                          </p>
                          <div
                            className={`multiRadio ${
                              selectedUsers.length ===
                              users.filter((x) => {
                                return !x.archivedSplit && !x.archived;
                              }).length
                                ? "selMutli"
                                : ""
                            }`}
                          >
                            <img
                              src={navyTick}
                              alt="Selected team"
                              className={`selTeamReportImg ${
                                selectedUsers.length ===
                                users.filter((x) => {
                                  return !x.archivedSplit && !x.archived;
                                }).length
                                  ? ""
                                  : "invis"
                              }`}
                            />
                          </div>
                        </div>
                        {users.map((user, i) => {
                          if (user.archivedSplit) {
                            return (
                              <p className="archivedSplitRow">
                                Archived employees:
                              </p>
                            );
                          } else {
                            return (
                              <div
                                className={`multiSelectItemRow ${
                                  users.filter((x) => {
                                    return x.archived || x.archivedSplit;
                                  }).length === 0 &&
                                  i ===
                                    users.filter((x) => {
                                      return !x.archived && !x.archivedSplit;
                                    }).length -
                                      1
                                    ? "noBorder"
                                    : ""
                                } ${
                                  users.filter((x) => {
                                    return x.archived || x.archivedSplit;
                                  }).length > 0 && i === users.length - 1
                                    ? "noBorder"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (selectedUsers.includes(user.userID)) {
                                    setSelectedUsers(
                                      selectedUsers.filter((x) => {
                                        return x !== user.userID;
                                      })
                                    );
                                  } else {
                                    setSelectedUsers([
                                      ...selectedUsers,
                                      user.userID,
                                    ]);
                                  }
                                }}
                              >
                                <p
                                  className={`multiSelectTxt ${
                                    selectedUsers.includes(user.userID)
                                      ? "multiSelectTxtSel"
                                      : ""
                                  }`}
                                >
                                  {user.fName} {user.lName}
                                </p>
                                <div
                                  className={`multiRadio ${
                                    selectedUsers.includes(user.userID)
                                      ? "selMutli"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={navyTick}
                                    alt="Selected team"
                                    className={`selTeamReportImg ${
                                      !selectedUsers.includes(user.userID)
                                        ? "invis"
                                        : ""
                                    }`}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="reportGenParent">
            {!memoVals.mob &&
              reportsPage === "hours" &&
              !loadingReport &&
              hoursDataUsers[0] &&
              !regenNeeded && (
                <div
                  className={`editUserMessageBtn unarchBtn reportCsvBtn reportCsvBtnDesktop ${
                    disableDownload ? "disabledCsv" : ""
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/report-hours`,
                        {
                          csv: true,
                          nowDs: dateStringer.createStringFromTimestamp(
                            new Date().getTime()
                          ),
                          userIDs: selectedUsers,
                          startDs:
                            dateStringer.createStringFromDateInput(
                              startDateInput
                            ),
                          endDs:
                            dateStringer.createStringFromDateInput(
                              endDateInput
                            ),
                        },
                        {
                          responseType: "blob",
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        // Create a Blob from the response data
                        const blob = new Blob([response.data], {
                          type: "text/csv",
                        });

                        // Create a URL for the Blob
                        const url = window.URL.createObjectURL(blob);

                        // Create a link element to trigger the download
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = `flowrota_hours_pay_data_${dateStringer
                          .printedDateFromDs(
                            dateStringer.createStringFromDateInput(
                              startDateInput
                            )
                          )
                          .toLowerCase()}_to_${dateStringer
                          .printedDateFromDs(
                            dateStringer.createStringFromDateInput(endDateInput)
                          )
                          .toLowerCase()}_export.csv`; // Specify the filename
                        document.body.appendChild(link);

                        // Trigger the download
                        link.click();

                        // Cleanup
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(link);
                        setDisableDownload(true);
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  Export to CSV{" "}
                  <img
                    src={download}
                    alt="Message"
                    className="downloadCsvImg"
                  />
                </div>
              )}
            <p
              className={`genReportBtn ${regenNeeded ? "" : "noRegenNeeded"} ${
                !startDateInput ||
                startDateInput.length !== 10 ||
                !startDateInput ||
                startDateInput.length !== 10 ||
                (reportsPage === "hours" && !selectedUsers[0])
                  ? "disable"
                  : ""
              } ${
                reportsPage === "teams" && !selectedTeams[0] ? "disable" : ""
              }`}
              onClick={() => {
                // yearStartTsArr.forEach((ts) => {
                //   console.log({
                //     ts: dateStringer.createStringFromTimestamp(ts),
                //     startTs: dateStringer.createStringFromTimestamp(startTs),
                //     endTs: dateStringer.createStringFromTimestamp(endTs),
                //   });
                //   if (startTs < ts && endTs > ts) {
                //     overlap = ts;
                //   }
                // });

                // console.log({ yearStartTsArr, overlap });

                // get data here
                setRegenNeeded(false);
                setLoadingReport(true);

                if (noData) {
                  setNoData(false);
                }
                // if (reportsPage === "hours") {
                genReport();
                // }

                // if (reportsPage === "teams") {
                // get teamspends report
                // genTeamSpends();
                // }
              }}
            >
              Generate <img src={wand} alt="Generate" className="genGoImg" />
            </p>
          </div>
        </div>{" "}
        <div className="shiftTilFilterHolder"></div>
        <div
          className={`filterSplitterContainer ${
            !memoVals.mob ? "borderLeftGrey" : ""
          }`}
        >
          <div className={`${!memoVals.mob ? "borderLeftGrey" : ""}`}></div>
        </div>
        <div
          className={`teamSchedulePage ${
            !memoVals.mob ? "desktopTeamSchedHeight" : "reportsMobContent"
          }`}
          onClick={() => {
            if (showSelectUser) {
              setShowSelectUser(false);
            }
            if (showSelectTeam) {
              setShowSelectTeam(false);
            }
          }}
        >
          {/* new content */}
          <div
            className={`reportsDateRow ${
              memoVals.mob ? "reportsDateRowMob" : ""
            }`}
          >
            <div className="reportsDateInputParent">
              <input
                type="date"
                id="startDateInputter"
                className={`reportsDateInputter ${
                  memoVals.mob && memoVals.device.os === "iOS"
                    ? "reportsDateInputterMob"
                    : ""
                } ${memoVals.device.ios ? "iosDateInput" : ""}`}
                onChange={(e) => {
                  setStartDateInput(e.target.value);
                }}
                value={startDateInput}
                onClick={(e) => e.target.showPicker && e.target.showPicker()}
              ></input>
              <p className="reportsUntil">until</p>
              <input
                id="endDateInputter"
                type="date"
                min={startDateInput}
                className={`reportsDateInputter ${
                  memoVals.mob && memoVals.device.os === "iOS"
                    ? "reportsDateInputterMob"
                    : ""
                } ${memoVals.device.ios ? "iosDateInput" : ""}`}
                onChange={(e) => {
                  setEndDateInput(e.target.value);
                }}
                value={endDateInput}
                onClick={(e) => e.target.showPicker && e.target.showPicker()}
              ></input>
            </div>
            {!memoVals.mob && (
              <div className="reportsMonthTemps">{produceMonths()}</div>
            )}
          </div>

          {reportsPage === "items" &&
          !loadingReport &&
          hoursDataUsers[0] &&
          !regenNeeded ? (
            <div className="reportHoursDiv">
              <div className="reportHoursTitleRow">
                <div className="reportHoursA1"></div>
                <div className="reportHoursTitle">Total hours</div>{" "}
              </div>
              {hoursDataUsers.map((user, i) => {
                return (
                  <div className={`reportUserRow ${i === 0 ? "noBorder" : ""}`}>
                    <div
                      className={`reportUserProf ${
                        hoveredUserID === user.userID ? "reportUserProfHov" : ""
                      } ${
                        hoveredUser === user.userID
                          ? "reportUserProfHovUser"
                          : ""
                      }`}
                      onMouseEnter={() => {
                        console.log("Mouse entered ", user.userID);
                        if (!memoVals.mob) {
                          setHoveredUserID(user.userID);
                          setHoveredUser(user.userID);
                        }
                      }}
                      onMouseLeave={() => {
                        if (!memoVals.mob) {
                          if (hoveredUserID === user.userID) {
                            setHoveredUserID("");
                          }
                          if (hoveredUser === user.userID) {
                            setHoveredUser("");
                          }
                        }
                      }}
                      onClick={() => {
                        memoVals.setShowEditUser((x) => user.userID);
                      }}
                    >
                      <img
                        src={user.picUrl || profilePicturePlaceholder}
                        alt={user.fName}
                        className="reportUserPic"
                      />
                      <div className="reportUserInfo">
                        <p className="reportUserInfoName">
                          {user.fName} {user.lName}
                        </p>
                        <p className="reportUserInfoJt">{user.jobTitle}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
          {reportsPage === "hours" &&
          !loadingReport &&
          hoursDataUsers[0] &&
          !regenNeeded ? (
            <div
              className={`teamSchedulePage ${
                !memoVals.mob ? "desktopTeamSchedHeight" : ""
              }`}
            >
              <div
                className={`reportHoursContainer ${
                  !memoVals.mob
                    ? "reportHoursContainerDesktop whiteBg"
                    : "mobReportScroll"
                } ${!memoVals.mob ? "borderLeftGrey_" : ""} ${
                  loading ? "none" : ""
                } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
                  memoVals.device.ios ? "reportHoursContainerIos" : ""
                }`}
              >
                <Fragment>
                  <div
                    className={`reportsHoursUserCol ${
                      memoVals.mob ? "reportsHoursUserColMob" : ""
                    }`}
                  >
                    <div className="reportHoursUsersColA1">
                      {memoVals.mob && !loadingReport && (
                        <div
                          className={`editUserMessageBtn unarchBtn reportCsvBtn ${
                            disableDownload ? "disabledCsv" : ""
                          }`}
                          onClick={() => {
                            axios
                              .post(
                                `${serverURL}/report-hours`,
                                {
                                  csv: true,
                                  nowDs: dateStringer.createStringFromTimestamp(
                                    new Date().getTime()
                                  ),
                                  userIDs: selectedUsers,
                                  startDs:
                                    dateStringer.createStringFromDateInput(
                                      startDateInput
                                    ),
                                  endDs:
                                    dateStringer.createStringFromDateInput(
                                      endDateInput
                                    ),
                                },
                                {
                                  responseType: "blob",
                                  withCredentials: true,
                                  credentials: "include",
                                }
                              )
                              .then((response) => {
                                // Create a Blob from the response data
                                const blob = new Blob([response.data], {
                                  type: "text/csv",
                                });

                                // Create a URL for the Blob
                                const url = window.URL.createObjectURL(blob);

                                // Create a link element to trigger the download
                                const link = document.createElement("a");
                                link.href = url;
                                link.download = `flowrota_hours_pay_data_${dateStringer
                                  .printedDateFromDs(
                                    dateStringer.createStringFromDateInput(
                                      startDateInput
                                    )
                                  )
                                  .toLowerCase()}_to_${dateStringer
                                  .printedDateFromDs(
                                    dateStringer.createStringFromDateInput(
                                      endDateInput
                                    )
                                  )
                                  .toLowerCase()}_export.csv`; // Specify the filename
                                document.body.appendChild(link);

                                // Trigger the download
                                link.click();

                                // Cleanup
                                window.URL.revokeObjectURL(url);
                                document.body.removeChild(link);
                                setDisableDownload(true);
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }}
                        >
                          Export to CSV{" "}
                          <img
                            src={download}
                            alt="Message"
                            className="downloadCsvImg"
                          />
                        </div>
                      )}
                    </div>
                    {hoursDataUsers.map((user, i) => {
                      return (
                        <div
                          className={`reportUserProf ${
                            i === hoursDataUsers.length - 1 ? "noBorder" : ""
                          } ${
                            hoveredUserID === user.userID
                              ? "reportUserProfHov"
                              : ""
                          }  ${
                            hoveredUser === user.userID
                              ? "reportUserProfHovUser"
                              : ""
                          }`}
                          onClick={() => {
                            memoVals.setShowEditUser((x) => user.userID);
                            // memoVals.setShowEditUser((x) => {
                            //   return {
                            //     userID: user.userID,
                            //     tab: true,
                            //     // // monthArr: [],
                            //     // selectedStartTs: new Date().getTime(),
                            //     // minDateInput:
                            //     //   dateStringer.createDateInputFromDateString(
                            //     //     dateStringer.createStringFromTimestamp(
                            //     //       new Date().getTime()
                            //     //     )
                            //     //   ),
                            //     // maxDateInput:
                            //     //   dateStringer.createDateInputFromDateString(
                            //     //     dateStringer.createStringFromTimestamp(
                            //     //       new Date().getTime()
                            //     //     )
                            //     //   ),
                            //   };
                            // });
                          }}
                          onMouseEnter={() => {
                            console.log("Mouse entered ", user.userID);
                            if (!memoVals.mob) {
                              setHoveredUserID(user.userID);
                              setHoveredUser(user.userID);
                            }
                          }}
                          onMouseLeave={() => {
                            if (!memoVals.mob) {
                              if (hoveredUserID === user.userID) {
                                setHoveredUserID("");
                              }
                              if (hoveredUser === user.userID) {
                                setHoveredUser("");
                              }
                            }
                          }}
                        >
                          <img
                            src={user.picUrl || profilePicturePlaceholder}
                            alt={user.fName}
                            className="reportUserPic"
                          />
                          <div className="reportUserInfo">
                            <p
                              className={`reportUserInfoName ${
                                memoVals.mob ? "reportUserInfoNameMob" : ""
                              }`}
                            >
                              {user.fName} {user.lName}
                            </p>
                            <p className="reportUserInfoJt">
                              {dateStringer.shorten(
                                user.jobTitle,
                                memoVals.mob ? 19 : 33
                              )}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                    {hoursDataUsers.length > 12 && (
                      <div
                        className={`reportHoursUsersColA1 noBorderBottom ${
                          memoVals.mob ? "x585898423" : ""
                        }`}
                      ></div>
                    )}
                  </div>

                  <div
                    className={`reportHoursBody ${
                      memoVals.mob ? "reportHoursBodyMob" : ""
                    }`}
                  >
                    <div className="reportHoursUsersColA1 reportHoursUsersColA2">
                      {titles.map((x, i) => {
                        return (
                          <div
                            className={`reportHoursTitleCell ${
                              x.title === "Net pay" ||
                              x.title === "Total hours" ||
                              x.title === "Total regular hours" ||
                              x.title === "Overtime pay" ||
                              x.title === "Unpaid leave" ||
                              x.title === "TOIL used" ||
                              x.title === "Total overtime hours" ||
                              x.title === "Total leave" ||
                              x.title === "Total absence" ||
                              (i === lastRegIndex && lastRegIndex < 888)
                                ? "totPayBlock"
                                : ""
                            }`}
                            onClick={() => {
                              explainSetter(x);
                            }}
                          >
                            {x.shift ? (
                              <>
                                Regular
                                <br />
                              </>
                            ) : x.overtime ? (
                              <>
                                Overtime
                                <br />
                              </>
                            ) : (
                              ""
                            )}
                            {x.title}{" "}
                            {/* {x.title === "Total hours" ? "(worked)" : ""} */}
                            {x.title === "Total regular hours" ||
                            x.title === "Total overtime hours" ||
                            x.title === "Day hours" ||
                            x.title === "Night hours"
                              ? "(paid)"
                              : ""}
                            {x.shift || x.overtime ? "(worked)" : ""}
                            {x.dynamic && x.absence ? " (type)" : ""}
                            {x.dynamic && (x.leave || x.absence)
                              ? x.rate === 0
                                ? x.title === "Unpaid absence"
                                  ? ""
                                  : " (unpaid)"
                                : ` (${x.rate}x)`
                              : ""}
                            {x.shiftLeave ? "(paid leave)" : ""}
                            {(x.title === "Unpaid leave" ||
                              x.title === "Paid leave") &&
                            !x.dynamic
                              ? "(total)"
                              : ""}
                          </div>
                        );
                      })}
                    </div>

                    {Array.isArray(hoursDataUsers) && hoursDataUsers[0]
                      ? hoursDataUsers.map((user, i) => {
                          return (
                            <div
                              className={`reportHoursUserRow ${
                                i === hoursDataUsers.length - 1
                                  ? "noBorder"
                                  : ""
                              } ${
                                hoveredUserID === user.userID
                                  ? "reportUserProfHov"
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                if (!memoVals.mob) {
                                  setHoveredUserID(user.userID);
                                }
                              }}
                              onMouseLeave={() => {
                                if (!memoVals.mob) {
                                  setHoveredUserID("");
                                }
                              }}
                              onClick={() => {
                                setShowUserPayRollModal({
                                  show: true,
                                  userID: user.userID,
                                  startDs:
                                    dateStringer.createStringFromDateInput(
                                      startDateInput
                                    ),
                                  endDs:
                                    dateStringer.createStringFromDateInput(
                                      endDateInput
                                    ),
                                });
                              }}
                            >
                              {user.userTitleData.map((x, ii) => {
                                return (
                                  <div
                                    className={`reportsHoursDataBlock ${
                                      x.title === "Net pay" ||
                                      x.title === "Total hours" ||
                                      x.title === "Total regular hours" ||
                                      x.title === "Overtime pay" ||
                                      x.title === "Unpaid leave" ||
                                      x.title === "TOIL used" ||
                                      x.title === "Total overtime hours" ||
                                      x.title === "Total leave" ||
                                      x.title === "Total absence" ||
                                      (ii === lastRegIndex &&
                                        lastRegIndex < 888)
                                        ? "totPayBlock"
                                        : ""
                                    }`}
                                  >
                                    {/* {memoVals.currencySymbol} */}
                                    {/* {x.title} */}
                                    {x.money ? memoVals.currencySymbol : ""}
                                    {x.hours
                                      ? x.value === 0
                                        ? "-"
                                        : dateStringer.formatMinsDurationToHours(
                                            x.value
                                          )
                                      : x.qty && x.value === 0
                                      ? ""
                                      : x.money
                                      ? dateStringer.formatCost(x.value)
                                      : x.value}
                                    {x.qty
                                      ? x.value === 0
                                        ? "-"
                                        : x.value === 1
                                        ? " time"
                                        : " times"
                                      : ""}
                                    {x.cost !== 0 && !isNaN(x.cost) ? (
                                      <br />
                                    ) : (
                                      ""
                                    )}
                                    {x.cost !== 0 && !isNaN(x.cost) ? (
                                      <span className="reportCostUnder">
                                        {memoVals.currencySymbol}
                                        {dateStringer.formatCost(x.cost)}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })
                      : ""}
                    {Array.isArray(hoursDataUsers) &&
                    hoursDataUsers.length > 12 ? (
                      <div className="reportHoursUsersColA1 reportHoursUsersColA2 reportHoursUsersColA3">
                        {titles.map((x, i) => {
                          return (
                            <div
                              className={`reportHoursTitleCell ${
                                x.title === "Net pay" ||
                                x.title === "Total hours" ||
                                x.title === "Total regular hours" ||
                                x.title === "Overtime pay" ||
                                x.title === "Unpaid leave" ||
                                x.title === "TOIL used" ||
                                x.title === "Total overtime hours" ||
                                x.title === "Total leave" ||
                                x.title === "Total absence" ||
                                (i === lastRegIndex && lastRegIndex < 888)
                                  ? "totPayBlock"
                                  : ""
                              }`}
                              onClick={() => {
                                explainSetter(x);
                              }}
                            >
                              {x.shift ? (
                                <>
                                  Regular
                                  <br />
                                </>
                              ) : x.overtime ? (
                                <>
                                  Overtime
                                  <br />
                                </>
                              ) : (
                                ""
                              )}
                              {x.title}
                              {x.dynamic && x.absence ? " (type)" : ""}

                              {x.dynamic && (x.leave || x.absence)
                                ? x.rate === 0
                                  ? x.title === "Unpaid absence"
                                    ? ""
                                    : " (unpaid)"
                                  : ` (${x.rate}x)`
                                : ""}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Fragment>
              </div>
              {/* end new content */}
            </div>
          ) : (
            ""
          )}
          {reportsPage === "teams" ? (
            !selectedTeams[0] ? (
              <div className="noDataReportTxt">Select a team first</div>
            ) : regenNeeded ? (
              <div className="noDataReportTxt">
                {memoVals.mob ? "Tap" : "Click"} 'Generate' to generate wage
                report
              </div>
            ) : (
              <div
                className={`teamSchedulePage ${
                  !memoVals.mob ? "desktopTeamSchedHeight" : ""
                }`}
              >
                <div
                  className={`reportHoursContainer ${
                    !memoVals.mob
                      ? "reportHoursContainerDesktop whiteBg"
                      : "mobReportScroll"
                  } ${!memoVals.mob ? "borderLeftGrey_" : ""} ${
                    loading ? "none" : ""
                  } ${memoVals.device.vhIssue ? "vhIssue" : ""} ${
                    memoVals.device.ios ? "reportHoursContainerIos" : ""
                  } ${
                    reportsPage === "teams" ? "reportHoursContainerTeams" : ""
                  }`}
                >
                  <div
                    className={`teamWageLower ${
                      memoVals.mob
                        ? `teamWageLowerMob ${
                            memoVals.device.ios ? "teamWageLowerMobIos" : ""
                          }`
                        : ""
                    }`}
                  >
                    <div
                      className={`teamWageUserRow teamWageUserRowHeader ${
                        memoVals.mob && "teamWageUserRowHeaderMob"
                      }`}
                    >
                      <div className="teamWageUserPPimg"></div>
                      <p className="teamWageDataBlock fontWeight500">
                        Employee ({hoursDataUsers.length})
                      </p>{" "}
                      <p className="teamWageDataBlock x4757873 invis">
                        Job title{" "}
                        {/* <img
                          onClick={() => {
                            setJtToggle(!jtToggle);
                          }}
                          src={jtSort}
                          alt="Sort by job title"
                          className="sortJtImg"
                        /> */}
                      </p>
                      <p className="teamWageDataBlock fontWeight500">
                        Tot payable
                      </p>
                      <p className="teamWageDataBlock fontWeight500">Salary</p>
                      <p className="teamWageDataBlock fontWeight500">Shifts</p>
                      <p className="teamWageDataBlock fontWeight500">
                        Overtime
                      </p>
                      <p className="teamWageDataBlock fontWeight500">Leave</p>
                      <p className="teamWageDataBlock fontWeight500">Absence</p>
                    </div>
                    <div className="teamWageUserRow teamWageUserRowHeaderSub">
                      <div className="teamWageUserPPimg"></div>
                      <p className="teamWageDataBlock"></p>{" "}
                      <p className="teamWageDataBlock"></p>
                      <p className="teamWageDataBlock colour00aaff">
                        {memoVals.currencySymbol}
                        {dateStringer.formatCost(teamData.totPayable || 0)}
                      </p>
                      <p className="teamWageDataBlock">
                        {" "}
                        {memoVals.currencySymbol}
                        {dateStringer.formatCost(teamData.salaries || 0)}
                      </p>
                      <p className="teamWageDataBlock">
                        {" "}
                        {memoVals.currencySymbol}
                        {dateStringer.formatCost(teamData.shiftPay || 0)}
                        <br />
                        <span className="teamWageHrSpan">
                          {dateStringer.formatMinsDurationToHours(
                            teamData.shifts
                          )}
                        </span>
                      </p>
                      <p className="teamWageDataBlock">
                        {" "}
                        {memoVals.currencySymbol}
                        {dateStringer.formatCost(
                          teamData.overtimePay || 0
                        )}{" "}
                        <br />
                        <span className="teamWageHrSpan">
                          {dateStringer.formatMinsDurationToHours(
                            teamData.overtime
                          )}
                        </span>
                      </p>
                      <p className="teamWageDataBlock">
                        {" "}
                        {memoVals.currencySymbol}
                        {dateStringer.formatCost(teamData.leavePay || 0)}
                        <br />
                        <span className="teamWageHrSpan">
                          {dateStringer.formatMinsDurationToHours(
                            teamData.leave
                          )}
                        </span>
                      </p>
                      <p className="teamWageDataBlock">
                        {" "}
                        {memoVals.currencySymbol}
                        {dateStringer.formatCost(teamData.absencePay || 0)}
                        <br />
                        <span className="teamWageHrSpan">
                          {dateStringer.formatMinsDurationToHours(
                            teamData.absence
                          )}
                        </span>
                      </p>
                    </div>
                    <div
                      className={`teamWageUserArr ${
                        memoVals.mob && "teamWageUserArrMob"
                      }`}
                    >
                      {hoursDataUsers.map((user, i) => {
                        let totPayable = 0;
                        let salaries = 0;
                        let shifts = 0;
                        let overtime = 0;
                        let shiftPay = 0;
                        let overtimePay = 0;
                        let leave = 0;
                        let absence = 0;
                        let leavePay = 0;
                        let absencePay = 0;

                        if (
                          user.userTitleData &&
                          Array.isArray(user.userTitleData)
                        ) {
                          let netFilt = user.userTitleData.filter((xx) => {
                            return xx.title === "Net pay";
                          });

                          if (netFilt[0]) {
                            totPayable += parseFloat(netFilt[0].value);
                          }

                          let grossSal = user.userTitleData.filter((xx) => {
                            return xx.title === "Gross salary";
                          });
                          let deductions = user.userTitleData.filter((xx) => {
                            return xx.title === "Salary deductions";
                          });
                          let additions = user.userTitleData.filter((xx) => {
                            return xx.title === "Salary additions";
                          });

                          grossSal.forEach((x) => {
                            salaries += parseFloat(x.value);
                          });
                          additions.forEach((x) => {
                            salaries += parseFloat(x.value);
                          });
                          deductions.forEach((x) => {
                            salaries -= parseFloat(x.value);
                          });

                          let shiftFilt = user.userTitleData.filter((xx) => {
                            return xx.title === "Total regular hours";
                          });

                          if (shiftFilt[0]) {
                            shiftPay += parseFloat(shiftFilt[0].cost);
                            shifts += parseFloat(shiftFilt[0].value);
                          }
                          let overFilt = user.userTitleData.filter((xx) => {
                            return xx.title === "Total overtime hours";
                          });
                          if (overFilt[0]) {
                            overtimePay += parseFloat(overFilt[0].cost);
                            overtime += parseFloat(overFilt[0].value);
                          }

                          let leaveFilt = user.userTitleData.filter((xx) => {
                            return xx.title === "Paid leave";
                          });
                          if (overFilt[0]) {
                            leavePay += parseFloat(leaveFilt[0].cost);
                            leave += parseFloat(leaveFilt[0].value);
                          }

                          let absFilt = user.userTitleData.filter((xx) => {
                            return xx.title === "Total paid absence";
                          });
                          if (absFilt[0]) {
                            absencePay += parseFloat(absFilt[0].cost);
                            absence += parseFloat(absFilt[0].value);
                          }
                        }

                        return (
                          <div
                            className={`teamWageUserRow ${
                              user.jtTitle ? `teamWageUserRowJtTitle ` : ""
                            } ${user.finalRow && `teamWageUserRowFinalRow`} ${
                              !user.finalRow && !user.jtTitle
                                ? "teamWageUserRowClickable"
                                : ""
                            }`}
                            key={i}
                            onClick={() => {
                              if (!user.finalRow && !user.jtTitle) {
                                memoVals.setShowEditUser((x) => {
                                  return {
                                    userID: user.userID,
                                    tab: true,
                                    minDateInput: startDateInput,
                                    maxDateInput: endDateInput,
                                  };
                                });
                              }
                            }}
                          >
                            <img
                              src={user.picUrl || profilePicturePlaceholder}
                              alt={user.fName}
                              className={`teamWageUserPPimg ${
                                user.jtTitle || user.finalRow ? "hidden" : ""
                              }`}
                            />
                            {user.jtTitle ? (
                              <p className="teamWageDataBlock x473578752">
                                {user.jtTitle}
                                {user.jtTitle[user.jtTitle.length - 1] === "s"
                                  ? ""
                                  : "s"}
                              </p>
                            ) : (
                              <p className="teamWageDataBlock fontWeight500">
                                {user.fName} {user.lName}
                              </p>
                            )}
                            <p className="teamWageDataBlock teamWageDataBlockThin hidden">
                              {user.jobTitle}{" "}
                            </p>

                            {!user.jtTitle && (
                              <p
                                className={`teamWageDataBlock ${
                                  user.finalRow && "teamWageDataBlockFinalRow"
                                }`}
                              >
                                {memoVals.currencySymbol}
                                {dateStringer.formatCost(totPayable || 0)}
                              </p>
                            )}

                            {!user.jtTitle && (
                              <p
                                className={`teamWageDataBlock ${
                                  user.finalRow && "teamWageDataBlockFinalRow"
                                }`}
                              >
                                {" "}
                                {memoVals.currencySymbol}
                                {dateStringer.formatCost(salaries || 0)}
                              </p>
                            )}

                            {!user.jtTitle && (
                              <p
                                className={`teamWageDataBlock ${
                                  user.finalRow && "teamWageDataBlockFinalRow"
                                }`}
                              >
                                {" "}
                                {memoVals.currencySymbol}
                                {dateStringer.formatCost(shiftPay || 0)}
                                <br />
                                <span className="teamWageHrSpan">
                                  {dateStringer.formatMinsDurationToHours(
                                    shifts
                                  )}
                                </span>
                              </p>
                            )}

                            {!user.jtTitle && (
                              <p
                                className={`teamWageDataBlock ${
                                  user.finalRow && "teamWageDataBlockFinalRow"
                                }`}
                              >
                                {" "}
                                {memoVals.currencySymbol}
                                {dateStringer.formatCost(overtimePay || 0)}{" "}
                                <br />
                                <span className="teamWageHrSpan">
                                  {dateStringer.formatMinsDurationToHours(
                                    overtime
                                  )}
                                </span>
                              </p>
                            )}

                            {!user.jtTitle && (
                              <p
                                className={`teamWageDataBlock ${
                                  user.finalRow && "teamWageDataBlockFinalRow"
                                }`}
                              >
                                {" "}
                                {memoVals.currencySymbol}
                                {dateStringer.formatCost(leavePay || 0)} <br />
                                <span className="teamWageHrSpan">
                                  {dateStringer.formatMinsDurationToHours(
                                    leave
                                  )}
                                </span>
                              </p>
                            )}

                            {!user.jtTitle && (
                              <p
                                className={`teamWageDataBlock ${
                                  user.finalRow && "teamWageDataBlockFinalRow"
                                }`}
                              >
                                {" "}
                                {memoVals.currencySymbol}
                                {dateStringer.formatCost(absencePay || 0)}{" "}
                                <br />
                                <span className="teamWageHrSpan">
                                  {dateStringer.formatMinsDurationToHours(
                                    absence
                                  )}
                                </span>
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : !loadingReport && !loading && regenNeeded && selectedUsers[0] ? (
            <div className="noDataReportTxt">
              {memoVals.mob ? "Tap" : "Click"} 'Generate' to re-generate report
            </div>
          ) : (
            ""
          )}

          {!loadingReport && !loading && !noData && !selectedUsers[0] ? (
            <p className="noDataReportTxt">Select employees above</p>
          ) : (
            ""
          )}
          {!loadingReport && !loading && noData ? (
            <p className="noDataReportTxt">No data</p>
          ) : (
            ""
          )}

          {loadingReport ? (
            <img
              src={horizontalBalls}
              alt="Loading"
              className="teamSchedLoadingBallsImg"
            />
          ) : (
            ""
          )}
          {/* end new content */}
        </div>
        {/* {showClosedDay ? (
          <div
            className="indiOnlyUnderlay"
            onClick={() => {
              setShowClosedDay("");
            }}
          >
            <div className="overlapsRenewalModalBox">
              <div className="overlapsRenTxt">{showClosedDay}</div>
              <p
                className="tempSavedCloseBtn"
                onClick={() => {
                  setShowClosedDay("");
                }}
              >
                Close
              </p>
            </div>
          </div>
        ) : (
          ""
        )} */}
        {/* {renderLeaveReqModal()} */}
        {/* modals can go here */}
        {showOverlapYearStartModal ? (
          <div className="addAbsenceModalUnderlay">
            <div className="formCreatedModal">
              <p className="overlapsRenTxt">
                Your reporting period overlaps{" "}
                {dateStringer.printedDateFromDs(
                  showOverlapYearStartModal,
                  true,
                  true
                )}{" "}
                - the start of your company's business year.
                <br />
                <br />A report cannot span across two business years. Your start
                and end dates should fall within a single business year.
              </p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setShowOverlapYearStartModal("");
                  }}
                >
                  Understood
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {explain.title ? (
          <div
            className="addAbsenceModalUnderlay"
            onClick={() => {
              setExplain({ title: "" });
            }}
          >
            <div
              className="formCreatedModal"
              onCanPlay={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="payRollExplainModalTitle">{explain.title}</p>
              <p className="overlapsRenTxt">{explain.text}</p>
              <div className="areYouModalBtnsDiv">
                <p
                  className="closeSwapsNoLongerBtn"
                  onClick={() => {
                    setExplain({ title: "" });
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Reports;
