import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useMemo,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios done
import axios from "axios";

import "../../../styles/settings.css";
import "../../../styles/admin.css";

import whiteAdd from "../../../img/general/whiteAdd.svg";
import sortByInline from "../../../img/general/sortByInline.svg";
import whiteCog from "../../../img/general/whiteCog.svg";
import purpleCog from "../../../img/general/purpleCog.svg";

import whitecross from "../../../img/general/whitecross.svg";
import backToAllUsers from "../../../img/general/backToAllUsers.svg";
import navyClose from "../../../img/general/navyClose.svg";
import edit from "../../../img/general/edit.svg";
import mgrIcon2 from "../../../img/general/mgrIcon2.svg";
import userIcon from "../../../img/general/userIcon.svg";

import horizontalBalls from "../../../img/loaders/horizontalBalls.svg";

import { StyleContext } from "../../../contexts/StyleContext";
import { DataContext } from "../../../contexts/DataContext";

import serverURL from "../../../serverURL";

// import the info modal

const Teams = () => {
  const { setAccNav, mob, mobModal, full } = useContext(StyleContext);
  const {
    setShowViewTeamModal,
    setShowAddTeamModal,
    updateTeam,
    setUpdateTeam,
    setShowEditUser,
    device,
  } = useContext(DataContext);

  const memoVals = useMemo(
    () => ({
      setAccNav,
      mob,
      mobModal,
      full,
      setShowViewTeamModal,
      setShowAddTeamModal,
      updateTeam,
      setUpdateTeam,
      setShowEditUser,
      device,
    }),
    [
      setAccNav,
      mob,
      mobModal,
      full,
      setShowViewTeamModal,
      setShowAddTeamModal,
      updateTeam,
      setUpdateTeam,
      setShowEditUser,
      device,
    ]
  );

  useEffect(() => {
    memoVals.setAccNav((x) => "admin");
  }, []);

  let [teams, setTeams] = useState([]);
  let [showSettings, setShowSettings] = useState(false);
  let [loading, setLoading] = useState(true);
  let [showTopBorder, setShowTopBorder] = useState(false);

  useEffect(() => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Update the slug
    currentURL.pathname = "/admin/teams";

    // Change the URL without reloading the page
    window.history.pushState({}, "", currentURL.href);
  }, []);

  let [mgrsCanEditTeams, setMgrsCanEditTeams] = useState(false);

  useEffect(() => {
    if (!teams[0]) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .get(
          `${serverURL}/get-all-teams`,
          // { someData: 1 },
          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            setTeams(response.data.teams || []);
            setMgrsCanEditTeams(response.data.mgrsCanEditTeams);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  useEffect(() => {
    if (memoVals.updateTeam) {
      if (memoVals.updateTeam.delete) {
        let newArr = [];
        teams.forEach((team) => {
          if (team.teamID !== memoVals.updateTeam.teamID) {
            newArr.push(team);
          }
        });

        setTeams(newArr);
      }

      if (memoVals.updateTeam.new) {
        setTeams([...teams, memoVals.updateTeam]);
      }

      if (memoVals.updateTeam.editing) {
        let newArr = [];
        teams.forEach((t) => {
          if (t.teamID === memoVals.updateTeam.teamID) {
            newArr.push(memoVals.updateTeam);
          } else {
            newArr.push(t);
          }
        });

        setTeams(newArr);
      }

      memoVals.setUpdateTeam((x) => false);
    }
  }, [memoVals.updateTeam]);

  let generateTeams = teams
    .sort((a, b) => {
      return a.teamName.localeCompare(b.teamName);
    })

    .map((team, i) => {
      let mgrs = team.mgrs;
      let users = team.users;
      return (
        <div
          className={`dashTeamItem adminTeamItem ${
            memoVals.mob ? "adminTeamItemMob" : ""
          }`}
          key={i}
        >
          <div className="dasthTeamItemUpper">
            <div className="userDashDetailsLeft">
              <div className="userDashDetailsLeftRow">
                {/* <p className="userDashDetailsLeftRowTitle">Team</p> */}
                <p className="userDashDetailsLeftRowValue fontSize16 fontWeight600">
                  {team.teamName}
                </p>
              </div>
            </div>
            <div className="userDashDetailsRight">
              <p
                className={`editProfileBtnDash teamDashBtn teamEditAdminBtn `}
                onClick={() => {
                  memoVals.setShowViewTeamModal((x) => {
                    return { teamID: team.teamID };
                  });
                }}
              >
                Manage{" "}
                <img
                  src={edit}
                  alt="Edit profile"
                  className={`editProfilePenIcon ${
                    !memoVals.full && !memoVals.mob
                      ? "editProfilePenIconMid"
                      : ""
                  }`}
                />
              </p>
            </div>
          </div>{" "}
          <div className="dashTeamUserSection">
            <div className="dashTeamMgrs">
              <p className="dashTeamMgrTitle">Managers</p>
              {!mgrs[0] ? (
                <p className="noTeamUsrsTxt">
                  No managers
                  <br />
                  <span
                    className="addMgrNowSpan"
                    onClick={() => {
                      memoVals.setShowViewTeamModal((x) => {
                        return { teamID: team.teamID };
                      });
                    }}
                  >
                    Add
                  </span>
                </p>
              ) : (
                mgrs.map((mgr, ind) => {
                  return (
                    <p
                      className={`dashTeamPerson`}
                      key={ind}
                      onClick={() => {
                        memoVals.setShowEditUser((x) => mgr.userID);
                      }}
                    >
                      <img
                        src={mgrIcon2}
                        alt="Manager"
                        className="dashMgrIcon"
                      />{" "}
                      {mgr.fName} {mgr.lName}
                    </p>
                  );
                })
              )}
            </div>
            <div className="dashTeamMgrs dashTeamMgrs2">
              <p className="dashTeamMgrTitle">Members</p>
              {!users[0] ? (
                <p className="noTeamUsrsTxt">
                  No members <br />
                  <span
                    className="addMgrNowSpan"
                    onClick={() => {
                      memoVals.setShowViewTeamModal((x) => {
                        return { teamID: team.teamID };
                      });
                    }}
                  >
                    Add
                  </span>
                </p>
              ) : (
                users.map((usr, index) => {
                  return (
                    <p
                      key={index}
                      className={`dashTeamPerson`}
                      onClick={() => {
                        console.log({ usr });
                        memoVals.setShowEditUser((x) => usr.userID);
                      }}
                    >
                      <img
                        src={userIcon}
                        alt="Manager"
                        className="dashMgrIcon"
                      />{" "}
                      {usr.fName} {usr.lName}
                    </p>
                  );
                })
              )}
            </div>
          </div>
        </div>
      );
    });
  return (
    <Fragment>
      <img src={whitecross} alt="_" className="preloadCloseSort" />
      <img src={sortByInline} alt="_" className="preloadCloseSort" />
      <img src={backToAllUsers} alt="_" className="preloadCloseSort" />
      <div className="adminTeamsBody">
        {!memoVals.mob && 2 + 2 === 5 ? (
          <div className="adminTeamHeaderSettingsRow">
            {" "}
            {/* ROW ------------------------------------------------------------------------ */}
            <div
              className={`AdminRowContainer x1974274 clickShiftRow `}
              onClick={() => {
                axios
                  .post(
                    `${serverURL}/toggle-mgrs-can-edit-teams`,
                    {
                      data: !mgrsCanEditTeams,
                    },
                    {
                      withCredentials: true,
                      credentials: "include",
                    }
                  )
                  .then((response) => {
                    if (response.data.message === "success") {
                      setMgrsCanEditTeams(response.data.mgrsCanEditTeams);
                    }
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              <div
                className={`settingsRow teamAdminSettingsRowPadding ${
                  !memoVals.mob ? "x1314213433" : ""
                }`}
              >
                <div className="rowWithSubText">
                  <p className="absenceRowMainText">
                    Allow managers to amend teams
                  </p>
                  <p className="absenceRowSubText overtimeRowSubText">
                    If enabled, managers can add or remove staff to teams that
                    they manage.
                  </p>
                </div>{" "}
                {/*  KNOB BEGIN */}
                <div className="knobContainer">
                  <div
                    className={`switchKnob ${
                      mgrsCanEditTeams ? "knobOn" : "knobOff"
                    }`}
                  ></div>
                  <div className="knobBg"></div>
                </div>
                {/*  KNOB END */}
              </div>
            </div>
            {/* ROW ------------------------------------------------------------------------ */}{" "}
          </div>
        ) : (
          ""
        )}
        <div className="adminUsersParent">
          <div
            className={`adminUserKeyBar ${
              memoVals.mob ? "adminUserKeyBarMob" : ""
            }`}
          >
            <div className="adminUserKeyLeft">
              <div
                className={`adminAddUsrBtn ${
                  memoVals.mob ? "adminAddUsrBtnMob" : ""
                } lilacColours colourWhite`}
                onClick={() => {
                  memoVals.setShowAddTeamModal((x) => true);
                }}
              >
                Add team
                <img
                  src={memoVals.mob ? whiteAdd : whiteAdd}
                  alt="Add new employee"
                  className="addNewEmpIcon"
                />
              </div>
            </div>
            <div className="adminUserKeyRight">
              <div
                className={`adminAddUsrBtn archiveAdminUsersBtn ${
                  memoVals.mob ? "usersArchiveRadiusMob" : "usersArchiveRadius"
                } blueColours_`}
                onClick={() => {
                  setShowSettings(true);
                }}
              >
                Settings
                <img
                  src={memoVals.mob ? purpleCog : whiteCog}
                  alt="Add new employee"
                  className={`addNewEmpIcon2`}
                />
              </div>
            </div>
          </div>
          <div
            className={`AdminSettingsContainer ${
              memoVals.mob
                ? `AdminSettingsContainerMob ${
                    memoVals.device.ios
                      ? "AdminSettingsContainerMobUsersIos"
                      : ""
                  } x3213456765 minWidth100vw x1383747 ${
                    showTopBorder ? "showUsersTopBorder" : ""
                  }`
                : "maxWidth600px borderRightGrey teamsAdminHeight"
            }`}
            onScroll={(e) => {
              if (memoVals.mob) {
                if (e.target.scrollTop > 0) {
                  setShowTopBorder(true);
                } else {
                  setShowTopBorder(false);
                }
              }
            }}
          >
            <p className="teamLocationExplain">
              Rotas in FlowRota are managed in teams. All employees should have
              one team designated as their primary team - for which any paid
              leave and absence is costed against.
            </p>
            <div
              className={`adminBoxWidther ${
                memoVals.mob ? "adminBoxWidtherMob" : ""
              }`}
            >
              {!teams[0] && !loading && (
                <p className="noAdminTeamsAvail">No teams yet</p>
              )}
              {!teams[0] && !loading && (
                <p
                  className="addTeamNowNoTeamsbTN"
                  onClick={() => {
                    memoVals.setShowAddTeamModal((x) => true);
                  }}
                >
                  Add one now
                </p>
              )}

              {loading ? (
                <img
                  src={horizontalBalls}
                  alt="Loading"
                  className="loadingUsersAdmin"
                />
              ) : (
                generateTeams
              )}
              <br />
            </div>
          </div>
        </div>
        {showSettings ? (
          <div
            className="clockOnModalUnderlay"
            onClick={() => {
              setShowSettings(false);
            }}
          >
            <div
              className={`mySwapsModal ${
                memoVals.mobModal ? "seeUserModalMob mobModalShoulder" : ""
              } ${memoVals.device.ios ? "paddingBottom35" : ""} mgrModal`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="mySwapsHeader" onClick={() => {}}>
                <img
                  src={memoVals.mobModal ? navyClose : navyClose}
                  alt="Close"
                  className="closeMySwapsModalImg"
                  onClick={() => {
                    setShowSettings(false);
                  }}
                />
                <p>Team settings</p>

                <p></p>
              </div>

              <div className="mySwapsContainer adminTeamSettingsContainer">
                <div
                  className={`settingsRow teamAdminSettingsRowPadding  ${
                    !memoVals.mob ? "x1314213433" : "x183973741"
                  }`}
                  onClick={() => {
                    axios
                      .post(
                        `${serverURL}/toggle-mgrs-can-edit-teams`,
                        {
                          data: !mgrsCanEditTeams,
                        },
                        {
                          withCredentials: true,
                          credentials: "include",
                        }
                      )
                      .then((response) => {
                        if (response.data.message === "success") {
                          setMgrsCanEditTeams(response.data.mgrsCanEditTeams);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  }}
                >
                  <div className="rowWithSubText">
                    <p className="absenceRowMainText">
                      Allow managers to amend teams
                    </p>
                    <p className="absenceRowSubText overtimeRowSubText">
                      If enabled, managers can add or remove staff to teams that
                      they manage.
                    </p>
                  </div>{" "}
                  {/*  KNOB BEGIN */}
                  <div className="knobContainer mgrCanEditKnobContainer">
                    <div
                      className={`switchKnob ${
                        mgrsCanEditTeams ? "knobOn" : "knobOff"
                      }`}
                    ></div>
                    <div className="knobBg"></div>
                  </div>
                  {/*  KNOB END */}
                </div>
              </div>
              <div className="mySwapsModalFooter">
                <p></p>

                <p
                  className="closeSeeUserIDBtn"
                  onClick={() => {
                    setShowSettings(false);
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default Teams;
