import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
  useMemo,
} from "react";
// cleaned 11 aug 23
// preloaded images 19 sep 23
// axios caught 21 sep 23
// ios not needed (all pages are children)
import axios from "axios";

import { UserContext } from "../../contexts/UserContext";
import { AdminContext } from "../../contexts/AdminContext";

import { DataContext } from "../../contexts/DataContext";
import { StyleContext } from "../../contexts/StyleContext";

import serverURL from "../../serverURL";
import dateStringer from "../../tools/dateStringer";

import Navbar from "../Navbar";
import CheckUser from "../../tools/CheckUser";
import MgrAccountNavbar from "./MgrAccountNavbar";

import General from "./admin/General";
import Users from "./admin/Users";
import Teams from "./admin/Teams";
import Locations from "./admin/Locations";
import TopBar from "../TopBar";

import Admins from "./admin/Admins";
import Billing from "./admin/Billing";
import Absence from "./admin/Absence";
import Permissions from "./admin/Permissions";

import LeaveTypes from "./admin/LeaveTypes";
import Overtime from "./admin/Overtime";
// import Schedule from "./admin/Schedule";
import Blank from "./admin/Blank";
import ClosedDays from "./admin/ClosedDays";

import ErrorModal from "./admin/subcomponents/ErrorModal";

import "../../styles/admin.css";
import adminBurgerMob from "../../img/general/adminBurgerMob.svg";
import popup from "../../img/general/popup.svg";

import terminalPreview from "../../img/general/terminalPreview.jpg";

import close from "../../img/modals/close.svg";

import StatsModal from "./admin/StatsModal";
import appURL from "../../appURL";

// content components

const Admin = ({
  showUsers,
  showTeams,
  showClosedDays,
  showLeaveTypes,
  showAbsence,
  showOvertime,
  showAdmins,
  showBilling,
  showPermissions,
  showLocations,
}) => {
  CheckUser();

  const {
    showAddSpecialDayModal,
    setShowAddSpecialDayModal,
    specialDaysData,
    setSpecialDaysData,
    setCustomLeaveTypes,
    setAbsenceTypes,
    sureRemoveZone,
    setSureRemoveZone,
    isAdminModal,
    setIsAdminModal,
  } = useContext(AdminContext);
  const { adminPage, setAdminPage, mob, mobModal } = useContext(StyleContext);
  const {
    setSelecteEditTeam,
    setSelectedUser,
    setSelectedNav,
    setEmployeeCount,
    employeeCount,
    teamCount,
    setTeamCount,
    specialDayName,
    setSpecialDayName,
    specialDayRate,
    setSpecialDayRate,
    specialDayCompanyClosed,
    setSpecialDayCompanyClosed,
    payClose,
    setPayClose,
    sureDeleteSpecialDay,
    setSureDeleteSpecialDay,
    setShowSpecialDayModal,
    sureRemoveLeaveType,
    setSureRemoveLeaveType,
    setIndicate,
    setShowLeaveTypeModal,
    sureRemoveAbsenceType,
    setSureRemoveAbsenceType,
    setGpsArray,
    device,
    setShowAbsenceTypeModal,
    locationsCount,
    setLocationsCount,
  } = useContext(DataContext);
  const { currencySymbol, checkUserState } = useContext(UserContext);

  const memoVals = useMemo(
    () => ({
      showAddSpecialDayModal,
      setShowAddSpecialDayModal,
      specialDaysData,
      setSpecialDaysData,
      setCustomLeaveTypes,
      setAbsenceTypes,
      sureRemoveZone,
      setSureRemoveZone,
      adminPage,
      setAdminPage,
      mob,
      mobModal,
      setSelecteEditTeam,
      setSelectedUser,
      setSelectedNav,
      setEmployeeCount,
      employeeCount,
      teamCount,
      setTeamCount,
      specialDayName,
      setSpecialDayName,
      specialDayRate,
      setSpecialDayRate,
      specialDayCompanyClosed,
      setSpecialDayCompanyClosed,
      payClose,
      setPayClose,
      sureDeleteSpecialDay,
      setSureDeleteSpecialDay,
      setShowSpecialDayModal,
      sureRemoveLeaveType,
      setSureRemoveLeaveType,
      setIndicate,
      setShowLeaveTypeModal,
      sureRemoveAbsenceType,
      setSureRemoveAbsenceType,
      setGpsArray,
      setShowAbsenceTypeModal,
      device,
      checkUserState,
      isAdminModal,
      setIsAdminModal,
      locationsCount,
      setLocationsCount,
    }),
    [
      showAddSpecialDayModal,
      setShowAddSpecialDayModal,
      specialDaysData,
      setSpecialDaysData,
      setCustomLeaveTypes,
      setAbsenceTypes,
      sureRemoveZone,
      setSureRemoveZone,
      adminPage,
      setAdminPage,
      mob,
      mobModal,
      setSelecteEditTeam,
      setSelectedUser,
      setSelectedNav,
      setEmployeeCount,
      employeeCount,
      teamCount,
      setTeamCount,
      specialDayName,
      setSpecialDayName,
      specialDayRate,
      setSpecialDayRate,
      specialDayCompanyClosed,
      setSpecialDayCompanyClosed,
      payClose,
      setPayClose,
      sureDeleteSpecialDay,
      setSureDeleteSpecialDay,
      setShowSpecialDayModal,
      sureRemoveLeaveType,
      setSureRemoveLeaveType,
      setIndicate,
      setShowLeaveTypeModal,
      sureRemoveAbsenceType,
      setSureRemoveAbsenceType,
      setGpsArray,
      setShowAbsenceTypeModal,
      device,
      checkUserState,
      isAdminModal,
      setIsAdminModal,
      locationsCount,
      setLocationsCount,
    ]
  );

  let [specialDateDateString, setSpecialDateDateString] = useState("");
  const inputRef = useRef();
  let [showTerminalPopUp, setShowTerminalPopUp] = useState(false);
  useEffect(() => {
    if (!memoVals.mob && inputRef.current) {
      inputRef.current.focus();
    }
  }, [memoVals.showAddSpecialDayModal]);

  let deleteSpecialDay = (id) => {
    axios
      .post(
        `${serverURL}/delete-special-day`,
        { id: id },

        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          memoVals.setSpecialDaysData((x) =>
            memoVals.specialDaysData.filter((x) => {
              return x._id !== id;
            })
          );
          memoVals.setIndicate((x) => {
            return {
              show: true,
              message: "Special day deleted",
              colour: "blue",
              duration: 4000,
            };
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let [wait, setWait] = useState(false);
  let [dsValid, setDsValid] = useState(false);
  const addShiftDateInputRef = useRef(null);

  useEffect(() => {
    if (
      addShiftDateInputRef.current &&
      !memoVals.mob &&
      memoVals.showAddSpecialDayModal
    ) {
      addShiftDateInputRef.current.addEventListener("focus", function (evt) {
        if (this.getAttribute("type") === "date") {
          if (this.showPicker) {
            this.showPicker();
          }
        }
      });
    }
  }, [memoVals.showAddSpecialDayModal]);
  useEffect(() => {
    memoVals.setSelectedNav((x) => 5.1);
  }, []);

  let [showMobMenu, setShowMobMenu] = useState(false);

  useEffect(() => {
    if (!memoVals.employeeCount || !memoVals.teamCount) {
      const cancelSource1 = axios.CancelToken.source();

      axios
        .post(
          `${serverURL}/count-staff`,
          { data: 1 },

          {
            withCredentials: true,
            credentials: "include",
            cancelToken: cancelSource1.token,
          }
        )
        .then((response) => {
          if (response.data.message === "success") {
            memoVals.setEmployeeCount((x) => response.data.count);
            memoVals.setTeamCount((x) => response.data.teamsCount);
            memoVals.setLocationsCount((x) => response.data.locationsCount);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      return () => {
        cancelSource1.cancel("Component unmounted");
      };
    }
  }, []);

  useEffect(() => {
    if (showBilling) {
      memoVals.setAdminPage((x) => "Billing");
    } else if (showAdmins) {
      memoVals.setAdminPage((x) => "Admins");
    } else if (showOvertime) {
      memoVals.setAdminPage((x) => "Overtime");
    } else if (showAbsence) {
      memoVals.setAdminPage((x) => "Absence");
    } else if (showLeaveTypes) {
      memoVals.setAdminPage((x) => "Leave Types");
    } else if (showUsers) {
      memoVals.setAdminPage((x) => "Users");
    } else if (showTeams) {
      memoVals.setAdminPage((x) => "Teams");
    } else if (showClosedDays) {
      memoVals.setAdminPage((x) => "Closed Days");
    } else if (showPermissions) {
      memoVals.setAdminPage((x) => "Permissions");
    } else if (showLocations) {
      memoVals.setAdminPage((x) => "Locations");
    } else {
      memoVals.setAdminPage((x) => "General");
    }
  }, []);

  let [sureDeleteShiftLeaveType, setSureDeleteLeaveType] = useState({
    show: false,
  });
  let [shiftLeaveTypes, setShiftLeaveTypes] = useState([]);

  // console.log({ showLeaveTypes, adminPage });
  const toggleAdminComponents = () => {
    if (memoVals.adminPage === "General") {
      return <General />;
    } else if (memoVals.adminPage === "Users") {
      return <Users />;
    } else if (memoVals.adminPage === "Teams") {
      return <Teams />;
    } else if (memoVals.adminPage === "Locations") {
      return <Locations />;
    } else if (memoVals.adminPage === "Admins") {
      return <Admins />;
    } else if (memoVals.adminPage === "Billing") {
      return <Billing />;
    } else if (memoVals.adminPage === "Permissions") {
      return <Permissions />;
    } else if (memoVals.adminPage === "Absence") {
      return <Absence />;
    } else if (memoVals.adminPage === "Leave Types") {
      return (
        <LeaveTypes
          sureDeleteShiftLeaveType={sureDeleteShiftLeaveType}
          setSureDeleteLeaveType={setSureDeleteLeaveType}
          shiftLeaveTypes={shiftLeaveTypes}
          setShiftLeaveTypes={setShiftLeaveTypes}
        />
      );
    } else if (memoVals.adminPage === "Overtime") {
      return <Overtime />;
    } else if (memoVals.adminPage === "Closed Days") {
      return <ClosedDays />;
    } else if (memoVals.adminPage === "Schedule") {
      return <Schedule />;
    } else if (memoVals.adminPage === "Blank") {
      return <Blank />;
    }
  };

  let adminBlurb = () => {
    return "Admin panel";
  };

  // master ----

  return (
    <Fragment>
      <ErrorModal />

      <StatsModal />
      <div
        className={` myAccountHeader ${
          memoVals.mob
            ? "noBorder bg58467e"
            : "pageLeft240pxDesktop adminHeaderHeightNonMob"
        } `}
      >
        {!memoVals.mob ? <TopBar /> : ""}

        {/* <div className="adminTopInnerContainer"> */}
        <p
          className={`companySettings ${memoVals.mob ? "compSettingsMob" : ""}`}
        >
          {adminBlurb()}
        </p>
        <div
          className={`terminalBtn ${memoVals.mob ? "terminalBtnMob" : ""} ${
            memoVals.checkUserState.expired ? "disable" : ""
          }`}
          onClick={() => {
            setShowTerminalPopUp(true);
          }}
        >
          Clock terminal{" "}
          <img src={popup} alt="Terminal" className="termPopupAdmin" />
        </div>

        <div className={`${memoVals.mob ? "adminMenuHolder" : "none"}`}>
          {memoVals.mob ? (
            <div
              className={`mobAdminMenuDiv ${
                showMobMenu ? "mobAdminMenuDivActive" : ""
              }`}
              onClick={() => {
                setShowMobMenu(!showMobMenu);
              }}
            >
              <p className="mobAdminPageTitle">
                {memoVals.adminPage === "General"
                  ? "General settings"
                  : memoVals.adminPage === "Users"
                  ? "Employees"
                  : memoVals.adminPage}
              </p>
              <img
                src={adminBurgerMob}
                alt="Admin menu"
                className="adminBurger"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {memoVals.mob && showMobMenu ? (
        <div
          className="adminMenuUnderlay"
          onClick={() => {
            setShowMobMenu(false);
          }}
        >
          <div className="mobAdminOptions">
            <p
              className={`mobAdminOption generalNoBorder ${
                memoVals.adminPage === "General" ? "mobAdminOptionSelected" : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "General");
                setShowMobMenu(false);
              }}
            >
              General settings
            </p>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Leave Types"
                  ? "mobAdminOptionSelected"
                  : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Leave Types");
                setShowMobMenu(false);
              }}
            >
              Leave
            </p>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Users" ? "mobAdminOptionSelected" : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Users");
                setShowMobMenu(false);
              }}
            >
              Employees
            </p>
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Teams" ? "mobAdminOptionSelected" : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Teams");
                setShowMobMenu(false);
              }}
            >
              Teams
            </p>
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Locations"
                  ? "mobAdminOptionSelected"
                  : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Locations");
                setShowMobMenu(false);
              }}
            >
              Locations
            </p>
            <div
              className={`mobAdminOption ${
                memoVals.adminPage === "Closed Days"
                  ? "mobAdminOptionSelected"
                  : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Closed Days");
                setShowMobMenu(false);
              }}
            >
              <p>Closed days</p>
              {memoVals.adminPage === "Closed Days" ? (
                ""
              ) : (
                <p className="mobAdminOptionInclBankHol">
                  including {currencySymbol === "£" ? "bank" : "public"}{" "}
                  holidays
                </p>
              )}
            </div>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Absence" ? "mobAdminOptionSelected" : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Absence");
                setShowMobMenu(false);
              }}
            >
              Absence
            </p>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Overtime"
                  ? "mobAdminOptionSelected"
                  : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Overtime");
                setShowMobMenu(false);
              }}
            >
              Overtime
            </p>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Permissions"
                  ? "mobAdminOptionSelected"
                  : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Permissions");
                setShowMobMenu(false);
              }}
            >
              Permissions
            </p>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Billing" ? "mobAdminOptionSelected" : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Billing");
                setShowMobMenu(false);
              }}
            >
              Billing & Account
            </p>{" "}
            <p
              className={`mobAdminOption ${
                memoVals.adminPage === "Admins" ? "mobAdminOptionSelected" : ""
              }`}
              onClick={() => {
                memoVals.setAdminPage((x) => "Admins");
                setShowMobMenu(false);
              }}
            >
              Admins
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className={`adminWrapper ${
          !memoVals.mob ? "pageLeft240pxDesktop" : "bg58467e "
        }`}
      >
        <div
          className={`adminDesktopMenu ${memoVals.mob ? "none" : ""} ${
            memoVals.adminPage === "Teams" ||
            memoVals.adminPage === "Users" ||
            memoVals.adminPage === "Locations"
              ? "adminDesktopMenuTeams"
              : ""
          }`}
        >
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "General" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "General");
            }}
          >
            <p
              className={
                memoVals.adminPage === "General" ? "adminBlob" : "none"
              }
            ></p>
            General settings
          </div>{" "}
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Leave Types" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Leave Types");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Leave Types" ? "adminBlob" : "none"
              }
            ></p>
            Leave
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Users" ? "adminTitleDesktopSel" : ""
            } flexRow`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Users");
              memoVals.setSelectedUser((x) => null);
            }}
          >
            <p
              className={memoVals.adminPage === "Users" ? "adminBlob" : "none"}
            ></p>
            Employees{" "}
            {memoVals.employeeCount ? (
              <span className="employeeCount">
                {memoVals.employeeCount < 0 ? 0 : memoVals.employeeCount}
              </span>
            ) : (
              ""
            )}
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Teams" ? "adminTitleDesktopSel" : ""
            } flexRow`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Teams");
              memoVals.setSelecteEditTeam((x) => null);
            }}
          >
            <p
              className={memoVals.adminPage === "Teams" ? "adminBlob" : "none"}
            ></p>{" "}
            Teams{" "}
            {memoVals.teamCount ? (
              <span className="employeeCount">
                {memoVals.teamCount < 0 ? 0 : memoVals.teamCount}
              </span>
            ) : (
              ""
            )}
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Locations" ? "adminTitleDesktopSel" : ""
            } flexRow`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Locations");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Locations" ? "adminBlob" : "none"
              }
            ></p>{" "}
            Locations{" "}
            {memoVals.locationsCount ? (
              <span className="employeeCount">
                {memoVals.locationsCount < 0 ? 0 : memoVals.locationsCount}
              </span>
            ) : (
              ""
            )}
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Closed Days" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Closed Days");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Closed Days" ? "adminBlob" : "none"
              }
            ></p>
            <p>Day rules</p>
            {memoVals.adminPage !== "Closed Days" ? (
              <p className="bankHolMenuSub">
                including{" "}
                {currencySymbol === "£" ? "bank holidays" : "public holidays"}
              </p>
            ) : (
              ""
            )}
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Absence" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Absence");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Absence" ? "adminBlob" : "none"
              }
            ></p>
            Absence
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Overtime" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Overtime");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Overtime" ? "adminBlob" : "none"
              }
            ></p>
            Overtime
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Permissions" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Permissions");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Permissions" ? `adminBlob` : "none"
              }
            ></p>
            Permissions
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Billing" ? "adminTitleDesktopSel" : ""
            } ${
              !memoVals.checkUserState.active &&
              memoVals.adminPage !== "Billing"
                ? "bg5864b8_"
                : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Billing");
            }}
          >
            <p
              className={
                memoVals.adminPage === "Billing" ? `adminBlob` : "none"
              }
            ></p>
            Account & Billing
          </div>
          <div
            className={`adminMenuUnitDesktop ${
              memoVals.adminPage === "Admins" ? "adminTitleDesktopSel" : ""
            }`}
            onClick={() => {
              memoVals.setAdminPage((x) => "Admins");
            }}
          >
            <p
              className={memoVals.adminPage === "Admins" ? "adminBlob" : "none"}
            ></p>
            Administrators
          </div>
        </div>
        {toggleAdminComponents()}

        {memoVals.showAddSpecialDayModal ? (
          <div
            className="specialDayModalUnderlay"
            onClick={() => {
              memoVals.setShowAddSpecialDayModal((x) => false);
            }}
          >
            <div
              className={`addSpecialDayModalBox addSpecialDayModalBoxExpanded`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className={`addSpecialDayContentBox ${
                  memoVals.mobModal
                    ? `mobModalShoulder ${
                        memoVals.device.ios ? "paddingBottom20" : ""
                      }`
                    : ""
                }`}
              >
                <div
                  className="specialDayModalHeader"
                  onClick={() => {
                    if (memoVals.mobModal) {
                      memoVals.setShowAddSpecialDayModal((x) => false);
                    }
                  }}
                >
                  <img
                    src={close}
                    className="specialAddClose"
                    alt="Close"
                    onClick={() => {
                      memoVals.setShowAddSpecialDayModal((x) => false);
                    }}
                  />

                  <p className="specialDayAddHeaderTitle">Add special day</p>
                </div>
                {/* middle */}
                <div className="specialDayModalMiddle">
                  <p className="addSpecialDayInfoTxt">
                    Set rules for a specific day
                  </p>
                  <input
                    type="text"
                    className="specialDayNameButtonToOpenInputter"
                    ref={inputRef}
                    placeholder="Title (eg: closed for refurb)"
                    value={specialDayName}
                    onChange={(e) => {
                      let val = e.target.value;
                      memoVals.setSpecialDayName((x) => val);
                    }}
                  ></input>

                  <input
                    type="date"
                    className={`specDayEditDateInput ${
                      memoVals.device.ios ? "specDayEditDateInputIos" : ""
                    }`}
                    onChange={(e) => {
                      if (
                        e.target.value.length === 10 &&
                        e.target.value[0] === "2"
                      ) {
                        setSpecialDateDateString(
                          dateStringer.createStringFromDateInput(e.target.value)
                        );
                        setDsValid(true);
                      } else {
                        setDsValid(false);
                      }
                    }}
                    ref={addShiftDateInputRef}
                  />
                </div>
                {/* footer */}
                <div className="specialDayModalFooter">
                  <div className="specialFooterContainer">
                    <div className="rateAndClosedContainer">
                      {/* Closed toggler */}
                      <div
                        className="rateBox closedRateBox"
                        onClick={() => {
                          memoVals.setSpecialDayCompanyClosed(
                            (x) => !memoVals.specialDayCompanyClosed
                          );
                        }}
                      >
                        <div className="rateTitileClosed">Closed</div>
                        <div className="rateContainerClosed">
                          {/*  KNOB BEGIN */}
                          <div className="knobContainer closeComp">
                            <div
                              className={`switchKnob ${
                                memoVals.specialDayCompanyClosed
                                  ? "knobOn"
                                  : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      </div>

                      {/* Closed pay toggler */}
                      <div
                        className={`${
                          memoVals.specialDayCompanyClosed ? "rateBox" : "none"
                        }`}
                        onClick={() => {
                          memoVals.setPayClose((x) => !memoVals.payClose);
                        }}
                      >
                        <div className="rateTitileClosed payClosed">
                          Pay staff
                        </div>
                        <div className="rateContainerClosed payStaffKnob">
                          {/*  KNOB BEGIN */}
                          <div className="knobContainer closedPay">
                            <div
                              className={`switchKnob ${
                                memoVals.payClose ? "knobOn" : "knobOff"
                              }`}
                            ></div>
                            <div className="knobBg"></div>
                          </div>
                          {/*  KNOB END */}
                        </div>
                      </div>

                      {/* Pay rate dropdown */}
                      <div
                        className={`${
                          memoVals.specialDayCompanyClosed ? "none" : "rateBox"
                        }`}
                      >
                        <p className="rateTitile">Pay rate </p>
                        <p className="rateContainer">
                          <select
                            className="specialDaysRateDropDown"
                            onChange={(e) => {
                              memoVals.setSpecialDayRate((x) => e.target.value);
                            }}
                            value={memoVals.specialDayRate}
                            placeholder={memoVals.specialDayRate}
                          >
                            <option value="0">0x</option>
                            <option value="0.3">0.3x</option>
                            <option value="0.5">0.5x</option>
                            <option value="0.6">0.6x</option>
                            <option value="0.75">0.75x</option>

                            <option value="1">1x</option>
                            <option value="1.5">1.5x</option>
                            <option value="1.75">1.75x</option>
                            <option value="2">2x</option>
                            <option value="2.25">2.25x</option>
                            <option value="2.5">2.5x</option>
                            <option value="2.75">2.75x</option>
                            <option value="3">3x</option>
                            <option value="3.25">3.25x</option>
                            <option value="3.5">3.5x</option>
                            <option value="3.75">3.75x</option>
                            <option value="4">4x</option>
                          </select>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Submit button */}
                <div className="addSpecDayFooter">
                  <div
                    className={`${
                      wait
                        ? "submitAddSpecialDay waitBg"
                        : "submitAddSpecialDay"
                    } ${
                      dsValid && memoVals.specialDayName
                        ? ""
                        : "fadeSubmitAddSpecialDay"
                    }`}
                    onClick={() => {
                      axios
                        .post(
                          `${serverURL}/add-special-day`,
                          {
                            name: memoVals.specialDayName,
                            date: specialDateDateString,
                            rate: memoVals.specialDayRate,
                            companyClosed: memoVals.specialDayCompanyClosed,
                            closedPay: memoVals.payClose,
                          },

                          {
                            withCredentials: true,
                            credentials: "include",
                          }
                        )
                        .then((resposne) => {
                          if (resposne.data.specialDays) {
                            console.log("Success!");
                            axios
                              .get(`${serverURL}/get-special-days`, {
                                withCredentials: true,
                                credentials: "include",
                              })
                              .then((response) => {
                                if (!response.data.error) {
                                  let dataToAdd = [];
                                  response.data.specialDaysArray.forEach(
                                    (item) => {
                                      if (item.date) {
                                        dataToAdd.push({
                                          name: item.name,
                                          _id: item._id,
                                          date: item.date,
                                          rate: item.rate,
                                          companyClosed: item.companyClosed,
                                          closedPay: item.closedPay,
                                          tsForOrder:
                                            dateStringer.createTimestampFromString(
                                              item.date
                                            ),
                                        });
                                      }
                                    }
                                  );
                                  memoVals.setSpecialDaysData((x) => dataToAdd);
                                  memoVals.setShowAddSpecialDayModal(
                                    (x) => !memoVals.showAddSpecialDayModal
                                  );
                                  setWait(false);
                                }
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          } else {
                            console.log("fail");
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    }}
                  >
                    <p
                      onClick={() => {
                        setWait(!wait);
                      }}
                    >
                      Save
                    </p>
                  </div>
                  {/* close here */}
                  <p
                    className="closeAddSpecDayBtn"
                    onClick={() => {
                      memoVals.setShowAddSpecialDayModal((x) => false);
                    }}
                  >
                    Close
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {memoVals.sureDeleteSpecialDay ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            memoVals.setSureDeleteSpecialDay((x) => "");
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to delete this special day?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  deleteSpecialDay(memoVals.sureDeleteSpecialDay);
                  memoVals.setShowSpecialDayModal((x) => {
                    return { show: false };
                  });
                  memoVals.setSureDeleteSpecialDay((x) => "");
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  memoVals.setSureDeleteSpecialDay((x) => "");
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {memoVals.sureRemoveLeaveType ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            memoVals.setSureRemoveLeaveType((x) => "");
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to archive this leave type?
              <br />
              <br />
              Any existing leave periods will remain in place, but staff won't
              be able to request this leave type.
              <br />
              <br />
              You can re-instate this leave type at any time.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let original = memoVals.sureRemoveLeaveType;
                  memoVals.setSureRemoveLeaveType((x) => "");

                  axios
                    .post(
                      `${serverURL}/remove-custom-leave-type`,
                      {
                        leaveTypeID: memoVals.sureRemoveLeaveType,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setCustomLeaveTypes(
                          (x) => response.data.customLeaveTypes
                        );
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Custom leave type archived",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        memoVals.setShowLeaveTypeModal((x) => {
                          return { show: false };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  memoVals.setSureRemoveLeaveType((x) => "");
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {memoVals.sureRemoveZone ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            memoVals.setSureRemoveZone((x) => "");
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to delete this zone?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  // let original = memoVals.sureRemoveLeaveType;
                  memoVals.setSureRemoveZone((x) => "");

                  axios
                    .post(
                      `${serverURL}/add-gps-zone`,
                      {
                        removeID: memoVals.sureRemoveZone,
                      },
                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setGpsArray((x) => response.data.gpsArray);
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  memoVals.setSureRemoveZone((x) => "");
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {memoVals.sureRemoveAbsenceType ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();
            memoVals.setSureRemoveAbsenceType((x) => "");
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to archive this absence type?
              <br />
              <br />
              Any existing absences will remain in place, but managers won't be
              able to add this type of absence from now on.
              <br />
              <br />
              You can re-instate this absence type at any time.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  let original = memoVals.sureRemoveAbsenceType;
                  memoVals.setSureRemoveAbsenceType((x) => "");

                  axios
                    .post(
                      `${serverURL}/remove-custom-leave-type`,
                      {
                        absenceTypeID: memoVals.sureRemoveAbsenceType,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        memoVals.setAbsenceTypes(
                          (x) => response.data.absenceTypes
                        );
                        memoVals.setIndicate((x) => {
                          return {
                            show: true,
                            message: "Absence type archived",
                            colour: "blue",
                            duration: 4000,
                          };
                        });
                        setShowAbsenceTypeModal((x) => {
                          return { show: false };
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  memoVals.setSureRemoveAbsenceType((x) => "");
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {sureDeleteShiftLeaveType.show ? (
        <div
          className="addAbsenceModalUnderlay"
          onClick={(e) => {
            e.stopPropagation();

            setSureDeleteLeaveType({ show: false });
          }}
        >
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              Are you sure you want to delete this shift leave type,{" "}
              {sureDeleteShiftLeaveType.name}?
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="areYouSureModalYesBtn"
                onClick={() => {
                  axios
                    .post(
                      `${serverURL}/add-shift-leave-type`,
                      {
                        removeLeaveTypeID: sureDeleteShiftLeaveType.leaveTypeID,
                      },

                      {
                        withCredentials: true,
                        credentials: "include",
                      }
                    )
                    .then((response) => {
                      if (response.data.message === "success") {
                        setShiftLeaveTypes(response.data.shiftLeaveTypes);
                        setSureDeleteLeaveType({ show: false });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                    });
                }}
              >
                Yes
              </p>
              <p
                className="areYouModalNoBtn"
                onClick={() => {
                  setSureDeleteLeaveType({ show: false });
                }}
              >
                Cancel
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {showTerminalPopUp ? (
        <div
          className="editUserUnderlay"
          onClick={() => {
            setShowTerminalPopUp(false);
          }}
        >
          <div
            className={`overlapsRenewalModalBox termModalBox ${
              memoVals.mob ? "termModalBoxMob slideUp" : ""
            } ${memoVals.device.ios ? "paddingBottom35" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className={`terminalModalContent ${
                memoVals.mob ? "terminalModalContentMob" : ""
              }`}
            >
              <img
                src={terminalPreview}
                alt="FlowRota terminal"
                className={`termPreviewImg ${
                  memoVals.mob ? "termPreviewImgMob" : ""
                }`}
              />

              <p className="overlapsRenTxt">
                To allow staff to clock on using a clock terminal at a physical
                location, go to the address below in the web browser of a device
                connected to the internet.
                <br />
                <br />
                <span className="fadeHttps">https://</span>
                app.flowrota.com/terminal
                <br />
                <br />
              </p>
            </div>
            <p
              className="tempSavedCloseBtn"
              onClick={() => {
                setShowTerminalPopUp(false);
              }}
            >
              Close
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {memoVals.isAdminModal ? (
        <div className="addAbsenceModalUnderlay">
          <div className="formCreatedModal">
            <p className="overlapsRenTxt">
              {memoVals.isAdminModal} is a FlowRota admin with the ability to
              approve any requests, shift changes, and clock cards, including
              their own.
              <br />
              <br />
              You cannot disable {memoVals.isAdminModal} from approving their
              own items unless you remove their admin permissions in the{" "}
              <span
                onClick={() => {
                  memoVals.setIsAdminModal((x) => "");
                  memoVals.setAdminPage((x) => "Admins");
                }}
                // href={`${appURL}/admin/administrators`}
                className="cursorPointer colour00aaff"
              >
                Administrators{" "}
              </span>
              page.
            </p>

            <div className="areYouModalBtnsDiv">
              <p
                className="closeSwapsNoLongerBtn"
                onClick={() => {
                  memoVals.setIsAdminModal((x) => "");
                }}
              >
                Understood
              </p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Navbar />
      <MgrAccountNavbar />
    </Fragment>
  );
};

export default Admin;
